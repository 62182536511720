import React from 'react';
import { Label as FluentLabel, Icon } from '@fluentui/react';
import { concatStyleSets } from '@fluentui/react/lib/Styling';
import InfoIconTooltip from '../InfoIconTooltip/InfoIconTooltip';
import { LabelContainerStyled } from './Label.styles';
function Label(_a) {
    var description = _a.description, iconName = _a.iconName, label = _a.label, onClick = _a.onClick, _b = _a.required, required = _b === void 0 ? false : _b, styles = _a.styles;
    if (!label) {
        return null;
    }
    var labelStyes = concatStyleSets({ root: { marginLeft: 5 } }, styles === null || styles === void 0 ? void 0 : styles.fluentLabel);
    return (React.createElement(LabelContainerStyled, { onClick: onClick, style: styles === null || styles === void 0 ? void 0 : styles.container },
        React.createElement("div", { className: "icon-label-container" },
            React.createElement(Icon, { iconName: iconName, styles: { root: { marginTop: 9 } } }),
            React.createElement(FluentLabel, { styles: labelStyes, required: required }, label)),
        description && (React.createElement(InfoIconTooltip, { iconName: "Info", styles: { iconStyles: { root: { marginTop: 9 } } }, content: description }))));
}
export default Label;
