import React from 'react';
import { RouteFieldType } from '@evocom/types';
import { getFluentIconNameByFieldType } from '@evocom/utils';
import Label from '../Label/Label';
import ChoiceResultContent from './Components/ChoiceResultContent';
import DefaultResultContent from './Components/DefaultResultContent';
import NumberResultContent from './Components/NumberResultContent';
import RatingResultContent from './Components/RatingResultContent';
import { DynamicSurveyResultFieldStyled } from './DynamicSurveyResultField.styles';
function DynamicSurveyResultField(_a) {
    var fieldLink = _a.fieldLink, tenantId = _a.tenantId, getFileContents = _a.getFileContents;
    var fieldType = fieldLink.field.fieldType;
    var iconName = getFluentIconNameByFieldType(fieldType);
    function displayAsDefaultResult() {
        return (fieldType === RouteFieldType.SmallText ||
            fieldType === RouteFieldType.LongText ||
            fieldType === RouteFieldType.Scanner ||
            fieldType === RouteFieldType.DateTime ||
            fieldType === RouteFieldType.Boolean ||
            fieldType === RouteFieldType.Person ||
            fieldType === RouteFieldType.Hyperlink ||
            fieldType === RouteFieldType.Document ||
            fieldType === RouteFieldType.Signature ||
            fieldType === RouteFieldType.Multimedia);
    }
    function renderSurveyResultContent() {
        if (displayAsDefaultResult()) {
            return (React.createElement(DefaultResultContent, { tenantId: tenantId, getFileContents: getFileContents, fieldLink: fieldLink }));
        }
        if (fieldType === RouteFieldType.Number) {
            return React.createElement(NumberResultContent, { fieldLink: fieldLink });
        }
        if (fieldType === RouteFieldType.Choice) {
            return React.createElement(ChoiceResultContent, { fieldLink: fieldLink });
        }
        if (fieldType === RouteFieldType.Rating) {
            return React.createElement(RatingResultContent, { fieldLink: fieldLink });
        }
        return '';
    }
    return (React.createElement(DynamicSurveyResultFieldStyled, null,
        React.createElement(Label, { label: fieldLink.field.name, iconName: iconName, description: fieldLink.field.description }),
        React.createElement("div", { className: "c-survey-result-content-wrapper" }, renderSurveyResultContent())));
}
export default DynamicSurveyResultField;
