var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { concatStyleSets } from '@fluentui/react';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { Image } from '@fluentui/react/lib/Image';
import { Modal } from '@fluentui/react/lib/Modal';
import Files from '../FileManagement/FileManagement';
import Label from '../Label/Label';
import DocumentFieldStyled from './DocumentField.styles';
function DocumentField(_a) {
    var _this = this;
    var defaultValue = _a.defaultValue, description = _a.description, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.displayImagePreview, displayImagePreview = _c === void 0 ? false : _c, getFileContents = _a.getFileContents, label = _a.label, labelIconName = _a.labelIconName, onChange = _a.onChange, getFile = _a.getFile, _d = _a.required, required = _d === void 0 ? false : _d, styles = _a.styles;
    var t = useTranslation().t;
    var fileManagementRef = useRef(null);
    var _e = useState(false), initialized = _e[0], setInitialized = _e[1];
    var _f = useState(null), currentDocument = _f[0], setCurrentDocument = _f[1];
    var _g = useState(false), isImageModalOpen = _g[0], setIsImageModalOpen = _g[1];
    var _h = useState(defaultValue), initialDefaultValue = _h[0], setInitialDefaultValue = _h[1];
    var _j = useState(null), fileImageBlobUrl = _j[0], setFileImageBlobUrl = _j[1];
    var _k = useState(null), fileModalImageBlobUrl = _k[0], setFileModalImageBlobUrl = _k[1];
    var isImage = useCallback(function (text) {
        return /.png|.jpg|.jpeg|.gif|.tiff|.tif/i.test(text);
    }, []);
    useEffect(function () {
        return function () {
            if (fileImageBlobUrl) {
                window.URL.revokeObjectURL(fileImageBlobUrl);
            }
        };
    }, [fileImageBlobUrl]);
    useEffect(function () {
        var setImageBlobUrl = function () { return __awaiter(_this, void 0, void 0, function () {
            var fileContents, blob, blobUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(getFileContents && initialDefaultValue && initialDefaultValue.id)) return [3, 3];
                        return [4, getFileContents({ id: initialDefaultValue.id })];
                    case 1:
                        fileContents = _a.sent();
                        return [4, fileContents.blob()];
                    case 2:
                        blob = _a.sent();
                        blobUrl = URL.createObjectURL(blob);
                        setFileImageBlobUrl(blobUrl);
                        _a.label = 3;
                    case 3: return [2];
                }
            });
        }); };
        if (initialDefaultValue && !initialized) {
            if (isImage(initialDefaultValue === null || initialDefaultValue === void 0 ? void 0 : initialDefaultValue.text)) {
                setImageBlobUrl();
            }
            if (initialDefaultValue.file) {
                setCurrentDocument(initialDefaultValue.file);
            }
            setInitialized(true);
        }
    }, [initialDefaultValue, getFileContents, initialized, isImage]);
    function openFileChooser() {
        var _a;
        if ((_a = fileManagementRef.current) === null || _a === void 0 ? void 0 : _a.openFileChooser) {
            fileManagementRef.current.openFileChooser();
        }
    }
    function onActionButtonClick() {
        if (disabled) {
            downloadDocument();
        }
        else {
            openFileChooser();
        }
    }
    function onFileSelection(files) {
        var newFile = files[0] || null;
        setFileImageBlobUrl(null);
        setCurrentDocument(newFile);
        if (onChange)
            onChange(newFile);
    }
    function toggleImageModal() {
        return __awaiter(this, void 0, void 0, function () {
            var fileContents, blob, blobUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!isImageModalOpen && !currentDocument && getFileContents && initialDefaultValue)) return [3, 3];
                        return [4, getFileContents({ id: initialDefaultValue.id })];
                    case 1:
                        fileContents = _a.sent();
                        return [4, fileContents.blob()];
                    case 2:
                        blob = _a.sent();
                        blobUrl = URL.createObjectURL(blob);
                        setFileModalImageBlobUrl(blobUrl);
                        _a.label = 3;
                    case 3:
                        setIsImageModalOpen(function (prevState) { return !prevState; });
                        return [2];
                }
            });
        });
    }
    function getDownloadUrl() {
        var _a;
        if ((_a = currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.preview) === null || _a === void 0 ? void 0 : _a.url) {
            return currentDocument.preview.url;
        }
        return window.URL.createObjectURL(currentDocument);
    }
    function downloadDocument() {
        if (currentDocument) {
            var url = getDownloadUrl();
            var element = document.createElement('a');
            element.setAttribute('href', url);
            element.setAttribute('download', currentDocument.name);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        else if (initialDefaultValue && getFileContents) {
            var downloadLink_1 = document.createElement('a');
            getFileContents({ id: initialDefaultValue.id })
                .then(function (response) { return response.blob(); })
                .then(function (blob) { return URL.createObjectURL(blob); })
                .then(function (blobUrl) {
                downloadLink_1.href = blobUrl;
                downloadLink_1.download = initialDefaultValue.text;
                document.body.appendChild(downloadLink_1);
                downloadLink_1.click();
                URL.revokeObjectURL(blobUrl);
            });
        }
    }
    function getButtonMenuProps() {
        if (currentDocument || initialDefaultValue) {
            return {
                items: [
                    {
                        key: 'download',
                        text: t('documentField.button.menu.download'),
                        onClick: function () { return downloadDocument(); },
                        iconProps: { iconName: 'Download' }
                    },
                    {
                        key: 'replace',
                        disabled: disabled,
                        text: t('documentField.button.menu.replace'),
                        onClick: openFileChooser,
                        iconProps: { iconName: 'Upload' }
                    },
                    {
                        key: 'delete',
                        disabled: disabled,
                        text: t('documentField.button.menu.delete'),
                        onClick: function () {
                            setCurrentDocument(null);
                            setFileImageBlobUrl(null);
                            setInitialDefaultValue(null);
                            if (onChange)
                                onChange(null);
                        },
                        iconProps: { iconName: 'Delete' }
                    }
                ]
            };
        }
        return undefined;
    }
    function Button() {
        var _a;
        var customSplitButtonStyles = {
            flexContainer: {
                width: '100%'
            },
            rootHasMenu: {
                width: '100%'
            },
            splitButtonContainer: {
                width: '100%',
                'span:first-child': {
                    width: '100%'
                }
            },
            root: {
                width: '100%',
                paddingLeft: '5px',
                paddingRight: '5px',
                border: '1px solid #a19f9d',
                borderRadius: 3,
                ':hover': {
                    border: '1px solid black'
                }
            },
            textContainer: {
                display: 'inline',
                flexGrow: 0
            },
            splitButtonMenuButton: {
                border: '1px solid #a19f9d',
                borderRadius: 3,
                ':hover': {
                    border: '1px solid black'
                }
            }
        };
        var actionButtonStyles = concatStyleSets(customSplitButtonStyles, styles === null || styles === void 0 ? void 0 : styles.actionButtonStyles);
        var menuProps = getButtonMenuProps();
        var buttonText = currentDocument || initialDefaultValue
            ? (currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.name) || (initialDefaultValue === null || initialDefaultValue === void 0 ? void 0 : initialDefaultValue.text)
            : t('documentField.button.text.upload');
        var title = currentDocument
            ? t('documentField.button.title.download')
            : t('documentField.button.title.upload');
        return (React.createElement("div", { style: {
                width: '100%',
                paddingLeft: ((_a = currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.preview) === null || _a === void 0 ? void 0 : _a.url) || fileImageBlobUrl ? 10 : 0
            } },
            React.createElement(DefaultButton, { split: true, menuProps: menuProps, disabled: disabled && !initialDefaultValue, onClick: onActionButtonClick, text: buttonText, title: title, styles: actionButtonStyles, iconProps: {
                    iconName: currentDocument ? 'Download' : 'Attach',
                    styles: {
                        root: { fontSize: currentDocument ? '14px' : '16px' }
                    }
                } })));
    }
    function ImagePreview() {
        var _a, _b;
        if ((((_a = currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.preview) === null || _a === void 0 ? void 0 : _a.url) || fileImageBlobUrl) && displayImagePreview) {
            return (React.createElement("img", { "aria-hidden": "true", alt: "img", className: "document-image-preview", src: ((_b = currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.preview) === null || _b === void 0 ? void 0 : _b.url) || fileImageBlobUrl || undefined, onClick: toggleImageModal }));
        }
        return null;
    }
    function ImagePreviewCloseButton() {
        var ImagePreviewCloseButtonStyles = {
            root: {
                backgroundColor: 'rgb(207, 207, 207, 0.4)',
                color: 'rgb(50, 49, 48);',
                marginRight: 15,
                marginTop: 10,
                position: 'absolute',
                right: 0
            },
            rootHovered: { backgroundColor: 'rgb(207, 207, 207, 0.6)', color: 'rgb(32, 31, 30)' },
            rootPressed: { backgroundColor: 'rgb(189, 189, 189, 0.6)', color: 'rgb(32, 31, 30)' }
        };
        return (React.createElement(IconButton, { iconProps: { iconName: 'Cancel' }, onClick: toggleImageModal, styles: ImagePreviewCloseButtonStyles }));
    }
    function ModalImage() {
        var _a, _b;
        if (((_a = currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.preview) === null || _a === void 0 ? void 0 : _a.url) || fileModalImageBlobUrl) {
            return (React.createElement("div", { style: { overflow: 'auto', maxHeight: '80vh', maxWidth: '80vh' } },
                React.createElement(ImagePreviewCloseButton, null),
                React.createElement(Image, { shouldFadeIn: false, src: ((_b = currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.preview) === null || _b === void 0 ? void 0 : _b.url) || fileModalImageBlobUrl, styles: { image: { width: '100%', height: '100%', objectFit: 'cover' } } })));
        }
        return null;
    }
    return (React.createElement(DocumentFieldStyled, null,
        React.createElement(Files, { ref: fileManagementRef, clickable: false, multiple: false, onChange: onFileSelection },
            React.createElement(Label, { label: label, required: required, iconName: labelIconName, description: description }),
            React.createElement("div", { style: { display: 'flex', width: '100%' } },
                React.createElement(ImagePreview, null),
                React.createElement(Button, null))),
        React.createElement(Modal, { isOpen: isImageModalOpen, onDismiss: toggleImageModal, isBlocking: false, styles: { scrollableContent: { overflow: 'hidden' } } },
            React.createElement(ModalImage, null))));
}
export default DocumentField;
