var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment';
import React from 'react';
import { RouteFieldType } from '@evocom/types';
import { Persona, PersonaSize } from '@fluentui/react';
import BooleanField from '../../BooleanField/BooleanField';
import DocumentField from '../../DocumentField/DocumentField';
import HyperLinkField from '../../HyperLinkField/HyperLinkField';
import MultiMediaField from '../../MultiMediaField/MultiMediaField';
import Rating from '../../Rating/Rating';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import SignatureField from '../../SignatureField/SignatureField';
import SurveyResultDefaultValueWrapper from './SurveyResultDefaultValueWrapper';
function TextResultContent(_a) {
    var fieldLink = _a.fieldLink, tenantId = _a.tenantId, getFileContents = _a.getFileContents;
    var values = fieldLink.values, field = fieldLink.field;
    var fieldType = field.fieldType;
    var defaultValueToDisplay = React.createElement("div", null, "-");
    function renderSmallTextContent(textFieldResultProps) {
        if (typeof textFieldResultProps.value === 'string') {
            return React.createElement("div", null, textFieldResultProps.value);
        }
        return defaultValueToDisplay;
    }
    function isLongTextFieldWithAppendChanges(valueToCheck) {
        return !!valueToCheck.currentValue;
    }
    function renderLongTextContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        var valueToDisplay;
        if (value && isLongTextFieldWithAppendChanges(value)) {
            valueToDisplay = value === null || value === void 0 ? void 0 : value.currentValue;
        }
        else {
            valueToDisplay = value;
        }
        if (typeof valueToDisplay === 'string') {
            return (React.createElement(RichTextEditor, { styles: { editorWrapper: { padding: 0 } }, defaultValue: valueToDisplay, disabled: true, required: false }));
        }
        return defaultValueToDisplay;
    }
    function renderDateTimeContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        var valueType = typeof value;
        if (value && (valueType === 'string' || value instanceof Date)) {
            if ((field === null || field === void 0 ? void 0 : field.dateFormat) === 1) {
                return moment(value).format('LL');
            }
            if ((field === null || field === void 0 ? void 0 : field.dateFormat) === 2) {
                return moment(value).format('LLLL');
            }
            return defaultValueToDisplay;
        }
        return defaultValueToDisplay;
    }
    function renderBooleanFieldContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        return (React.createElement("div", { style: { marginTop: 5 } },
            React.createElement(BooleanField, { defaultValue: value, textOk: field.textOk, textNOk: field.textNOk, disabled: true, styles: {
                    positiveButton: { root: { height: '25px' } },
                    negativeButton: { root: { height: '25px' } }
                } })));
    }
    function renderPersonFieldContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        var styles = {
            marginTop: '5px',
            border: '1px solid #d5d5d5',
            borderRadius: '21px',
            maxWidth: 'fit-content',
            padding: '4px',
            paddingLeft: '4px',
            background: '#fdfafa'
        };
        if (value) {
            return (React.createElement("div", { style: styles },
                React.createElement(Persona, { size: PersonaSize.size32, imageUrl: value.pictureUrl, text: value.name || '', showSecondaryText: true, secondaryText: value.rolename || value.email, styles: { root: { maxWidth: 'fit-content' } } })));
        }
        return defaultValueToDisplay;
    }
    function renderHyperlinkFieldContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        if (value) {
            return (React.createElement(HyperLinkField, { styles: {
                    linkWrapperStyles: {
                        backgroundColor: 'transparent',
                        padding: 3,
                        border: 'none'
                    }
                }, defaultValue: value, disabled: true }));
        }
        return defaultValueToDisplay;
    }
    function renderDocumentFieldContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        if (value) {
            var fileIdIndex = value.url.indexOf('id=') + 3;
            var id = value.url.substr(fileIdIndex);
            var defaultValue = __assign(__assign({}, value), { id: id });
            return (React.createElement("div", { style: { marginTop: 6 } },
                React.createElement(DocumentField, { disabled: true, displayImagePreview: true, getFileContents: getFileContents, defaultValue: defaultValue })));
        }
        return defaultValueToDisplay;
    }
    function renderSignatureFieldContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        if (value) {
            return (React.createElement("div", { style: { marginTop: 6 } },
                React.createElement(SignatureField, { defaultValue: value, disabled: true })));
        }
        return defaultValueToDisplay;
    }
    function renderMultiMediaFieldContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        if (value && getFileContents) {
            var defaultValue = value === null || value === void 0 ? void 0 : value.map(function (fileValue) {
                var _a;
                if (fileValue.url &&
                    fileValue.url !== 'https://tempuri.org' &&
                    !((_a = fileValue.url) === null || _a === void 0 ? void 0 : _a.includes('microsoftstream.com'))) {
                    var fileIdIndex = fileValue.url.indexOf('id=') + 3;
                    var id = fileValue.url.substr(fileIdIndex);
                    return __assign(__assign({}, fileValue), { id: id });
                }
                return __assign({}, fileValue);
            });
            return (React.createElement("div", { style: { marginTop: 6 } },
                React.createElement(MultiMediaField, { defaultValue: defaultValue, tenantId: tenantId, getFileContents: getFileContents, disabled: true })));
        }
        return defaultValueToDisplay;
    }
    function renderRatingFieldContent(textFieldResultProps) {
        var value = textFieldResultProps.value;
        if (value) {
            return (React.createElement(Rating, { styles: {
                    root: {
                        marginTop: 0,
                        border: 'none',
                        backgroundColor: 'none',
                        borderRadius: '0px',
                        ':hover': { border: 'none' }
                    }
                }, defaultValue: value, disabled: true, maxRating: field.numStars }));
        }
        return defaultValueToDisplay;
    }
    function renderNumberFieldContent(textFieldResultProps) {
        if (typeof textFieldResultProps.value === 'string' ||
            typeof textFieldResultProps.value === 'number') {
            return React.createElement("div", null, textFieldResultProps.value);
        }
        return defaultValueToDisplay;
    }
    function renderChoiceFieldContent(textFieldResultProps) {
        if (Array.isArray(textFieldResultProps.value) && textFieldResultProps.aggregation !== 8) {
            return (React.createElement("div", null, textFieldResultProps.value.map(function (v) { return (React.createElement(React.Fragment, null,
                v.value ? "- " + v.value : '-',
                React.createElement("br", null))); })));
        }
        return defaultValueToDisplay;
    }
    function onRenderContent(textFieldResultProps) {
        if (fieldType === RouteFieldType.SmallText || fieldType === RouteFieldType.Scanner) {
            return renderSmallTextContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.LongText) {
            return renderLongTextContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.DateTime) {
            return renderDateTimeContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Boolean) {
            return renderBooleanFieldContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Person) {
            return renderPersonFieldContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Hyperlink) {
            return renderHyperlinkFieldContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Document) {
            return renderDocumentFieldContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Signature) {
            return renderSignatureFieldContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Multimedia) {
            return renderMultiMediaFieldContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Rating) {
            return renderRatingFieldContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Number) {
            return renderNumberFieldContent(textFieldResultProps);
        }
        if (fieldType === RouteFieldType.Choice) {
            return renderChoiceFieldContent(textFieldResultProps);
        }
        return defaultValueToDisplay;
    }
    function renderTextFieldValue(textFieldResultProps, index) {
        var _a;
        var valuesLength = (_a = fieldLink.values) === null || _a === void 0 ? void 0 : _a.length;
        if (textFieldResultProps.aggregation === 1) {
            return (React.createElement("div", null,
                React.createElement(SurveyResultDefaultValueWrapper, { fieldResultProps: textFieldResultProps, onRenderContent: onRenderContent, renderBottomBorder: index + 1 !== valuesLength })));
        }
        return null;
    }
    if (values && Array.isArray(values)) {
        return React.createElement("div", null, values.map(renderTextFieldValue));
    }
    return null;
}
export default TextResultContent;
