import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Callout } from '@fluentui/react';
import { ActionButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { userType } from '../../types';
import { checkScreenWidth } from '../../utils/helpers';
import { Devider, HeaderButtonsRowStyled } from './HeaderButtonsRow.styled';
import HeaderCmdBarButton from './HeaderCmdBarButton';

function HeaderButtonsRow({
  currentUser,
  tenants,
  styles,
  logout,
  unreadNotifications,
  onToggleNotifications,
  onToggleReleaseNotes
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const currentTenantId = window.localStorage.getItem('tenantId');
  const currentVersion = JSON.parse(window.localStorage.getItem('version'));
  const [tenantSearchTerm, setTenantSearchTerm] = useState('');

  const fontColor = styles?.color;

  function getPersonaButton() {
    return (
      <DefaultButton
        styles={{
          root: {
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: '0px',
            height: '100%',
            marginLeft: '7px',
            marginRight: '7px',
            paddingBottom: '6px',
            paddingLeft: '18px',
            paddingRight: '7px',
            selectors: {
              '@media (max-width: 450px)': {
                marginLeft: '0px',
                marginRight: '0px',
                paddingLeft: '12px',
                paddingRight: '5px'
              }
            }
          },
          rootHovered: {
            backgroundColor: 'rgba(255,255,255,0.1)',
            selectors: {
              '@media (max-width: 450px)': {
                backgroundColor: 'transparent'
              }
            }
          },
          rootPressed: {
            backgroundColor: 'rgba(255,255,255,0.1)',
            selectors: {
              '@media (max-width: 450px)': {
                backgroundColor: 'transparent'
              }
            }
          }
        }}
        text={getPersona()}
        onClick={() => setIsCalloutVisible((prevState) => !prevState)}
      />
    );
  }

  function onTenantSelect(tenantId) {
    if (tenantId && currentTenantId !== tenantId) {
      window.localStorage.setItem('tenantId', tenantId);
      window.location.href = '/tasks/my-open';
    } else {
      setIsCalloutVisible(false);
    }
  }

  function getCurrentTenantName() {
    if (tenants.length > 0) {
      const currentTenant = tenants.find((tenant) => currentTenantId === tenant.tenantId);

      return currentTenant?.name;
    }

    return null;
  }

  let tenantsList = [...tenants];

  if (tenantSearchTerm) {
    tenantsList = tenants.filter((tenant) =>
      tenant.name.toLowerCase().includes(tenantSearchTerm.toLowerCase())
    );
  }

  function getCalloutConent() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Persona
            hidePersonaDetails={false}
            imageUrl={currentUser.pictureUrl}
            secondaryText={currentUser.login}
            showSecondaryText={true}
            size={PersonaSize.size48}
            styles={{ root: { padding: '20px', marginTop: '20px', marginBottom: '20px' } }}
            text={currentUser.name}
          />
          <ActionButton
            styles={{
              label: { fontSize: '14px' },
              root: { marginTop: '13px', marginRight: '5px', border: 'none' }
            }}
            onClick={logout}
          >
            Logout
          </ActionButton>
        </div>
        <div style={{ maxHeight: 500, overflow: 'auto' }}>
          {tenants.length > 10 && (
            <SearchBox
              placeholder={t('searchBar.placeholder')}
              autoComplete="off"
              underlined
              styles={{ root: { margin: 10, marginTop: 0 } }}
              onClear={() => setTenantSearchTerm(null)}
              onChange={(_, value) => setTenantSearchTerm(value)}
              defaultValue={tenantSearchTerm}
              onSearch={setTenantSearchTerm}
            />
          )}
          {tenants &&
            tenantsList.map((tenant) => (
              <DefaultButton
                key={tenant.tenantId}
                onClick={() => onTenantSelect(tenant.tenantId)}
                styles={{
                  root: {
                    border: 'none',
                    borderRadius: '0px',
                    borderTop: '1px solid #eaeaea',
                    height: '45px',
                    width: '100%'
                  }
                }}
              >
                {tenant.name}
              </DefaultButton>
            ))}
        </div>
      </div>
    );
  }

  function getPersona() {
    return (
      <Persona
        hidePersonaDetails={checkScreenWidth('extraSmall', 'small')}
        imageUrl={currentUser.pictureUrl}
        secondaryText={getCurrentTenantName()}
        showSecondaryText={tenants && tenants.length > 1}
        size={PersonaSize.size32}
        styles={{
          secondaryText: { color: fontColor || 'white' },
          primaryText: {
            color: fontColor || 'white',
            height: '16px',
            selectors: { '&:hover': { color: fontColor || 'white' } }
          },
          root: { paddingTop: '5px' }
        }}
        text={currentUser.name}
      />
    );
  }

  function showNotificationsPanel() {
    onToggleNotifications();
  }

  function showReleaseNotesPanel() {
    if (currentVersion?.new) {
      window.localStorage.setItem('version', JSON.stringify({ ...currentVersion, new: false }));
    }

    onToggleReleaseNotes();
  }

  return (
    <HeaderButtonsRowStyled>
      <Devider backgroundColor={styles?.deviderColor} />
      <HeaderCmdBarButton
        displayDevider={true}
        iconName="Info"
        onClickMethod={showReleaseNotesPanel}
        styles={styles?.headerCmdBarButton}
        title={t('header.iconbutton.releaseNotes')}
        badgeIcon={currentUser?.isAdmin && currentVersion?.new ? 'CRMCustomerInsightsApp' : null}
      />
      <Devider backgroundColor={styles?.deviderColor} />
      <HeaderCmdBarButton
        displayDevider={true}
        iconName={unreadNotifications > 0 ? 'RingerSolid' : 'Ringer'}
        onClickMethod={showNotificationsPanel}
        styles={styles?.headerCmdBarButton}
        title={t('header.iconbutton.notifications')}
        badgeText={unreadNotifications > 0 ? unreadNotifications.toString() : null}
      />
      {currentUser?.isAdmin && (
        <>
          <Devider backgroundColor={styles?.deviderColor} />
          <HeaderCmdBarButton
            iconName="Settings"
            onClickMethod={() => history.push(`/administration`)}
            styles={styles?.headerCmdBarButton}
            title={t('header.iconbutton.settings')}
          />
        </>
      )}
      <Devider backgroundColor={styles?.deviderColor} />
      <HeaderCmdBarButton
        displayDevider={true}
        iconName="Help"
        onClickMethod={() => {
          window.open(t('header.iconbutton.helpHref'), '_blank').focus();
        }}
        styles={styles?.headerCmdBarButton}
        title={t('header.iconbutton.help')}
      />
      <Devider backgroundColor={styles?.deviderColor} />
      <div className="c-profile-persona">{getPersonaButton()}</div>
      <Callout
        gapSpace={0}
        styles={{
          root: {
            maxWidth: '500px',
            selectors: {
              '@media (max-width: 450px)': {
                width: '100%',
                maxWidth: '100%',
                left: '0 !important'
              }
            }
          }
        }}
        target=".c-profile-persona"
        onDismiss={() => setIsCalloutVisible(false)}
        hidden={!isCalloutVisible}
        directionalHint={6}
        calloutWidth={450}
        preventDismissOnScroll={true}
        isBeakVisible={false}
      >
        {getCalloutConent()}
      </Callout>
    </HeaderButtonsRowStyled>
  );
}

HeaderButtonsRow.propTypes = {
  currentUser: userType.isRequired,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, tenantId: PropTypes.string })
  ).isRequired,
  styles: PropTypes.objectOf(PropTypes.any),
  logout: PropTypes.func.isRequired,
  unreadNotifications: PropTypes.number.isRequired,
  onToggleNotifications: PropTypes.func.isRequired,
  onToggleReleaseNotes: PropTypes.func.isRequired
};

HeaderButtonsRow.defaultProps = {
  styles: null
};

export default HeaderButtonsRow;
