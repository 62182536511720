var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export function getTextFieldStyles(_a) {
    var disabled = _a.disabled;
    return {
        fieldGroup: [{ border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }],
        wrapper: disabled ? { border: '1px solid #a19f9d', borderRadius: 3 } : undefined,
        field: { color: '#323130' }
    };
}
export var DynamicFieldSectionStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 12px;\n"], ["\n  margin-top: 12px;\n"])));
var templateObject_1;
