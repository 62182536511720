import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextField } from '@fluentui/react';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import * as microsoftTeams from '@microsoft/teams-js';
import AppContext from '../../AppContext';

const MsTeamsConfigStyled = styled.div`
  margin-left: 5px;

  .ms-TextField-field {
    font-size: 14px;
  }

  .ms-DetailsHeader {
    padding-top: 0;
  }
`;

function MsTeamsConfig() {
  const { t } = useTranslation();

  const [config, setConfig] = useState({
    team: null,
    selectedTenant: null,
    tabName: 'Evocom',
    showTasks: true,
    showProcesses: true,
    showProjects: true
  });
  const [initialized, setInitialized] = useState(false);
  const [showTextField, setShowTextField] = useState(true);

  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentUser } = globalAppState;

  useEffect(() => {
    microsoftTeams.settings.getSettings((settings) => {
      const { entityId } = settings;

      if (entityId) {
        const hasEntityDevider = entityId.includes('/');

        if (hasEntityDevider) {
          const showTasks = entityId.split('/')[0] === '1';
          const showProcesses = entityId.split('/')[1] === '1';
          const showProjects = entityId.split('/')[2] === '1';

          setConfig((prevState) => ({
            ...prevState,
            showTasks,
            showProcesses,
            showProjects
          }));
        }

        setShowTextField(false);
      }

      setInitialized(true);
    });
  }, []);

  useEffect(() => {
    if (config.tabName && (config.showTasks || config.showProcesses || config.showProjects)) {
      microsoftTeams.settings.setValidityState(true);

      const url = `${window.location.origin}/${
        config.showTasks ? 'tasks/my-open' : 'routes/my/templates'
      }`;

      microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
        microsoftTeams.settings.setSettings({
          websiteUrl: url,
          contentUrl: url,
          entityId: `${config.showTasks ? 1 : 0}/${config.showProcesses ? 1 : 0}/${
            config.showProjects ? 1 : 0
          }`,
          suggestedDisplayName: config.tabName
        });

        saveEvent.notifySuccess();
      });
    } else {
      microsoftTeams.settings.setValidityState(false);
    }
  }, [config]);

  if (!currentUser) {
    return t('msTeams.config.userNotFound');
  }

  if (!initialized) {
    return null;
  }

  return (
    <MsTeamsConfigStyled>
      {showTextField && (
        <TextField
          styles={{
            root: { marginBottom: '15px', maxWidth: '300px' }
          }}
          required
          label={t('msTeams.config.label.tabname')}
          defaultValue={config.tabName}
          onChange={(_, value) => setConfig((prevState) => ({ ...prevState, tabName: value }))}
        />
      )}

      <div style={{ marginBottom: '8px', fontWeight: 600 }}>
        {t('msTeams.config.checkBox.header')}
      </div>

      <Checkbox
        label={
          <div>
            <span style={{ fontWeight: 600 }}>{t('msTeams.config.checkBox.label.tasks')}</span>
            <div style={{ maxWidth: 500 }}>{t('msTeams.config.checkBox.subtext.tasks')}</div>
          </div>
        }
        checked={config.showTasks}
        onChange={(_, checked) => setConfig((prevState) => ({ ...prevState, showTasks: checked }))}
      />

      <Checkbox
        label={
          <div>
            <span style={{ fontWeight: 600 }}>{t('msTeams.config.checkBox.label.processes')}</span>
            <div style={{ maxWidth: 500 }}>{t('msTeams.config.checkBox.subtext.processes')}</div>
          </div>
        }
        checked={config.showProcesses}
        styles={{ root: { marginTop: '15px' } }}
        onChange={(_, checked) =>
          setConfig((prevState) => ({ ...prevState, showProcesses: checked }))
        }
      />
      <Checkbox
        label={
          <div>
            <span style={{ fontWeight: 600 }}>{t('msTeams.config.checkBox.label.projects')}</span>
            <div style={{ maxWidth: 500 }}>{t('msTeams.config.checkBox.subtext.projects')}</div>
          </div>
        }
        checked={config.showProjects}
        styles={{ root: { marginTop: '15px' } }}
        onChange={(_, checked) =>
          setConfig((prevState) => ({ ...prevState, showProjects: checked }))
        }
      />
    </MsTeamsConfigStyled>
  );
}

export default MsTeamsConfig;
