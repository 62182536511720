// dev environment
const dev = {
  API_URL: 'https://ui-dev-eps.azurewebsites.net/api',
  // API_URL: 'https://staging-eps.azurewebsites.net/api',
  // API_URL: 'https://productivity.evocom.net/api',
  VERSION: process.env.REACT_APP_VERSION,
  DEV_MODE: true
};

// production environment
const prod = {
  API_URL: '/api',
  VERSION: process.env.REACT_APP_VERSION,
  DEV_MODE: false
};

export default process.env.REACT_APP_STAGE === 'prod' ? prod : dev;
