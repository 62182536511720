export var breakpoints = {
    extraLarge: 1281,
    largeMax: 1280,
    largeMin: 1025,
    mediumMax: 1024,
    mediumMin: 768,
    smallMax: 767,
    smallMin: 481,
    extraSmallMax: 480,
    extraSmallMin: 320
};
