import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DefaultButton } from '@fluentui/react/lib/Button';
import breakpoints from '../../utils/breakpoints';

const AdministrationBarStyled = styled.div`
  z-index: 100;
  bottom: 0px;
  margin-top: 10px;
  max-width: 250px;
  min-width: 250px;
  position: absolute;

  @media (max-width: ${breakpoints.smallMax}px) {
    min-width: 100%;
    width: 100%;
  }
`;

function AdministrationBar({ toggleSideBar }) {
  const { t } = useTranslation();

  const history = useHistory();

  function openAdministration(event, history) {
    event.preventDefault();
    history.push(`/administration`);
    if (toggleSideBar) {
      toggleSideBar();
    }
  }

  return (
    <AdministrationBarStyled>
      <div
        style={{
          backgroundColor: '#eaeaea',
          height: '1px',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '93%'
        }}
      />
      <DefaultButton
        iconProps={{ iconName: 'Settings' }}
        styles={{
          root: {
            border: 'none',
            borderRight: '1px solid #eaeaea',
            height: '45px',
            paddingLeft: '6px',
            width: '100%'
          },
          label: { marginLeft: '3px', float: 'left', fontWeight: 400 }
        }}
        onClick={(event) => openAdministration(event, history)}
        text={t('sideBar.administration')}
      />
    </AdministrationBarStyled>
  );
}

AdministrationBar.propTypes = {
  toggleSideBar: PropTypes.func
};

AdministrationBar.defaultProps = {
  toggleSideBar: null
};

export default AdministrationBar;
