import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { AnimationStyles } from '@fluentui/react/lib';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Icon } from '@fluentui/react/lib/Icon';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { TextField } from '@fluentui/react/lib/TextField';
import { useTrackEvent } from '@microsoft/applicationinsights-react-js';
import * as microsoftTeams from '@microsoft/teams-js';
import { reactPlugin as applicationInsightsReactPlugin } from '../../AppInsights/AppInsights';
import fetchRequest from '../../services/api';
import { isInIframe } from '../../utils/helpers';
import { evocomBeeLogo } from '../App/components/Layout/Icons';
import SupportChoiceGroup from './SupportChoiceGroup';

// styles
const SubtitleRowStyled = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 3px;

  .arrow-icon {
    font-size: 14px;
    margin-right: 6px;
    margin-top: 5px;
  }
`;

function WelcomePage({ defaultAuthError, defaultLoginHint, login }) {
  const { t } = useTranslation(null, { useSuspense: false });

  const trackError = useTrackEvent(applicationInsightsReactPlugin, 'WPError');

  const [authError, setAuthError] = useState();
  const [checkResult, setCheckResult] = useState(null);
  const [eMail, setEMail] = useState(null);
  const [isSubmissionDisabled, setIsSubmissionDisabled] = useState(true);
  const [messageBars, setMessageBars] = useState([]);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState({
    checked: false,
    submitted: false
  });

  microsoftTeams.initialize();

  const msTeamsEnvironment = window.sessionStorage.getItem('msTeamsEnvironment');
  const isMsConfigPage = window.location.href.includes('teams-config');

  const resetMessageBars = useCallback(() => {
    if (authError) {
      setAuthError(null);
    }

    if (messageBars.length) {
      setMessageBars([]);
    }

    if (checkResult) {
      setCheckResult(null);
    }
  }, [authError, checkResult, messageBars.length]);

  const trackAIError = useCallback(
    (dataPayload) => {
      if (applicationInsightsReactPlugin) {
        trackError(dataPayload);
      }
    },
    [trackError]
  );

  useEffect(() => {
    if (defaultLoginHint) {
      setEMail(defaultLoginHint);
    }
  }, [defaultLoginHint]);

  useEffect(() => {
    try {
      setAuthError(defaultAuthError);

      const loginEmail = window.localStorage.getItem('loginEmail');
      const errorToLog =
        defaultAuthError &&
        typeof defaultAuthError === 'string' &&
        !defaultAuthError.includes('login is already in progress')
          ? defaultAuthError
          : null;

      const shouldLogError = errorToLog && loginEmail && !checkResult;

      if (shouldLogError) {
        trackAIError({ email: loginEmail, error: errorToLog });
        const body = JSON.stringify({ email: loginEmail, error: errorToLog });
        const url = `Init/FailedLogin?clientId=default`;

        fetchRequest({ url, body, method: 'POST', anonymousCall: true }).then(() => {
          // remove eMail for login error logging
          localStorage.removeItem('loginEmail');
        });
      }
    } catch (error) {
      trackAIError({ error });
    }
  }, [defaultAuthError, checkResult, trackAIError]);

  useEffect(() => {
    setIsSubmissionDisabled(!termsAndConditionsChecked?.checked || !eMail);
  }, [termsAndConditionsChecked?.checked, eMail]);

  useEffect(() => {
    // useEffect to set warning messageBars
    const userCancelledFlow =
      authError && (authError === 'User cancelled the flow.' || authError === 'CancelledByUser');

    if ((!checkResult || (checkResult && msTeamsEnvironment)) && userCancelledFlow) {
      setMessageBars(() => [
        {
          key: 'userCancelledAuthWarning',
          animationStyles: AnimationStyles.slideDownIn20,
          isMultiline: true,
          messageBarType: MessageBarType.warning,
          content: <SupportChoiceGroup resetMessageBars={resetMessageBars} />
        }
      ]);

      setAuthError(null);
    }
    const authAlreadyInProgress = authError && authError.includes('Login_In_Progress');

    if (checkResult && authAlreadyInProgress) {
      setMessageBars(() => [
        {
          key: 'authAlreadyInProgress',
          animationStyles: AnimationStyles.slideDownIn20,
          isMultiline: true,
          messageBarType: MessageBarType.warning,
          content: t('welcomePage.messageBar.loginInProgress')
        }
      ]);

      setAuthError(null);
    }
  }, [t, checkResult, authError, resetMessageBars, msTeamsEnvironment]);

  function onTextFieldChange(value) {
    resetMessageBars();

    if (showLoginButton) {
      setShowLoginButton(false);
    }

    setEMail(value || null);
  }

  function getLoadingSpinnerMessageBarContent(text) {
    return (
      <div stlye={{ marginLeft: '5px' }}>
        <Spinner
          size={SpinnerSize.small}
          styles={{ label: { color: 'rgb(50, 49, 48)' } }}
          labelPosition="right"
          label={text}
        />
      </div>
    );
  }

  function scrollDown(amount) {
    window.scrollBy(0, amount);
  }

  function setTimeShiftedMessagesBars(checkEmailResult) {
    const { epUser, domainNotAllowed, tenant, userIsLocked } = checkEmailResult;

    let timeOut = 700;
    if (isMsConfigPage) {
      scrollDown(100);
    }

    if (!domainNotAllowed && !tenant && !epUser) {
      setTimeout(() => {
        setMessageBars((prevState) => {
          const prevStateClone = [...prevState];
          const content = t('welcomePage.messageBar.noTenantFound');

          if (prevStateClone.length === 1) {
            prevStateClone[0].content = content;
            prevStateClone[0].messageBarType = MessageBarType.warning;
            prevStateClone[0].isMultiline = true;
            prevStateClone[0].key = 'noTenantFound';
          }

          return prevStateClone;
        });
      }, timeOut);

      return null;
    }

    if (userIsLocked) {
      setTimeout(() => {
        setMessageBars((prevState) => {
          const prevStateClone = [...prevState];
          const content = t('welcomePage.messageBar.userIsLocked');

          if (prevStateClone.length === 1) {
            prevStateClone[0].content = content;
            prevStateClone[0].messageBarType = MessageBarType.warning;
            prevStateClone[0].isMultiline = true;
            prevStateClone[0].key = 'userIsLocked';
          }

          return prevStateClone;
        });
      }, timeOut);

      return null;
    }

    if (domainNotAllowed) {
      setTimeout(() => {
        setMessageBars((prevState) => {
          const prevStateClone = [...prevState];
          const content = t('welcomePage.messageBar.domainNotAllowed');

          if (prevStateClone.length === 1) {
            prevStateClone[0].content = content;
            prevStateClone[0].messageBarType = MessageBarType.warning;
            prevStateClone[0].isMultiline = true;
            prevStateClone[0].key = 'domainNotAllowed';
          }

          return prevStateClone;
        });
      }, timeOut);

      return null;
    }

    setTimeout(() => {
      setMessageBars((prevState) => {
        const prevStateClone = [...prevState];
        prevStateClone[0].messageBarType = MessageBarType.success;
        prevStateClone[0].content = (
          <div>
            <div>{t('welcomePage.messageBar.foundMSTenant')}</div>
            <div>Name: {tenant.name}</div>
            <div>ID: {tenant.tenantId}</div>
          </div>
        );

        prevStateClone.push({
          key: 'searchCompany',
          animationStyles: AnimationStyles.slideDownIn20,
          isMultiline: false,
          messageBarType: MessageBarType.info,
          content: getLoadingSpinnerMessageBarContent(
            t('welcomePage.messageBar.searchEvocomTenant')
          )
        });

        return prevStateClone;
      });

      if (isMsConfigPage) {
        scrollDown(100);
      }
    }, timeOut);

    timeOut += 700;

    setTimeout(() => {
      setMessageBars((prevState) => {
        const prevStateClone = [...prevState];
        prevStateClone[1].messageBarType = MessageBarType.success;
        prevStateClone[1].content = (
          <div>
            <div>Evocom {t('welcomePage.messageBar.tenant')}</div>
            <div>Name: {tenant.name}</div>
          </div>
        );

        return prevStateClone;
      });

      if (isMsConfigPage) {
        scrollDown(100);
      }
    }, timeOut);

    timeOut += 500;
    setTimeout(() => {
      setMessageBars((prevState) => {
        const prevStateClone = [...prevState];

        prevStateClone.push({
          key: 'preparedEP',
          animationStyles: AnimationStyles.slideDownIn20,
          isMultiline: true,
          messageBarType: MessageBarType.success,
          content: t('welcomePage.messageBar.tenantReady')
        });

        return prevStateClone;
      });

      if (isMsConfigPage) {
        scrollDown(100);
      }
    }, timeOut);

    timeOut += 400;

    setTimeout(() => {
      setShowLoginButton(true);

      if (isMsConfigPage) {
        scrollDown(100);
      }
    }, timeOut);

    return null;
  }

  function setEpMsalAuthStartedCookie() {
    // max age = 3 minutes
    document.cookie = 'epMsalAuthStarted=true;max-age=180';
  }

  function setLoginHintAndStartMsalLogin() {
    if (msTeamsEnvironment) {
      window.localStorage.setItem('msTeamsLoginHint', eMail);
      onOpenAuthenticationPopUp();
    } else {
      setEpMsalAuthStartedCookie();
      login(eMail);
    }
  }

  function startLoginProcess(result) {
    setCheckResult(result);

    if (result?.epUser) {
      setLoginHintAndStartMsalLogin();
    } else {
      setTimeShiftedMessagesBars(result);
    }
  }

  function onSubmitEmail() {
    setMessageBars([
      {
        key: 'searchTenant',
        animationStyles: AnimationStyles.slideDownIn20,
        isMultiline: false,
        messageBarType: MessageBarType.info,
        content: getLoadingSpinnerMessageBarContent(t('welcomePage.messageBar.searchMSTenant'))
      }
    ]);

    setIsSubmissionDisabled(true);
    setTermsAndConditionsChecked({ checked: true, submitted: true });

    const body = JSON.stringify({ check: eMail });

    fetchRequest({
      url: `Init/CheckEp?clientId=default`,
      body,
      method: 'POST',
      anonymousCall: true
    })
      .then(startLoginProcess)
      .catch((error) => {
        trackAIError({ error });
      });
  }

  function onOpenAuthenticationPopUp() {
    microsoftTeams.authentication.authenticate({
      url: `${window.location.origin}/authentication-start`,
      width: 550,
      height: 535,
      successCallback: () => {
        localStorage.removeItem('msTeamsLoginHint');
        window.location.reload();
      },
      failureCallback: (reason) => {
        setAuthError(reason);
      }
    });
  }

  const title = isInIframe() ? 'Evocom' : 'Evocom Productivity';
  // Array with subtitle sentences for easier styling
  const subTitleRows = t('welcomePage.subTitle').split('.');
  const logoAndText = (
    <div style={{ marginTop: isMsConfigPage ? '0' : '50px', textAlign: 'center' }}>
      <div style={{ maxWidth: 'max-content', marginRight: 'auto', marginLeft: 'auto' }}>
        {evocomBeeLogo(isMsConfigPage ? 120 : 200)}
      </div>
      <div
        style={{
          marginTop: '10px',
          fontFamily:
            'Segoe UI Semibold WestEuropean, Segoe UI Semibold, Segoe UI, Tahoma, Arial, sans-serif',
          fontSize: isMsConfigPage ? '31px' : '35px'
        }}
      >
        {title}
      </div>
      <div
        style={{
          margin: isMsConfigPage ? '5px auto 5px auto' : '10px auto 25px auto',
          fontFamily: 'Segoe UI Web Regular, Segoe UI, Segoe WP, Tahoma, Arial, sans-serif',
          fontSize: isMsConfigPage ? '14px' : '16px',
          display: 'inline-block',
          maxWidth: '770px',
          textAlign: 'left'
        }}
      >
        <div style={{ fontWeight: '600', fontSize: '17px' }}>{subTitleRows[0]}.</div>
        <br />
        <SubtitleRowStyled>
          <Icon iconName="ArrowUpRight" className="arrow-icon" />
          <div className="subtitle-text">{subTitleRows[1]}.</div>
        </SubtitleRowStyled>
        <SubtitleRowStyled>
          <Icon iconName="ArrowUpRight" className="arrow-icon" />
          <div className="subtitle-text">{subTitleRows[2]}.</div>
        </SubtitleRowStyled>
        <SubtitleRowStyled>
          <Icon iconName="ArrowUpRight" className="arrow-icon" />
          <div className="subtitle-text">{subTitleRows[3]}.</div>
        </SubtitleRowStyled>
        <SubtitleRowStyled>
          <Icon iconName="ArrowUpRight" className="arrow-icon" />
          <div className="subtitle-text">{subTitleRows[4]}.</div>
        </SubtitleRowStyled>
        <div style={{ marginBottom: 45 }}>
          <a
            style={{ color: '#004e9e' }}
            href="https://evocom.de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('welcomePage.learnMoreLink', { title })}...
          </a>
        </div>
        {subTitleRows[5]}.<div style={{ marginTop: 5 }}>{t('welcomePage.subTitle2')}</div>
      </div>
    </div>
  );

  function onKeyUpEmailTextField(event) {
    if (event?.key === 'Enter') {
      if (!isSubmissionDisabled) {
        onSubmitEmail(eMail);
      }

      event.preventDefault();
    }
  }

  function getMessageBar(messageBar) {
    if (messageBar) {
      const { key, animationStyles, isMultiline, messageBarType, content, truncated } = messageBar;
      const styles = animationStyles ? { ...animationStyles } : undefined;

      return (
        <div key={key} style={styles}>
          <MessageBar
            isMultiline={!!isMultiline}
            truncated={!!truncated}
            styles={{
              innerText: { paddingTop: '2px' },
              root: { marginTop: '3px', transition: 'background-color 0.5s ease-in-out;' },
              iconContainer: {
                display: messageBarType !== MessageBarType.info ? 'inline' : 'none'
              }
            }}
            messageBarType={messageBarType}
          >
            {content}
          </MessageBar>
        </div>
      );
    }

    return null;
  }

  return (
    <div style={{ height: '100%' }}>
      {logoAndText}
      <div style={{ margin: `0 auto auto auto`, maxWidth: '400px' }}>
        <TextField
          value={eMail || ''}
          styles={{
            fieldGroup: { height: '45px' },
            field: { fontSize: '16px' }
          }}
          onKeyUp={onKeyUpEmailTextField}
          label={t('welcomePage.emailTextField.label')}
          required={true}
          placeholder={t('welcomePage.emailTextField.placeholder')}
          autoFocus
          type="email"
          onChange={(_, value) => onTextFieldChange(value)}
        />
        {!termsAndConditionsChecked?.submitted && (
          <Checkbox
            styles={{ root: { marginTop: '15px' }, text: { fontSize: '12px' } }}
            label={t('welcomePage.termsAndConditions')}
            onChange={() => {
              setTermsAndConditionsChecked((prevState) => ({
                ...prevState,
                checked: !prevState.checked
              }));
            }}
          />
        )}
        <PrimaryButton
          styles={{
            label: { fontSize: '16px' },
            root: {
              backgroundColor: '#004e9e',
              width: '100%',
              marginTop: '15px',
              height: '45px',
              marginBottom: '10px'
            }
          }}
          text={t('welcomePage.button.confirm')}
          disabled={isSubmissionDisabled}
          onClick={onSubmitEmail}
        />
        {messageBars && messageBars.length ? messageBars.map(getMessageBar) : null}
        {showLoginButton && (
          <PrimaryButton
            styles={{
              label: { fontSize: '16px' },
              root: {
                backgroundColor: '#004e9e',
                width: '100%',
                marginTop: '15px',
                height: '45px',
                marginBottom: '10px',
                ...AnimationStyles.slideDownIn20
              }
            }}
            text={t('welcomePage.button.login')}
            onClick={setLoginHintAndStartMsalLogin}
          />
        )}
      </div>
      <div style={{ height: '40px' }} />
      <footer style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <a
          style={{ fontSize: '12px', margin: '0 15px 15px 15px', color: '#323130' }}
          href={t('welcomePage.href.privacyPolicy')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('welcomePage.link.privacyPolicy')}
        </a>
        <a
          style={{ fontSize: '12px', margin: '0 15px 15px 15px', color: '#323130' }}
          href={t('welcomePage.href.termsOfUse')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('welcomePage.link.termsOfUse')}
        </a>
      </footer>
    </div>
  );
}

WelcomePage.propTypes = {
  defaultAuthError: PropTypes.string,
  defaultLoginHint: PropTypes.string,
  login: PropTypes.func.isRequired
};

WelcomePage.defaultProps = {
  defaultAuthError: null,
  defaultLoginHint: null
};

export default WelcomePage;
