import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestStatus, TaskStatus, TaskType } from '@evocom/types';
import { FontWeights, Text } from '@fluentui/react';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Icon } from '@fluentui/react/lib/Icon';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import StatusBar from '../StatusBar/StatusBar';
import { CompletedTaskCardStyled, TaskCardStyled } from './TaskCard.styled';
import TaskCardCmdBar from './TaskCardCmdBar';
function TaskCard(_a) {
    var task = _a.task, handleCmdBarButtonClick = _a.handleCmdBarButtonClick, handleTaskStatusUpdate = _a.handleTaskStatusUpdate, onOpenTask = _a.onOpenTask, onPriorityChange = _a.onPriorityChange;
    var dueDate = task.dueDate, id = task.id, intId = task.intId, requestStatusId = task.requestStatusId, routeInstanceId = task.routeInstanceId, startDate = task.startDate, statusId = task.statusId, title = task.title, routeInstanceName = task.routeInstanceName, routeDefinitionName = task.routeDefinitionName, typeId = task.typeId, projectName = task.projectName, parentTitle = task.parentTitle, sprintId = task.sprintId, sprintName = task.sprintName;
    var calculatedSprintName = '';
    var t = useTranslation().t;
    var _b = useState(statusId), currentStatusId = _b[0], setCurrentStatusId = _b[1];
    var childTasks = useState([])[0];
    function getInitialNotificationState(newItems) {
        if (typeof newItems === 'boolean') {
            return newItems;
        }
        if (Array.isArray(newItems)) {
            return newItems.length > 0;
        }
        return false;
    }
    var _c = useState(function () {
        return getInitialNotificationState(task.newChecklistItems);
    }), newChecklistItems = _c[0], setNewChecklistItems = _c[1];
    var _d = useState(function () {
        return getInitialNotificationState(task.newComments);
    }), newComments = _d[0], setNewComments = _d[1];
    var _e = useState(function () { return getInitialNotificationState(task.newFiles); }), newFiles = _e[0], setNewFiles = _e[1];
    var personaCounter = 0;
    useEffect(function () {
        setCurrentStatusId(statusId);
    }, [statusId]);
    function resetNotificationsByType(type) {
        if (type === 'checklist') {
            setNewChecklistItems(false);
        }
        else if (type === 'comments') {
            setNewComments(false);
        }
        else if (type === 'files') {
            setNewFiles(false);
        }
    }
    function resetAllNotifications() {
        setNewChecklistItems(false);
        setNewComments(false);
        setNewFiles(false);
    }
    function onCheckboxChange() {
        setCurrentStatusId(function (prevStatus) {
            var newStatusId = prevStatus === TaskStatus.Completed ? TaskStatus.NotStarted : TaskStatus.Completed;
            handleTaskStatusUpdate(id, newStatusId);
            return newStatusId;
        });
    }
    function onCmdBarButtonClick(type, task) {
        resetNotificationsByType(type);
        handleCmdBarButtonClick(type, task);
    }
    function onTaskCardClick() {
        resetAllNotifications();
        onOpenTask(task);
    }
    function getChildTasksInfos(childTask) {
        var _a, _b;
        var statusContainerStyle = function () {
            if (childTask.approvalStatusId === 1)
                return { backgroundColor: '#7fb800' };
            if (childTask.approvalStatusId === 2)
                return { backgroundColor: '#e81123' };
            return { backgroundColor: 'white' };
        };
        if (personaCounter < 3) {
            personaCounter += 1;
            return (React.createElement("div", { className: "flex justify-between", key: childTask.id },
                React.createElement(Persona, { className: "task-pivot-item-list-persona", text: ((_a = childTask.assignedTo) === null || _a === void 0 ? void 0 : _a.name) || '', size: PersonaSize.size24, imageUrl: ((_b = childTask.assignedTo) === null || _b === void 0 ? void 0 : _b.pictureUrl) || undefined, hidePersonaDetails: false }),
                React.createElement("div", { style: statusContainerStyle(), className: "approval-status-box" }, childTask.approvalStatusId === 0 ? null : (React.createElement(Icon, { className: "approval-status-icon", iconName: childTask.approvalStatusId === 1 ? 'Like' : 'Dislike' })))));
        }
        if (personaCounter === 3) {
            personaCounter += 1;
            return (React.createElement("div", { key: personaCounter, className: "ms-fontWeight-regular pt-1 pl-1" },
                "+",
                childTasks.length - 3));
        }
        return null;
    }
    function TaskCardTitle() {
        var styles = {
            root: {
                color: '#323130',
                fontSize: '17px',
                fontWeight: FontWeights.semilight,
                whiteSpace: 'inherit'
            }
        };
        return (React.createElement(Text, { as: "div", block: true, className: "task-title", nowrap: true, styles: styles }, title));
    }
    function TaskCardIntId() {
        if (task.typeId === 100) {
            return null;
        }
        return (React.createElement(Text, { as: "div", block: true, className: "task-intId", styles: {
                root: {
                    fontSize: 11,
                    marginRight: 15,
                    fontWeight: FontWeights.semibold
                }
            } }, "#" + intId));
    }
    function onInstanceTitleClick(event) {
        if (event) {
            event.stopPropagation();
        }
        handleCmdBarButtonClick('openProcessInstance', task);
    }
    function onProjectNameClick(event) {
        if (event) {
            event.stopPropagation();
        }
        handleCmdBarButtonClick('openProject', task);
    }
    function onSprintNameClick(event) {
        if (event) {
            event.stopPropagation();
        }
        handleCmdBarButtonClick('openSprint', task);
    }
    function onParentTaskTitleClick(event) {
        if (event) {
            event.stopPropagation();
        }
        handleCmdBarButtonClick('openProjectBacklog', task);
    }
    function TaskCardInstanceAndParentTitle() {
        if (task.typeId === 100) {
            return null;
        }
        var instanceName = null;
        var definitionName = null;
        var taskProjectName = null;
        var taskSprintName = null;
        var parentTaskTitle = null;
        if (sprintName || sprintId) {
            calculatedSprintName = sprintName;
            if (sprintId === '11111111-1111-1111-1111-111111111111') {
                calculatedSprintName = t('subheader.projects.commandbar.productBacklog');
            }
            else if (sprintId === 'ffffffff-ffff-ffff-ffff-ffffffffffff') {
                calculatedSprintName = t('subheader.projects.commandbar.removed');
            }
        }
        if (routeDefinitionName) {
            definitionName = (React.createElement(Text, { as: "div", block: true, nowrap: true, className: "task-intId", title: routeDefinitionName, styles: {
                    root: {
                        ':hover': { cursor: 'default' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: FontWeights.semibold,
                        fontSize: 11
                    }
                } }, routeDefinitionName));
        }
        if (routeInstanceName) {
            instanceName = (React.createElement(Text, { as: "div", block: true, onClick: onInstanceTitleClick, nowrap: true, className: "task-intId", title: routeInstanceName, styles: {
                    root: {
                        ':hover': { textDecoration: 'underline' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: FontWeights.semibold,
                        fontSize: 11
                    }
                } }, routeInstanceName));
        }
        if (projectName) {
            taskProjectName = (React.createElement(Text, { as: "div", block: true, onClick: onProjectNameClick, nowrap: true, children: projectName, className: "task-intId", title: projectName, styles: {
                    root: {
                        ':hover': { textDecoration: 'underline' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: FontWeights.semibold,
                        fontSize: 11
                    }
                } }));
        }
        if (calculatedSprintName) {
            taskSprintName = (React.createElement(Text, { as: "div", block: true, onClick: onSprintNameClick, nowrap: true, children: calculatedSprintName, className: "task-intId", title: calculatedSprintName, styles: {
                    root: {
                        ':hover': { textDecoration: 'underline' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: FontWeights.semibold,
                        fontSize: 11
                    }
                } }));
        }
        if (parentTitle) {
            parentTaskTitle = (React.createElement(Text, { as: "div", block: true, onClick: onParentTaskTitleClick, nowrap: true, children: parentTitle, className: "task-intId", title: parentTitle, styles: {
                    root: {
                        ':hover': { textDecoration: 'underline' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: FontWeights.semibold,
                        fontSize: 11
                    }
                } }));
        }
        var devider = (React.createElement(Text, { as: "div", block: true, nowrap: true, className: "task-intId", styles: {
                root: {
                    marginRight: 5,
                    marginLeft: 5,
                    fontWeight: FontWeights.semibold,
                    fontSize: 11
                }
            } }, ' > '));
        if (definitionName && instanceName) {
            return (React.createElement("div", { style: { display: 'flex', maxWidth: '75%' } },
                definitionName,
                devider,
                instanceName));
        }
        if (task.typeId === 50 && taskProjectName && taskSprintName) {
            return (React.createElement("div", { style: { display: 'flex', maxWidth: '75%' } },
                taskProjectName,
                devider,
                taskSprintName));
        }
        if (task.typeId === 50 && taskProjectName) {
            return React.createElement("div", { style: { display: 'flex', maxWidth: '75%' } }, taskProjectName);
        }
        if (task.typeId === 51 && taskProjectName && parentTaskTitle) {
            return (React.createElement("div", { style: { display: 'flex', maxWidth: '75%' } },
                taskProjectName,
                devider,
                parentTaskTitle));
        }
        if (task.typeId === 51 && taskProjectName) {
            return React.createElement("div", { style: { display: 'flex', maxWidth: '75%' } }, taskProjectName);
        }
        return null;
    }
    function TaskCardCheckBox() {
        var isApprovalTask = typeId === TaskType.Approval || typeId === TaskType.ApprovalParent;
        var isProcessTask = !!routeInstanceId;
        var isPlannerTask = task.typeId === 100;
        var isRequestTask = typeId === TaskType.Request &&
            (requestStatusId === RequestStatus.Waiting || requestStatusId === RequestStatus.Rejected);
        var isStatusCheckBoxHidden = isProcessTask || isRequestTask || isApprovalTask || isPlannerTask;
        if (isStatusCheckBoxHidden) {
            return null;
        }
        var checked = currentStatusId === TaskStatus.Completed;
        return React.createElement(Checkbox, { checked: checked, onChange: onCheckboxChange });
    }
    function TaskCardPlannerIcon() {
        var isPlannerTask = task.typeId === 100;
        if (!isPlannerTask) {
            return null;
        }
        return (React.createElement("i", { style: { color: '#2a874b', fontSize: '22px' }, className: "ms-Icon ms-Icon--PlannerLogo", "aria-hidden": "true" }));
    }
    function TaskCardHeader() {
        return (React.createElement("div", { className: "task-header" },
            React.createElement("div", { "aria-hidden": "true", style: { width: 'calc(100% - 34px)', marginRight: '10px' }, onClick: onTaskCardClick },
                React.createElement("div", { style: { display: 'flex' } },
                    React.createElement(TaskCardIntId, null),
                    React.createElement(TaskCardInstanceAndParentTitle, null)),
                React.createElement(TaskCardTitle, null)),
            React.createElement(TaskCardCheckBox, null),
            React.createElement(TaskCardPlannerIcon, null)));
    }
    function TaskCardDate(_a) {
        var date = _a.date;
        var formatedDate = moment(date).format('DD.MM.YYYY');
        var styles = { root: { fontWeight: FontWeights.regular } };
        return (React.createElement(Text, { as: "div", variant: "small", styles: styles }, formatedDate));
    }
    function CompletedTaskCard() {
        return (React.createElement(CompletedTaskCardStyled, null,
            React.createElement("div", { style: { padding: '0.75rem' } },
                React.createElement(TaskCardHeader, null))));
    }
    if (currentStatusId === TaskStatus.Completed) {
        return React.createElement(CompletedTaskCard, null);
    }
    return (React.createElement(TaskCardStyled, null,
        React.createElement("div", { style: { padding: '0.75rem' } },
            React.createElement(TaskCardHeader, null),
            React.createElement("div", { className: "task-dates" },
                startDate && React.createElement(TaskCardDate, { date: new Date(startDate) }),
                dueDate && React.createElement(TaskCardDate, { date: new Date(dueDate) })),
            startDate && dueDate && (React.createElement(StatusBar, { startDate: new Date(startDate), endDate: new Date(dueDate) })),
            typeId === TaskType.ApprovalParent && childTasks.map(getChildTasksInfos)),
        React.createElement(TaskCardCmdBar, { onPriorityChange: function (priority) {
                return onPriorityChange ? onPriorityChange(task, priority) : undefined;
            }, notifications: { newChecklistItems: newChecklistItems, newComments: newComments, newFiles: newFiles }, handleCmdBarButtonClick: onCmdBarButtonClick, task: task })));
}
export default TaskCard;
