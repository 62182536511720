var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { convertEpUserOrTeamToPersonaProps } from '@evocom/utils';
import { IconButton, Persona, PersonaSize } from '@fluentui/react';
import CombinedPicker from '../CombinedPicker/CombinedPicker';
import Label from '../Label/Label';
var TaskDetailsBodyPersonaWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 8px;\n  padding-bottom: 12px;\n  margin-bottom: 1rem;\n  box-sizing: unset;\n  width: 100%;\n  height: 55px;\n\n  .c-persona-remove-item-icon {\n    visibility: hidden;\n  }\n\n  &:hover {\n    background-color: ", ";\n    cursor: ", ";\n    transition: background-color 500ms;\n    border-radius: 3px;\n\n    .c-persona-remove-item-icon {\n      visibility: visible;\n    }\n  }\n\n  .c-date-picker__text {\n    padding-left: 6px;\n  }\n"], ["\n  padding-top: 8px;\n  padding-bottom: 12px;\n  margin-bottom: 1rem;\n  box-sizing: unset;\n  width: 100%;\n  height: 55px;\n\n  .c-persona-remove-item-icon {\n    visibility: hidden;\n  }\n\n  &:hover {\n    background-color: ", ";\n    cursor: ", ";\n    transition: background-color 500ms;\n    border-radius: 3px;\n\n    .c-persona-remove-item-icon {\n      visibility: visible;\n    }\n  }\n\n  .c-date-picker__text {\n    padding-left: 6px;\n  }\n"])), function (props) { return (props.disabled ? 'transparent' : '#f2f2f2'); }, function (props) { return (props.disabled ? 'default' : 'pointer'); });
function TaskDetailsBodyPersona(_a) {
    var defaultValue = _a.defaultValue, _b = _a.disabled, disabled = _b === void 0 ? false : _b, itemLimit = _a.itemLimit, label = _a.label, onChange = _a.onChange, onSearch = _a.onSearch, placeholder = _a.placeholder, _c = _a.required, required = _c === void 0 ? false : _c;
    var combinedPickerRef = useRef(null);
    var _d = useState(false), consentNeeded = _d[0], setConsentNeeded = _d[1];
    var personaLabel = (React.createElement(Label, { label: label, required: required, styles: {
            fluentLabel: {
                root: {
                    padding: 0,
                    fontSize: '13px',
                    fontWeight: 400,
                    color: '#605e5c',
                    ':hover': {
                        cursor: disabled ? 'default' : 'pointer'
                    }
                }
            }
        } }));
    if (disabled && defaultValue) {
        var persona = convertEpUserOrTeamToPersonaProps(defaultValue);
        return (React.createElement(TaskDetailsBodyPersonaWrapper, { disabled: disabled },
            personaLabel,
            React.createElement(Persona, { styles: { root: { marginLeft: '7px', marginTop: '4px' } }, text: persona.text, size: PersonaSize.size28, secondaryText: persona.secondaryText, showSecondaryText: persona.showSecondaryText, imageUrl: persona.imageUrl, hidePersonaDetails: false })));
    }
    function getDefaultValue() {
        if (defaultValue) {
            var persona = convertEpUserOrTeamToPersonaProps(defaultValue);
            return [persona];
        }
        return null;
    }
    function onFocusCombinedPicker() {
        if (combinedPickerRef.current) {
            combinedPickerRef.current.focusInput();
        }
    }
    function onRenderItem(props) {
        var item = props.item, onRemoveItem = props.onRemoveItem;
        return (React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(Persona, { styles: { root: { marginLeft: '2px', marginTop: '4px' } }, text: item.text, size: PersonaSize.size28, secondaryText: item.secondaryText, showSecondaryText: !!item.secondaryText, imageUrl: item.imageUrl, hidePersonaDetails: false }),
            React.createElement(IconButton, { styles: {
                    root: { marginTop: '3px', height: '25px' },
                    rootHovered: { backgroundColor: 'transparent' }
                }, iconProps: {
                    className: 'c-persona-remove-item-icon',
                    styles: { root: { fontSize: '18px', color: 'rgb(96, 94, 92)' } },
                    iconName: 'CalculatorMultiply'
                }, onClick: onRemoveItem })));
    }
    function onCombinedPickerSearch(filterText) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, onSearch({ filterText: filterText }).then(function (searchResult) {
                        setConsentNeeded(!!searchResult.consentNeeded);
                        return searchResult.items;
                    })];
            });
        });
    }
    var combinedPickerDefaultValue = getDefaultValue() || undefined;
    var combinedPickerStyles = {
        text: [
            {
                border: 'none',
                ':hover': {
                    border: 'none'
                },
                marginLeft: 5,
                marginRight: 5,
                height: 36,
                ':after': {
                    border: 'none',
                    borderRadius: '0px',
                    borderBottom: defaultValue ? 'none' : '1px solid #8c8c8c'
                }
            }
        ]
    };
    return (React.createElement(TaskDetailsBodyPersonaWrapper, { onClick: onFocusCombinedPicker },
        personaLabel,
        React.createElement(CombinedPicker, { combinedPickerRef: combinedPickerRef, defaultValue: combinedPickerDefaultValue, displayConsentWarning: consentNeeded, disabled: disabled, itemLimit: itemLimit, onChange: onChange, onRenderItem: onRenderItem, onSearch: onCombinedPickerSearch, placeholder: placeholder, required: required, styles: combinedPickerStyles })));
}
export default TaskDetailsBodyPersona;
var templateObject_1;
