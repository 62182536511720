import numbro from 'numbro';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@fluentui/react/lib/TextField';
import Label from '../Label/Label';
import { german } from './languages/de-DE';
function NumbersField(_a) {
    var defaultValue = _a.defaultValue, description = _a.description, _b = _a.disabled, disabled = _b === void 0 ? false : _b, label = _a.label, labelIconName = _a.labelIconName, _c = _a.maxDecimals, maxDecimals = _c === void 0 ? 0 : _c, onChange = _a.onChange, placeholder = _a.placeholder, _d = _a.required, required = _d === void 0 ? false : _d, styles = _a.styles;
    var t = useTranslation().t;
    useEffect(function () {
        var currentNumbroLanguage = numbro.language();
        var currentLanguage = navigator.language.toLowerCase();
        var currentLanguageIsGerman = ['de', 'de-de', 'de-at', 'de-ch'].includes(currentLanguage);
        if (currentLanguageIsGerman && currentNumbroLanguage !== 'de-DE') {
            numbro.registerLanguage(german);
            numbro.setLanguage('de-DE');
        }
    }, []);
    function onTextFieldChange(_, newValue) {
        var isValid = isValidNumber(newValue);
        if (onChange && newValue && isValid) {
            var validNumber = convertStringToNumber(newValue);
            onChange(validNumber);
        }
        else if (onChange) {
            onChange(null);
        }
    }
    function isNumber(valueToCheck) {
        return typeof valueToCheck === 'number';
    }
    function countDecimals(number) {
        var stringNumber = String(number);
        var containsDecimal = stringNumber.includes('.');
        if (containsDecimal) {
            return stringNumber.split('.')[1].length;
        }
        return 0;
    }
    function hasValidNumberOfDecimalNumbers(number) {
        var decimals = countDecimals(number);
        return decimals <= maxDecimals;
    }
    function convertStringToNumber(stringToParse) {
        return numbro(stringToParse).value();
    }
    function isValidNumber(value) {
        if (!value) {
            return false;
        }
        var stringValue = value;
        if (isNumber(stringValue)) {
            stringValue = value.toString();
        }
        var convertedNumber = convertStringToNumber(stringValue);
        if (convertedNumber) {
            return hasValidNumberOfDecimalNumbers(convertedNumber);
        }
        return false;
    }
    function onGetErrorMessage(value) {
        var isValid = isValidNumber(value);
        if (!value || isValid) {
            return undefined;
        }
        var convertedNumber = convertStringToNumber(value);
        if (convertedNumber && !hasValidNumberOfDecimalNumbers(convertedNumber)) {
            return t('numbersField.error.invaliDecimal');
        }
        return t('numbersField.error.invalidNumber');
    }
    function getTextFieldDefaultValue() {
        var isValid = isValidNumber(defaultValue);
        if (isValid) {
            return numbro(defaultValue).value().toString();
        }
        return undefined;
    }
    var textFieldDefaultValue = getTextFieldDefaultValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(Label, { description: description, iconName: labelIconName, label: label, required: required }),
        React.createElement(TextField, { autoComplete: "off", defaultValue: textFieldDefaultValue, disabled: disabled, onChange: onTextFieldChange, onGetErrorMessage: onGetErrorMessage, placeholder: placeholder || t('numbersField.placeholder'), styles: styles })));
}
export default NumbersField;
