import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import history from '../modules/App/history';

const { instrumentationKey } = window.config;

const reactPlugin = instrumentationKey ? new ReactPlugin() : null;

const ai = instrumentationKey
  ? new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: { [reactPlugin.identifier]: { history } }
      }
    })
  : null;

if (ai) {
  ai.loadAppInsights();
}

export default (Component) => withAITracking(reactPlugin, Component);
export { ai };
export { reactPlugin };
