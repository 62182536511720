import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontIcon } from '@fluentui/react';
import { Persona } from '@fluentui/react/lib/Persona';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import ActivitiesListStyled from './ActivitiesList.styles';
import Activity from './Activity';
import GroupedActivity from './GroupedActivity';
export default function ActivitiesList(_a) {
    var activities = _a.activities, groupedActivities = _a.groupedActivities, handleOnActivityClick = _a.handleOnActivityClick, handleOnReadIconClick = _a.handleOnReadIconClick, handleOnGroupedReadIconClick = _a.handleOnGroupedReadIconClick, handleOnGroupedActivityClick = _a.handleOnGroupedActivityClick, containerContext = _a.containerContext;
    var t = useTranslation().t;
    function getToolTipContent(activityCreator) {
        if (!activityCreator) {
            return null;
        }
        var email = activityCreator.email, pictureUrl = activityCreator.pictureUrl, name = activityCreator.name, userId = activityCreator.userId;
        return (React.createElement(Persona, { key: userId, imageUrl: pictureUrl || undefined, text: name || '', secondaryText: email || '' }));
    }
    function getUserTooltip(user, activityId) {
        var calloutProps = { gapSpace: 0 };
        return (React.createElement(TooltipHost, { delay: 2, key: "activity-tool-tip-" + (user.userId + activityId), tooltipProps: { onRenderContent: function () { return getToolTipContent(user); } }, id: user.userId, calloutProps: calloutProps },
            React.createElement("span", { key: "activity-creator-name-" + user.userId, className: "activity-creator" }, user === null || user === void 0 ? void 0 : user.name)));
    }
    function getCreatorTooltip(activity) {
        return getUserTooltip(activity.creator, activity.id);
    }
    function callInterpolationFunction(functionName, activity) {
        var _a, _b, _c, _d, _e;
        var fallback = React.createElement("span", null);
        if (functionName === 'notificationCreator') {
            return getCreatorTooltip(activity);
        }
        if (functionName === 'newTaskAssignedTo') {
            if ((_a = activity.newTask) === null || _a === void 0 ? void 0 : _a.assignedTo) {
                return getUserTooltip(activity.newTask.assignedTo, activity.id);
            }
            return fallback;
        }
        if (functionName === 'oldTaskAssignedTo') {
            if ((_b = activity.oldTask) === null || _b === void 0 ? void 0 : _b.assignedTo) {
                return getUserTooltip(activity.oldTask.assignedTo, activity.id);
            }
            return fallback;
        }
        if (functionName === 'mentionedPerson') {
            if ((_d = (_c = activity.comment) === null || _c === void 0 ? void 0 : _c.mentions) === null || _d === void 0 ? void 0 : _d.length) {
                return getUserTooltip((_e = activity.comment) === null || _e === void 0 ? void 0 : _e.mentions[0], activity.id);
            }
            return fallback;
        }
        return fallback;
    }
    function timeAgo(value, format) {
        var dateFormat = format;
        var date = moment(value);
        var now = moment().startOf('second');
        if (!format) {
            dateFormat = 'DD.MM.YYYY';
        }
        var startOfOneHourAgo = now.clone().add(-1, 'h');
        var startOfToday = now.clone().startOf('day');
        var startOfYesterday = now.clone().add(-1, 'd').startOf('day');
        var formattedTimeAgo = '';
        if (date.isBefore(startOfYesterday)) {
            formattedTimeAgo = moment(value).format(dateFormat);
        }
        if (date.isSameOrAfter(startOfYesterday) && date.isBefore(startOfToday)) {
            formattedTimeAgo = t('activitiesList.timeAgo.yesterday');
        }
        if (date.isSameOrAfter(startOfToday) && date.isBefore(startOfOneHourAgo)) {
            var diffHours = now.diff(date, 'hours');
            formattedTimeAgo =
                diffHours === 1
                    ? t('activitiesList.timeAgo.hour', { hour: diffHours.toString() })
                    : t('activitiesList.timeAgo.hours', { hours: diffHours.toString() });
        }
        if (date.isSameOrAfter(startOfOneHourAgo) && date.isSameOrBefore(now)) {
            var diffMinutes = now.diff(date, 'minutes');
            formattedTimeAgo =
                diffMinutes === 1
                    ? t('activitiesList.timeAgo.minute', { minute: diffMinutes.toString() })
                    : t('activitiesList.timeAgo.minutes', { minutes: diffMinutes.toString() });
        }
        return formattedTimeAgo;
    }
    function getActivityIconName(type) {
        if ((type >= 0 && type <= 6) ||
            type === 34 ||
            (type >= 45 && type <= 54) ||
            (type >= 79 && type <= 83)) {
            return 'TaskSolid';
        }
        if ((type >= 7 && type <= 10) || type === 84 || type === 85) {
            return 'Message';
        }
        if (type >= 11 && type <= 17) {
            return 'CheckList';
        }
        if (type >= 18 && type <= 20) {
            return 'FileImage';
        }
        if (type >= 18 && type <= 20) {
            return 'FileImage';
        }
        if (type >= 21 && type <= 29) {
            return 'Teamwork';
        }
        if (type >= 30 && type <= 33) {
            return 'FeedbackRequestSolid';
        }
        if (type >= 35 && type <= 37) {
            return 'ServerProcesses';
        }
        if ((type >= 38 && type <= 42) || (type >= 73 && type <= 75) || type === 86 || type === 87) {
            return 'Processing';
        }
        if (type === 43 || type === 44 || type === 55 || type === 56 || type === 69 || type === 76) {
            return 'ProcessMetaTask';
        }
        if ((type >= 57 && type <= 60) || type === 70) {
            return 'Checkbox';
        }
        if ((type >= 61 && type <= 64) || type === 71 || type === 77) {
            return 'Database';
        }
        if (type >= 65 || type <= 68 || type === 72 || type === 78) {
            return 'BarChartHorizontal';
        }
        return 'SingleColumnEdit';
    }
    function getActivityIcon(type) {
        return (React.createElement(FontIcon, { iconName: getActivityIconName(type), className: getActivityIconClassName(type) }));
    }
    function getActivityIconClassName(type) {
        if ((type >= 57 && type <= 60) || type === 70) {
            return 'route-approval-icon';
        }
        return '';
    }
    function onRenderCell(activity) {
        if (!activity) {
            return null;
        }
        return (React.createElement(Activity, { activity: activity, callInterpolationFunction: callInterpolationFunction, containerContext: containerContext, getActivityIcon: getActivityIcon, handleOnActivityClick: handleOnActivityClick, handleOnReadIconClick: handleOnReadIconClick, key: activity.id, timeAgo: timeAgo }));
    }
    function onRenderGroupCell(groupedActivity) {
        if (!groupedActivity || !groupedActivity.notifications.length) {
            return null;
        }
        return (React.createElement(GroupedActivity, { key: groupedActivity.id, groupedActivity: groupedActivity, handleOnActivityClick: handleOnActivityClick, handleOnReadIconClick: handleOnReadIconClick, handleOnGroupedReadIconClick: handleOnGroupedReadIconClick, handleOnGroupedActivityClick: handleOnGroupedActivityClick, callInterpolationFunction: callInterpolationFunction, getActivityIcon: getActivityIcon, getCreatorTooltip: getCreatorTooltip, timeAgo: timeAgo }));
    }
    var content = null;
    if (activities === null || activities === void 0 ? void 0 : activities.length) {
        content = activities.map(onRenderCell);
    }
    if (groupedActivities === null || groupedActivities === void 0 ? void 0 : groupedActivities.length) {
        content = groupedActivities.map(onRenderGroupCell);
    }
    if (content) {
        return React.createElement(ActivitiesListStyled, null, content);
    }
    return null;
}
