import React, { forwardRef, useImperativeHandle, useRef } from 'react';
var mimeTypeRegexp = /^(application|audio|example|image|message|model|multipart|text|video)\/[a-z0-9.+*-]+$/;
var extRegexp = /\.[a-zA-Z0-9]*$/;
var FileManagement = forwardRef(function (_a, ref) {
    var _b = _a.accepts, accepts = _b === void 0 ? null : _b, children = _a.children, _c = _a.className, className = _c === void 0 ? 'files-dropzone' : _c, _d = _a.clickable, clickable = _d === void 0 ? true : _d, _e = _a.dropActiveClassName, dropActiveClassName = _e === void 0 ? 'files-dropzone-active' : _e, _f = _a.maxFileSize, maxFileSize = _f === void 0 ? Infinity : _f, _g = _a.maxFiles, maxFiles = _g === void 0 ? Infinity : _g, _h = _a.minFileSize, minFileSize = _h === void 0 ? 0 : _h, _j = _a.multiple, multiple = _j === void 0 ? true : _j, _k = _a.name, name = _k === void 0 ? 'file' : _k, onChange = _a.onChange, onError = _a.onError, style = _a.style;
    useImperativeHandle(ref, function () { return ({
        openFileChooser: function () {
            onOpenFileChooser();
        }
    }); });
    var dropzone = useRef(null);
    var inputElement = useRef(null);
    function isDragEvent(event) {
        return event.dataTransfer !== undefined;
    }
    function onDrop(event) {
        event.preventDefault();
        onDragLeave();
        var filesAdded = [];
        if (isDragEvent(event)) {
            filesAdded = Array.from(event.dataTransfer.files);
        }
        else if (event.target.files) {
            filesAdded = Array.from(event.target.files);
        }
        if (!multiple && filesAdded && filesAdded.length > 1) {
            filesAdded = [filesAdded[0]];
        }
        var newFiles = [];
        for (var i = 0; i < filesAdded.length; i++) {
            var file = filesAdded[i];
            file.extension = fileExtension(file);
            file.sizeReadable = fileSizeReadable(file.size);
            if (file.type && mimeTypeLeft(file.type) === 'image') {
                file.preview = {
                    type: 'image',
                    url: window.URL.createObjectURL(file)
                };
            }
            else {
                file.preview = {
                    type: 'file'
                };
            }
            if (newFiles.length >= maxFiles) {
                handleError({ code: 4, message: 'maximum file count reached' }, file);
                break;
            }
            if (fileTypeAcceptable(file) && fileSizeAcceptable(file)) {
                newFiles.push(file);
            }
        }
        onChange(newFiles);
    }
    function onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
    }
    function onDragEnter() {
        var el = dropzone;
        if (el.current) {
            el.current.className += " " + dropActiveClassName;
        }
    }
    function onDragLeave() {
        var el = dropzone;
        if (dropzone.current && el.current) {
            dropzone.current.className = el.current.className.replace(" " + dropActiveClassName, '');
        }
    }
    function onOpenFileChooser() {
        if (inputElement === null || inputElement === void 0 ? void 0 : inputElement.current) {
            inputElement.current.value = '';
            inputElement.current.click();
        }
    }
    function fileTypeAcceptable(file) {
        if (!accepts) {
            return true;
        }
        var result = accepts.some(function (accept) {
            if (file.type && accept.match(mimeTypeRegexp)) {
                var typeLeft = mimeTypeLeft(file.type);
                var typeRight = mimeTypeRight(file.type);
                var acceptLeft = accept.split('/')[0];
                var acceptRight = accept.split('/')[1];
                if (acceptLeft && acceptRight) {
                    if (acceptLeft === typeLeft && acceptRight === '*') {
                        return true;
                    }
                    if (acceptLeft === typeLeft && acceptRight === typeRight) {
                        return true;
                    }
                }
            }
            if (file.extension && accept.match(extRegexp)) {
                var ext = accept.substr(1);
                return file.extension.toLowerCase() === ext.toLowerCase();
            }
            return false;
        });
        if (!result) {
            handleError({ code: 1, message: file.name + " is not a valid file type" }, file);
        }
        return result;
    }
    function fileSizeAcceptable(file) {
        if (file.size > maxFileSize) {
            handleError({ code: 2, message: file.name + " is too large" }, file);
            return false;
        }
        if (file.size < minFileSize) {
            handleError({ code: 3, message: file.name + " is too small" }, file);
            return false;
        }
        return true;
    }
    function mimeTypeLeft(mime) {
        return mime.split('/')[0];
    }
    function mimeTypeRight(mime) {
        return mime.split('/')[1];
    }
    function fileExtension(file) {
        var extensionSplit = file.name.split('.');
        if (extensionSplit.length > 1) {
            return extensionSplit[extensionSplit.length - 1];
        }
        return 'none';
    }
    function fileSizeReadable(size) {
        if (size >= 1000000000) {
            return Math.ceil(size / 1000000000) + " GB";
        }
        if (size >= 1000000) {
            return Math.ceil(size / 1000000) + " MB";
        }
        if (size >= 1000) {
            return Math.ceil(size / 1000) + " kB";
        }
        return Math.ceil(size) + " B";
    }
    function handleError(error, file) {
        if (onError) {
            onError(error, file);
        }
    }
    function onClick() {
        if (clickable) {
            onOpenFileChooser();
        }
    }
    return (React.createElement("div", null,
        React.createElement("input", { accept: (accepts === null || accepts === void 0 ? void 0 : accepts.length) ? accepts.join() : '', multiple: multiple, name: name, onChange: onDrop, ref: inputElement, style: { display: 'none' }, type: "file" }),
        React.createElement("div", { "aria-hidden": "true", className: className, onClick: onClick, onDragEnter: onDragEnter, onDragLeave: onDragLeave, onDragOver: onDragOver, onDrop: onDrop, ref: dropzone, style: style }, children)));
});
export default FileManagement;
