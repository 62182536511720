import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskType } from '@evocom/types';
import { CommandBar } from '@fluentui/react';
function TaskPanelCmdBar(_a) {
    var _b;
    var task = _a.task, tab = _a.tab, fromSubTaskId = _a.fromSubTaskId, onOpenTask = _a.onOpenTask, onSaveTask = _a.onSaveTask, onSubmitTask = _a.onSubmitTask, onOpenProcessInstance = _a.onOpenProcessInstance;
    var t = useTranslation().t;
    var _c = useState({
        save: true,
        submit: true
    }), cmdBarItemsDisabledState = _c[0], setCmdBarItemsDisabledState = _c[1];
    var isNewTask = !(task === null || task === void 0 ? void 0 : task.id);
    var isRegularTask = (task === null || task === void 0 ? void 0 : task.typeId) === TaskType.Task;
    var isRequest = (task === null || task === void 0 ? void 0 : task.typeId) === TaskType.Request;
    var isProcessDataSubTask = (task === null || task === void 0 ? void 0 : task.typeId) === TaskType.RouteDataSub;
    var isRegularProcessTask = (task === null || task === void 0 ? void 0 : task.typeId) === TaskType.RouteTask;
    var isDataProcessTask = (task === null || task === void 0 ? void 0 : task.typeId) === TaskType.RouteData;
    var isSurveyProcessTask = (task === null || task === void 0 ? void 0 : task.typeId) === TaskType.RouteSurvey;
    var isSurveyParentTask = (task === null || task === void 0 ? void 0 : task.typeId) === TaskType.SurveyParent;
    var isApprovalProcessTask = (task === null || task === void 0 ? void 0 : task.typeId) === TaskType.RouteApproval;
    var hasValidProcessInstance = (task === null || task === void 0 ? void 0 : task.routeInstanceId) && task.routeInstanceId !== '00000000-0000-0000-0000-000000000000';
    var hasValidParentTaskId = (task === null || task === void 0 ? void 0 : task.parentTaskId) && task.parentTaskId !== '00000000-0000-0000-0000-000000000000';
    useEffect(function () {
        setCmdBarItemsDisabledState(function () {
            if (isNewTask) {
                return {
                    save: !(task.title && task.assignedTo && task.dueDate && task.startDate),
                    submit: !(task.title && task.assignedTo && task.dueDate && task.startDate)
                };
            }
            return {
                save: !(task.title && task.assignedTo && task.dueDate && task.startDate),
                submit: !(task.title && task.assignedTo && task.dueDate && task.startDate)
            };
        });
    }, [isNewTask, task]);
    var cmdBarFarItems = [];
    var commandBarItems = [];
    if (fromSubTaskId) {
        cmdBarFarItems.push({
            iconProps: { iconName: 'Back' },
            key: 'back',
            onClick: function () { return onOpenTask(fromSubTaskId); },
            text: t("detailTask.commandBar.button.backToSubTask")
        });
    }
    commandBarItems.push({
        key: 'saveAndClose',
        onClick: onSaveTask,
        text: t('detailTask.commandBar.button.saveAndclose'),
        iconProps: { iconName: 'SaveAndClose' },
        disabled: cmdBarItemsDisabledState.save,
        split: true,
        subMenuProps: {
            items: [
                {
                    key: 'saveTask',
                    text: t('detailTask.commandBar.button.save'),
                    iconProps: { iconName: 'Save' },
                    disabled: cmdBarItemsDisabledState.save,
                    onClick: onSaveTask
                }
            ]
        }
    });
    if (!isNewTask && (isRegularTask || isRequest || isProcessDataSubTask)) {
        commandBarItems.push({
            disabled: cmdBarItemsDisabledState.submit,
            iconProps: { iconName: 'Completed' },
            key: 'submit',
            onClick: onSubmitTask,
            text: t('detailTask.commandBar.button.complete')
        });
    }
    if (((_b = task === null || task === void 0 ? void 0 : task.permissions) === null || _b === void 0 ? void 0 : _b.update) &&
        (isRegularProcessTask || isDataProcessTask || isSurveyProcessTask)) {
        commandBarItems.push({
            disabled: cmdBarItemsDisabledState.submit,
            iconProps: { iconName: 'Completed' },
            key: 'submit',
            onClick: function () { return onSubmitTask; },
            text: t('detailTask.commandBar.button.submit')
        });
    }
    if (hasValidProcessInstance) {
        commandBarItems.push({
            iconProps: { iconName: 'Org' },
            key: 'openRouteInstance',
            onClick: function () { return onOpenProcessInstance(); },
            style: { display: window.location.href.includes('instance') ? 'none' : 'inline' },
            text: t("detailTask.commandBar.button.openRouteInstance")
        });
    }
    if (hasValidParentTaskId &&
        !isDataProcessTask &&
        (isRegularTask ||
            isProcessDataSubTask ||
            ((isApprovalProcessTask || isSurveyProcessTask) && isSurveyParentTask))) {
        commandBarItems.push({
            iconProps: { iconName: 'arrowUpRight' },
            key: 'openParentTask',
            onClick: function () {
                if (task.parentTaskId) {
                    onOpenTask(task.parentTaskId);
                }
            },
            text: t('detailTask.commandBar.button.openParentTask')
        });
    }
    if (commandBarItems) {
        return React.createElement(CommandBar, { farItems: cmdBarFarItems, items: commandBarItems });
    }
    return null;
}
export default TaskPanelCmdBar;
