import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'signature_pad';
import { DefaultButton, Stack } from '@fluentui/react';
import Label from '../Label/Label';
import { SignaturePadWrapper } from './SignatureField.styles';
function SignatureField(_a) {
    var defaultValue = _a.defaultValue, description = _a.description, _b = _a.disabled, disabled = _b === void 0 ? false : _b, label = _a.label, labelIconName = _a.labelIconName, onChange = _a.onChange, _c = _a.required, required = _c === void 0 ? false : _c;
    var _d = useState(500), canvasWidth = _d[0], setCanvasWidth = _d[1];
    var t = useTranslation().t;
    var signaturePad = useRef();
    var canvasRef = useRef(null);
    var wrapperRef = useRef(null);
    var onCanvasEnd = useCallback(function () {
        var _a, _b;
        var data = null;
        if (signaturePad && !((_a = signaturePad.current) === null || _a === void 0 ? void 0 : _a.isEmpty())) {
            data = (_b = signaturePad.current) === null || _b === void 0 ? void 0 : _b.toDataURL();
        }
        if (onChange) {
            onChange({ signature: data });
        }
    }, [onChange, signaturePad]);
    useEffect(function () {
        var _a;
        if (!signaturePad.current && canvasRef.current) {
            signaturePad.current = new SignaturePad(canvasRef.current, {
                onEnd: onCanvasEnd,
                maxWidth: 2,
                minDistance: 1,
                throttle: 0
            });
            if (defaultValue && defaultValue.signature) {
                (_a = signaturePad.current) === null || _a === void 0 ? void 0 : _a.fromDataURL(defaultValue.signature);
            }
        }
    }, [defaultValue, onCanvasEnd, signaturePad]);
    useLayoutEffect(function () {
        if (wrapperRef.current) {
            var outputsize = function (entries) {
                if (entries[0] && entries[0].contentRect.width !== canvasWidth) {
                    setCanvasWidth(entries[0].contentRect.width);
                }
            };
            new ResizeObserver(outputsize).observe(wrapperRef.current);
        }
    }, [canvasWidth, signaturePad]);
    function onUndo() {
        var _a, _b;
        var data = (_a = signaturePad.current) === null || _a === void 0 ? void 0 : _a.toData();
        if (data) {
            data.pop();
            (_b = signaturePad.current) === null || _b === void 0 ? void 0 : _b.fromData(data);
        }
    }
    function onClear() {
        var _a;
        (_a = signaturePad.current) === null || _a === void 0 ? void 0 : _a.clear();
        if (onChange) {
            onChange();
        }
    }
    function getCanvas() {
        if (!defaultValue && disabled) {
            return (React.createElement("canvas", { height: "100", style: { background: '#f3f2f1', border: '1px solid #a19f9d', borderRadius: 3 } }));
        }
        if ((defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.signature) && disabled) {
            return (React.createElement("img", { style: {
                    background: '#f3f2f1',
                    border: '1px solid #a19f9d',
                    borderRadius: 3,
                    maxWidth: '100%'
                }, "aria-hidden": "true", alt: "img", src: defaultValue.signature }));
        }
        return (React.createElement("canvas", { className: "c-signature-pad", height: "100", ref: canvasRef, style: { border: '1px solid #a19f9d', borderRadius: 3 }, width: canvasWidth }));
    }
    function getEditButtons() {
        if (disabled) {
            return null;
        }
        var buttonStyle = {
            root: {
                borderRadius: 3
            }
        };
        return (React.createElement(Stack, { horizontal: true, tokens: { childrenGap: 5 } },
            React.createElement(DefaultButton, { iconProps: { iconName: 'Undo', styles: { root: { fontSize: 12 } } }, styles: buttonStyle, text: t('signatureField.undo'), onClick: onUndo }),
            React.createElement(DefaultButton, { iconProps: { iconName: 'Clear', styles: { root: { fontSize: 12 } } }, styles: buttonStyle, text: t('signatureField.clear'), onClick: onClear })));
    }
    return (React.createElement(SignaturePadWrapper, { ref: wrapperRef },
        React.createElement(Label, { description: description, iconName: labelIconName, label: label, required: required }),
        getCanvas(),
        getEditButtons()));
}
export default SignatureField;
