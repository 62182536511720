var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsList, DetailsRow, Callout as FluentCallout, Icon, IconButton, SpinnerSize, TextField } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import CodeScannerField from '../CodeScannerField/CodeScannerField';
import Label from '../Label/Label';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { LookupWrapper } from './IntegrationField.styles';
function IntegrationField(integrationFieldProps) {
    var _a, _b;
    var _c = integrationFieldProps.allowScan, allowScan = _c === void 0 ? false : _c, defaultValue = integrationFieldProps.defaultValue, description = integrationFieldProps.description, _d = integrationFieldProps.disabled, disabled = _d === void 0 ? false : _d, externalServiceId = integrationFieldProps.externalServiceId, getDefinition = integrationFieldProps.getDefinition, getDefinitionExternalData = integrationFieldProps.getDefinitionExternalData, label = integrationFieldProps.label, labelIconName = integrationFieldProps.labelIconName, onChange = integrationFieldProps.onChange, onSearch = integrationFieldProps.onSearch, _e = integrationFieldProps.required, required = _e === void 0 ? false : _e;
    var t = useTranslation().t;
    var _f = useState(null), searchResult = _f[0], setSearchResult = _f[1];
    var textFieldRef = useRef(null);
    var _g = useState(defaultValue), selectedId = _g[0], setSelectedId = _g[1];
    var _h = useState(null), selectedData = _h[0], setSelectedData = _h[1];
    var _j = useState(false), initialized = _j[0], setinitialized = _j[1];
    var _k = useState(null), externalService = _k[0], setExternalService = _k[1];
    var _l = useState({
        search: false,
        selectedItem: !!defaultValue
    }), isLoading = _l[0], setIsLoading = _l[1];
    useEffect(function () {
        var _a, _b, _c, _d;
        if (selectedId && isLoading.selectedItem) {
            var requestName = (_d = (_c = (_b = (_a = externalService === null || externalService === void 0 ? void 0 : externalService.serviceData) === null || _a === void 0 ? void 0 : _a.connection) === null || _b === void 0 ? void 0 : _b.queryFields) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.internalName;
            var parameterString = externalServiceId + "?" + requestName + "=" + selectedId;
            if (requestName) {
                getDefinitionExternalData({ parameterString: parameterString }).then(function (result) {
                    setSelectedData(result.fieldValues);
                    setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { selectedItem: false })); });
                });
            }
        }
    }, [
        defaultValue,
        (_b = (_a = externalService === null || externalService === void 0 ? void 0 : externalService.serviceData) === null || _a === void 0 ? void 0 : _a.connection) === null || _b === void 0 ? void 0 : _b.queryFields,
        externalServiceId,
        getDefinitionExternalData,
        isLoading.selectedItem,
        selectedId
    ]);
    useEffect(function () {
        if (!initialized) {
            setinitialized(true);
            getDefinition({ id: externalServiceId }).then(function (fetchedExternalService) {
                setExternalService(fetchedExternalService);
            });
        }
    }, [externalServiceId, getDefinition, initialized]);
    var textFieldId = useId('search-textfield');
    function checkExactSearchResultMatch(searchTerm, result) {
        var exactMatchCount = 0;
        var exactMatchResultKey;
        result.forEach(function (resultRow) {
            resultRow.values.forEach(function (columnValue) {
                if (columnValue.value === searchTerm) {
                    exactMatchCount += 1;
                    exactMatchResultKey = columnValue.key;
                }
            });
        });
        if (exactMatchCount === 1) {
            setSelectedId(exactMatchResultKey);
            setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { selectedItem: true })); });
            if (onChange)
                onChange(exactMatchResultKey);
        }
        else {
            setSearchResult(result);
        }
        setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { search: false })); });
    }
    var onTextFieldChange = debounce(function (filterText) {
        if (filterText && onSearch) {
            setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { search: true })); });
            onSearch({ id: externalServiceId, search: filterText }).then(function (result) {
                checkExactSearchResultMatch(filterText, result);
            });
        }
        setSearchResult(null);
    }, 800);
    function onRenderRow(props) {
        if (props) {
            return React.createElement(DetailsRow, __assign({}, props, { styles: { root: { cursor: 'pointer' } } }));
        }
        return null;
    }
    function onRowClick(selectedItem) {
        setSelectedId(selectedItem.key);
        setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { selectedItem: true })); });
        if (onChange)
            onChange(selectedItem.key);
        setSearchResult(null);
    }
    function Callout() {
        var calloutContent = null;
        if (isLoading.search) {
            calloutContent = (React.createElement(LoadingSpinner, { styles: { container: { width: '450px', margin: 10 } }, size: SpinnerSize.large }));
        }
        if (searchResult === null || searchResult === void 0 ? void 0 : searchResult.length) {
            var columns_1 = [];
            var items_1 = [];
            searchResult[0].values.forEach(function (resultValue) {
                var internalName = resultValue.internalName, title = resultValue.title;
                columns_1.push({
                    fieldName: internalName,
                    key: internalName,
                    minWidth: 200,
                    name: title
                });
            });
            searchResult.forEach(function (result) {
                var detailsListItem = {};
                result.values.forEach(function (resultValue) {
                    var internalName = resultValue.internalName, key = resultValue.key, value = resultValue.value;
                    detailsListItem[internalName] = value;
                    if (!detailsListItem.key) {
                        detailsListItem.key = key;
                    }
                });
                items_1.push(detailsListItem);
            });
            calloutContent = (React.createElement(DetailsList, { compact: true, checkboxVisibility: 2, onRenderRow: onRenderRow, onActiveItemChanged: onRowClick, items: items_1, columns: columns_1 }));
        }
        if (searchResult && searchResult.length === 0) {
            calloutContent = (React.createElement("div", { style: { textAlign: 'center', width: '450px', padding: 20 } },
                React.createElement(Icon, { styles: { root: { fontSize: 28 } }, iconName: "SearchIssue" }),
                React.createElement("div", null, "Kein Ergebnis")));
        }
        return (React.createElement(FluentCallout, { isBeakVisible: false, directionalHint: 4, onDismiss: function () { return setSearchResult(null); }, gapSpace: 2, calloutMaxHeight: 200, target: "#" + textFieldId }, calloutContent));
    }
    function onRemoveSelectedInstance() {
        setSelectedData(null);
        setSelectedId(null);
        if (onChange)
            onChange();
        setTimeout(function () { var _a; return (_a = textFieldRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 100);
    }
    function getFieldIconName(_a) {
        var value = _a.value;
        var isString = typeof value === 'string';
        if (isString && moment(value, '', true).isValid()) {
            return 'Calendar';
        }
        if (isString) {
            return 'AlignLeft';
        }
        if (typeof value === 'number') {
            return 'NumberSymbol';
        }
        return null;
    }
    function onRenderResultListIconColum(item) {
        var iconName = getFieldIconName(item);
        if (iconName) {
            return React.createElement(Icon, { iconName: iconName, styles: { root: { marginTop: 2 } } });
        }
        return null;
    }
    function onRenderResultListNameColum(item) {
        return React.createElement("div", null, item.title);
    }
    function getDateTime(date, displayTime) {
        if (!date) {
            return null;
        }
        if (displayTime) {
            return moment(date).format('L') + ", " + moment(date).format('LT');
        }
        return moment(date).format('L');
    }
    function onRenderResultListValueColum(item) {
        var value = item.value;
        var defaultValue = React.createElement("div", null, "-");
        var isString = typeof value === 'string';
        if (isString && moment(value, '', true).isValid()) {
            return getDateTime(value || null, false) || defaultValue;
        }
        if (isString) {
            return React.createElement("div", null, value) || defaultValue;
        }
        if (typeof value === 'number') {
            return React.createElement("div", null, value) || defaultValue;
        }
        return defaultValue;
    }
    function renderInputTextField() {
        if (allowScan) {
            return (React.createElement(CodeScannerField, { defaultValue: defaultValue, disabled: disabled, onChange: onTextFieldChange, placeholder: t('integrationField.placeholder', {
                    context: disabled ? 'disabled' : undefined
                }), textFieldId: textFieldId, textFieldRef: textFieldRef }));
        }
        return (React.createElement(TextField, { autoComplete: "off", componentRef: textFieldRef, disabled: disabled, placeholder: t('integrationField.placeholder', {
                context: disabled ? 'disabled' : undefined
            }), id: textFieldId, onChange: function (_, newValue) { return onTextFieldChange(newValue); } }));
    }
    if (isLoading.selectedItem) {
        return (React.createElement(LookupWrapper, null,
            React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
            React.createElement(LoadingSpinner, { styles: { container: { margin: 10 } }, size: SpinnerSize.large })));
    }
    if (selectedData) {
        var columns = [
            {
                isResizable: false,
                key: 'icon',
                minWidth: 20,
                maxWidth: 20,
                name: 'Icon',
                onRender: onRenderResultListIconColum,
                isIconOnly: true
            },
            {
                fieldName: 'title',
                isResizable: true,
                key: 'title',
                onRender: onRenderResultListNameColum,
                minWidth: 80,
                maxWidth: 140,
                name: 'Name'
            },
            {
                fieldName: 'value',
                isResizable: true,
                key: 'value',
                onRender: onRenderResultListValueColum,
                minWidth: 160,
                name: 'Value'
            }
        ];
        return (React.createElement(LookupWrapper, null,
            React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
            React.createElement("div", { className: "c-selected-instance_wrapper" },
                React.createElement("div", { className: "c-selected-instance_label" }, !disabled && (React.createElement(IconButton, { styles: { root: { marginTop: '3px', height: '25px' } }, iconProps: {
                        styles: { root: { fontSize: '13px' } },
                        iconName: 'CalculatorMultiply'
                    }, onClick: onRemoveSelectedInstance }))),
                React.createElement(DetailsList, { compact: true, checkboxVisibility: 2, items: selectedData || [], columns: columns }))));
    }
    return (React.createElement(LookupWrapper, null,
        React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
        renderInputTextField(),
        React.createElement(Callout, null)));
}
export default IntegrationField;
