var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import DateTime from '../DateTime/DateTime';
var TaskDetailsBodyDatePickerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 8px;\n  width: 100%;\n  margin-right: 1rem;\n\n  &:hover {\n    background-color: ", ";\n    cursor: ", ";\n    transition: background-color 500ms;\n    border-radius: 3px;\n\n    .c-date-picker .c-date-picker__text {\n      cursor: ", ";\n      background-color: ", ";\n      transition: background-color 500ms;\n    }\n  }\n\n  .c-date-picker__text {\n    padding-left: 6px;\n    color: #323130;\n    background-color: transparent;\n  }\n"], ["\n  padding-top: 8px;\n  width: 100%;\n  margin-right: 1rem;\n\n  &:hover {\n    background-color: ", ";\n    cursor: ", ";\n    transition: background-color 500ms;\n    border-radius: 3px;\n\n    .c-date-picker .c-date-picker__text {\n      cursor: ", ";\n      background-color: ", ";\n      transition: background-color 500ms;\n    }\n  }\n\n  .c-date-picker__text {\n    padding-left: 6px;\n    color: #323130;\n    background-color: transparent;\n  }\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'transparent' : '#f2f2f2');
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'default' : 'pointer');
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'default' : 'pointer');
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'transparent' : '#f2f2f2');
});
function TaskDetailsBodyDatePicker(_a) {
    var defaultValue = _a.defaultValue, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.display, display = _c === void 0 ? true : _c, label = _a.label, onChange = _a.onChange, placeholder = _a.placeholder, _d = _a.required, required = _d === void 0 ? false : _d;
    var isDatePickerDisabled = disabled || !onChange;
    if (!display) {
        return null;
    }
    return (React.createElement(TaskDetailsBodyDatePickerWrapper, { disabled: isDatePickerDisabled },
        React.createElement(DateTime, { disabled: isDatePickerDisabled, displayTime: false, label: label, placeholder: placeholder, onSelectDate: onChange, required: required, defaultValue: defaultValue, styles: {
                labelStyles: {
                    root: {
                        padding: 0,
                        fontSize: '13px',
                        fontWeight: 400,
                        color: '#605e5c'
                    }
                },
                datePickerStyles: {
                    icon: {
                        display: 'none'
                    }
                },
                textFieldStyles: {
                    fieldGroup: {
                        backgroundColor: 'transparent',
                        height: 25,
                        border: 'none',
                        ':after': { border: 'none' }
                    }
                }
            } })));
}
export default TaskDetailsBodyDatePicker;
var templateObject_1;
