var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommandBarButton, CommandButton, IconButton, Text } from '@fluentui/react/';
import { getTheme } from '@fluentui/react/lib/Styling';
import FacePile from '../FacePile/FacePile';
import TaskCardCmdBarStyled from './TaskCardCmdBar.styled';
function TaskCardCmdBar(_a) {
    var _b, _c;
    var notifications = _a.notifications, handleCmdBarButtonClick = _a.handleCmdBarButtonClick, task = _a.task, onPriorityChange = _a.onPriorityChange;
    var t = useTranslation().t;
    var defaultTaskId = '00000000-0000-0000-0000-000000000000';
    var typeId = task.typeId, parentTaskId = task.parentTaskId, requestStatusId = task.requestStatusId;
    var newChecklistItems = notifications.newChecklistItems, newComments = notifications.newComments, newFiles = notifications.newFiles;
    function onCmdBarButtonClick(type) {
        handleCmdBarButtonClick(type, task);
    }
    function getEditButtonText() {
        if (typeId === 1) {
            return t('taskCard.commandBar.button.edit.task');
        }
        if (typeId === 2) {
            return t('taskCard.commandBar.button.edit.request');
        }
        return t('taskCard.commandBar.button.edit.approval');
    }
    function getExpandButtonText() {
        if (typeId === 1 || (typeId === 2 && requestStatusId === 2) || typeId === 7 || typeId === 8) {
            return t('taskCard.commandBar.button.open.task');
        }
        if (typeId === 2) {
            return t('taskCard.commandBar.button.open.request');
        }
        if (typeId === 11 || typeId === 12) {
            return t('taskCard.commandBar.button.open.data');
        }
        if (typeId === 13 || typeId === 14) {
            return t('taskCard.commandBar.button.open.survey');
        }
        if (typeId === 20) {
            return t('taskCard.commandBar.button.open.dataSubTask');
        }
        if (typeId === 50) {
            return t('taskCard.commandBar.button.open.projectBacklog');
        }
        if (typeId === 51 || typeId === 61) {
            return t('taskCard.commandBar.button.open.projectTask');
        }
        if (typeId === 60) {
            return t('taskCard.commandBar.button.open.projectMilestone');
        }
        return t('taskCard.commandBar.button.open.approval');
    }
    function getDeleteButtonText() {
        if (typeId === 1 || (typeId === 2 && requestStatusId === 2)) {
            return t('taskCard.commandBar.button.delete.task');
        }
        if (typeId === 2) {
            return t('taskCard.commandBar.button.delete.request');
        }
        if (typeId === 11 || typeId === 12) {
            return t('taskCard.commandBar.button.delete.data');
        }
        if (typeId === 13 || typeId === 14) {
            return t('taskCard.commandBar.button.delete.survey');
        }
        if (typeId === 50) {
            return t('taskCard.commandBar.button.delete.projectBacklog');
        }
        if (typeId === 51 || typeId === 61) {
            return t('taskCard.commandBar.button.delete.projectTask');
        }
        if (typeId === 60) {
            return t('taskCard.commandBar.button.delete.milestone');
        }
        return t('taskCard.commandBar.button.delete.approval');
    }
    function showOrHideCheckListIcon() {
        var isAcceptedRequestTask = typeId === 2 && requestStatusId !== 2;
        var isSubTask = parentTaskId !== defaultTaskId;
        if (isAcceptedRequestTask ||
            isSubTask ||
            typeId === 3 ||
            typeId === 9 ||
            typeId === 11 ||
            typeId === 13 ||
            typeId === 100 ||
            typeId === 51 ||
            typeId === 60 ||
            typeId === 61) {
            return 'none';
        }
        return 'inline';
    }
    function getPrioritiyColor(priority) {
        switch (priority) {
            case 1:
                return { backgroundColor: '#e44a4a', borderColor: '#CF1A1A' };
            case 2:
                return { backgroundColor: '#FF4D4D', borderColor: '#FF4D4D' };
            case 3:
                return { backgroundColor: '#FF7F7F', borderColor: '#FF7F7F' };
            case 4:
                return { backgroundColor: '#FFB3B3', borderColor: '#FFB3B3' };
            case 5:
                return { backgroundColor: '#f3f2f1', borderColor: '#f3f2f1' };
            case 6:
                return { backgroundColor: '#F6F5F4', borderColor: '#F6F5F4' };
            default:
                return { backgroundColor: '#F8F7F6', borderColor: '#F8F7F6' };
        }
    }
    function PriorityCircle(_a) {
        var priority = _a.priority;
        var _b = getPrioritiyColor(priority), backgroundColor = _b.backgroundColor, borderColor = _b.borderColor;
        return (React.createElement("div", { id: task.id + "-priority", className: "cmd-bar-priority-button", style: { width: '40px', height: '43px' } },
            React.createElement("div", { style: { boxSizing: 'content-box', backgroundColor: backgroundColor, borderColor: borderColor }, className: "cmd-bar-priority-button-circle" },
                React.createElement(Text, { as: "div", className: "cmd-bar-priority-button-count", variant: "xSmall" }, priority))));
    }
    function PriorityIconButton() {
        var _a, _b;
        var priority = task.priority;
        if (priority) {
            var menuItems = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(function (itemKey) {
                return {
                    key: itemKey.toString(),
                    text: t('taskCard.commandBar.button.priority', { context: itemKey.toString() }),
                    data: { id: itemKey }
                };
            });
            return (React.createElement(CommandButton, { styles: { root: { height: '43px' } }, disabled: !((_a = task.permissions) === null || _a === void 0 ? void 0 : _a.update), onRenderMenuIcon: function () {
                    return React.createElement(PriorityCircle, { priority: priority });
                }, menuProps: {
                    onItemClick: function (_, item) {
                        var _a;
                        if (((_a = task.permissions) === null || _a === void 0 ? void 0 : _a.update) && item && onPriorityChange) {
                            onPriorityChange(item.data.id);
                        }
                    },
                    items: ((_b = task.permissions) === null || _b === void 0 ? void 0 : _b.update) ? menuItems : []
                } }));
        }
        return null;
    }
    var cmdBarOverflowItems = [
        {
            key: 'fullScreen',
            text: getExpandButtonText(),
            iconProps: { iconName: 'FullScreen' },
            onClick: function () { return handleCmdBarButtonClick('openTask', task); }
        }
    ];
    if ((_b = task.permissions) === null || _b === void 0 ? void 0 : _b.delete) {
        cmdBarOverflowItems.unshift({
            key: 'recycleBin',
            text: getDeleteButtonText(),
            iconProps: { iconName: 'RecycleBin' },
            onClick: function () { return handleCmdBarButtonClick('delete', task); }
        });
    }
    if (((_c = task.permissions) === null || _c === void 0 ? void 0 : _c.update) && task.typeId === 1) {
        cmdBarOverflowItems.unshift({
            key: 'edit',
            text: getEditButtonText(),
            iconProps: { iconName: 'Edit' },
            onClick: function () { return handleCmdBarButtonClick('editTask', task); }
        });
    }
    var hideOpenParentButton = task.parentTaskId === defaultTaskId ||
        ((task.typeId === 10 || task.typeId === 14) && task.statusId !== 5);
    if (task.routeInstanceId) {
        cmdBarOverflowItems.unshift({
            key: 'routeInstance',
            text: t('taskCard.commandBar.button.open.instance'),
            iconProps: { iconName: 'Org' },
            onClick: function () { return handleCmdBarButtonClick('openProcessInstance', task); }
        });
    }
    var iconButtonStyles = {
        height: '43px',
        width: '40px',
        color: '#666',
        borderRadius: '0px'
    };
    return (React.createElement(TaskCardCmdBarStyled, null,
        React.createElement("div", { className: "cmd-border-top" }),
        React.createElement("div", { className: "flex-container" },
            React.createElement(FacePile, { task: task }),
            React.createElement("div", { className: "icon-buttons" },
                React.createElement(PriorityIconButton, null),
                React.createElement(IconButton, { iconProps: { iconName: 'MessageFill' }, key: "comments", styles: {
                        root: __assign({ display: task.typeId === 100 || (task.typeId === 2 && task.requestStatusId !== 2)
                                ? 'none'
                                : 'inline' }, iconButtonStyles),
                        rootHovered: {
                            color: '#666'
                        }
                    }, className: newComments ? 'notification-highlight' : undefined, onClick: function () { return onCmdBarButtonClick('conversation'); } }),
                React.createElement(IconButton, { iconProps: { iconName: 'Attach' }, key: "files", styles: {
                        root: __assign({ display: task.typeId === 100 || (task.typeId === 2 && task.requestStatusId !== 2)
                                ? 'none'
                                : 'inline' }, iconButtonStyles),
                        rootHovered: {
                            color: '#666'
                        }
                    }, className: newFiles ? 'notification-highlight' : undefined, onClick: function () { return onCmdBarButtonClick('attachments'); } }),
                React.createElement(IconButton, { iconProps: { iconName: 'MultiSelect' }, key: "checklist", styles: {
                        root: __assign({ display: showOrHideCheckListIcon(), marginRight: '1px' }, iconButtonStyles),
                        rootHovered: {
                            color: '#666'
                        }
                    }, className: newChecklistItems ? 'notification-highlight' : undefined, onClick: function () { return onCmdBarButtonClick('checklist'); } }),
                React.createElement(IconButton, { iconProps: { iconName: 'arrowUpRight', styles: { root: { fontSize: '17px' } } }, key: "parentTask", styles: {
                        root: __assign({ display: hideOpenParentButton ? 'none' : 'inline' }, iconButtonStyles)
                    }, onClick: function () { return handleCmdBarButtonClick('openParent', task); } }),
                React.createElement(CommandBarButton, { styles: {
                        root: __assign(__assign({ display: task.typeId === 100 ? 'none' : 'inline' }, iconButtonStyles), { borderBottomRightRadius: '4px' }),
                        rootExpanded: { backgroundColor: getTheme().palette.themePrimary },
                        rootExpandedHovered: {
                            backgroundColor: getTheme().palette.themePrimary,
                            color: 'white'
                        },
                        menuIcon: { fontSize: '16px' }
                    }, className: "cmd-bar-overflow-button-border", menuIconProps: { iconName: 'More' }, key: "more", menuProps: { items: cmdBarOverflowItems } })))));
}
export default TaskCardCmdBar;
