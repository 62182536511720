var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Facepile as FabricFacePile } from '@fluentui/react/lib/Facepile';
import { Icon } from '@fluentui/react/lib/Icon';
import { PersonaSize } from '@fluentui/react/lib/Persona';
var FacepileStyled = styled(FabricFacePile)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ms-Facepile-itemButton {\n    cursor: default;\n  }\n"], ["\n  .ms-Facepile-itemButton {\n    cursor: default;\n  }\n"])));
function FacePile(_a) {
    var task = _a.task;
    function getTeamImageInitials(title) {
        var splitTitle = title.split(' ');
        var imageInitials = splitTitle[0][0];
        if (splitTitle[1]) {
            imageInitials += splitTitle[1][0];
        }
        return imageInitials;
    }
    function renderChevronItem(type) {
        if ([3, 7, 9, 11, 13].indexOf(type) === -1) {
            return (React.createElement(Icon, { styles: {
                    root: {
                        fontSize: 13,
                        fontWeight: 100,
                        paddingTop: '15px',
                        marginLeft: 5,
                        marginRight: 5,
                        color: '#323130'
                    }
                }, iconName: "ChevronRight" }));
        }
        return null;
    }
    function renderFacepile(user, task, renderChevronIcon) {
        var facepile = null;
        if (user) {
            facepile = (React.createElement(React.Fragment, null,
                React.createElement(FacepileStyled, { "aria-describedby": user.userId, personaSize: PersonaSize.size24, personas: [
                        { imageUrl: (user === null || user === void 0 ? void 0 : user.pictureUrl) || undefined, personaName: (user === null || user === void 0 ? void 0 : user.name) || undefined }
                    ] }),
                renderChevronIcon ? renderChevronItem(task.typeId) : null));
        }
        return facepile;
    }
    function renderTeamFacepile(task) {
        if (task && task.assignedToTeam) {
            return (React.createElement(FacepileStyled, { personas: [
                    {
                        personaName: task.assignedToTeam.displayTitle,
                        imageInitials: getTeamImageInitials(task.assignedToTeam.title)
                    }
                ], personaSize: PersonaSize.size24, "aria-describedby": task.assignedToTeam.id }));
        }
        return null;
    }
    function renderFacePileAssignedTo(task) {
        switch (task.typeId) {
            case 1:
            case 4:
            case 7:
            case 8:
            case 10:
            case 14:
            case 20:
            case 50:
            case 51:
            case 60:
            case 61:
            case 100:
                return renderFacepile(task.assignedTo, task);
            case 2:
                if (task.requestStatusId === 2) {
                    return renderFacepile(task.assignedTo, task);
                }
                return renderTeamFacepile(task);
            case 12:
                if (task.assignedTo) {
                    return renderFacepile(task.assignedTo, task);
                }
                return renderTeamFacepile(task);
            default:
                return renderTeamFacepile(task);
        }
    }
    return (React.createElement("div", { style: { display: 'flex' } },
        renderFacepile(task.creator, task, true),
        renderFacePileAssignedTo(task)));
}
export default FacePile;
var templateObject_1;
