var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { CommandBarButton } from '@fluentui/react';
import DynamicField from '../DynamicField/DynamicField';
import { DynamicFieldSectionStyled } from './DynamicFieldSection.styles';
export var SectionType;
(function (SectionType) {
    SectionType[SectionType["default"] = 0] = "default";
    SectionType[SectionType["lookUp"] = 1] = "lookUp";
    SectionType[SectionType["integration"] = 2] = "integration";
    SectionType[SectionType["fieldGroup"] = 3] = "fieldGroup";
})(SectionType || (SectionType = {}));
function DynamicFieldSection(_a) {
    var fields = _a.fields, disabled = _a.disabled, id = _a.id, onCombinedPickerSearch = _a.onCombinedPickerSearch, label = _a.label, getDefinitionExternalData = _a.getDefinitionExternalData, getLookUpInstance = _a.getLookUpInstance, getDefinition = _a.getDefinition, handleLookUpSearch = _a.handleLookUpSearch, tenantId = _a.tenantId, getDefinitionExternalDataList = _a.getDefinitionExternalDataList, getFileContents = _a.getFileContents, getFile = _a.getFile, onChange = _a.onChange, onRenderLabel = _a.onRenderLabel, _b = _a.sectionType, sectionType = _b === void 0 ? SectionType.default : _b;
    var t = useTranslation().t;
    var _c = useState(false), collapsed = _c[0], setCollapsed = _c[1];
    var _d = useState(0), fieldsHeight = _d[0], setFieldsHeight = _d[1];
    var fieldsRef = useRef();
    useLayoutEffect(function () {
        if (fieldsRef.current) {
            setFieldsHeight(fieldsRef.current.clientHeight);
        }
    }, [fieldsRef]);
    function onDynamicFieldChange(props) {
        var fieldId = props.fieldId, value = props.value, nestedSectionId = props.nestedSectionId;
        if (onChange)
            onChange({
                sectionId: id,
                fieldId: fieldId,
                sectionType: props.sectionType || sectionType,
                value: value,
                nestedSectionId: nestedSectionId
            });
    }
    function onRenderDynamicFormField(fieldLink) {
        return (React.createElement(DynamicField, { getDefinition: getDefinition, getLookUpInstance: getLookUpInstance, getDefinitionExternalData: getDefinitionExternalData, handleLookUpSearch: handleLookUpSearch, getDefinitionExternalDataList: getDefinitionExternalDataList, getFileContents: getFileContents, tenantId: tenantId, disabled: disabled, getFile: getFile, onCombinedPickerSearch: onCombinedPickerSearch, fieldLink: fieldLink, key: fieldLink.id, onChange: onDynamicFieldChange }));
    }
    function onLabelClick() {
        setCollapsed(function (prevState) { return !prevState; });
    }
    function getLabel() {
        if (!label) {
            return null;
        }
        if (onRenderLabel) {
            return onRenderLabel(label);
        }
        return (React.createElement(CommandBarButton, { iconProps: {
                iconName: 'ChevronDown',
                styles: {
                    root: {
                        transition: 'transform 0.15s linear 0s',
                        transform: collapsed ? 'rotate(-90deg)' : 'rotate(0)'
                    }
                }
            }, onClick: onLabelClick, text: label, styles: {
                root: { padding: '7px 0px 7px 0px', width: '100%', borderBottom: '1px solid #d2d2d7' },
                label: { textAlign: 'left' },
                textContainer: {
                    fontSize: 20
                }
            } }));
    }
    var transitionStyles = {
        entering: { height: fieldsHeight, opacity: 1 },
        entered: { height: 'auto', opacity: 1 },
        exiting: { height: fieldsHeight, opacity: 0 },
        exited: { height: 0, opacity: 0 }
    };
    var duration = 200;
    var sectionLabel = getLabel();
    return (React.createElement(DynamicFieldSectionStyled, { hasLabel: !!sectionLabel, key: id, id: id },
        sectionLabel,
        React.createElement(Transition, { in: !collapsed, timeout: { appear: 0, enter: duration, exit: 0 } }, function (state) {
            return (React.createElement("div", { style: __assign({ overflow: 'hidden', paddingRight: 3, paddingBottom: 3, transition: "height " + duration + "ms ease-out, opacity " + duration + "ms ease-out" }, transitionStyles[state]) },
                React.createElement("div", { ref: fieldsRef, className: "c-dynamic-form-fields-container" }, fields.map(onRenderDynamicFormField))));
        })));
}
export default DynamicFieldSection;
