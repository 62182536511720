import { any, arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

export const userType = shape({
  login: string,
  name: string,
  pictureUrl: string,
  tenantId: string,
  userId: string
});

export const teamType = shape({
  id: string,
  title: string,
  type: shape({ id: number, title: string }),
  department: shape({ id: number, title: string }),
  status: shape({ id: number, title: string }),
  members: arrayOf(shape({}))
});

export const processCategoryType = shape({
  id: string,
  name: string,
  description: string,
  sortOrder: number,
  type: number,
  allNames: arrayOf(shape({ language: string, text: string })),
  allDescriptions: arrayOf(shape({ language: string, text: string }))
});

export const projectType = shape({
  id: string,
  name: string,
  description: string,
  status: number,
  type: number,
  startDate: any,
  plannedEndDate: any,
  numberOfSprints: number,
  sprintDuration: number,
  currentSprint: any,
  team: teamType,
  allNames: arrayOf(shape({ language: string, text: string })),
  allDescriptions: arrayOf(shape({ language: string, text: string }))
});

export const matchType = shape({
  params: shape({
    id: string,
    mode: string,
    taskId: string,
    tab: string
  })
});

export const historyType = shape({
  push: func,
  location: shape({ pathname: string })
});

export const matchDefaultType = shape({
  params: shape({
    id: null,
    mode: null,
    taskId: null,
    tab: null
  })
});

export const taskType = shape({
  parentTaskId: string,
  typeId: number,
  id: string,
  title: string,
  creationDate: string,
  startDate: any,
  dueDate: any,
  creator: userType,
  assignedTo: userType,
  description: string,
  routeInstanceId: string,
  type: oneOfType([
    shape({
      id: number,
      title: string
    }),
    string
  ]),
  statusId: number
});

export const fileType = shape({
  creationDate: string,
  creator: userType,
  editDate: string,
  editor: userType,
  extension: string,
  id: string,
  name: string,
  routeInstanceId: string,
  taskId: string,
  taskName: string,
  type: oneOfType([string, number]),
  url: string
});

export const fieldType = shape({
  field: shape({
    dateFormat: number,
    choiceFormat: number,
    choices: arrayOf(string),
    maxLength: number,
    numStars: number,
    numDecimals: number,
    id: string,
    name: string,
    description: string,
    fieldType: number
  })
});

export const stepType = shape({
  groups: arrayOf(
    shape({
      name: string,
      description: null,
      fields: arrayOf(
        shape({
          field: fieldType,
          visibility: number,
          sortOrder: number,
          value: oneOfType([
            number,
            string,
            bool,
            arrayOf(string),
            arrayOf(shape({ id: string, value: string })),
            userType
          ])
        })
      )
    })
  ),
  routeInstanceId: string,
  status: number,
  taskId: string,
  taskType: number,
  dateFrom: string,
  dateTo: string,
  id: string,
  name: string,
  description: string,
  stepType: number,
  successors: arrayOf(
    shape({
      id: string,
      branch: number
    })
  ),
  predecessors: arrayOf(
    shape({
      id: string,
      branch: number
    })
  )
});

export const instanceType = shape({
  id: string,
  routeDefinitionId: string,
  name: string,
  description: string,
  steps: arrayOf(stepType),
  status: number,
  version: string,
  imageUrl: string,
  creationDate: string,
  creator: userType,
  editDate: string,
  editor: userType,
  team: teamType
});

export const columnType = shape({
  id: string,
  name: string,
  isFiltered: bool,
  isResizable: bool,
  isSorted: bool,
  isSortedDescending: bool,
  fieldType: number
});

export const templateType = shape({
  id: string,
  name: string,
  description: string,
  steps: arrayOf(stepType),
  stepsMatrix: shape({
    items: arrayOf(arrayOf(shape({ key: string, id: string }))),
    size: arrayOf(number)
  }),
  version: string,
  creationDate: string,
  creator: userType,
  editDate: string,
  editor: userType,
  team: teamType,
  dataFields: arrayOf(
    shape({
      id: string,
      name: string,
      description: string,
      fieldType: number
    })
  )
});

export const locationType = shape({
  code: string,
  country: shape({
    id: string,
    name: string,
    code: string
  }),
  id: string,
  name: string,
  text: string
});

export const tagType = shape({
  id: string,
  text: string
});
