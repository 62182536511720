import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Callout, DirectionalHint, Icon } from '@fluentui/react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { getTheme } from '@fluentui/react/lib/Styling';
import fetchRequest, { apiErrorHandler } from '../../services/api';
import AppContext from '../App/AppContext';
import { evocomBeeLogo } from '../App/components/Layout/Icons';
import { HeaderStyled, LogoWrapper } from './Header.styled';
import HeaderButtonsRow from './HeaderButtonsRow';

const SuggestionItemStyled = styled.div`
  padding: 0px 12px;
  line-height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

function Header({ toggleNavigationPanel, logout, onToggleNotifications, onToggleReleaseNotes }) {
  const { t } = useTranslation();
  const [searchBoxWidth, setSearchBoxWidth] = useState('25%');

  const history = useHistory();

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { theme, currentUser, tenants, searchTerm, unreadNotifications } = globalAppState;

  const [tagSearchTerm, setTagSearchTerm] = useState(null);
  const [searchBoxKey, setSearchBoxKey] = useState(1);
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const [searchSuggestions, setSearchSuggestions] = useState(null);

  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (history?.location && tagSearchTerm) {
      if (
        !history.location.pathname.startsWith('/tags') &&
        history.location.pathname !== location
      ) {
        setTagSearchTerm(null);
      }

      setLocation(history.location.pathname);
    }
  }, [history.location, history.location.pathname, location, tagSearchTerm]);

  async function getTagSearchResults(searchTerm) {
    const url = `tags?showDeleted=true&searchTerms=${encodeURIComponent(searchTerm)}`;

    let tags = await fetchRequest({ url }).catch(apiErrorHandler);

    if (tags?.length) {
      tags = tags.filter((tag) => !tag.children?.length);
      tags = tags.filter((tag) => !tag.isDeleted);
    }

    return tags;
  }

  const onSearchBoxChange = debounce((value) => {
    if (value === '') {
      onSearchChange(null);
    } else if (value?.startsWith('#')) {
      onSearchTags(value);
    }
  }, 500);

  function onSearchTags(value) {
    setTagSearchTerm(value);

    if (value.startsWith('#')) {
      if (value.length > 1) {
        getTagSearchResults(value.replace('#', '')).then((tags) => {
          setSearchSuggestions(tags);
          setIsCalloutVisible(true);
        });
      } else if (isCalloutVisible) {
        setIsCalloutVisible(false);
      }
    }
  }

  function onSearchChange(newSeachTerm) {
    if (tagSearchTerm) {
      setTagSearchTerm(null);
    }

    if (isCalloutVisible) {
      setIsCalloutVisible(false);
    }

    dispatch({ type: 'searchTerm', data: newSeachTerm });
  }

  function showTagsSearchResults(tag) {
    setIsCalloutVisible(false);
    setSearchSuggestions(null);
    setTagSearchTerm(tag ? `#${tag.fullText}` : null);
    setSearchBoxKey((prevKey) => prevKey + 1);

    if (tag) {
      history.push(`/tags/${tag.id}`);
    }
  }

  function onSearch(value) {
    if (value.length > 0 && value.trim().length === 0) {
      onSearchChange(null);
    } else if (!value.startsWith('#')) {
      onSearchChange(value);
    }
  }

  function getSearchSuggestions() {
    const searchBox = document.getElementsByClassName('c-header__searchBox')?.[0];

    let width = '150px';

    if (searchBox) {
      width = `${searchBox.offsetWidth - (searchSuggestions?.length > 8 ? 15 : 0)}px`;
    }

    return (
      <div
        style={{
          minWidth: width,
          padding: '5px',
          maxHeight: '300px'
        }}
      >
        <div
          style={{
            padding: '0px 12px',
            lineHeight: '40px',
            fontSize: '12px',
            color: 'rgb(107, 188, 250)',
            borderBottom: '1px solid rgb(237, 235, 233)'
          }}
        >
          {t('header.searchBox.suggestions.title')}
        </div>
        {searchSuggestions?.length ? (
          <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
            {searchSuggestions.map((suggestion) => {
              return (
                <SuggestionItemStyled
                  role="presentation"
                  key={`suggestion-${suggestion.id}`}
                  onClick={() => showTagsSearchResults(suggestion)}
                >
                  {`#${suggestion.fullText}`}
                </SuggestionItemStyled>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              fontSize: '12px',
              textAlign: 'center',
              lineHeight: '30px',
              color: 'rgb(96, 94, 92)'
            }}
          >
            {t('header.searchBox.suggestions.noResults')}
          </div>
        )}
      </div>
    );
  }

  return (
    <HeaderStyled id="header">
      <Icon
        key="globalNavButton"
        className="global-navigation-button"
        iconName="GlobalNavButton"
        onClick={() => toggleNavigationPanel()}
      />
      <LogoWrapper onClick={() => history.push(`/tasks/my-open`)}>
        {evocomBeeLogo(30, 'white')}
      </LogoWrapper>
      <div style={{ minWidth: 0 }} className="flex full-width">
        <a href="/tasks/my-open" className="c-header__title">
          {theme.title}
        </a>
        <div style={{ width: '70%' }}>
          <SearchBox
            key={`searchBox-${searchBoxKey}-${searchTerm}`}
            className="c-header__searchBox"
            placeholder={t('searchBar.placeholder')}
            clearButtonProps={{
              styles: {
                root: { height: '100%' },
                rootHovered: { backgroundColor: 'rgba(255,255,255,0.3) !important' },
                icon: { fontSize: '13px' }
              }
            }}
            autoComplete="off"
            styles={{
              root: {
                backgroundColor: 'rgba(255,255,255,0.6)',
                border: 'none',
                height: '35px',
                marginLeft: 'auto',
                marginRight: '20px',
                marginTop: '1px',
                maxWidth: '600px',
                minWidth: '300px',
                transition: 'width 0.4s',
                width: searchBoxWidth
              },
              field: { color: '#323130' },
              iconContainer: { width: '35px ' },
              icon: { color: getTheme().palette.themePrimary }
            }}
            iconProps={{ styles: { root: { opacity: 100 } } }}
            onBlur={() => {
              if (!searchTerm && !tagSearchTerm) {
                setSearchBoxWidth('25%');
              }
            }}
            onFocus={() => setSearchBoxWidth('80%')}
            onClear={() => onSearchChange(null)}
            onChange={(_, value) => {
              onSearchBoxChange(value);
            }}
            defaultValue={searchTerm || ''}
            onSearch={onSearch}
          />
        </div>
        {isCalloutVisible ? (
          <Callout
            className="c-header__search-suggestions"
            role="alertdialog"
            gapSpace={0}
            target=".c-header__searchBox"
            onDismiss={() => setIsCalloutVisible(false)}
            setInitialFocus
            isBeakVisible={false}
            directionalHint={DirectionalHint.bottomLeftEdge}
          >
            {getSearchSuggestions()}
          </Callout>
        ) : null}
      </div>
      <HeaderButtonsRow
        logout={logout}
        currentUser={currentUser}
        tenants={tenants}
        unreadNotifications={unreadNotifications}
        onToggleNotifications={onToggleNotifications}
        onToggleReleaseNotes={onToggleReleaseNotes}
      />
    </HeaderStyled>
  );
}

Header.propTypes = {
  toggleNavigationPanel: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  onToggleNotifications: PropTypes.func.isRequired,
  onToggleReleaseNotes: PropTypes.func.isRequired
};

export default Header;
