import PropTypes from 'prop-types';
import React, { createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ActivitiesList } from '@evocom/shared';
import { ActionButton, Panel, PanelType } from '@fluentui/react';
import useContainerEndNotifer from '../../hooks/useContainerEndNotifer';
import fetchRequest, { apiErrorHandler } from '../../services/api';
import { checkScreenWidth, getActivityRedirectUrl } from '../../utils/helpers';
import AppContext from '../App/AppContext';
import EmptyListPlaceHolder from '../App/components/Global/Lists/EmptyListPlaceHolder';
import Loading from '../App/components/Global/Loading';

const headerHeight = checkScreenWidth(['extraSmall']) ? 0 : 48;

const NotificationsContainer = styled.div`
  padding: 0 5px 0 5px;
  overflow-y: auto;
  height: 100%;
`;

function NotificationsPanel({ showNotificationsPanel, onDismiss }) {
  const { t } = useTranslation();

  // List items
  const [notifications, setNotifications] = useState([]);

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { currentUser, msTeamId, unreadNotifications } = globalAppState;

  const listRef = createRef();

  const handleContainerEnd = () => {
    setSearchProps((prevState) => ({ ...prevState, itemsPerPage: prevState.itemsPerPage + 30 }));
  };

  useContainerEndNotifer(listRef, handleContainerEnd);

  const [searchProps, setSearchProps] = useState({
    itemsPerPage: 30,
    pageIndex: 0
  });

  const { userId } = currentUser;

  const history = useHistory();

  // Loading states
  const [isLoading, setIsLoading] = useState(true);

  const getUrl = useCallback(() => {
    const { itemsPerPage, pageIndex } = searchProps;

    if (userId) {
      let url = `Notifications/Grouped?pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}&recipientId=${userId}&onlyNew=true`;

      if (msTeamId) {
        url += `&teamId=${msTeamId}`;
      }

      return url;
    }

    return null;
  }, [msTeamId, searchProps, userId]);

  const addIdToNotificationGroup = useCallback(
    (notificationGroup) => ({ ...notificationGroup, id: notificationGroup.objectId }),
    []
  );

  const loadNotifications = useCallback(() => {
    const url = getUrl();

    if (url) {
      fetchRequest({ url })
        .then((fetchedNotifications) => {
          const fetchedNotificationsWithGroupId = fetchedNotifications.items.map(
            addIdToNotificationGroup
          );

          setNotifications(fetchedNotificationsWithGroupId);

          setIsLoading(false);
        })
        .catch(apiErrorHandler);
    }
  }, [addIdToNotificationGroup, getUrl]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  function updateNotificationsCount(reset = false, numberOfReadNotifications) {
    let newNotificationNumber = unreadNotifications;

    if (reset) {
      // set to zero
      newNotificationNumber = 0;
    } else if (numberOfReadNotifications) {
      // subtract multiple notifications
      newNotificationNumber = unreadNotifications - numberOfReadNotifications;
    } else {
      // subtract a single notification
      newNotificationNumber = unreadNotifications - 1;
    }

    dispatch({
      type: 'updateNotificationsCount',
      data: newNotificationNumber
    });
  }

  function fetchReadNotification(id) {
    fetchRequest({
      url: `Notification/Read?notificationId=${id}`,
      method: 'PUT'
    }).catch(apiErrorHandler);
  }

  function redirect(notification) {
    const url = getActivityRedirectUrl(notification);

    if (url) {
      history.push(url);
    }
  }

  function findNotification(idToSearch, notificationsArray) {
    return notificationsArray.find((notification) => notification.id === idToSearch);
  }

  function filterNotificationsFromNotificationArray(givenNotificationArray, notificationsToFilter) {
    return givenNotificationArray.filter(
      (notification) => !findNotification(notification.id, notificationsToFilter)
    );
  }

  function removeReadNotifications(notificationsToRemove) {
    let unreadNotifications = [...notifications];

    unreadNotifications = unreadNotifications.map((notificationGroup) => {
      return {
        ...notificationGroup,
        notifications: filterNotificationsFromNotificationArray(
          notificationGroup.notifications,
          notificationsToRemove
        )
      };
    });

    setNotifications(unreadNotifications);
  }

  function markSingleNotificationAsRead(notification, followLink = false) {
    if (userId) {
      updateNotificationsCount();

      fetchReadNotification(notification.notificationId);

      if (followLink) {
        redirect(notification);
      }

      removeReadNotifications([notification]);
    }
  }

  function markNotificationGroupAsRead(groupedNotification, followLink = false) {
    if (userId) {
      const groupLength = groupedNotification.notifications.length;

      updateNotificationsCount(false, groupLength);

      groupedNotification.notifications.map((notification) =>
        fetchReadNotification(notification.notificationId)
      );

      if (followLink) {
        redirect(groupedNotification.notifications[groupLength - 1]);
      }

      removeReadNotifications(groupedNotification.notifications);
    }
  }

  function markAllNotificationsAsRead() {
    fetchRequest({ url: `Notification/Read`, method: 'PUT' })
      .then(() => {
        setNotifications([]);
        updateNotificationsCount(true);
      })
      .catch(apiErrorHandler);
  }

  function onRenderFooterContent() {
    if (!isLoading && notifications?.length > 4) {
      return (
        <ActionButton onClick={markAllNotificationsAsRead}>
          {t('notificationsPanel.footer.markAllAsRead')}
        </ActionButton>
      );
    }

    return null;
  }

  function getPanelContent() {
    if (isLoading) {
      return <Loading text={t('loading.myNotifications.text')} />;
    }

    if (notifications?.length) {
      return (
        <NotificationsContainer ref={listRef}>
          <ActivitiesList
            groupedActivities={notifications}
            handleOnActivityClick={(notification) =>
              markSingleNotificationAsRead(notification, true)
            }
            handleOnReadIconClick={markSingleNotificationAsRead}
            handleOnGroupedReadIconClick={markNotificationGroupAsRead}
            handleOnGroupedActivityClick={(groupedNotification) =>
              markNotificationGroupAsRead(groupedNotification, true)
            }
          />
        </NotificationsContainer>
      );
    }

    return (
      <EmptyListPlaceHolder
        hidden={!!notifications?.length}
        noItemsText={t('notificationsPanel.placeHolder')}
        listIconName="Ringer"
      />
    );
  }

  const panelType = checkScreenWidth(['extraSmall', 'small'])
    ? PanelType.smallFluid
    : PanelType.small;

  return (
    <Panel
      headerText={t('notificationsPanel.title')}
      isBlocking={false}
      styles={{
        footer: { height: '40px', textAlign: 'center' },
        footerInner: { padding: 'auto', paddingTop: 0 },
        main: { overflow: 'hidden' },
        root: { marginTop: `${headerHeight}px` },
        scrollableContent: { overflow: 'hidden' },
        subComponentStyles: { closeButton: { root: { color: 'rgb(96, 94, 92) !important' } } }
      }}
      onRenderBody={() => getPanelContent()}
      isOpen={showNotificationsPanel}
      onDismiss={onDismiss}
      type={panelType}
      onRenderFooterContent={onRenderFooterContent}
    />
  );
}

export default NotificationsPanel;

NotificationsPanel.propTypes = {
  showNotificationsPanel: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired
};
