import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { isInIframe, isInMsTeamsEnvironment } from '../../utils/helpers';
import AppContext from '../App/AppContext';
import HeaderButtonsRow from './HeaderButtonsRow';

const HeaderStyled = styled.div`
  .header-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    font-weight: bold;
    background: white;
    color: ${(props) => props.theme.themePrimary};
    border-radius: 50%;
    padding: 0 5px;
    border: 1px solid ${(props) => props.theme.themePrimary};
    text-align: center;
  }

  .header-badge-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 11px;
    color: ${(props) => props.theme.themePrimary};
    font-weight: bold;
  }
`;

function MsTeamsHeader({ logout, contentToShow, onToggleNotifications, onToggleReleaseNotes }) {
  const { t } = useTranslation();

  const history = useHistory();

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { currentUser, tenants, unreadNotifications } = globalAppState;

  const isInRegularIframe = isInIframe() && !isInMsTeamsEnvironment();

  function resetSearchTerm() {
    dispatch({ type: 'searchTerm', data: null });
  }

  return (
    <HeaderStyled style={{ display: 'flex', justifyContent: 'space-between', height: '50px' }}>
      <Pivot
        onLinkClick={(pivotItem) => {
          if (pivotItem?.props?.url) {
            history.push(pivotItem.props.url);
            resetSearchTerm();
          }
        }}
      >
        {contentToShow.tasks || isInRegularIframe ? (
          <PivotItem
            onClick={resetSearchTerm}
            url="/tasks/my-open"
            headerText={t('msTeams.header.pivot.tasks')}
          />
        ) : null}
        {contentToShow.projects || isInRegularIframe ? (
          <PivotItem
            onClick={resetSearchTerm}
            url="/projects/my"
            headerText={t('msTeams.header.pivot.projects')}
          />
        ) : null}
        {contentToShow.processes || isInRegularIframe ? (
          <PivotItem
            onClick={resetSearchTerm}
            url="/process-instances/my"
            headerText={t('msTeams.header.pivot.processes')}
          />
        ) : null}
        {contentToShow.processes || isInRegularIframe ? (
          <PivotItem
            onClick={resetSearchTerm}
            url="/process-templates/landscape"
            headerText={t('msTeams.header.pivot.templates')}
          />
        ) : null}
      </Pivot>
      <HeaderButtonsRow
        styles={{
          deviderColor: 'rgba(43, 43, 42, 0.1)',
          color: '#323130',
          headerCmdBarButton: {
            commandBarButton: {
              iconHovered: { color: '#323130' },
              iconPressed: { color: '#323130' },
              iconProps: { root: { color: '#323130' } }
            }
          }
        }}
        logout={logout}
        currentUser={currentUser}
        unreadNotifications={unreadNotifications}
        onToggleNotifications={onToggleNotifications}
        onToggleReleaseNotes={onToggleReleaseNotes}
        tenants={tenants}
      />
    </HeaderStyled>
  );
}

MsTeamsHeader.propTypes = {
  onToggleNotifications: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  contentToShow: PropTypes.shape({
    tasks: PropTypes.bool,
    processes: PropTypes.bool,
    projects: PropTypes.bool
  }).isRequired,
  onToggleReleaseNotes: PropTypes.func.isRequired
};

export default MsTeamsHeader;
