var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getSectionDocuments, prepareFieldGroupForEvaluation } from '@evocom/utils';
import DynamicFieldSection, { SectionType } from '../DynamicFieldSection/DynamicFieldSection';
export var RteWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1.5rem;\n  margin-top: 8px;\n"], ["\n  margin-bottom: 1.5rem;\n  margin-top: 8px;\n"])));
function DynamicFieldForm(_a) {
    var sections = _a.sections, onCombinedPickerSearch = _a.onCombinedPickerSearch, getDefinition = _a.getDefinition, getDefinitionExternalDataList = _a.getDefinitionExternalDataList, getDefinitionExternalData = _a.getDefinitionExternalData, disabled = _a.disabled, tenantId = _a.tenantId, onSectionsUpdate = _a.onSectionsUpdate, evaluateFieldGroup = _a.evaluateFieldGroup, getLookUpInstance = _a.getLookUpInstance, handleLookUpSearch = _a.handleLookUpSearch, getFileContents = _a.getFileContents, getFile = _a.getFile;
    var t = useTranslation().t;
    function findSectionIndex(sectionsToSearch, sectionIdToFind) {
        return sectionsToSearch.findIndex(function (section) { return section.id === sectionIdToFind; });
    }
    function findFieldIndex(fieldsToSearch, fieldIdToFind) {
        return fieldsToSearch.findIndex(function (field) { return field.id === fieldIdToFind; });
    }
    function getFieldIndices(_a) {
        var nestedSectionId = _a.nestedSectionId, sectionId = _a.sectionId, fieldId = _a.fieldId;
        var sectionIndex = findSectionIndex(sections, sectionId);
        var fieldIndex;
        var fieldGroupIndex;
        if (nestedSectionId) {
            fieldGroupIndex = findFieldIndex(sections[sectionIndex].fields, nestedSectionId);
            var fieldsToSearch = sections[sectionIndex].fields[fieldGroupIndex].fieldGroup.fields;
            fieldIndex = findFieldIndex(fieldsToSearch, fieldId);
        }
        else {
            fieldIndex = findFieldIndex(sections[sectionIndex].fields, fieldId);
        }
        return { sectionIndex: sectionIndex, fieldGroupIndex: fieldGroupIndex, fieldIndex: fieldIndex };
    }
    var onDynamicFormChange = debounce(function (_a) {
        var nestedSectionId = _a.nestedSectionId, sectionId = _a.sectionId, fieldId = _a.fieldId, value = _a.value;
        var sectionsClone = __spreadArray([], sections);
        var _b = getFieldIndices({
            nestedSectionId: nestedSectionId,
            sectionId: sectionId,
            fieldId: fieldId
        }), sectionIndex = _b.sectionIndex, fieldGroupIndex = _b.fieldGroupIndex, fieldIndex = _b.fieldIndex;
        if (sectionIndex > -1 && fieldIndex > -1 && fieldGroupIndex > -1) {
            var newFieldGroupLink = sectionsClone[sectionIndex].fields[fieldGroupIndex];
            newFieldGroupLink.fieldGroup.fields[fieldIndex].value = value;
            var documents_1 = getSectionDocuments(newFieldGroupLink.fieldGroup.fields);
            newFieldGroupLink = prepareFieldGroupForEvaluation(newFieldGroupLink);
            evaluateFieldGroup(newFieldGroupLink).then(function (evaluatedFieldGroupLink) {
                var evaluatedFieldGroupLinkClone = __assign({}, evaluatedFieldGroupLink);
                documents_1.forEach(function (_a) {
                    var file = _a.file, fieldLinkIndex = _a.fieldLinkIndex;
                    var field = evaluatedFieldGroupLinkClone.fieldGroup.fields[fieldLinkIndex];
                    if (field) {
                        field.value = file;
                    }
                });
                sectionsClone[sectionIndex].fields[fieldGroupIndex] = evaluatedFieldGroupLinkClone;
                onSectionsUpdate(sectionsClone);
            });
        }
        else if (sectionIndex > -1 && fieldIndex > -1) {
            sectionsClone[sectionIndex].fields[fieldIndex].value = value;
            onSectionsUpdate(sectionsClone);
        }
    }, 100);
    if (sections) {
        return sections.map(function (section) {
            return (React.createElement(DynamicFieldSection, { getDefinition: getDefinition, onCombinedPickerSearch: onCombinedPickerSearch, getLookUpInstance: getLookUpInstance, disabled: disabled, handleLookUpSearch: handleLookUpSearch, getDefinitionExternalDataList: getDefinitionExternalDataList, getFileContents: getFileContents, getDefinitionExternalData: getDefinitionExternalData, getFile: getFile, key: section.id, fields: section.fields, tenantId: tenantId, id: section.id, label: section.name, onChange: onDynamicFormChange, sectionType: SectionType.default }));
        });
    }
    return null;
}
export default DynamicFieldForm;
var templateObject_1;
