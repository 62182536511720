var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dropdown } from '@fluentui/react';
var TaskDetailsBodyPriorityWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 8px;\n  width: 100%;\n  margin-right: 1rem;\n\n  &:hover {\n    background-color: #f2f2f2;\n    cursor: pointer;\n    transition: background-color 500ms;\n    border-radius: 3px;\n\n    .c-date-picker .c-date-picker__text {\n      cursor: pointer;\n      background-color: #f2f2f2;\n      transition: background-color 500ms;\n    }\n  }\n\n  .c-date-picker__text {\n    padding-left: 6px;\n  }\n"], ["\n  padding-top: 8px;\n  width: 100%;\n  margin-right: 1rem;\n\n  &:hover {\n    background-color: #f2f2f2;\n    cursor: pointer;\n    transition: background-color 500ms;\n    border-radius: 3px;\n\n    .c-date-picker .c-date-picker__text {\n      cursor: pointer;\n      background-color: #f2f2f2;\n      transition: background-color 500ms;\n    }\n  }\n\n  .c-date-picker__text {\n    padding-left: 6px;\n  }\n"])));
function TaskDetailsBodyPriority(_a) {
    var _b = _a.required, required = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, onChange = _a.onChange, priority = _a.priority;
    var t = useTranslation().t;
    var dropDownRef = useRef(null);
    function onFocusDropdown() {
        if (dropDownRef === null || dropDownRef === void 0 ? void 0 : dropDownRef.current) {
            dropDownRef.current.focus(true);
        }
    }
    var menuItems = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(function (itemKey) {
        return {
            key: itemKey,
            text: t('taskCard.commandBar.button.priority', { context: itemKey.toString() }),
            data: { id: itemKey }
        };
    });
    return (React.createElement(TaskDetailsBodyPriorityWrapper, { onClick: onFocusDropdown },
        React.createElement(Dropdown, { label: t('taskDetailsBody.priority.label'), selectedKey: priority, componentRef: dropDownRef, disabled: disabled, options: menuItems, dropdownWidth: 155, onChange: function (_, option) {
                onChange(option.key);
            }, onRenderTitle: function (selectedItems) {
                if (selectedItems) {
                    return React.createElement("div", null, selectedItems[0].key);
                }
                return null;
            }, styles: {
                dropdown: {
                    width: 40
                },
                caretDownWrapper: {
                    backgroundColor: 'transparent'
                },
                title: {
                    border: 'none',
                    backgroundColor: 'transparent',
                    padding: 0
                },
                label: {
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#605e5c',
                    padding: 0
                },
                root: { backgroundColor: 'transparent', marginLeft: '8px' }
            } })));
}
export default TaskDetailsBodyPriority;
var templateObject_1;
