var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
export var RteWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1.5rem;\n  margin-top: 8px;\n\n  .rte-focused,\n  .rte-base {\n    position: relative;\n    min-height: 32px;\n  }\n\n  .rte-focused {\n    background-color: none;\n\n    &:after {\n      border: ", ";\n      border-radius: 3px;\n      bottom: -1px;\n      content: '';\n      left: -1px;\n      pointer-events: none;\n      position: absolute;\n      right: -1px;\n      top: -1px;\n    }\n\n    .public-DraftEditor-content {\n      min-height: 40px;\n    }\n  }\n\n  .rte-base {\n    border-radius: 3px;\n    transition: background-color 500ms;\n\n    &:hover {\n      background-color: #f2f2f2;\n    }\n  }\n\n  .rte-disabled {\n    background-color: #f3f2f1;\n    position: relative;\n    min-height: 32px;\n    color: #a19f9d;\n\n    * {\n      color: #a19f9d;\n    }\n  }\n"], ["\n  margin-bottom: 1.5rem;\n  margin-top: 8px;\n\n  .rte-focused,\n  .rte-base {\n    position: relative;\n    min-height: 32px;\n  }\n\n  .rte-focused {\n    background-color: none;\n\n    &:after {\n      border: ", ";\n      border-radius: 3px;\n      bottom: -1px;\n      content: '';\n      left: -1px;\n      pointer-events: none;\n      position: absolute;\n      right: -1px;\n      top: -1px;\n    }\n\n    .public-DraftEditor-content {\n      min-height: 40px;\n    }\n  }\n\n  .rte-base {\n    border-radius: 3px;\n    transition: background-color 500ms;\n\n    &:hover {\n      background-color: #f2f2f2;\n    }\n  }\n\n  .rte-disabled {\n    background-color: #f3f2f1;\n    position: relative;\n    min-height: 32px;\n    color: #a19f9d;\n\n    * {\n      color: #a19f9d;\n    }\n  }\n"])), function (props) { return "2px solid " + props.theme.themePrimary; });
function TaskPanelDescription(_a) {
    var description = _a.description, onDescriptionChange = _a.onDescriptionChange, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var _c = useState(false), focused = _c[0], setFocused = _c[1];
    function onFocus() {
        setFocused(true);
    }
    function onBlur() {
        setFocused(false);
    }
    return (React.createElement(RteWrapper, null,
        React.createElement(RichTextEditor, { defaultValue: description, disabled: disabled, displayCmdBar: focused, label: t('taskDetailsBody.description.label'), placeholder: t('taskDetailsBody.description.placeholder'), styles: {
                label: {
                    root: {
                        paddingBottom: '1px',
                        color: '#605e5c',
                        marginLeft: 0,
                        fontSize: '13px',
                        fontWeight: 400
                    }
                }
            }, onBlur: onBlur, onChange: function (_a) {
                var value = _a.value;
                return onDescriptionChange(value);
            }, onFocus: onFocus, required: false })));
}
export default TaskPanelDescription;
var templateObject_1;
