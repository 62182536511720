var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { IconButton } from '@fluentui/react/lib/Button';
var EditorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n\n  .public-DraftEditorPlaceholder-root {\n    position: absolute;\n    color: ", ";\n    pointer-events: none;\n  }\n\n  .public-DraftEditor-content {\n    height: ", ";\n    min-height: ", ";\n  }\n\n  img {\n    max-width: 50%;\n    max-height: 50%;\n  }\n"], ["\n  padding: ", ";\n\n  .public-DraftEditorPlaceholder-root {\n    position: absolute;\n    color: ", ";\n    pointer-events: none;\n  }\n\n  .public-DraftEditor-content {\n    height: ",
    ";\n    min-height: ",
    ";\n  }\n\n  img {\n    max-width: 50%;\n    max-height: 50%;\n  }\n"])), function (props) { return (props.readOnly ? '6px 0px 3px 8px' : '5px'); }, function (props) { return props.theme.neutralSecondary; }, function (props) {
    if (props.style && props.style.height) {
        return props.style.height;
    }
    return 'auto';
}, function (props) {
    if (props.style && props.style.minHeight) {
        return props.style.minHeight;
    }
    return 'auto';
});
var RichTextEditorStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  /* border: ", "; */\n  /* background-color: #eae8e7;\n  position: relative;\n\n  &:hover {\n    &:after {\n      border: 2px solid #d7d6d5;\n      border-radius: 2px;\n      bottom: -1px;\n      content: '';\n      left: -1px;\n      pointer-events: none;\n      position: absolute;\n      right: -1px;\n      top: -1px;\n    }\n  } */\n"], ["\n  /* border: ", "; */\n  /* background-color: #eae8e7;\n  position: relative;\n\n  &:hover {\n    &:after {\n      border: 2px solid #d7d6d5;\n      border-radius: 2px;\n      bottom: -1px;\n      content: '';\n      left: -1px;\n      pointer-events: none;\n      position: absolute;\n      right: -1px;\n      top: -1px;\n    }\n  } */\n"])), function (props) { return (props.readOnly ? 'none' : '1px solid black'); });
var MentionStyled = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { return props.theme.themePrimary; });
var TagStyled = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 600;\n  color: ", ";\n  cursor: ", ";\n"], ["\n  font-weight: 600;\n  color: ", ";\n  cursor: ", ";\n"])), function (props) { return props.theme.themePrimary; }, function (props) { return (props.readOnly ? 'pointer' : 'auto'); });
var RichStylingButton = styled(IconButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var RichTextEditorCmdBarStyled = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 2px 2px 0 2px;\n"], ["\n  padding: 2px 2px 0 2px;\n"])));
var SuggestionsWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  min-width: 250px;\n  border-radius: 3px;\n  margin: 0;\n  padding: 0;\n  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);\n  background: white;\n  position: fixed;\n  z-index: 10000000;\n  left: ", ";\n  top: ", ";\n\n  .suggestion-title {\n    padding-top: 0px;\n    padding-right: 12px;\n    padding-bottom: 0px;\n    padding-left: 12px;\n    font-size: 12px;\n    color: ", ";\n    line-height: 40px;\n    border-bottom: 1px solid rgb(237, 235, 233);\n    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,\n      BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;\n    -webkit-font-smoothing: antialiased;\n    font-weight: 400;\n  }\n"], ["\n  min-width: 250px;\n  border-radius: 3px;\n  margin: 0;\n  padding: 0;\n  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);\n  background: white;\n  position: fixed;\n  z-index: 10000000;\n  left: ", ";\n  top: ", ";\n\n  .suggestion-title {\n    padding-top: 0px;\n    padding-right: 12px;\n    padding-bottom: 0px;\n    padding-left: 12px;\n    font-size: 12px;\n    color: ", ";\n    line-height: 40px;\n    border-bottom: 1px solid rgb(237, 235, 233);\n    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,\n      BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;\n    -webkit-font-smoothing: antialiased;\n    font-weight: 400;\n  }\n"])), function (props) { return props.left + "px"; }, function (props) { return props.top + "px"; }, function (props) { return props.theme.themePrimary; });
var Suggestion = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 0;\n\n  background: ", ";\n  color: #343d46;\n  cursor: pointer;\n\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  margin: 0;\n\n  background: ", ";\n  color: #343d46;\n  cursor: pointer;\n\n  &:hover {\n    background: ", ";\n  }\n"])), function (_a) {
    var isSelected = _a.isSelected;
    return (isSelected ? '#edebe9' : 'white');
}, function (_a) {
    var isSelected = _a.isSelected;
    return (isSelected ? '#BAB8B6' : '#edebe9');
});
var PersonSuggestion = styled(Suggestion)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 7px 15px;\n"], ["\n  padding: 7px 15px;\n"])));
var TagSuggestion = styled(Suggestion)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 5px 15px;\n  font-size: 14px;\n  height: 34px;\n  font-weight: 600;\n  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,\n    BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;\n\n  .create-tag-suggestion {\n    color: ", ";\n    font-weight: 400;\n  }\n"], ["\n  padding: 5px 15px;\n  font-size: 14px;\n  height: 34px;\n  font-weight: 600;\n  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,\n    BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;\n\n  .create-tag-suggestion {\n    color: ", ";\n    font-weight: 400;\n  }\n"])), function (props) { return props.theme.themePrimary; });
export { EditorWrapper, MentionStyled, PersonSuggestion, RichStylingButton, RichTextEditorCmdBarStyled, RichTextEditorStyled, SuggestionsWrapper, TagStyled, TagSuggestion };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
