var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { DefaultButton, concatStyleSets } from '@fluentui/react';
import Label from '../Label/Label';
function BooleanField(_a) {
    var _b = _a.defaultValue, defaultValue = _b === void 0 ? null : _b, description = _a.description, _c = _a.disabled, disabled = _c === void 0 ? false : _c, displayResultOnly = _a.displayResultOnly, label = _a.label, labelIconName = _a.labelIconName, onChange = _a.onChange, _d = _a.required, required = _d === void 0 ? false : _d, _e = _a.textNOk, textNOk = _e === void 0 ? 'NOK' : _e, _f = _a.textOk, textOk = _f === void 0 ? 'OK' : _f, styles = _a.styles;
    var _g = useState(defaultValue), checked = _g[0], setChecked = _g[1];
    var positiveGreenColors = {
        color: '#2e7f48',
        borderColor: '#2e7f48',
        backgroundColor: '#a7cab4'
    };
    var negativeRedColors = {
        color: '#a4262c',
        borderColor: '#a4262c',
        backgroundColor: '#f4abb1'
    };
    var positiveStyles = {
        root: {
            transition: 'background-color 0.3s ease-out',
            marginRight: 10,
            borderRadius: 4,
            width: '100%'
        },
        rootChecked: __assign(__assign({}, positiveGreenColors), { marginRight: 10, borderRadius: 4 }),
        rootDisabled: { borderColor: '#a19f9d', borderRadius: 4 },
        rootCheckedDisabled: positiveGreenColors,
        rootCheckedHovered: { color: positiveGreenColors.color },
        rootHovered: positiveGreenColors
    };
    var negativeStyles = {
        root: { transition: 'background-color 0.3s ease-out', borderRadius: 4, width: '100%' },
        rootChecked: __assign(__assign({}, negativeRedColors), { borderRadius: 4 }),
        rootDisabled: { borderColor: '#a19f9d', borderRadius: 4 },
        rootCheckedDisabled: negativeRedColors,
        rootCheckedHovered: { color: negativeRedColors.color },
        rootHovered: negativeRedColors
    };
    if (styles === null || styles === void 0 ? void 0 : styles.positiveButton) {
        positiveStyles = concatStyleSets(positiveStyles, styles.positiveButton);
    }
    if (styles === null || styles === void 0 ? void 0 : styles.negativeButton) {
        negativeStyles = concatStyleSets(negativeStyles, styles.negativeButton);
    }
    function onButtonClick(buttonType) {
        var newCheckedValue = buttonType;
        if (checked === buttonType) {
            newCheckedValue = null;
        }
        setChecked(newCheckedValue);
        if (onChange) {
            onChange(newCheckedValue);
        }
    }
    var displayPositiveButton = !displayResultOnly || (displayResultOnly && disabled && checked === true);
    var displayNegativeButton = !displayResultOnly || (displayResultOnly && disabled && checked === false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Label, { description: description, iconName: labelIconName, label: label, required: required }),
        React.createElement("div", { style: { display: 'flex', width: '100%' } },
            displayPositiveButton && (React.createElement(DefaultButton, { allowDisabledFocus: true, checked: checked === true, disabled: disabled, onClick: function () { return onButtonClick(true); }, styles: positiveStyles, text: textOk })),
            displayNegativeButton && (React.createElement(DefaultButton, { allowDisabledFocus: true, checked: checked === false, disabled: disabled, onClick: function () { return onButtonClick(false); }, styles: negativeStyles, text: textNOk })))));
}
export default BooleanField;
