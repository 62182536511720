import React, { useEffect, useState } from 'react';
import AggregationBlock from './AggregationBlock';
import DefaultResultContent from './DefaultResultContent';
import SurveyResultOverflowButton from './SurveyResultOverflowButton';
function RatingResultContent(_a) {
    var fieldLink = _a.fieldLink;
    var _b = useState(false), showDetails = _b[0], setShowDetails = _b[1];
    var _c = useState(false), showAggregation = _c[0], setShowAggregation = _c[1];
    var values = fieldLink.values;
    useEffect(function () {
        if (values) {
            var shouldShowDetails = !values.find(function (value) { return value.aggregation === 4; });
            var shouldShowAggregation = !!values.find(function (value) { return value.aggregation !== 1; });
            setShowDetails(shouldShowDetails);
            setShowAggregation(shouldShowAggregation);
        }
    }, [values]);
    function renderAggregatedField() {
        return React.createElement(AggregationBlock, { fieldLink: fieldLink });
    }
    function renderDetails() {
        return (React.createElement("div", { style: { marginTop: showAggregation ? 10 : 0 } },
            React.createElement(DefaultResultContent, { fieldLink: fieldLink })));
    }
    return (React.createElement("div", null,
        showAggregation && (React.createElement(SurveyResultOverflowButton, { showDetails: showDetails, setShowDetails: setShowDetails })),
        showAggregation && renderAggregatedField(),
        showDetails && renderDetails()));
}
export default RatingResultContent;
