import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { getTheme } from '@fluentui/react/lib/Styling';
import { checkScreenWidth } from '../../utils/helpers';
import Navigation from './Navigation';

const PanelStyled = styled(Panel)`
  .panel-header {
    background-color: ${(props) => props.theme.themePrimary};
    height: 45px;
    display: flex;
    justify-content: space-between;
  }
`;

const TitleStyled = styled.div`
  padding-left: 15px;
  padding-top: 8px;
  color: white;
  font-size: 19px;
  font-weight: 100;
  width: 100%;
`;

export default function NavigationPanel({ showNavigationPanel, toggleSideBar }) {
  return (
    <div>
      <PanelStyled
        hasCloseButton={false}
        onRenderNavigation={() => (
          <div
            className="panel-header"
            style={{
              backgroundColor: getTheme().palette.themePrimary
            }}
          >
            <TitleStyled>{window.theme.title}</TitleStyled>
            <IconButton
              iconProps={{
                iconName: 'ChromeClose'
              }}
              styles={{
                root: {
                  color: 'white',
                  fontWeight: 100,
                  paddingTop: '12px',
                  paddingRight: '20px',
                  fontSize: '16px'
                },
                rootHovered: {
                  color: 'white',
                  backgroundColor: getTheme().palette.themePrimary
                },
                rootPressed: {
                  color: 'white',
                  backgroundColor: getTheme().palette.themePrimary
                }
              }}
              onClick={() => toggleSideBar()}
            />
          </div>
        )}
        isOpen={showNavigationPanel}
        styles={{
          contentInner: { height: '100%' },
          scrollableContent: { height: '100%' },
          main: {
            maxWidth: checkScreenWidth(['extraSmall', 'small']) ? '100%' : '230px'
          },
          navigation: [
            {
              backgroundColor: getTheme().palette.themePrimary
            }
          ]
        }}
        isLightDismiss
        type={
          checkScreenWidth(['extraSmall', 'small'])
            ? PanelType.smallFluid
            : PanelType.smallFixedNear
        }
        onRenderBody={() => <Navigation toggleSideBar={toggleSideBar} />}
      />
    </div>
  );
}

NavigationPanel.propTypes = {
  showNavigationPanel: PropTypes.bool.isRequired,
  toggleSideBar: PropTypes.func
};

NavigationPanel.defaultProps = {
  toggleSideBar: null
};
