import fetchRequest from './api';

export function tagsSearch({ searchTerm, onlyParents, parentId, showDeleted }) {
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let url = `tags?searchTerms=${encodedSearchTerm}`;

  if (onlyParents) {
    url += '&onlyParents=true';
  }

  if (parentId) {
    url += `&parentId=${parentId}`;
  }

  if (showDeleted) {
    url += `&showDeleted=true`;
  }

  return fetchRequest({ url });
}

export function userSearch({ searchTerm, type = 0, teamId }) {
  const encodedSearchTerm = encodeURIComponent(searchTerm);
  let url = `User/Search?searchTerm=${encodedSearchTerm}&type=${type}`;

  if (teamId) {
    url += `&teamId=${teamId}`;
  }

  return fetchRequest({ url });
}

export function teamsSearch({ searchTerm, userId }) {
  const encodedSearchTerm = encodeURIComponent(searchTerm);
  let url = `Teams?searchTerm=${encodedSearchTerm}`;

  if (userId) {
    url += `&userId=${userId}`;
  }

  return fetchRequest({ url });
}

export function getTask({ id }) {
  const url = `Task/${id}`;

  return fetchRequest({ url });
}

export function createTask(task) {
  const url = `Task`;
  const body = JSON.stringify(task);

  return fetchRequest({ url, method: 'POST', body });
}

export function updateTask(task) {
  const url = `Task`;
  const body = JSON.stringify(task);

  return fetchRequest({ url, method: 'PUT', body });
}

/**
 * Gets the contents of a single file as byte stream. If the file is an image it can also be resized.
 */
export function fileContents({ id, size }) {
  let url = `File/Contents?id=${id}`;

  if (size) {
    url += `&size=${size}`;
  }

  return fetchRequest({ url, ignoreContentType: true });
}

export function getFile({ id }) {
  const url = `File/${id}`;

  return fetchRequest({ url, ignoreContentType: true });
}

export function getLookUpInstance({ instanceId, fieldIds }) {
  const url = 'Route/Instance/LookupRow';
  const method = 'POST';
  const body = JSON.stringify({
    instanceId,
    displayFieldIds: fieldIds
  });

  return fetchRequest({ url, method, body });
}

export function searchLookUpInstances({ searchTerm, definitionId, lookupType, outcome, fieldIds }) {
  const url = `Route/Instance/LookupList`;
  const method = 'POST';
  const body = JSON.stringify({
    definitionId,
    lookupType,
    outcome,
    searchTerms: searchTerm,
    displayFieldIds: fieldIds
  });

  return fetchRequest({ url, method, body });
}

export function evaluateFieldGroup(fieldGroup) {
  const url = `Route/FieldGroup/EvalRules`;
  const method = 'PUT';
  const body = JSON.stringify(fieldGroup);

  return fetchRequest({ url, method, body });
}

export function uploadFile({ file, taskId, fieldId, definitionId }) {
  const url = `File`;
  const method = 'POST';

  // build body
  const body = new FormData();
  body.append('file', file, file.name);

  if (taskId) {
    body.append('relatedTask', taskId);
  }

  if (fieldId) {
    body.append('relatedField', fieldId);
  }

  if (definitionId) {
    body.append('relatedDefinition', definitionId);
  }

  return fetchRequest({ url, method, body, ignoreContentType: true });
}

export function getDefinition({ id, status, version }) {
  let url = `Route/Definition/${id}`;

  if (status) {
    url += `&status=${status}`;
  }

  if (version) {
    url += `&version=${version}`;
  }

  return fetchRequest({ url });
}

export function getDefinitionExternalDataList({ id, search }) {
  let url = `Route/Definition/ExternalDataList/${id}`;

  if (search) {
    const encodedSearchTerm = encodeURIComponent(search);

    url += `?search=${encodedSearchTerm}`;
  }

  return fetchRequest({ url });
}

export function getDefinitionExternalData({ parameterString }) {
  const url = `Route/Definition/ExternalData/${parameterString}`;

  return fetchRequest({ url });
}
