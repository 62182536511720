var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useLayoutEffect } from 'react';
import Quagga from '@ericblade/quagga2';
import QrCodeReader from './QrCodeReader';
Quagga.registerReader('qrcode', QrCodeReader);
var defaultConstraints = {
    width: 640,
    height: 480
};
var defaultLocatorSettings = {
    patchSize: 'medium',
    halfSample: true
};
var defaultDecoders = [
    'qrcode'
];
var Scanner = function (_a) {
    var cameraId = _a.cameraId, _b = _a.constraints, constraints = _b === void 0 ? defaultConstraints : _b, _c = _a.decoders, decoders = _c === void 0 ? defaultDecoders : _c, facingMode = _a.facingMode, _d = _a.locate, locate = _d === void 0 ? true : _d, _e = _a.locator, locator = _e === void 0 ? defaultLocatorSettings : _e, _f = _a.numOfWorkers, numOfWorkers = _f === void 0 ? navigator.hardwareConcurrency || 0 : _f, onDetected = _a.onDetected, onScannerReady = _a.onScannerReady, onScannerError = _a.onScannerError, scannerRef = _a.scannerRef;
    var errorCheck = useCallback(function (result) {
        if (!onDetected) {
            return;
        }
        Quagga.stop();
        Quagga.offDetected(errorCheck);
        onDetected(result.codeResult.code);
    }, [onDetected]);
    var quaggaCallback = useCallback(function (error) {
        if (error && onScannerError) {
            onScannerError(error);
        }
        if (scannerRef && scannerRef.current) {
            Quagga.start();
            if (onScannerReady) {
                onScannerReady();
            }
        }
    }, [onScannerError, onScannerReady, scannerRef]);
    useLayoutEffect(function () {
        var quaggaConfig = {
            inputStream: {
                type: 'LiveStream',
                constraints: __assign(__assign(__assign({}, constraints), (cameraId && { deviceId: cameraId })), (!cameraId && { facingMode: facingMode })),
                target: (scannerRef === null || scannerRef === void 0 ? void 0 : scannerRef.current) || ''
            },
            locator: locator,
            numOfWorkers: numOfWorkers,
            decoder: { readers: decoders },
            locate: locate
        };
        Quagga.init(quaggaConfig, quaggaCallback);
        Quagga.onDetected(errorCheck);
        return function () {
            Quagga.offDetected(errorCheck);
            Quagga.stop();
        };
    }, [
        cameraId,
        onDetected,
        onScannerReady,
        scannerRef,
        errorCheck,
        constraints,
        locator,
        decoders,
        locate,
        facingMode,
        numOfWorkers,
        quaggaCallback
    ]);
    return null;
};
export default Scanner;
