var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { IconButton } from '@fluentui/react';
var MultiMediaFieldStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .document-image-preview {\n    height: 50px;\n    max-width: 150px;\n    object-fit: cover;\n\n    :hover {\n      cursor: pointer;\n    }\n  }\n\n  .c-preview-overlay_overflow-button {\n    display: none;\n    position: absolute;\n    top: 5px;\n    right: 5px;\n  }\n\n  .c-preview-overlay_wrapper:hover {\n    cursor: pointer;\n\n    .c-preview-overlay_overflow-button {\n      z-index: 99;\n      display: inline;\n    }\n  }\n\n  .c-preview-overlay {\n    z-index: 90;\n    width: 132px;\n    height: 132px;\n    position: absolute;\n    background: transparent;\n    top: 0px;\n    left: 0px;\n  }\n\n  .c-preview-icon {\n    z-index: 90;\n    font-size: 28px;\n    color: white;\n    position: absolute;\n    top: 50px;\n  }\n"], ["\n  .document-image-preview {\n    height: 50px;\n    max-width: 150px;\n    object-fit: cover;\n\n    :hover {\n      cursor: pointer;\n    }\n  }\n\n  .c-preview-overlay_overflow-button {\n    display: none;\n    position: absolute;\n    top: 5px;\n    right: 5px;\n  }\n\n  .c-preview-overlay_wrapper:hover {\n    cursor: pointer;\n\n    .c-preview-overlay_overflow-button {\n      z-index: 99;\n      display: inline;\n    }\n  }\n\n  .c-preview-overlay {\n    z-index: 90;\n    width: 132px;\n    height: 132px;\n    position: absolute;\n    background: transparent;\n    top: 0px;\n    left: 0px;\n  }\n\n  .c-preview-icon {\n    z-index: 90;\n    font-size: 28px;\n    color: white;\n    position: absolute;\n    top: 50px;\n  }\n"])));
var IconButtonStyled = styled(IconButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export { MultiMediaFieldStyled, IconButtonStyled };
var templateObject_1, templateObject_2;
