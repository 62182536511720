import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTheme } from '@fluentui/react';
import FileManagement from '../FileManagement/FileManagement';
import { RichStylingButton, RichTextEditorCmdBarStyled } from './RichTextEditor.styles';
function RichTextEditorCmdBar(_a) {
    var attachImage = _a.attachImage, displayCmdBar = _a.displayCmdBar, openLinkDialog = _a.openLinkDialog, removeLink = _a.removeLink, toggleBlockType = _a.toggleBlockType, toggleInlineStyle = _a.toggleInlineStyle;
    var t = useTranslation().t;
    var theme = getTheme();
    var iconButtonStyles = {
        icon: {
            fontSize: 16,
            color: theme.palette.neutralPrimary
        }
    };
    return (React.createElement(RichTextEditorCmdBarStyled, { style: {
            height: displayCmdBar ? 'auto' : 0,
            visibility: displayCmdBar ? 'visible' : 'hidden'
        } },
        React.createElement(RichStylingButton, { iconProps: { iconName: 'Bold' }, onClick: function () { return toggleInlineStyle('BOLD'); }, onMouseDown: function (e) { return e.preventDefault(); }, styles: iconButtonStyles, title: t('rte.icon.button.title.bold') }),
        React.createElement(RichStylingButton, { iconProps: { iconName: 'Italic' }, onClick: function () { return toggleInlineStyle('ITALIC'); }, onMouseDown: function (e) { return e.preventDefault(); }, styles: iconButtonStyles, title: t('rte.icon.button.title.italic') }),
        React.createElement(RichStylingButton, { iconProps: { iconName: 'Underline' }, onClick: function () { return toggleInlineStyle('UNDERLINE'); }, onMouseDown: function (e) { return e.preventDefault(); }, styles: iconButtonStyles, title: t('rte.icon.button.title.underline') }),
        React.createElement(RichStylingButton, { iconProps: { iconName: 'BulletedList' }, onClick: function () { return toggleBlockType('unordered-list-item'); }, onMouseDown: function (e) { return e.preventDefault(); }, styles: iconButtonStyles, title: t('rte.icon.button.title.bulletedList') }),
        React.createElement(RichStylingButton, { iconProps: { iconName: 'NumberedList' }, onClick: function () { return toggleBlockType('ordered-list-item'); }, onMouseDown: function (e) { return e.preventDefault(); }, styles: iconButtonStyles, title: t('rte.icon.button.title.numberedList') }),
        React.createElement(RichStylingButton, { iconProps: { iconName: 'Strikethrough' }, onClick: function () { return toggleInlineStyle('STRIKETHROUGH'); }, onMouseDown: function (e) { return e.preventDefault(); }, styles: iconButtonStyles, title: t('rte.icon.button.title.strikethrough') }),
        React.createElement(RichStylingButton, { iconProps: { iconName: 'Link' }, onClick: function () { return openLinkDialog(); }, onMouseDown: function (e) { return e.preventDefault(); }, styles: iconButtonStyles, title: t('rte.icon.button.title.createLink') }),
        React.createElement(RichStylingButton, { iconProps: { iconName: 'RemoveLink' }, onClick: function () { return removeLink(); }, onMouseDown: function (e) { return e.preventDefault(); }, styles: iconButtonStyles, title: t('rte.icon.button.title.removeLink') }),
        React.createElement("div", { role: "button", style: { display: 'inline-block' } },
            React.createElement(FileManagement, { className: "upload-image", onChange: attachImage, clickable: true, multiple: false, accepts: ['image/*'] },
                React.createElement(RichStylingButton, { onMouseDown: function (e) { return e.preventDefault(); }, iconProps: { iconName: 'PictureCenter' }, title: t('rte.icon.button.title.attachImage'), styles: iconButtonStyles })))));
}
export default RichTextEditorCmdBar;
