import React, { useState } from 'react';
import { TextField } from '@fluentui/react';
import Label from '../Label/Label';
function SmallTextField(_a) {
    var defaultValue = _a.defaultValue, description = _a.description, _b = _a.disabled, disabled = _b === void 0 ? false : _b, label = _a.label, labelIconName = _a.labelIconName, _c = _a.maxLength, maxLength = _c === void 0 ? 150 : _c, _d = _a.maxSingleLineChars, maxSingleLineChars = _d === void 0 ? 150 : _d, multiline = _a.multiline, onChange = _a.onChange, placeholder = _a.placeholder, _e = _a.required, required = _e === void 0 ? false : _e, styles = _a.styles;
    var _f = useState((defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.length) || 0), charsLength = _f[0], setCharsLength = _f[1];
    function onTextFieldChange(_, newValue) {
        if (onChange)
            onChange(newValue);
        setCharsLength((newValue === null || newValue === void 0 ? void 0 : newValue.length) || 0);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Label, { description: description, iconName: labelIconName, label: label, required: required }),
        React.createElement(TextField, { autoComplete: "off", defaultValue: defaultValue, disabled: disabled, maxLength: maxLength, multiline: multiline || maxSingleLineChars < charsLength, onChange: onTextFieldChange, placeholder: placeholder, styles: styles })));
}
export default SmallTextField;
