import React, { useEffect, useState } from 'react';
import { Rating as FabricRating, concatStyleSets } from '@fluentui/react';
import Label from '../Label/Label';
import RatingStyled from './Rating.styles';
function Rating(_a) {
    var defaultValue = _a.defaultValue, description = _a.description, _b = _a.disabled, disabled = _b === void 0 ? false : _b, label = _a.label, labelIconName = _a.labelIconName, _c = _a.maxRating, maxRating = _c === void 0 ? 5 : _c, onChange = _a.onChange, _d = _a.required, required = _d === void 0 ? false : _d, styles = _a.styles;
    var _e = useState(defaultValue), ratingState = _e[0], setRatingState = _e[1];
    useEffect(function () {
        setRatingState(defaultValue);
    }, [defaultValue]);
    function onRatingChange(_, newRating) {
        if (newRating) {
            setRatingState(newRating);
            if (onChange) {
                onChange(newRating);
            }
        }
    }
    function getRatingFieldStyles() {
        var fieldStyles = {
            root: {
                position: 'relative',
                marginTop: 5,
                height: '32px',
                border: '1px solid #a19f9d',
                borderRadius: '3px',
                ':hover': {
                    border: '1px solid black'
                }
            },
            ratingFocusZone: {
                paddingLeft: 3
            }
        };
        if (disabled) {
            var disabledStyles = {
                root: {
                    backgroundColor: '#f3f2f1',
                    ':hover': { border: '1px solid #a19f9d' }
                }
            };
            fieldStyles = concatStyleSets(fieldStyles, disabledStyles);
        }
        if (styles) {
            fieldStyles = concatStyleSets(fieldStyles, styles);
        }
        return fieldStyles;
    }
    var ratingStyles = getRatingFieldStyles();
    return (React.createElement(RatingStyled, null,
        React.createElement(Label, { label: label, required: required, iconName: labelIconName, description: description }),
        React.createElement(FabricRating, { allowZeroStars: true, max: maxRating, onChange: onRatingChange, rating: ratingState, readOnly: disabled, styles: ratingStyles })));
}
export default Rating;
