var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { NotificationGroupType, TaskType } from '@evocom/types';
import { ActionButton, ActivityItem, IconButton } from '@fluentui/react';
import Activity from './Activity';
export default function GroupedActivityContainer(_a) {
    var _b;
    var callInterpolationFunction = _a.callInterpolationFunction, getActivityIcon = _a.getActivityIcon, getCreatorTooltip = _a.getCreatorTooltip, groupedActivity = _a.groupedActivity, handleOnActivityClick = _a.handleOnActivityClick, handleOnGroupedActivityClick = _a.handleOnGroupedActivityClick, handleOnGroupedReadIconClick = _a.handleOnGroupedReadIconClick, handleOnReadIconClick = _a.handleOnReadIconClick, timeAgo = _a.timeAgo;
    var t = useTranslation().t;
    var groupChildrenRef = useRef();
    var _c = useState(false), isGroupOpen = _c[0], setIsGroupOpen = _c[1];
    var _d = useState(0), childrenHeight = _d[0], setChildrenHeight = _d[1];
    useLayoutEffect(function () {
        if (groupChildrenRef.current) {
            setChildrenHeight(groupChildrenRef.current.clientHeight);
        }
    }, []);
    var childrenLength = groupedActivity.notifications.length;
    function getGroupedActivityIcon() {
        if (groupedActivity.notifications.length === 1) {
            return getActivityIcon(groupedActivity.notifications[0].type);
        }
        if (groupedActivity.type === 1) {
            return getActivityIcon(1);
        }
        if (groupedActivity.type === 2) {
            return getActivityIcon(21);
        }
        if (groupedActivity.type === 3) {
            return getActivityIcon(38);
        }
        if (groupedActivity.type === 4) {
            return getActivityIcon(36);
        }
        return getActivityIcon(groupedActivity.notifications[childrenLength - 1].type);
    }
    var activityItemProps = {
        activityDescription: getGroupedActivityDescription(groupedActivity),
        comments: null,
        activityIcon: getGroupedActivityIcon(),
        timeStamp: timeAgo((_b = groupedActivity.notifications[childrenLength - 1]) === null || _b === void 0 ? void 0 : _b.creationDate)
    };
    var hasUnreadActivity = !!groupedActivity.notifications.find(function (activity) { return activity.isNew; });
    function getTaskTypeDescription(typeId) {
        var taskType = typeId || TaskType.Task;
        return t("activitiesList.task.type." + taskType);
    }
    function getDistinctCreators(notifications) {
        var distinctCreators = [];
        notifications.map(function (notification) {
            var alreadyPushed = distinctCreators.find(function (dc) { return dc.userId === notification.creator.userId; });
            if (!alreadyPushed) {
                distinctCreators.push(notification.creator);
            }
            return undefined;
        });
        return distinctCreators;
    }
    function getGroupedActivityTaskDescription(groupedActivity) {
        var _a, _b, _c, _d, _e, _f, _g;
        var notifications = groupedActivity.notifications;
        var taskDeleteTypes = [33, 34, 44, 49, 50, 53, 54, 56, 58, 60, 62, 64, 66, 68];
        var deletedTaskNotification = notifications.find(function (notification) {
            return taskDeleteTypes.includes(notification.type);
        });
        var taskCreateTypes = [1, 30, 43, 45, 46, 51, 52, 55, 57, 59, 61, 63, 65, 67];
        var createdTaskNotification = notifications.find(function (notification) {
            return taskCreateTypes.includes(notification.type);
        });
        var translation = null;
        var description = [];
        if (deletedTaskNotification) {
            description.push(getCreatorTooltip(deletedTaskNotification));
            translation = t('activitiesList.group.header.task', {
                context: 'deletedc',
                taskType: getTaskTypeDescription((_a = deletedTaskNotification.oldTask) === null || _a === void 0 ? void 0 : _a.typeId),
                taskTitle: ((_b = deletedTaskNotification.oldTask) === null || _b === void 0 ? void 0 : _b.title) || ''
            });
        }
        else if (createdTaskNotification) {
            description.push(getCreatorTooltip(createdTaskNotification));
            translation = t('activitiesList.group.header.task', {
                context: 'created',
                taskType: getTaskTypeDescription((_c = createdTaskNotification.newTask) === null || _c === void 0 ? void 0 : _c.typeId),
                taskTitle: ((_d = createdTaskNotification.newTask) === null || _d === void 0 ? void 0 : _d.title) || ''
            });
        }
        else {
            var firstUpdateNotification_1 = notifications[notifications.length - 1];
            description.push(getCreatorTooltip(firstUpdateNotification_1));
            var distinctCreators = getDistinctCreators(notifications.filter(function (n) { var _a, _b; return ((_a = n.creator) === null || _a === void 0 ? void 0 : _a.userId) !== ((_b = firstUpdateNotification_1.creator) === null || _b === void 0 ? void 0 : _b.userId); }));
            var taskType = getTaskTypeDescription((_e = firstUpdateNotification_1.newTask) === null || _e === void 0 ? void 0 : _e.typeId);
            var taskTitle = ((_f = firstUpdateNotification_1.oldTask) === null || _f === void 0 ? void 0 : _f.title) || ((_g = firstUpdateNotification_1.newTask) === null || _g === void 0 ? void 0 : _g.title);
            translation = t('activitiesList.group.header.task', {
                context: 'updated',
                count: distinctCreators.length > 0 ? distinctCreators.length : undefined,
                taskType: taskType,
                taskTitle: taskTitle
            });
        }
        if (translation) {
            description.push(getDescriptionSpan(notifications[0].id, translation));
        }
        return description;
    }
    function getGroupedActivityRouteInstanceDescription(groupedActivity) {
        var notifications = groupedActivity.notifications;
        var firstUpdateNotification = notifications[notifications.length - 1];
        var translationsArray = t("activitiesList.activity." + firstUpdateNotification.type + ".description", {
            firstUpdateNotification: firstUpdateNotification,
            returnObjects: true
        });
        return translationsArray.map(function (translation) {
            if (translation.indexOf('fx.') === 0) {
                var interpolationFunctionName = translation.split('.')[1];
                return callInterpolationFunction(interpolationFunctionName, firstUpdateNotification);
            }
            return getDescriptionSpan(firstUpdateNotification.id, translation);
        });
    }
    function getGroupedActivityRouteDefinitionDescription(groupedActivity) {
        var notifications = groupedActivity.notifications;
        var firstUpdateNotification = notifications[notifications.length - 1];
        var deletedRouteDefinitionNotification = notifications.find(function (notification) { return notification.type === 37; });
        var createdRouteDefinitionNotification = notifications.find(function (notification) { return notification.type === 35; });
        var translation = null;
        var description = [];
        if (deletedRouteDefinitionNotification) {
            description.push(getCreatorTooltip(deletedRouteDefinitionNotification));
            translation = t('activitiesList.group.header.routeDefinition', {
                context: 'deletedb',
                activity: firstUpdateNotification
            });
        }
        else if (createdRouteDefinitionNotification) {
            description.push(getCreatorTooltip(createdRouteDefinitionNotification));
            translation = t('activitiesList.group.header.routeDefinition', {
                context: 'created',
                activity: firstUpdateNotification
            });
        }
        else {
            description.push(getCreatorTooltip(firstUpdateNotification));
            var distinctCreators = getDistinctCreators(notifications.filter(function (n) { var _a, _b; return ((_a = n.creator) === null || _a === void 0 ? void 0 : _a.userId) !== ((_b = firstUpdateNotification.creator) === null || _b === void 0 ? void 0 : _b.userId); }));
            translation = t('activitiesList.group.header.routeDefinition', {
                context: 'updated',
                count: distinctCreators.length > 0 ? distinctCreators.length : undefined,
                activity: firstUpdateNotification
            });
        }
        if (translation) {
            description.push(getDescriptionSpan(notifications[0].id, translation));
        }
        return description;
    }
    function getGroupedActivityTeamDescription(groupedActivity) {
        var notifications = groupedActivity.notifications;
        var firstUpdateNotification = notifications[notifications.length - 1];
        var deletedTeamNotification = notifications.find(function (notification) { return notification.type === 26; });
        var createdTeamNotification = notifications.find(function (notification) { return notification.type === 21; });
        var translation = null;
        var description = [];
        if (deletedTeamNotification) {
            description.push(getCreatorTooltip(deletedTeamNotification));
            translation = t('activitiesList.group.header.team', {
                context: 'deleteda',
                activity: firstUpdateNotification
            });
        }
        else if (createdTeamNotification) {
            description.push(getCreatorTooltip(createdTeamNotification));
            translation = t('activitiesList.group.header.team', {
                context: 'created',
                activity: firstUpdateNotification
            });
        }
        else {
            description.push(getCreatorTooltip(firstUpdateNotification));
            var distinctCreators = getDistinctCreators(notifications.filter(function (n) { var _a, _b; return ((_a = n.creator) === null || _a === void 0 ? void 0 : _a.userId) !== ((_b = firstUpdateNotification.creator) === null || _b === void 0 ? void 0 : _b.userId); }));
            translation = t('activitiesList.group.header.team', {
                context: 'updated',
                count: distinctCreators.length > 0 ? distinctCreators.length : undefined,
                activity: firstUpdateNotification
            });
        }
        if (translation) {
            description.push(getDescriptionSpan(notifications[0].id, translation));
        }
        return description;
    }
    function getDescriptionSpan(id, translation) {
        return (React.createElement("span", { key: "activity-header-description-" + id + "-text", className: "activity-description" }, translation));
    }
    function getGroupedActivityDescription(groupedActivity) {
        if (groupedActivity.type === NotificationGroupType.Task) {
            return getGroupedActivityTaskDescription(groupedActivity);
        }
        if (groupedActivity.type === NotificationGroupType.Team) {
            return getGroupedActivityTeamDescription(groupedActivity);
        }
        if (groupedActivity.type === NotificationGroupType.RouteInstance) {
            return getGroupedActivityRouteInstanceDescription(groupedActivity);
        }
        if (groupedActivity.type === NotificationGroupType.RouteDefinition) {
            return getGroupedActivityRouteDefinitionDescription(groupedActivity);
        }
        return [];
    }
    function onReadIconClick(activity, groupedActivity) {
        if (activity && !!handleOnReadIconClick) {
            handleOnReadIconClick(activity);
        }
        if (groupedActivity && !!handleOnGroupedReadIconClick) {
            handleOnGroupedReadIconClick(groupedActivity);
        }
    }
    function getNewActivityIcons(activity, groupedActivity) {
        return (React.createElement("div", { className: "new-notification-container" },
            React.createElement(IconButton, { iconProps: { iconName: 'RedEye' }, onClick: function () { return onReadIconClick(activity, groupedActivity); }, className: "read-notification-button", title: t('activitiesList.mark-as-read') }),
            React.createElement("div", { className: "new-notification-indicator" })));
    }
    function onGroupedActivityClick(groupedActivity) {
        if (handleOnGroupedActivityClick) {
            handleOnGroupedActivityClick(groupedActivity);
        }
    }
    function getGroupedActivityHeader() {
        var key = groupedActivity.notifications[0].id + "-header";
        return (React.createElement("div", { style: { paddingBottom: '7px' } },
            React.createElement("div", { key: key, className: "activity-list-item " + (hasUnreadActivity ? 'unread-notification' : '') },
                React.createElement("div", { "aria-hidden": "true", onClick: function () { return onGroupedActivityClick(groupedActivity); }, className: "activity-item " + (handleOnGroupedActivityClick ? 'active' : '') },
                    React.createElement(ActivityItem, __assign({}, activityItemProps))),
                getNewActivityIcons(undefined, groupedActivity),
                React.createElement(ActionButton, { styles: {
                        root: {
                            bottom: 5,
                            height: 'fit-content',
                            minHeight: 10,
                            minWidth: 150,
                            position: 'absolute',
                            right: 0,
                            width: 'fit-content'
                        },
                        flexContainer: { fontSize: 10 }
                    }, onClick: function () {
                        setIsGroupOpen(function (prevState) { return !prevState; });
                    }, text: t('activitiesList.group.header.moreActivitiesButton', {
                        activitiesCount: childrenLength
                    }) }))));
    }
    if (!groupedActivity.notifications.length) {
        return null;
    }
    if (groupedActivity.notifications.length === 1) {
        return (React.createElement(Activity, { activity: groupedActivity.notifications[0], callInterpolationFunction: callInterpolationFunction, getActivityIcon: getActivityIcon, handleOnActivityClick: handleOnActivityClick, handleOnReadIconClick: handleOnReadIconClick, key: groupedActivity.notifications[0].id, timeAgo: timeAgo }));
    }
    var children = groupedActivity.notifications.map(function (activity) {
        return (React.createElement(Activity, { activity: activity, callInterpolationFunction: callInterpolationFunction, getActivityIcon: getActivityIcon, handleOnActivityClick: handleOnActivityClick, handleOnReadIconClick: handleOnReadIconClick, key: activity.id, timeAgo: timeAgo }));
    });
    function getActivityChildren() {
        var transitionStyles = {
            entering: { height: childrenHeight, opacity: 1 },
            entered: { height: 'auto', opacity: 1 },
            exiting: { height: childrenHeight, opacity: 0 },
            exited: { height: 0, opacity: 0 }
        };
        var duration = 300;
        return (React.createElement(Transition, { in: isGroupOpen, timeout: { appear: 0, enter: duration, exit: 0 } }, function (state) {
            return (React.createElement("div", { style: __assign({ overflow: 'hidden', transition: "height " + duration + "ms ease-out, opacity " + duration + "ms ease-out", marginLeft: '15px' }, transitionStyles[state]) }, children));
        }));
    }
    if (!childrenHeight) {
        return (React.createElement("div", { style: { overflow: 'hidden', marginLeft: '15px', height: 'auto' }, ref: groupChildrenRef }, children));
    }
    return (React.createElement(React.Fragment, null,
        getGroupedActivityHeader(),
        getActivityChildren()));
}
