import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@fluentui/react/';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
function RichTextEditorLinkDialog(_a) {
    var onDismiss = _a.onDismiss, onConfirm = _a.onConfirm, defaultLink = _a.defaultLink;
    var t = useTranslation().t;
    var _b = useState(''), linkText = _b[0], setLinkText = _b[1];
    function onTextFieldKeyPress(ev) {
        if (ev.which === 13) {
            if (linkText) {
                onConfirm(linkText);
            }
        }
    }
    return (React.createElement(Dialog, { hidden: false, onDismiss: function () { return onDismiss(); }, dialogContentProps: {
            type: DialogType.normal,
            title: t('rte.dialog.link.title')
        } },
        React.createElement(TextField, { label: t('rte.dialog.link.subtitle'), required: true, defaultValue: defaultLink || undefined, onKeyPress: function (ev) {
                return onTextFieldKeyPress(ev);
            }, onChange: function (_, value) { return setLinkText(value); }, autoFocus: true }),
        React.createElement(DialogFooter, null,
            React.createElement(PrimaryButton, { disabled: !linkText, onClick: function () {
                    if (linkText) {
                        onConfirm(linkText);
                    }
                }, text: t('link.dialog.confirmation.button.primary') }),
            React.createElement(DefaultButton, { onClick: function () { return onDismiss(); }, text: t('link.dialog.cancel.button.default') }))));
}
export default RichTextEditorLinkDialog;
