import PropTypes from 'prop-types';
import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      dismissButtonAriaLabel="Close"
      overflowButtonAriaLabel="See more"
      onDismiss={() => {}}
      actions={
        <div>
          <MessageBarButton onClick={resetErrorBoundary}>try again</MessageBarButton>
        </div>
      }
      styles={{ root: { maxWidth: '500px' } }}
    >
      <div style={{ fontSize: 17 }}>Something went wrong:</div>
      <div>{error.message}</div>
    </MessageBar>
  );
}

export function ErrorBoundary({ children, fallbackComponent }) {
  return (
    <ReactErrorBoundary
      onReset={() => window.location.reload()}
      FallbackComponent={fallbackComponent || ErrorFallback}
    >
      {children}
    </ReactErrorBoundary>
  );
}

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
  error: PropTypes.any
};

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  fallbackComponent: PropTypes.func
};
