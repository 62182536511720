import React from 'react';
import { Icon, TooltipHost, concatStyleSets } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
function InfoIconTooltip(_a) {
    var content = _a.content, styles = _a.styles, _b = _a.closeDelay, closeDelay = _b === void 0 ? 500 : _b, iconName = _a.iconName;
    var tooltipId = useId('tooltip');
    function getTootipStyles() {
        var tooltipStyles = {
            root: { root: { display: 'inline-block' } }
        };
        if (styles === null || styles === void 0 ? void 0 : styles.tooltipStyles) {
            tooltipStyles = concatStyleSets(tootipStyles, styles.tooltipStyles);
        }
        return tooltipStyles;
    }
    function getIconStyles() {
        var iconStyles = {
            root: {
                fontSize: '15px',
                color: '#a6a6a6',
                selectors: { ':hover': { cursor: 'help' } }
            }
        };
        if (styles === null || styles === void 0 ? void 0 : styles.iconStyles) {
            iconStyles = concatStyleSets(iconStyles, styles.iconStyles);
        }
        return iconStyles;
    }
    if (!content) {
        return null;
    }
    var calloutProps = { gapSpace: 0 };
    var tootipStyles = getTootipStyles();
    var iconStyles = getIconStyles();
    return (React.createElement(TooltipHost, { content: content, id: tooltipId, closeDelay: closeDelay, calloutProps: calloutProps, styles: tootipStyles },
        React.createElement(Icon, { iconName: iconName || 'Info', styles: iconStyles })));
}
export default InfoIconTooltip;
