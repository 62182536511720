var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChoiceFieldFormat, DateTimeFieldFormat, RouteFieldType, RouteFieldVisibility } from '@evocom/types';
import { convertPersonaIntoEpUser, getFluentIconNameByFieldType } from '@evocom/utils';
import BooleanField from '../BooleanField/BooleanField';
import ChoiceGroupField from '../ChoiceGroupField/ChoiceGroupField';
import CodeScannerField from '../CodeScannerField/CodeScannerField';
import CombinedPicker from '../CombinedPicker/CombinedPicker';
import DateTime from '../DateTime/DateTime';
import DocumentField from '../DocumentField/DocumentField';
import DynamicFieldSection, { SectionType } from '../DynamicFieldSection/DynamicFieldSection';
import DynamicSurveyResultField from '../DynamicSurveyResultField/DynamicSurveyResultField';
import HyperLinkField from '../HyperLinkField/HyperLinkField';
import IntegrationField from '../IntegrationField/IntegrationField';
import LocationField from '../LocationField/LocationField';
import LongTextField from '../LongTextField/LongTextField';
import LookUp from '../LookUp/LookUp';
import MultiMediaField from '../MultiMediaField/MultiMediaField';
import NumbersField from '../NumbersField/NumbersField';
import Rating from '../Rating/Rating';
import SignatureField from '../SignatureField/SignatureField';
import SmallTextField from '../SmallTextField/SmallTextField';
import { DynamicFieldSectionStyled, getTextFieldStyles } from './DynamicField.styles';
function DynamicField(_a) {
    var onCombinedPickerSearch = _a.onCombinedPickerSearch, disabled = _a.disabled, fieldLink = _a.fieldLink, getLookUpInstance = _a.getLookUpInstance, getDefinitionExternalData = _a.getDefinitionExternalData, getDefinitionExternalDataList = _a.getDefinitionExternalDataList, getDefinition = _a.getDefinition, handleLookUpSearch = _a.handleLookUpSearch, getFileContents = _a.getFileContents, tenantId = _a.tenantId, getFile = _a.getFile, style = _a.style, onChange = _a.onChange;
    var t = useTranslation().t;
    var _b = useState(false), consentNeeded = _b[0], setConsentNeeded = _b[1];
    var fieldType = fieldLink.fieldGroup ? 0 : fieldLink.field.fieldType;
    function onDynamicFieldChange(value) {
        console.log('🚀 ~ onDynamicFieldChange ~ value', value);
        if (onChange)
            onChange({ fieldId: fieldLink.id, value: value });
    }
    function onFieldGroupChange(_a) {
        var fieldId = _a.fieldId, sectionId = _a.sectionId, value = _a.value, sectionType = _a.sectionType;
        if (onChange) {
            onChange({ fieldId: fieldId, nestedSectionId: sectionId, value: value, sectionType: sectionType });
        }
    }
    function convertChoiceIntoOption(choice) {
        return {
            key: choice.id,
            text: choice.value
        };
    }
    function handleCombinedPickerSearch(filterText) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, onCombinedPickerSearch({ filterText: filterText }).then(function (searchResult) {
                        setConsentNeeded(!!searchResult.consentNeeded);
                        return searchResult.items;
                    })];
            });
        });
    }
    function onChoiceGroupChange(selectedOptions) {
        if (Array.isArray(selectedOptions) && selectedOptions.length) {
            var selectedChoices = selectedOptions.map(function (choice) { return ({
                id: choice.key,
                value: choice.text
            }); });
            onDynamicFieldChange(selectedChoices);
        }
        else {
            onDynamicFieldChange(null);
        }
    }
    function getFieldProps() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var fieldType = ((_a = fieldLink.field) === null || _a === void 0 ? void 0 : _a.fieldType) || 0;
        if (fieldType === RouteFieldType.FieldGroup) {
            return {
                label: fieldLink.fieldGroup.name,
                description: fieldLink.fieldGroup.description,
                disabled: disabled || fieldLink.visibility === RouteFieldVisibility.ReadOnly,
                fields: fieldLink.fieldGroup.fields
            };
        }
        var commonFieldProps = {
            fieldType: fieldType,
            defaultValue: fieldLink.value,
            disabled: disabled || fieldLink.visibility === RouteFieldVisibility.ReadOnly,
            label: (_b = fieldLink.field) === null || _b === void 0 ? void 0 : _b.name,
            required: fieldLink.visibility === RouteFieldVisibility.Required,
            description: (_c = fieldLink.field) === null || _c === void 0 ? void 0 : _c.description
        };
        if (fieldType === RouteFieldType.SmallText) {
            return __assign(__assign({}, commonFieldProps), { maxLength: fieldLink.field.maxLength });
        }
        if (fieldType === RouteFieldType.LongText) {
            var appendChanges = fieldLink.field.appendChanges;
            return __assign(__assign({}, commonFieldProps), { defaultValue: appendChanges ? (_d = fieldLink.value) === null || _d === void 0 ? void 0 : _d.currentValue : fieldLink.value, versions: appendChanges ? ((_e = fieldLink.value) === null || _e === void 0 ? void 0 : _e.versions) || [] : undefined });
        }
        if (fieldType === RouteFieldType.Number) {
            return __assign(__assign({}, commonFieldProps), { maxDecimals: fieldLink.field.numDecimals });
        }
        if (fieldType === RouteFieldType.DateTime) {
            return __assign(__assign({}, commonFieldProps), { displayTime: fieldLink.field.dateFormat === DateTimeFieldFormat.DateAndTime });
        }
        if (fieldType === RouteFieldType.Choice) {
            return __assign(__assign({}, commonFieldProps), { allowFillIn: fieldLink.field.allowFillIn, allowMultipleSelections: fieldLink.field.choiceFormat === ChoiceFieldFormat.MultiSelect, defaultValue: (_f = fieldLink.value) === null || _f === void 0 ? void 0 : _f.map(convertChoiceIntoOption), options: fieldLink.field.choices.map(convertChoiceIntoOption) });
        }
        if (fieldType === RouteFieldType.Boolean) {
            return __assign(__assign({}, commonFieldProps), { textOk: fieldLink.field.textOk, textNOk: fieldLink.field.textNOk });
        }
        if (fieldType === RouteFieldType.Person) {
            var defaultValue = [];
            if (fieldLink.value) {
                defaultValue.push(__assign(__assign({}, fieldLink.value), { text: fieldLink.value.name || undefined, size: 11, secondaryText: fieldLink.value.email || undefined, showSecondaryText: !!fieldLink.value.email, id: fieldLink.value.userId || undefined, imageUrl: fieldLink.value.pictureUrl || undefined }));
            }
            return __assign(__assign({}, commonFieldProps), { displayConsentWarning: consentNeeded, onSearch: handleCombinedPickerSearch, defaultValue: defaultValue });
        }
        if (fieldType === RouteFieldType.Rating) {
            return __assign(__assign({}, commonFieldProps), { maxRating: fieldLink.field.numStars });
        }
        if (fieldType === RouteFieldType.Document) {
            var defaultValue = void 0;
            var value = fieldLink.value;
            if (((_g = fieldLink.value) === null || _g === void 0 ? void 0 : _g.url) && ((_h = fieldLink.value) === null || _h === void 0 ? void 0 : _h.url) !== 'https://tempuri.org') {
                var fileIdIndex = value.url.indexOf('id=') + 3;
                var id = value.url.substr(fileIdIndex);
                defaultValue = __assign(__assign({}, fieldLink.value), { id: id });
            }
            else if ((_j = fieldLink.value) === null || _j === void 0 ? void 0 : _j.file) {
                defaultValue = __assign({}, fieldLink.value);
            }
            return __assign(__assign({}, commonFieldProps), { getFileContents: getFileContents,
                getFile: getFile,
                defaultValue: defaultValue });
        }
        if (fieldType === RouteFieldType.Lookup) {
            var _k = fieldLink.field, lookupOnlyOutcome = _k.lookupOnlyOutcome, lookupDisplayFieldIds = _k.lookupDisplayFieldIds, lookupDefinitionId = _k.lookupDefinitionId, lookupType = _k.lookupType;
            return __assign(__assign({}, commonFieldProps), { definitionId: lookupDefinitionId, fieldIds: lookupDisplayFieldIds, getFileContents: getFileContents, getInstance: getLookUpInstance, lookupType: lookupType, onSearch: handleLookUpSearch, outcome: lookupOnlyOutcome });
        }
        if (fieldType === RouteFieldType.ExternalData) {
            var _l = fieldLink.field, externalServiceId = _l.externalServiceId, allowScan = _l.allowScan;
            return __assign(__assign({}, commonFieldProps), { externalServiceId: externalServiceId,
                allowScan: allowScan });
        }
        if (fieldType === RouteFieldType.Signature) {
            return __assign({}, commonFieldProps);
        }
        if (fieldType === RouteFieldType.Multimedia) {
            var value_1 = fieldLink.value;
            var defaultValue = value_1 === null || value_1 === void 0 ? void 0 : value_1.map(function (fileValue) {
                var _a;
                if ((value_1 === null || value_1 === void 0 ? void 0 : value_1.url) &&
                    (value_1 === null || value_1 === void 0 ? void 0 : value_1.url) !== 'https://tempuri.org' &&
                    !((_a = value_1 === null || value_1 === void 0 ? void 0 : value_1.url) === null || _a === void 0 ? void 0 : _a.includes('microsoftstream.com'))) {
                    var fileIdIndex = value_1.url.indexOf('id=') + 3;
                    var id = value_1.url.substr(fileIdIndex);
                    return __assign(__assign({}, fileValue), { id: id });
                }
                return __assign({}, fileValue);
            });
            return __assign(__assign({}, commonFieldProps), { getFileContents: getFileContents,
                getFile: getFile,
                defaultValue: defaultValue });
        }
        return commonFieldProps;
    }
    function getDynamicField() {
        var _a;
        var placeholderContext = disabled ? 'disabled' : undefined;
        if ((_a = fieldLink.values) === null || _a === void 0 ? void 0 : _a.length) {
            return (React.createElement(DynamicSurveyResultField, { tenantId: tenantId, getFileContents: getFileContents, fieldLink: fieldLink }));
        }
        if (fieldType === RouteFieldType.FieldGroup) {
            var _b = getFieldProps(), label = _b.label, fields = _b.fields, disabled_1 = _b.disabled;
            return (React.createElement(DynamicFieldSection, { getLookUpInstance: getLookUpInstance, getDefinitionExternalData: getDefinitionExternalData, getDefinitionExternalDataList: getDefinitionExternalDataList, getDefinition: getDefinition, handleLookUpSearch: handleLookUpSearch, getFileContents: getFileContents, getFile: getFile, label: label, sectionType: SectionType.fieldGroup, id: fieldLink.id, disabled: disabled_1, onCombinedPickerSearch: onCombinedPickerSearch, fields: fields, onChange: onFieldGroupChange }));
        }
        var iconName = getFluentIconNameByFieldType(fieldLink.field.fieldType);
        if (fieldType === RouteFieldType.SmallText) {
            var _c = getFieldProps(), defaultValue = _c.defaultValue, disabled_2 = _c.disabled, label = _c.label, maxLength = _c.maxLength, required = _c.required, description = _c.description;
            return (React.createElement(SmallTextField, { defaultValue: defaultValue, description: description, disabled: disabled_2, label: label, labelIconName: iconName, maxLength: maxLength, maxSingleLineChars: 90, onChange: onDynamicFieldChange, placeholder: t('dynamicField.smallText.placeholder', { context: placeholderContext }), required: required, styles: getTextFieldStyles }));
        }
        if (fieldType === RouteFieldType.LongText) {
            var _d = getFieldProps(), defaultValue = _d.defaultValue, disabled_3 = _d.disabled, required = _d.required, label = _d.label, description = _d.description, versions_1 = _d.versions;
            return (React.createElement(LongTextField, { defaultValue: defaultValue, disabled: disabled_3, labelIconName: iconName, required: required, label: label, onChange: function (newValue) {
                    if (Array.isArray(versions_1)) {
                        onDynamicFieldChange({ currentValue: newValue, versions: versions_1 });
                    }
                    else {
                        onDynamicFieldChange(newValue);
                    }
                }, description: description, versions: versions_1, placeholder: t('dynamicField.longText.placeholder', { context: placeholderContext }) }));
        }
        if (fieldType === RouteFieldType.Number) {
            var _e = getFieldProps(), defaultValue = _e.defaultValue, _f = _e.disabled, disabled_4 = _f === void 0 ? false : _f, _g = _e.maxDecimals, maxDecimals = _g === void 0 ? 0 : _g, placeholder = _e.placeholder, label = _e.label, description = _e.description, _h = _e.required, required = _h === void 0 ? false : _h, styles = _e.styles;
            return (React.createElement(NumbersField, { defaultValue: defaultValue, description: description, disabled: disabled_4, label: label, labelIconName: iconName, maxDecimals: maxDecimals, onChange: onDynamicFieldChange, required: required, placeholder: t('dynamicField.numbersField.placeholder', { context: placeholderContext }), styles: getTextFieldStyles }));
        }
        if (fieldType === RouteFieldType.Boolean) {
            var _j = getFieldProps(), defaultValue = _j.defaultValue, _k = _j.disabled, disabled_5 = _k === void 0 ? false : _k, label = _j.label, description = _j.description, textOk = _j.textOk, textNOk = _j.textNOk, _l = _j.required, required = _l === void 0 ? false : _l;
            return (React.createElement(BooleanField, { defaultValue: defaultValue, onChange: onDynamicFieldChange, labelIconName: iconName, label: label, textOk: textOk, textNOk: textNOk, required: required, description: description, disabled: disabled_5 }));
        }
        if (fieldType === RouteFieldType.DateTime) {
            var _m = getFieldProps(), defaultValue = _m.defaultValue, description = _m.description, _o = _m.disabled, disabled_6 = _o === void 0 ? false : _o, _p = _m.displayTime, displayTime = _p === void 0 ? false : _p, label = _m.label, _q = _m.required, required = _q === void 0 ? false : _q;
            var dateTimeStyles = disabled_6
                ? {
                    textFieldStyles: {
                        wrapper: { border: '1px solid #a19f9d', borderRadius: 3 },
                        field: { color: '#323130' }
                    },
                    timePickerStyles: { inputDisabled: { color: '#323130' }, root: { height: 34 } }
                }
                : undefined;
            return (React.createElement(DateTime, { allowPastDate: true, defaultValue: defaultValue, description: description, disabled: disabled_6, displayTime: displayTime, label: label, labelIconName: iconName, onSelectDate: onDynamicFieldChange, required: required, timeLabel: "Zeit", styles: dateTimeStyles, placeholder: t('dynamicField.dateTime.placeholder', { context: placeholderContext }) }));
        }
        if (fieldType === RouteFieldType.Choice) {
            var _r = getFieldProps(), _s = _r.allowFillIn, allowFillIn = _s === void 0 ? false : _s, _t = _r.allowMultipleSelections, allowMultipleSelections = _t === void 0 ? false : _t, _u = _r.defaultValue, defaultValue = _u === void 0 ? [] : _u, _v = _r.disabled, disabled_7 = _v === void 0 ? false : _v, label = _r.label, description = _r.description, options = _r.options, _w = _r.required, required = _w === void 0 ? false : _w;
            return (React.createElement(ChoiceGroupField, { label: label, description: description, allowFillIn: allowFillIn, allowMultipleSelections: allowMultipleSelections, defaultValue: defaultValue, disabled: disabled_7, onChange: onChoiceGroupChange, options: options, placeholder: t('dynamicField.choiceGroup.placeholder', { context: placeholderContext }), labelIconName: iconName, required: required }));
        }
        if (fieldType === RouteFieldType.Person) {
            var _x = getFieldProps(), _y = _x.defaultValue, defaultValue = _y === void 0 ? [] : _y, _z = _x.disabled, disabled_8 = _z === void 0 ? false : _z, _0 = _x.displayConsentWarning, displayConsentWarning = _0 === void 0 ? false : _0, onSearch = _x.onSearch, label = _x.label, description = _x.description, _1 = _x.required, required = _1 === void 0 ? false : _1;
            return (React.createElement(CombinedPicker, { label: label, labelIconName: iconName, description: description, defaultValue: defaultValue, disabled: disabled_8, displayConsentWarning: displayConsentWarning, itemLimit: 1, placeholder: t('dynamicField.combinedPicker.placeholder', {
                    context: placeholderContext
                }), required: required, onSearch: onSearch, onChange: function (selectedPersonArray) {
                    if (Array.isArray(selectedPersonArray) && selectedPersonArray.length) {
                        var selectedUser = convertPersonaIntoEpUser(selectedPersonArray[0]);
                        onDynamicFieldChange(selectedUser);
                    }
                    else {
                        onDynamicFieldChange(null);
                    }
                }, styles: { itemsWrapper: { padding: 2 } } }));
        }
        if (fieldType === RouteFieldType.Rating) {
            var _2 = getFieldProps(), defaultValue = _2.defaultValue, description = _2.description, _3 = _2.disabled, disabled_9 = _3 === void 0 ? false : _3, label = _2.label, _4 = _2.maxRating, maxRating = _4 === void 0 ? 5 : _4, _5 = _2.required, required = _5 === void 0 ? false : _5;
            return (React.createElement(Rating, { defaultValue: defaultValue, description: description, disabled: disabled_9, label: label, labelIconName: iconName, maxRating: maxRating, onChange: onDynamicFieldChange, required: required }));
        }
        if (fieldType === RouteFieldType.Hyperlink) {
            var _6 = getFieldProps(), defaultValue = _6.defaultValue, description = _6.description, disabled_10 = _6.disabled, label = _6.label, required = _6.required, styles = _6.styles;
            return (React.createElement(HyperLinkField, { defaultValue: defaultValue, description: description, disabled: disabled_10, label: label, labelIconName: iconName, onChange: onDynamicFieldChange, required: required, styles: styles }));
        }
        if (fieldType === RouteFieldType.Document) {
            var _7 = getFieldProps(), defaultValue = _7.defaultValue, description = _7.description, _8 = _7.disabled, disabled_11 = _8 === void 0 ? false : _8, getFile_1 = _7.getFile, getFileContents_1 = _7.getFileContents, label = _7.label, _9 = _7.required, required = _9 === void 0 ? false : _9;
            return (React.createElement(DocumentField, { defaultValue: defaultValue, description: description, disabled: disabled_11, displayImagePreview: true, getFile: getFile_1, getFileContents: getFileContents_1, label: label, labelIconName: iconName, onChange: function (file) {
                    if (file) {
                        onDynamicFieldChange({ file: file, url: 'https://tempuri.org', text: file === null || file === void 0 ? void 0 : file.name });
                    }
                    else {
                        onDynamicFieldChange(null);
                    }
                }, required: required }));
        }
        if (fieldType === RouteFieldType.ExternalData) {
            var _10 = getFieldProps(), allowScan = _10.allowScan, defaultValue = _10.defaultValue, definitionId = _10.definitionId, description = _10.description, _11 = _10.disabled, disabled_12 = _11 === void 0 ? false : _11, fieldIds = _10.fieldIds, getFileContents_2 = _10.getFileContents, getInstance = _10.getInstance, label = _10.label, lookupType = _10.lookupType, externalServiceId = _10.externalServiceId, onSearch = _10.onSearch, outcome = _10.outcome, _12 = _10.required, required = _12 === void 0 ? false : _12;
            return (React.createElement(IntegrationField, { allowScan: allowScan, defaultValue: defaultValue, definitionId: definitionId, description: description, disabled: disabled_12, externalServiceId: externalServiceId, fieldIds: fieldIds, getDefinition: getDefinition, getDefinitionExternalData: getDefinitionExternalData, getFileContents: getFileContents_2, getInstance: getInstance, label: label, labelIconName: iconName, lookupType: lookupType, onChange: onDynamicFieldChange, onSearch: getDefinitionExternalDataList, outcome: outcome, required: required }));
        }
        if (fieldType === RouteFieldType.Lookup) {
            var _13 = getFieldProps(), defaultValue = _13.defaultValue, definitionId = _13.definitionId, description = _13.description, _14 = _13.disabled, disabled_13 = _14 === void 0 ? false : _14, fieldIds = _13.fieldIds, getFileContents_3 = _13.getFileContents, getInstance = _13.getInstance, label = _13.label, lookupType = _13.lookupType, onSearch = _13.onSearch, outcome = _13.outcome, _15 = _13.required, required = _15 === void 0 ? false : _15;
            return (React.createElement(LookUp, { defaultValue: defaultValue, definitionId: definitionId, description: description, disabled: disabled_13, fieldIds: fieldIds, getFileContents: getFileContents_3, getInstance: getInstance, label: label, labelIconName: iconName, lookupType: lookupType, onChange: onDynamicFieldChange, onSearch: onSearch, outcome: outcome, required: required }));
        }
        if (fieldType === RouteFieldType.Location) {
            var _16 = getFieldProps(), defaultValue = _16.defaultValue, description = _16.description, label = _16.label, disabled_14 = _16.disabled, required = _16.required;
            return (React.createElement(LocationField, { label: label, description: description, labelIconName: iconName, defaultValue: defaultValue, onChange: onDynamicFieldChange, disabled: disabled_14, required: required }));
        }
        if (fieldType === RouteFieldType.Signature) {
            var _17 = getFieldProps(), defaultValue = _17.defaultValue, description = _17.description, label = _17.label, disabled_15 = _17.disabled, required = _17.required;
            return (React.createElement(SignatureField, { label: label, description: description, labelIconName: iconName, defaultValue: defaultValue, onChange: onDynamicFieldChange, disabled: disabled_15, required: required }));
        }
        if (fieldType === RouteFieldType.Scanner) {
            var _18 = getFieldProps(), defaultValue = _18.defaultValue, description = _18.description, label = _18.label, disabled_16 = _18.disabled, required = _18.required;
            return (React.createElement(CodeScannerField, { label: label, description: description, labelIconName: iconName, defaultValue: defaultValue, onChange: onDynamicFieldChange, disabled: disabled_16, required: required, placeholder: t('dynamicField.codeScannerField.placeholder', {
                    context: placeholderContext
                }) }));
        }
        if (fieldType === RouteFieldType.Multimedia) {
            var _19 = getFieldProps(), defaultValue = _19.defaultValue, description = _19.description, label = _19.label, disabled_17 = _19.disabled, required = _19.required;
            return (React.createElement(MultiMediaField, { defaultValue: defaultValue, label: label, description: description, tenantId: tenantId, getFileContents: getFileContents, labelIconName: iconName, onChange: onDynamicFieldChange, disabled: disabled_17, required: required }));
        }
        return null;
    }
    var field = getDynamicField();
    return React.createElement(DynamicFieldSectionStyled, { style: style }, field);
}
export default DynamicField;
