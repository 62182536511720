import { PublicClientApplication } from '@azure/msal-browser';
import * as microsoftTeams from '@microsoft/teams-js';

const { clientId, authority, scopes, extraScopesToConsent } = window.config;

const configuration = {
  auth: {
    clientId,
    authority,
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin,
    extraScopesToConsent
  },
  cache: { cacheLocation: 'localStorage', storeAuthStateInCookie: false }
};

export const msalInstance = new PublicClientApplication(configuration);

const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
const accounts = msalInstance.getAllAccounts();

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function isInMsTeamsEnvironment() {
  const msTeamsEnvironment = window.sessionStorage.getItem('msTeamsEnvironment');
  return msTeamsEnvironment;
}

function openMsTeamsPopUpAndAcquireToken() {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication.authenticate({
      url: `${window.location.origin}/authentication-start`,
      width: 550,
      height: 535,
      successCallback: () => {
        acquireAccessToken().then((token) => {
          resolve(token);
        });
      },
      failureCallback: () => {
        reject();
      }
    });
  });
}

export const loginRequest = {
  scopes,
  forceRefresh: false,
  account: activeAccount || accounts[0]
};

export const acquireAccessToken = async () => {
  return msalInstance.acquireTokenSilent(loginRequest).catch((error) => {
    // fallback to interaction when silent call fails
    if (error) {
      if (isInMsTeamsEnvironment() && isInIframe()) {
        return openMsTeamsPopUpAndAcquireToken();
      }

      if (!isInMsTeamsEnvironment() && isInIframe()) {
        return msalInstance.acquireTokenPopup(loginRequest);
      }

      if (!isInIframe()) {
        return msalInstance.acquireTokenRedirect(loginRequest);
      }
    }

    return null;
  });
};
