import 'react-app-polyfill/ie11';
import './utils/polyfills';
import './i18n';

import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ThemeProvider } from '@fluentui/react';
import { AppInsightsContextProvider } from './AppInsights/AppInsightsContext';
import { ErrorBoundary } from './ErrorHandler/ErrorBoundary';
import AuthenticationContainer from './modules/App/Authentication.container';
import { msalInstance } from './modules/AuthenticationMSAL/msal';

// initialize fabric icons
initializeIcons();

render(
  <ErrorBoundary>
    <AppInsightsContextProvider>
      <ThemeProvider style={{ height: '100%', background: 'transparent' }}>
        <Suspense fallback={<div />}>
          <MsalProvider instance={msalInstance}>
            <AuthenticationContainer />
          </MsalProvider>
        </Suspense>
      </ThemeProvider>
    </AppInsightsContextProvider>
  </ErrorBoundary>,
  document.querySelector('#evocom-tasks')
);
