import React from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
function StatusBar(_a) {
    var startDate = _a.startDate, endDate = _a.endDate;
    function getDays(startDate, endDate) {
        return (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    }
    function calcProgressIndicatorDays(startDate, endDate) {
        if (!startDate || !endDate) {
            return 0;
        }
        var start = new Date(startDate);
        var end = new Date(endDate);
        var days = (getDays(start, end) / getDays(start, new Date())).toFixed(1);
        var daysFloat = parseFloat(days);
        return daysFloat;
    }
    function progressIndicatorColor(start, end, percentage) {
        var progressColor = '';
        if (percentage <= 0.7) {
            progressColor = 'progress-color-green';
        }
        else if (percentage > 0.7 && percentage <= 0.9) {
            progressColor = 'progress-color-orange';
        }
        else if (percentage > 0.9) {
            progressColor = 'progress-color-red';
        }
        if (calcProgressIndicatorDays(start, end) <= 1) {
            progressColor = 'progress-color-red';
        }
        else if (calcProgressIndicatorDays(start, end) <= 3 && percentage < 0.9) {
            progressColor = 'progress-color-orange';
        }
        return progressColor;
    }
    function calcProgressIndicatorPercentage(startDate, endDate) {
        if (!startDate || !endDate) {
            return 0;
        }
        var start = new Date(startDate).getTime();
        var end = new Date(endDate).getTime();
        var today = new Date().getTime();
        var percentage = Math.round(((today - start) / (end - start)) * 100) / 100;
        if (today < start) {
            percentage = 0;
        }
        if (percentage < 0) {
            percentage = 100;
        }
        return percentage;
    }
    var percentComplete = calcProgressIndicatorPercentage(startDate, endDate);
    var progressColor = progressIndicatorColor(startDate, endDate, percentComplete);
    return React.createElement(ProgressIndicator, { className: progressColor, percentComplete: percentComplete });
}
export default StatusBar;
