import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
function MultiMediaVideo(_a) {
    var _b = _a.autoPlay, autoPlay = _b === void 0 ? false : _b, controls = _a.controls, height = _a.height, mediaValue = _a.mediaValue, tenantId = _a.tenantId, width = _a.width;
    var _c = useState(''), blobUrl = _c[0], setBlobUrl = _c[1];
    useEffect(function () {
        var _a, _b, _c;
        var url = '';
        var isNewVideoFile = (_b = (_a = mediaValue.file) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.includes('video');
        if (isNewVideoFile) {
            url = URL.createObjectURL(mediaValue.file);
        }
        else if (mediaValue.url) {
            var fileIdIndex = mediaValue.url.indexOf('id=') + 3;
            var id = (_c = mediaValue.url) === null || _c === void 0 ? void 0 : _c.substr(fileIdIndex);
            var host = window.location.host;
            if (host.includes('localhost'))
                host = 'ui-dev-eps.azurewebsites.net';
            url = "https://" + host + "/api/File/Stream?fileId=" + id + "&tenantId=" + tenantId;
        }
        setBlobUrl(url);
        return function () { return URL.revokeObjectURL(url); };
    }, [mediaValue.file, mediaValue.url, tenantId]);
    if (!blobUrl)
        return null;
    return (React.createElement(ReactPlayer, { playing: autoPlay, controls: controls, width: width || '100%', height: height || '100%', url: blobUrl }));
}
export default MultiMediaVideo;
