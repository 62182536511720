import React, { useCallback, useEffect, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react';
import { Spinner } from '@fluentui/react';
import * as microsoftTeams from '@microsoft/teams-js';
import { isInIframe } from '../../utils/helpers';
import { loginRequest } from '../AuthenticationMSAL/msal';
import WelcomePage from '../WelcomePage/WelcomePage';
import AppContainer from './App.container';

function Auth() {
  const [loginHint, setLoginHint] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  microsoftTeams.initialize();

  microsoftTeams.getContext((context) => {
    window.sessionStorage.setItem('msTeamsEnvironment', true);
    setLoginHint(context.loginHint);
  });

  const knownEpUser = window.localStorage.getItem('knownEpUser');
  const msAuthStart = window.location.href.includes('authentication-start');
  const msAuthEnd = window.location.href.includes('authentication-end');

  const getMsalLoginHint = useCallback(
    (eMail) => {
      // loginhint set as eMail. Is in localStorage because of the popup window
      const msTeamsLoginHint = window.localStorage.getItem('msTeamsLoginHint');

      return msTeamsLoginHint || eMail || loginHint;
    },
    [loginHint]
  );

  const onMsalLogin = useCallback(
    (eMail, redirectUri) => {
      // set eMail for login error logging
      window.localStorage.setItem('loginEmail', eMail);

      const loginHint = getMsalLoginHint(eMail);

      const fullLoginRequest = { ...loginRequest, loginHint };

      if (redirectUri) {
        fullLoginRequest.redirectUri = redirectUri;
      }

      if (isInIframe()) {
        instance.loginPopup(fullLoginRequest);
      } else {
        instance.loginRedirect(fullLoginRequest);
      }
    },
    [getMsalLoginHint, instance]
  );

  useEffect(() => {
    if (msAuthEnd && isAuthenticated) {
      // notifiy succsess to close auth ms-teams popup window

      microsoftTeams.authentication.notifySuccess();
    }
  }, [msAuthEnd, isAuthenticated]);

  useEffect(() => {
    if (msAuthStart && !initialized) {
      setInitialized(true);
      onMsalLogin(null, `${window.location.origin}/authentication-end`);
    }
  }, [msAuthStart, initialized, onMsalLogin]);

  if (msAuthEnd || msAuthStart) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <Spinner size={3} />
      </div>
    );
  }

  const queryParam = window.location.search;

  if (queryParam) {
    window.localStorage.setItem('initQueryParam', queryParam);
  }

  function getForceLoginState() {
    let onBoardingActive = false;
    if (window.baseConfig) {
      ({ onBoardingActive } = window.baseConfig);
    }

    if (onBoardingActive) {
      return !isInIframe() && knownEpUser;
    }

    return !isInIframe();
  }

  function doesCookieExist(cookieNameToCheck) {
    return document.cookie
      .split(';')
      .some((cookie) => cookie.trim().startsWith(`${cookieNameToCheck}=`));
  }

  function onLogout() {
    if (isInIframe()) {
      instance.logoutPopup();
    } else {
      instance.logoutRedirect();
    }
  }

  if (inProgress === 'none') {
    let authError;

    if (!authError && doesCookieExist('epMsalAuthStarted')) {
      authError = 'User cancelled the flow.';
    }

    if (!isAuthenticated && getForceLoginState()) {
      // not authenticated
      onMsalLogin();

      return null;
    }

    if (isAuthenticated) {
      // authenticated
      instance.setActiveAccount(accounts[0]);

      // remove cookie
      if (doesCookieExist('epMsalAuthStarted')) {
        document.cookie = 'epMsalAuthStarted=;max-age=0';
      }

      // next time the user should be forwarded directly
      if (accounts[0]?.username) {
        window.localStorage.setItem('knownEpUser', accounts[0].username);
      }
    }

    return (
      <>
        <AuthenticatedTemplate>
          <AppContainer logout={onLogout} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <WelcomePage
            defaultAuthError={authError}
            defaultLoginHint={loginHint}
            login={(eMail) => onMsalLogin(eMail)}
          />
        </UnauthenticatedTemplate>
      </>
    );
  }

  return null;
}

export default Auth;
