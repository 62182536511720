import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Nav } from '@fluentui/react/lib/Nav';
import breakpoints from '../../utils/breakpoints';
import AppContext from '../App/AppContext';

const NavStyled = styled(Nav)`
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  height: 100%;
  border-right: 1px solid #eaeaea;

  .ms-SearchBox {
    height: 44px;
    border: #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    min-width: 100%;
    width: 100%;

    .ms-SearchBox {
      padding-right: 9px;
    }
  }

  .ms-Nav-groupContent {
    animation-name: none;
    animation-duration: 0;
    animation-timing-function: ease;
    animation-fill-mode: none;
  }
`;

function Navigation({ toggleSideBar }) {
  const { t } = useTranslation();

  const [activeGroup, setActiveGroup] = useState(null);

  const location = useLocation()?.pathname;
  const history = useHistory();

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { currentUser, teams, permissions } = globalAppState;

  const checkIfUrlContainsString = useCallback(
    (stringToCheck) => location.includes(stringToCheck),
    [location]
  );

  useEffect(() => {
    if (!activeGroup) {
      if (checkIfUrlContainsString('/tasks') || checkIfUrlContainsString('/teams')) {
        setActiveGroup('tasks');
      } else if (checkIfUrlContainsString('/process-templates')) {
        setActiveGroup('process-templates');
      } else if (checkIfUrlContainsString('/process-instances')) {
        setActiveGroup('process-instances');
      } else if (checkIfUrlContainsString('/administration')) {
        setActiveGroup('administration');
      }
    }
  }, [checkIfUrlContainsString, location, activeGroup]);

  const [groupsExpandationState, setGroupsExpandationState] = useState({
    tasks: false,
    teams: false,
    routes: false,
    projects: false,
    processTemplates: false,
    activity: false
  });

  const onExpandNavigationItems = useCallback((item) => {
    setGroupsExpandationState({
      tasks: item.key === 'tasks' || false,
      teams: item.key === 'teams' || false,
      routes: item.key === 'routes' || false,
      projects: item.key === 'projects' || false,
      processTemplates: item.key === 'processTemplates' || false,
      activity: item.key === 'activity' || false
    });
  }, []);

  useEffect(() => {
    const expandationState = {
      tasks: false,
      teams: false,
      routes: false,
      projects: false,
      processTemplates: false,
      activity: false
    };
    if (checkIfUrlContainsString('/tasks')) {
      expandationState.tasks = true;
    } else if (checkIfUrlContainsString('/process-templates')) {
      expandationState.processTemplates = true;
    } else if (checkIfUrlContainsString('/teams') && !checkIfUrlContainsString('/administration')) {
      expandationState.teams = true;
    } else if (checkIfUrlContainsString('/process-instances')) {
      expandationState.routes = true;
    } else if (checkIfUrlContainsString('/projects')) {
      expandationState.projects = true;
    } else if (checkIfUrlContainsString('/activities')) {
      expandationState.activity = true;
    } else if (checkIfUrlContainsString('/notifications')) {
      expandationState.activity = true;
    } else if (!checkIfUrlContainsString('/administration')) {
      expandationState.tasks = true;
    }
    setGroupsExpandationState(expandationState);
  }, [checkIfUrlContainsString]);

  function resetSearch() {
    dispatch({ type: 'searchTerm', data: null });
  }

  function generateTeamNavigationLinks() {
    return teams.map((team) => ({
      key: team.id,
      name: team.displayTitle,
      url: `/teams/${team.id}`,
      group: 'tasks'
    }));
  }

  function getSelectedKey(pathname) {
    const checkPathName = (urlToCheck) => pathname.includes(urlToCheck);

    if (
      checkPathName('/teams') &&
      !checkPathName('/administration') &&
      !checkPathName('/process-templates')
    ) {
      return teams.find((team) => checkPathName(team.id))?.id;
    }
    if (checkPathName('/tasks/my-open')) {
      return 'myOpen';
    }
    if (checkPathName('/tasks/allocated')) {
      return 'allocated';
    }
    if (checkPathName('/tasks/all')) {
      return 'all';
    }
    if (checkPathName('/tasks/involved')) {
      return 'involved';
    }
    if (checkPathName('/tasks/completed')) {
      return 'completed';
    }
    if (checkPathName('/process-instances/my')) {
      return 'myRoutes';
    }
    if (checkPathName('/process-instances/involved')) {
      return 'involvedRoutes';
    }
    if (checkPathName('/process-instances/all')) {
      return 'allRoutes';
    }
    if (checkPathName('/process-instances/completed')) {
      return 'completedRoutes';
    }
    if (checkPathName('/projects/my')) {
      return 'myProjects';
    }
    if (checkPathName('/projects/involved')) {
      return 'involvedProjects';
    }
    if (checkPathName('/projects/all')) {
      return 'allProjects';
    }
    if (checkPathName('/projects/completed')) {
      return 'completedProjects';
    }
    if (checkPathName('/process-templates/landscape')) {
      return 'processLandscape';
    }
    if (checkPathName('/process-templates/end2end')) {
      return 'templatesByEnd2End';
    }
    if (checkPathName('/process-templates/teams')) {
      return 'templatesByTeams';
    }

    if (checkPathName('/administration')) {
      return null;
    }

    if (checkPathName('/activities')) {
      return 'activities';
    }

    if (checkPathName('/favorites')) {
      return 'favoriteTemplates';
    }

    return 'myOpen';
  }

  function onLinkClick(event, element) {
    event.preventDefault();
    const fromAdmin = window.location.href.includes('administration');

    if ((fromAdmin && element.group !== 'administration') || activeGroup !== element.group) {
      resetSearch();
      setActiveGroup(element.group);
    }

    history.push(element.url);
    if (toggleSideBar) {
      toggleSideBar();
    }
  }

  const links = [
    {
      name: t('navigation.group.title.tasks'),
      links: [
        {
          key: 'myOpen',
          name: t('navigation.group.title.tasksForMe'),
          url: '/tasks/my-open',
          group: 'tasks'
        },
        {
          key: 'allocated',
          name: t('navigation.group.title.tasksFromMe'),
          url: '/tasks/allocated',
          group: 'tasks'
        },
        {
          key: 'all',
          name: t('navigation.group.title.allTasks'),
          url: '/tasks/all',
          group: 'tasks'
        },
        {
          key: 'involved',
          name: t('navigation.group.title.involvedTasks'),
          url: '/tasks/involved',
          group: 'tasks'
        },
        {
          key: 'completed',
          name: t('navigation.group.title.completedTasks'),
          url: '/tasks/completed',
          group: 'tasks'
        }
      ],
      isExpanded: groupsExpandationState.tasks,
      onClick: (_, item) => onExpandNavigationItems(item),
      key: 'tasks'
    }
  ];

  if (teams.length > 0) {
    links.push({
      name: 'Teams',
      links: generateTeamNavigationLinks(),
      isExpanded: groupsExpandationState.teams,
      onClick: (_, item) => onExpandNavigationItems(item),
      key: 'teams',
      id: 'teams-parent-link'
    });
  }

  links.push({
    name: t('navigation.group.title.projects'),
    links: [
      {
        key: 'myProjects',
        name: t('navigation.group.title.myProjects'),
        url: '/projects/my',
        group: 'projects'
      },
      {
        key: 'involvedProjects',
        name: t('navigation.group.title.involvedProjects'),
        url: '/projects/involved',
        group: 'projects'
      },
      {
        key: 'allProjects',
        name: t('navigation.group.title.allProjects'),
        url: '/projects/all',
        group: 'projects'
      },
      {
        key: 'completedProjects',
        name: t('navigation.group.title.completedProjects'),
        url: '/projects/completed',
        group: 'projects'
      }
    ],
    isExpanded: groupsExpandationState.projects,
    onClick: (_, item) => onExpandNavigationItems(item),
    key: 'projects'
  });

  if (permissions?.startInstances || currentUser?.isAdmin) {
    links.push({
      name: t('navigation.group.title.routes'),
      links: [
        {
          key: 'myRoutes',
          name: t('navigation.group.title.myRoutes'),
          url: '/process-instances/my',
          group: 'routes'
        },
        {
          key: 'involvedRoutes',
          name: t('navigation.group.title.involvedRoutes'),
          url: '/process-instances/involved',
          group: 'routes'
        },
        {
          key: 'allRoutes',
          name: t('navigation.group.title.allRoutes'),
          url: '/process-instances/all',
          group: 'routes'
        },
        {
          key: 'completedRoutes',
          name: t('navigation.group.title.completedRoutes'),
          url: '/process-instances/completed',
          group: 'routes'
        }
      ],
      isExpanded: groupsExpandationState.routes,
      onClick: (_, item) => onExpandNavigationItems(item),
      key: 'routes'
    });
  }

  if (permissions?.startInstances || currentUser?.isAdmin) {
    links.push({
      name: t('navigation.group.title.processTemplates'),
      links: [
        {
          key: 'processLandscape',
          name: t('navigation.group.title.processLandscape'),
          url: '/process-templates/landscape',
          group: 'processTemplates'
        },
        {
          key: 'templatesByEnd2End',
          name: t('navigation.group.title.templatesByEnd2End'),
          url: '/process-templates/end2end',
          group: 'processTemplates'
        },
        {
          key: 'templatesByTeams',
          name: t('navigation.group.title.templatesByTeams'),
          url: '/process-templates/teams',
          group: 'processTemplates'
        },
        {
          key: 'favoriteTemplates',
          name: t('navigation.group.title.favoriteTemplates'),
          url: '/process-templates/favorites/all',
          group: 'processTemplates'
        }
      ],
      isExpanded: groupsExpandationState.processTemplates,
      onClick: (_, item) => onExpandNavigationItems(item),
      key: 'processTemplates',
      isGroup: true
    });
  }

  if (currentUser?.isAdmin) {
    links.push({
      key: 'activities',
      name: t('navigation.group.title.activities'),
      url: '/activities'
    });
  }

  const navigationGroups = [{ links }];

  const selectedKey = getSelectedKey(location);

  return (
    <NavStyled
      onLinkClick={onLinkClick}
      selectedKey={selectedKey}
      onLinkExpandClick={(_, item) => onExpandNavigationItems(item)}
      groups={navigationGroups}
      styles={{ root: { height: '100%' } }}
    />
  );
}

Navigation.propTypes = {
  toggleSideBar: PropTypes.func
};

Navigation.defaultProps = {
  toggleSideBar: null
};

export default Navigation;
