var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import jsQR from 'jsqr';
var QrCodeReader = (function () {
    function QrCodeReader() {
    }
    QrCodeReader.prototype.decodeImage = function (inputImageWrapper) {
        var data = inputImageWrapper.getAsRGBA();
        var result = jsQR(data, inputImageWrapper.size.x, inputImageWrapper.size.y);
        if (result === null) {
            return null;
        }
        return __assign({ codeResult: { code: result.data, format: 'qr_code' } }, result);
    };
    QrCodeReader.prototype.decodePattern = function () {
        return null;
    };
    return QrCodeReader;
}());
export default QrCodeReader;
