import { AnimationStyles, Icon, PrimaryButton } from '@fluentui/react/lib';
import PropTypes from 'prop-types';
import React from 'react';

function EmptyListPlaceHolder({ hidden, onCreateNew, createNewText, noItemsText, listIconName }) {
  if (hidden) {
    return null;
  }

  return (
    <div
      style={{
        ...AnimationStyles.fadeIn500,
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
      }}
    >
      <Icon
        styles={{
          root: {
            color: '#737373',
            fontSize: '84px',
            fontWeight: 400,
            lineHeight: '100px',
            margin: '15px',
            marginBottom: '10px'
          }
        }}
        iconName={listIconName}
      />
      <div>
        <div
          style={{
            color: '#737373',
            maxWidth: '450px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          {noItemsText}
        </div>
      </div>
      {onCreateNew && createNewText ? (
        <PrimaryButton
          styles={{
            root: {
              maxWidth: '200px',
              minHeight: '40px',
              marginRight: 'auto',
              marginLeft: 'auto',
              marginTop: '15px'
            }
          }}
          iconProps={{ iconName: 'Add' }}
          text={createNewText}
          onClick={() => onCreateNew()}
          allowDisabledFocus
        />
      ) : null}
    </div>
  );
}

EmptyListPlaceHolder.propTypes = {
  hidden: PropTypes.bool,
  onCreateNew: PropTypes.func,
  createNewText: PropTypes.string,
  noItemsText: PropTypes.string,
  listIconName: PropTypes.string
};

EmptyListPlaceHolder.defaultProps = {
  hidden: false,
  onCreateNew: null,
  createNewText: null,
  noItemsText: null,
  listIconName: null
};

export default EmptyListPlaceHolder;
