var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { IconButton, Modal } from '@fluentui/react';
import MultiMediaImage from './MultiMediaImage';
import MultiMediaVideo from './MultiMediaVideo';
function MultiMediaModal(_a) {
    var defaultMediaValueIndex = _a.defaultMediaValueIndex, getFileContents = _a.getFileContents, mediaValues = _a.mediaValues, onDismiss = _a.onDismiss, tenantId = _a.tenantId;
    var _b = useState(defaultMediaValueIndex), mediaValueIndex = _b[0], setMediaValueIndex = _b[1];
    function isImage(text) {
        if (!text)
            return false;
        return /.png|.jpg|.jpeg|.gif|.tiff|.tif/i.test(text);
    }
    function isVideo(text) {
        if (!text)
            return false;
        return /.mp4|.ogg|.ogv|.webm|.MOV|.WMV|.AVI/i.test(text);
    }
    function getButtonStyles() {
        var IconButtonStyles = {
            root: {
                backgroundColor: 'rgb(207, 207, 207, 0.4)',
                color: 'rgb(50, 49, 48);',
                position: 'absolute',
                zIndex: 100
            },
            rootHovered: { backgroundColor: 'rgb(207, 207, 207, 0.6)', color: 'rgb(32, 31, 30)' },
            rootPressed: { backgroundColor: 'rgb(189, 189, 189, 0.6)', color: 'rgb(32, 31, 30)' }
        };
        return IconButtonStyles;
    }
    function renderImagePreviewCloseButton() {
        var styles = getButtonStyles();
        if (typeof styles.root === 'object') {
            styles.root = __assign(__assign({}, styles.root), { marginRight: 15, marginTop: 10, right: 0 });
        }
        return React.createElement(IconButton, { iconProps: { iconName: 'Cancel' }, onClick: onDismiss, styles: styles });
    }
    function renderChangeIndexIconButtons() {
        var styles = getButtonStyles();
        var leftStyles = __assign({}, styles);
        if (typeof leftStyles.root === 'object') {
            leftStyles.root = __assign(__assign({}, leftStyles.root), { top: '50%', left: 15, width: 50, height: 50 });
            leftStyles.icon = { fontSize: 20 };
        }
        var rightStyles = __assign({}, styles);
        if (typeof rightStyles.root === 'object') {
            rightStyles.root = __assign(__assign({}, rightStyles.root), { top: '50%', right: 15, width: 50, height: 50 });
            rightStyles.icon = { fontSize: 20 };
        }
        return (React.createElement(React.Fragment, null,
            mediaValueIndex > 0 && (React.createElement(IconButton, { iconProps: { iconName: 'ChevronLeftMed' }, onClick: function () { return setMediaValueIndex(function (p) { return p - 1; }); }, styles: leftStyles })),
            mediaValueIndex < mediaValues.length - 1 && (React.createElement(IconButton, { iconProps: { iconName: 'ChevronRightMed' }, onClick: function () { return setMediaValueIndex(function (p) { return p + 1; }); }, styles: rightStyles }))));
    }
    function renderModalContent() {
        var _a, _b, _c, _d, _e, _f;
        var mediaValueToDisplay = mediaValues[mediaValueIndex];
        if ((_a = mediaValueToDisplay === null || mediaValueToDisplay === void 0 ? void 0 : mediaValueToDisplay.url) === null || _a === void 0 ? void 0 : _a.includes('https://web.microsoftstream.com/')) {
            var url = mediaValueToDisplay === null || mediaValueToDisplay === void 0 ? void 0 : mediaValueToDisplay.url;
            if ((_b = mediaValueToDisplay === null || mediaValueToDisplay === void 0 ? void 0 : mediaValueToDisplay.url) === null || _b === void 0 ? void 0 : _b.includes('?')) {
                url += '&autoplay=true&showinfo=true';
            }
            else {
                url += '?autoplay=true&showinfo=true';
            }
            return (React.createElement(React.Fragment, null,
                renderImagePreviewCloseButton(),
                renderChangeIndexIconButtons(),
                React.createElement("iframe", { title: "test", width: "100%", style: { border: 'none' }, height: "100%", src: url, allowFullScreen: true })));
        }
        if (((_d = (_c = mediaValueToDisplay.file) === null || _c === void 0 ? void 0 : _c.type) === null || _d === void 0 ? void 0 : _d.includes('image')) || isImage(mediaValueToDisplay.text)) {
            return (React.createElement("div", { style: { overflow: 'auto' } },
                renderImagePreviewCloseButton(),
                renderChangeIndexIconButtons(),
                React.createElement(MultiMediaImage, { objectFit: "contain", mediaValue: mediaValueToDisplay, getFileContents: getFileContents })));
        }
        if (((_f = (_e = mediaValueToDisplay.file) === null || _e === void 0 ? void 0 : _e.type) === null || _f === void 0 ? void 0 : _f.includes('video')) || isVideo(mediaValueToDisplay.text)) {
            return (React.createElement(React.Fragment, null,
                renderImagePreviewCloseButton(),
                renderChangeIndexIconButtons(),
                React.createElement(MultiMediaVideo, { autoPlay: true, controls: true, mediaValue: mediaValueToDisplay, tenantId: tenantId })));
        }
        return null;
    }
    return (React.createElement(Modal, { isOpen: true, onDismiss: onDismiss, isBlocking: false, styles: {
            scrollableContent: { height: '100%' },
            main: { width: '100%', height: '100%' }
        } }, renderModalContent()));
}
export default MultiMediaModal;
