var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isFinite from 'lodash/isFinite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { TextField } from '@fluentui/react/lib/TextField';
import Label from '../Label/Label';
function LocationField(_a) {
    var _b, _c, _d, _e;
    var defaultValue = _a.defaultValue, label = _a.label, description = _a.description, onChange = _a.onChange, _f = _a.disabled, disabled = _f === void 0 ? false : _f, labelIconName = _a.labelIconName, _g = _a.required, required = _g === void 0 ? false : _g;
    var _h = useState(defaultValue || {
        latitude: undefined,
        longitude: undefined
    }), coords = _h[0], setCoords = _h[1];
    var _j = useState(null), error = _j[0], setError = _j[1];
    var _k = useState(false), isLoading = _k[0], setIsLoading = _k[1];
    var t = useTranslation().t;
    var _l = useState({ latitude: true, longitude: true }), shortCoordinates = _l[0], setShortCoordinates = _l[1];
    var xsMaxMediaSelector = "@media (max-width: " + 480 + "px)";
    function isCoordValid(coord) {
        var numberValue = Number(coord);
        return isFinite(numberValue) && numberValue !== 0;
    }
    function successCallback(result) {
        var _a = result.coords, latitude = _a.latitude, longitude = _a.longitude;
        setShortCoordinates({ latitude: true, longitude: true });
        setCoords({ latitude: latitude, longitude: longitude });
        if (error) {
            resetError();
        }
        setIsLoading(false);
        if (onChange) {
            onChange({ latitude: latitude, longitude: longitude });
        }
    }
    function errorCallback(geolocationError) {
        setError(t("location.error." + geolocationError.code));
        setIsLoading(false);
    }
    function onGetLocation() {
        setIsLoading(true);
        if ('geolocation' in navigator) {
            var currentPositionOptions = {
                enableHighAccuracy: true,
                maximumAge: 10000,
                timeout: 60000
            };
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback, currentPositionOptions);
        }
        else {
            setError(t('location.error.4'));
            setIsLoading(false);
        }
    }
    function onCoordsTextFieldChange(attribute, value) {
        if (!isLoading) {
            setShortCoordinates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[attribute] = false, _a)));
            });
            setCoords(function (prevState) {
                var _a;
                var newState = __assign(__assign({}, prevState), (_a = {}, _a[attribute] = value, _a));
                if (onChange)
                    onChange(newState);
                return newState;
            });
        }
    }
    function isMobileDevice() {
        return typeof window.orientation !== 'undefined';
    }
    function isAppleDevice() {
        var platform = navigator.platform.toLowerCase();
        return (platform === 'macintosh' ||
            platform === 'macintel' ||
            platform === 'iphone' ||
            platform === 'ipad');
    }
    function onOpenMap() {
        var latitude = coords.latitude, longitude = coords.longitude;
        if (isMobileDevice()) {
            var urlCoords = "?q=" + latitude + "," + longitude;
            var url = isAppleDevice() ? "maps://" + urlCoords : "geo:" + urlCoords;
            window.open(url, '_system');
        }
        else {
            var url = "https://bing.com/maps/default.aspx?cp=" + latitude + "~" + longitude + "&lvl=18&sp=point." + latitude + "_" + longitude;
            window.open(url);
        }
    }
    function resetError() {
        setError(null);
    }
    function getCoordsTextFields() {
        var areTextfieldsDisabled = disabled || isLoading;
        var prefixStyles = { color: '#323130', background: 'transparent' };
        return (React.createElement("div", { style: { width: '100%' } },
            React.createElement(TextField, { styles: {
                    prefix: prefixStyles,
                    fieldGroup: [
                        { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
                    ]
                }, disabled: areTextfieldsDisabled, prefix: t('location.field.latitude') + ":", placeholder: t('location.field.placeholder.latitude'), onChange: function (_, value) { return onCoordsTextFieldChange('latitude', value); }, value: (latitude === null || latitude === void 0 ? void 0 : latitude.toString()) || undefined }),
            React.createElement(TextField, { styles: {
                    fieldGroup: [
                        { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
                    ],
                    prefix: prefixStyles,
                    root: { marginTop: 10 }
                }, disabled: areTextfieldsDisabled, prefix: t('location.field.longitude') + ":", placeholder: t('location.field.placeholder.longitude'), onChange: function (_, value) { return onCoordsTextFieldChange('longitude', value); }, value: (longitude === null || longitude === void 0 ? void 0 : longitude.toString()) || undefined })));
    }
    var latitude = coords === null || coords === void 0 ? void 0 : coords.latitude;
    if (shortCoordinates.latitude && isCoordValid(latitude)) {
        latitude = Number(latitude).toFixed(8);
    }
    var longitude = coords === null || coords === void 0 ? void 0 : coords.longitude;
    if (shortCoordinates.longitude && isCoordValid(longitude)) {
        longitude = Number(longitude).toFixed(8);
    }
    var hasValidCoords = isCoordValid(coords === null || coords === void 0 ? void 0 : coords.latitude) && isCoordValid(coords === null || coords === void 0 ? void 0 : coords.longitude);
    return (React.createElement("div", null,
        React.createElement(Label, { label: label, required: required, iconName: labelIconName, description: description }),
        !disabled && getCoordsTextFields(),
        error && (React.createElement(MessageBar, { messageBarType: MessageBarType.error, isMultiline: true, styles: {
                root: {
                    marginTop: '5px',
                    selectors: (_b = {}, _b[xsMaxMediaSelector] = { width: '100%' }, _b)
                }
            }, onDismiss: resetError, dismissButtonAriaLabel: t('location.field.close') }, error)),
        React.createElement("div", { style: { display: 'flex', marginTop: disabled ? 0 : 10 } },
            React.createElement(DefaultButton, { styles: {
                    textContainer: { flexGrow: 0 },
                    root: {
                        marginRight: '5px',
                        minWidth: '50%',
                        display: disabled ? 'none' : 'inline',
                        width: '100%',
                        selectors: (_c = {}, _c[xsMaxMediaSelector] = { height: '40px' }, _c)
                    }
                }, disabled: disabled || isLoading, iconProps: { iconName: 'Location' }, onClick: onGetLocation, primary: false, text: t('location.field.button.getLocation') }),
            isLoading && (React.createElement("div", { style: { minWidth: '50%', marginTop: 8 } },
                React.createElement(Spinner, { styles: {
                        root: {
                            marginLeft: '5px',
                            selectors: (_d = {}, _d[xsMaxMediaSelector] = { width: '100%' }, _d)
                        }
                    }, size: SpinnerSize.small, label: t('location.field.loading'), labelPosition: "right" }))),
            ((!isLoading && !disabled) || (disabled && hasValidCoords)) && (React.createElement(DefaultButton, { text: t('location.field.button.openMap'), onClick: onOpenMap, styles: {
                    textContainer: { flexGrow: 0 },
                    root: {
                        backgroundColor: '#f3f2f1',
                        visibility: hasValidCoords ? 'visible' : 'hidden',
                        width: '100%',
                        selectors: (_e = {}, _e[xsMaxMediaSelector] = { height: '40px' }, _e)
                    }
                }, iconProps: { iconName: 'Nav2DMapView' }, primary: false })),
            disabled && !hasValidCoords && (React.createElement("div", { style: {
                    padding: 7,
                    border: '1px solid #a19f9d',
                    borderRadius: 3,
                    backgroundColor: '#f3f2f1',
                    width: '100%',
                    color: '#a19f9d'
                } }, t('location.field.placeholder.disabled'))))));
}
export default LocationField;
