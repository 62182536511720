var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IconButton } from '@fluentui/react';
import { Label } from '@fluentui/react/lib/Label';
import { TagPicker } from '@fluentui/react/lib/Pickers';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
var SuggestionItemStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 7px;\n  width: 100%;\n  height: 100%;\n  text-align: start;\n  color: ", ";\n\n  &:hover {\n    cursor: ", ";\n  }\n\n  .create-tag {\n    padding-right: 5px;\n    padding-left: 2px;\n    color: ", ";\n  }\n\n  .tag-fullText {\n    color: rgb(133, 133, 133);\n    font-weight: 400;\n    font-size: 12px;\n    text-align: left;\n  }\n"], ["\n  padding: 7px;\n  width: 100%;\n  height: 100%;\n  text-align: start;\n  color: ", ";\n\n  &:hover {\n    cursor: ", ";\n  }\n\n  .create-tag {\n    padding-right: 5px;\n    padding-left: 2px;\n    color: ", ";\n  }\n\n  .tag-fullText {\n    color: rgb(133, 133, 133);\n    font-weight: 400;\n    font-size: 12px;\n    text-align: left;\n  }\n"])), function (props) { return (props.disabled ? '#c2c2c2' : null); }, function (props) { return (props.disabled ? 'default' : null); }, function (props) { return props.theme.themePrimary; });
var DisabledTagStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: #f3f2f1;\n  margin: 3px;\n  padding: 4px 9px;\n  border-radius: 3px;\n\n  :hover {\n    background-color: ", ";\n    cursor: pointer;\n    color: white;\n  }\n"], ["\n  background-color: #f3f2f1;\n  margin: 3px;\n  padding: 4px 9px;\n  border-radius: 3px;\n\n  :hover {\n    background-color: ", ";\n    cursor: pointer;\n    color: white;\n  }\n"])), function (props) { return props.theme.themePrimary; });
var TagStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #f3f2f1;\n\n  align-items: center;\n  display: flex;\n  margin: 3px;\n  padding: 2px 3px 2px 7px;\n  border-radius: 3px;\n\n  :hover {\n    .c-delete-tag-icon {\n      color: white;\n    }\n    background-color: ", ";\n    cursor: pointer;\n    color: white;\n  }\n"], ["\n  background-color: #f3f2f1;\n\n  align-items: center;\n  display: flex;\n  margin: 3px;\n  padding: 2px 3px 2px 7px;\n  border-radius: 3px;\n\n  :hover {\n    .c-delete-tag-icon {\n      color: white;\n    }\n    background-color: ", ";\n    cursor: pointer;\n    color: white;\n  }\n"])), function (props) { return props.theme.themePrimary; });
function TagPickerCustom(_a) {
    var selectedTags = _a.selectedTags, disabled = _a.disabled, onChange = _a.onChange, onTagsSearch = _a.onTagsSearch, onTagClick = _a.onTagClick, defaultValue = _a.defaultValue, placeHolder = _a.placeHolder;
    var t = useTranslation().t;
    var tagPickerRef = React.createRef();
    var defaultEmptyParentTag = { id: null, text: null };
    var _b = useState(defaultEmptyParentTag), parentTag = _b[0], setParentTag = _b[1];
    var defaultNoResultsFoundText = t('tagPicker.suggestions.noResult');
    var _c = useState(defaultNoResultsFoundText), noResultsFoundText = _c[0], setNoResultsFoundText = _c[1];
    var resultsMaximumNumber = 8;
    useEffect(function () {
        if (tagPickerRef && !disabled) {
            try {
                tagPickerRef.completeSelection = function (item) {
                    if ((item === null || item === void 0 ? void 0 : item.text) && !(item === null || item === void 0 ? void 0 : item.isDeleted) && !item.disabled) {
                        tagPickerRef.addItem(item);
                        tagPickerRef.updateValue('');
                        if (tagPickerRef.input.current) {
                            tagPickerRef.input.current.clear();
                        }
                        tagPickerRef.setState({ suggestionsVisible: false });
                    }
                    else {
                        tagPickerRef.setState({ suggestionsVisible: true });
                    }
                };
            }
            catch (error) {
                console.log('Error overriding picker method:', error);
            }
        }
    }, [tagPickerRef, disabled]);
    function compareStrings(a, b) {
        if (a && b) {
            var string1 = a.trim().toLowerCase();
            var string2 = b.trim().toLowerCase();
            string1 = getTagText(string1, true);
            string2 = getTagText(string2, true);
            return string1 === string2;
        }
        return false;
    }
    function removeSelectedTags(tagsArray) {
        if ((tagsArray === null || tagsArray === void 0 ? void 0 : tagsArray.length) && (selectedTags === null || selectedTags === void 0 ? void 0 : selectedTags.length)) {
            return tagsArray.filter(function (tag) {
                return selectedTags.findIndex(function (sTag) {
                    if (sTag.id && tag.id) {
                        return sTag.id === tag.id;
                    }
                    return compareStrings(sTag.text, tag.text);
                }) === -1;
            });
        }
        return tagsArray;
    }
    function findTagByText(tagsArray, tagText) {
        if (tagsArray && tagText) {
            return tagsArray.find(function (tag) { return compareStrings(tag.text, tagText); });
        }
        return null;
    }
    function getFilterTextParts(filterText) {
        var filterTextParts = {
            parentFilterText: null,
            childFilterText: null,
            hasDotSeperator: false
        };
        if (filterText && filterText !== '#') {
            var splittedFilterText = filterText.split('.');
            filterTextParts.parentFilterText = splittedFilterText[0] || null;
            filterTextParts.childFilterText = splittedFilterText[1] || null;
            filterTextParts.hasDotSeperator = filterText.includes('.');
            return filterTextParts;
        }
        return filterTextParts;
    }
    function removeParents(tags) {
        return tags.filter(function (tag) { var _a; return !((_a = tag.children) === null || _a === void 0 ? void 0 : _a.length); });
    }
    function onResolveSuggestions(filterText) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, parentFilterText, childFilterText, hasDotSeperator, tagTextToSearch, results, foundExistingTag, doesTagAlreadyExist, isTagAlreadySelected, isTagDisabled, showCreateNewOption;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = getFilterTextParts(filterText), parentFilterText = _a.parentFilterText, childFilterText = _a.childFilterText, hasDotSeperator = _a.hasDotSeperator;
                        if (!parentFilterText) return [3, 2];
                        tagTextToSearch = parentTag.id && childFilterText ? childFilterText : parentFilterText;
                        tagTextToSearch = getTagText(tagTextToSearch);
                        return [4, onTagsSearch({
                                searchTerm: tagTextToSearch,
                                onlyParents: false,
                                showDeleted: true
                            })];
                    case 1:
                        results = _b.sent();
                        foundExistingTag = findTagByText(results, parentFilterText);
                        if (hasDotSeperator && foundExistingTag && !childFilterText) {
                            if (!parentTag.id) {
                                setParentTag({ id: foundExistingTag.id, text: foundExistingTag.text });
                            }
                        }
                        else if (!hasDotSeperator) {
                            setParentTag(defaultEmptyParentTag);
                        }
                        results = results.map(function (result) { return (__assign(__assign({}, result), { name: result.fullText })); });
                        results = removeSelectedTags(results);
                        doesTagAlreadyExist = !!findTagByText(results, filterText);
                        isTagAlreadySelected = !!findTagByText(selectedTags, filterText);
                        isTagDisabled = foundExistingTag === null || foundExistingTag === void 0 ? void 0 : foundExistingTag.isDeleted;
                        showCreateNewOption = !isTagDisabled && !doesTagAlreadyExist && !isTagAlreadySelected && !hasDotSeperator;
                        results = removeParents(results);
                        if (showCreateNewOption) {
                            return [2, __spreadArray(__spreadArray([], results.slice(0, resultsMaximumNumber - 1)), [
                                    {
                                        createTag: true,
                                        fullText: filterText,
                                        text: filterText,
                                        name: getTagText(filterText),
                                        key: filterText
                                    }
                                ])];
                        }
                        if (isTagDisabled) {
                            setNoResultsFoundText(filterText + " " + t('tagPicker.disabled.tag'));
                        }
                        else if (noResultsFoundText !== defaultNoResultsFoundText) {
                            setNoResultsFoundText(defaultNoResultsFoundText);
                        }
                        return [2, results.map(function (tag) { return (__assign(__assign({}, tag), { name: getTagText(tag.fullText), key: tag.fullText })); })];
                    case 2: return [2, []];
                }
            });
        });
    }
    function getTextFromItem(tag) {
        return getTagText(tag.name);
    }
    function onTagPickerChange(tags) {
        if (tags) {
            setParentTag(defaultEmptyParentTag);
            var convertedTags_1 = [];
            if (tags === null || tags === void 0 ? void 0 : tags.length) {
                tags.map(function (tag) {
                    if (!(tag === null || tag === void 0 ? void 0 : tag.isDeleted)) {
                        convertedTags_1.push({
                            fullText: (tag === null || tag === void 0 ? void 0 : tag.fullText) || null,
                            id: (tag === null || tag === void 0 ? void 0 : tag.id) || null,
                            parentId: (tag === null || tag === void 0 ? void 0 : tag.parentId) || null,
                            text: getTagText(tag === null || tag === void 0 ? void 0 : tag.text, true),
                            disabled: tag === null || tag === void 0 ? void 0 : tag.disabled
                        });
                    }
                    return null;
                });
            }
            onChange(convertedTags_1);
        }
    }
    function onRenderSuggestionsItem(suggestionsProps) {
        if (suggestionsProps.createTag) {
            return (React.createElement(SuggestionItemStyled, null,
                React.createElement("div", { className: "flex" },
                    React.createElement("div", { className: "create-tag" }, t('tagPicker.create.tag')),
                    React.createElement("div", null, getTagText(suggestionsProps.text)))));
        }
        if (suggestionsProps.isDeleted) {
            return (React.createElement(TooltipHost, { delay: 0, tooltipProps: { onRenderContent: function () { return t('tagPicker.tooltip.suggestionTag'); } }, id: suggestionsProps.id + "-tooltip", calloutProps: { gapSpace: 5 }, key: suggestionsProps.text, styles: { root: { width: '100%' } } },
                React.createElement(SuggestionItemStyled, { disabled: suggestionsProps.isDeleted }, getTagText(suggestionsProps.fullText))));
        }
        return (React.createElement(SuggestionItemStyled, { disabled: suggestionsProps.isDeleted }, getTagText(suggestionsProps.fullText)));
    }
    var onRenderItem = function (itemProps) {
        var _a, _b;
        if (((_a = itemProps === null || itemProps === void 0 ? void 0 : itemProps.item) === null || _a === void 0 ? void 0 : _a.text) && !((_b = itemProps === null || itemProps === void 0 ? void 0 : itemProps.item) === null || _b === void 0 ? void 0 : _b.isDeleted)) {
            if (itemProps.item.disabled) {
                return (React.createElement(TooltipHost, { delay: 0, tooltipProps: { onRenderContent: function () { return t('tagPicker.tooltip.instanceTag'); } }, id: itemProps.id + "-tooltip", calloutProps: { gapSpace: 5 }, key: itemProps.item.text },
                    React.createElement(DisabledTagStyled, { onClick: function () {
                            if (onTagClick) {
                                onTagClick(itemProps.item.id);
                            }
                        }, style: { margin: '3px', padding: '3px 9px' } }, getTagText(itemProps.item.fullText || itemProps.item.text))));
            }
            return (React.createElement(TagStyled, { key: "tag-" + itemProps.item.text, onClick: function (ev) {
                    if (onTagClick) {
                        ev.stopPropagation();
                        onTagClick(itemProps.item.id);
                    }
                } },
                React.createElement("div", { style: { marginRight: 3, paddingBottom: 1 } }, getTagText(itemProps.item.fullText || itemProps.item.text)),
                React.createElement(IconButton, { iconProps: {
                        key: itemProps.item.text + "-cancel",
                        className: 'c-delete-tag-icon',
                        iconName: 'Cancel',
                        styles: { root: { color: 'black', fontSize: 12 } }
                    }, styles: {
                        root: { height: 22 },
                        rootHovered: { height: 22, backgroundColor: 'rgba(255,255,255,0.3)', color: 'white' }
                    }, onClick: function (ev) {
                        ev.stopPropagation();
                        itemProps.onRemoveItem(ev);
                    } })));
        }
        return null;
    };
    function getDefaultSelectedItems() {
        if (defaultValue && defaultValue.length) {
            var selectedItems_1 = [];
            defaultValue.map(function (tag) {
                if (!tag.isDeleted) {
                    selectedItems_1.push(__assign(__assign({}, tag), { name: getTagText(tag.fullText), key: tag.text, disabled: tag.disabled }));
                }
                return null;
            });
            return selectedItems_1;
        }
        return [];
    }
    function getTagText(tagText, remove) {
        if (remove === void 0) { remove = false; }
        if (tagText) {
            var hasHashTagSign = tagText.substring(0, 1) === '#';
            if (!remove && !hasHashTagSign) {
                return "#" + tagText;
            }
            if (remove && hasHashTagSign && tagText.length > 1) {
                return tagText.replace('#', '');
            }
            return tagText;
        }
        return tagText;
    }
    function onInputChange(text) {
        var regex = /(#)?([0-9a-zA-ZZäöüÄÖÜß]*(\.([0-9a-zA-ZZäöüÄÖÜß]*))?)/gm;
        var matchedRegex = text.match(regex);
        if (matchedRegex) {
            var matchedRegexText = matchedRegex[0];
            return matchedRegexText;
        }
        return '';
    }
    var tagLabelText = t('tagPicker.label.tags');
    if (disabled && defaultValue && defaultValue.length) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { paddingBottom: '2px' }, className: "ms-Label ms-font-s ms-fontColor-neutralSecondary" }, tagLabelText),
            React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap', marginBottom: '10px' } }, defaultValue.map(function (tag) { return (React.createElement(DisabledTagStyled, { onClick: function () {
                    if (onTagClick) {
                        onTagClick(tag.id);
                    }
                }, key: tag.text }, getTagText(tag.fullText))); }))));
    }
    if (!disabled) {
        return (React.createElement("div", null,
            React.createElement(Label, { styles: {
                    root: {
                        padding: 0,
                        fontSize: '13px',
                        fontWeight: 400,
                        color: '#605e5c'
                    }
                } }, tagLabelText),
            React.createElement(TagPicker, { componentRef: function (input) {
                    tagPickerRef = input;
                }, key: "{tagPicker-" + ((defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.length) || 0) + "}", defaultSelectedItems: getDefaultSelectedItems(), onRenderSuggestionsItem: onRenderSuggestionsItem, onResolveSuggestions: onResolveSuggestions, getTextFromItem: getTextFromItem, pickerSuggestionsProps: {
                    suggestionsHeaderText: t('tagPicker.suggestions.header'),
                    noResultsFoundText: noResultsFoundText
                }, onRenderItem: onRenderItem, styles: {
                    root: {
                        borderRadius: 3,
                        transition: 'background-color 500ms',
                        cursor: 'pointer',
                        ':hover': {
                            backgroundColor: '#f2f2f2'
                        },
                        border: 'none'
                    },
                    input: {
                        paddingLeft: 3,
                        cursor: 'pointer',
                        ':focus': {
                            cursor: 'text'
                        }
                    },
                    text: {
                        border: 'none',
                        selectors: {
                            ':after': {
                                border: 'none'
                            }
                        }
                    }
                }, inputProps: {
                    placeholder: placeHolder
                }, itemLimit: 20, disabled: false, resolveDelay: 100, onChange: onTagPickerChange, onRemoveItem: onTagPickerChange, onInputChange: onInputChange })));
    }
    return null;
}
export default TagPickerCustom;
var templateObject_1, templateObject_2, templateObject_3;
