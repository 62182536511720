var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@fluentui/react';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import { concatStyleSets } from '@fluentui/react/lib/Styling';
import Label from '../Label/Label';
import DateTimeStyled from './DateTime.styles';
function DateTime(_a) {
    var _b = _a.allowPastDate, allowPastDate = _b === void 0 ? false : _b, defaultValue = _a.defaultValue, description = _a.description, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.displayTime, displayTime = _d === void 0 ? false : _d, label = _a.label, labelIconName = _a.labelIconName, maxDate = _a.maxDate, minDate = _a.minDate, onSelectDate = _a.onSelectDate, placeholder = _a.placeholder, _e = _a.required, required = _e === void 0 ? false : _e, styles = _a.styles, timeLabel = _a.timeLabel, timeLabelIconName = _a.timeLabelIconName;
    var t = useTranslation().t;
    var defaultTimeOptions = getTimeOptions();
    var datePickerRef = useRef(null);
    var _f = useState(false), initialized = _f[0], setInitialized = _f[1];
    var _g = useState({
        selectedDate: undefined,
        selectedTime: '00:00',
        timeOptions: __spreadArray([], defaultTimeOptions)
    }), dateTimeModel = _g[0], setDateTimeModel = _g[1];
    var compareTimeValues = useCallback(function (a, b) {
        if (a < b)
            return -1;
        if (a > b)
            return 1;
        return 0;
    }, []);
    var validateDate = useCallback(function (dateStr) {
        return moment(dateStr).isValid();
    }, []);
    var getNewAndSortedTimeOptions = useCallback(function (newOptionValue) {
        var newOptions = __spreadArray([], defaultTimeOptions);
        var newOption = { key: newOptionValue, text: newOptionValue };
        newOptions.push(newOption);
        newOptions.sort(function (a, b) { return compareTimeValues(a.key, b.key); });
        return newOptions;
    }, [compareTimeValues, defaultTimeOptions]);
    var setInitialDateTimeModel = useCallback(function (_a) {
        var selectedTime = _a.selectedTime, selectedDate = _a.selectedDate;
        var compareTimeOptionToSelectedTime = function (option) {
            return option.key === selectedTime;
        };
        setDateTimeModel(function (prevState) {
            var timeOptions = prevState.timeOptions;
            var doesSelectedTimeOptionExist = timeOptions.find(compareTimeOptionToSelectedTime);
            if (selectedTime && !doesSelectedTimeOptionExist) {
                var newTimeOptions = getNewAndSortedTimeOptions(selectedTime);
                return __assign(__assign({}, prevState), { timeOptions: newTimeOptions, selectedTime: selectedTime, selectedDate: selectedDate });
            }
            return __assign(__assign({}, prevState), { selectedTime: selectedTime, selectedDate: selectedDate });
        });
    }, [getNewAndSortedTimeOptions]);
    useEffect(function () {
        if (!initialized) {
            setInitialized(true);
            var selectedDate_1;
            var selectedTime_1 = '00:00';
            if (defaultValue && validateDate(defaultValue.toString())) {
                selectedDate_1 = new Date(defaultValue);
                selectedTime_1 = moment(selectedDate_1).format('hh:mm');
            }
            setInitialDateTimeModel({ selectedTime: selectedTime_1, selectedDate: selectedDate_1 });
        }
    }, [defaultValue, initialized, setInitialDateTimeModel, validateDate]);
    function formatDate(date) {
        if (!date) {
            return '';
        }
        return moment(date).format('L');
    }
    function convertStringToDate(string, format) {
        return moment(string, format).toDate();
    }
    function parseDateFromString(dateStr) {
        if (!validateDate(dateStr)) {
            return null;
        }
        var date = convertStringToDate(dateStr, 'L');
        var currentTime = new Date().getTime();
        if (date.getTime() + 86399999 >= currentTime) {
            return date;
        }
        return null;
    }
    function getMinimumAllowableDate() {
        if (minDate) {
            return new Date(minDate);
        }
        if (allowPastDate) {
            return new Date('January 1, 1900');
        }
        return new Date();
    }
    function getMaximumAllowableDate() {
        if (maxDate) {
            return new Date(maxDate);
        }
        var today = new Date();
        return new Date(today.setFullYear(today.getFullYear() + 100));
    }
    function getTimeOptions() {
        var options = [];
        for (var index = 0; index < 24; index++) {
            var isTwoNumberDigit = index > 9;
            var hour = isTwoNumberDigit ? index : "0" + index;
            options.push({ key: hour + ":00", text: hour + ":00" });
            options.push({ key: hour + ":30", text: hour + ":30" });
        }
        return options;
    }
    function validateTime(timeString) {
        return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(timeString);
    }
    function validateAndSetNewTimeOption(value) {
        var isValidTime = validateTime(value);
        if (isValidTime) {
            var newTimeOptions_1 = getNewAndSortedTimeOptions(value);
            setDateTimeModel(function (prevState) { return (__assign(__assign({}, prevState), { timeOptions: newTimeOptions_1, selectedTime: value })); });
            var fullDateTime = void 0;
            if (dateTimeModel.selectedDate) {
                fullDateTime = getFullDateTime(dateTimeModel.selectedDate, value.toString());
            }
            if (onSelectDate) {
                onSelectDate(fullDateTime || null);
            }
        }
    }
    function onPendingValueChanged(option, index, value) {
        if (!option && !index && value) {
            validateAndSetNewTimeOption(value);
        }
    }
    function onComboBoxChange(_, option) {
        if (option && option.key) {
            setDateTimeModel(function (prevState) { return (__assign(__assign({}, prevState), { selectedTime: option.key.toString() })); });
            var fullDateTime = void 0;
            if (dateTimeModel.selectedDate) {
                fullDateTime = getFullDateTime(dateTimeModel.selectedDate, option.key.toString());
            }
            if (onSelectDate) {
                onSelectDate(fullDateTime || null);
            }
        }
    }
    function onLabelClick() {
        if (!disabled && (datePickerRef === null || datePickerRef === void 0 ? void 0 : datePickerRef.current)) {
            datePickerRef === null || datePickerRef === void 0 ? void 0 : datePickerRef.current.showDatePickerPopup();
        }
    }
    function getFullDateTime(givenDate, givenTime) {
        var hour = Number(givenTime.split(':')[0]);
        var minute = Number(givenTime.split(':')[1]);
        var fullDate = moment(givenDate).set({ hour: hour, minute: minute }).format('YYYY-MM-DD hh:mm');
        return new Date(fullDate);
    }
    function onDatePickerChange(date) {
        setDateTimeModel(function (prevState) { return (__assign(__assign({}, prevState), { selectedDate: date || undefined })); });
        var fullDateTime;
        if (date && validateDate(date)) {
            fullDateTime = getFullDateTime(date, dateTimeModel.selectedTime);
        }
        if (onSelectDate) {
            onSelectDate(fullDateTime || null);
        }
    }
    function getDatePickerStyles() {
        var datePickerStyles = {
            root: { width: '100%' }
        };
        if (styles === null || styles === void 0 ? void 0 : styles.datePickerStyles) {
            datePickerStyles = concatStyleSets(datePickerStyles, styles.datePickerStyles);
        }
        return datePickerStyles;
    }
    function getTextFieldStyles() {
        var textFieldStyles = {
            fieldGroup: {
                border: '1px solid #a19f9d',
                borderRadius: 3,
                ':after': { borderRadius: 3 }
            }
        };
        if (styles === null || styles === void 0 ? void 0 : styles.textFieldStyles) {
            textFieldStyles = concatStyleSets(textFieldStyles, styles.textFieldStyles);
        }
        return textFieldStyles;
    }
    function getTimePickerStyles() {
        var timePickerStyles = {
            optionsContainer: { maxHeight: 400 },
            container: { width: '100%' },
            input: { ':after': { border: '1px solid #a19f9d' } },
            root: {
                marginLeft: 10,
                ':after': { border: '1px solid #a19f9d', borderRadius: 3 }
            }
        };
        if (styles === null || styles === void 0 ? void 0 : styles.timePickerStyles) {
            timePickerStyles = concatStyleSets(timePickerStyles, styles.timePickerStyles);
        }
        return timePickerStyles;
    }
    var minimumDate = getMinimumAllowableDate();
    var maximumDate = getMaximumAllowableDate();
    var selectedDate = dateTimeModel.selectedDate, selectedTime = dateTimeModel.selectedTime, timeOptions = dateTimeModel.timeOptions;
    function TimePicker() {
        if (!displayTime) {
            return null;
        }
        var timePickerStyles = getTimePickerStyles();
        return (React.createElement("div", null,
            React.createElement(Label, { label: timeLabel, required: required, description: description, iconName: timeLabelIconName, styles: { container: { paddingLeft: 8 } } }),
            React.createElement(ComboBox, { allowFreeform: true, autoComplete: "off", comboBoxOptionStyles: { optionText: { minWidth: '50px' } }, disabled: !dateTimeModel.selectedTime || disabled, multiSelect: false, onChange: onComboBoxChange, onPendingValueChanged: onPendingValueChanged, options: timeOptions, selectedKey: selectedTime, styles: timePickerStyles })));
    }
    var datePickerStyles = getDatePickerStyles();
    var datePickerDescription = !displayTime ? description : undefined;
    var textFieldStyles = getTextFieldStyles();
    return (React.createElement(DateTimeStyled, { style: styles === null || styles === void 0 ? void 0 : styles.containerStyles },
        React.createElement("div", { style: { width: '100%' } },
            React.createElement(Label, { onClick: onLabelClick, label: label, required: required, iconName: labelIconName, description: datePickerDescription, styles: { fluentLabel: styles === null || styles === void 0 ? void 0 : styles.labelStyles } }),
            React.createElement(DatePicker, { className: "c-date-picker", allowTextInput: true, componentRef: datePickerRef, disabled: disabled, formatDate: formatDate, maxDate: maximumDate, strings: {
                    goToToday: t("dateTime.datePicker.goToToday"),
                    months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (m) {
                        return t("dateTime.datePicker.month", { context: m.toString() });
                    }),
                    shortMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (m) {
                        return t("dateTime.datePicker.month", { context: m.toString() }).substring(0, 3);
                    }),
                    days: [0, 1, 2, 3, 4, 5, 6].map(function (d) {
                        return t("dateTime.datePicker.day", { context: d.toString() });
                    }),
                    shortDays: [0, 1, 2, 3, 4, 5, 6].map(function (d) {
                        return t("dateTime.datePicker.day", { context: d.toString() }).substring(0, 2);
                    })
                }, textField: { inputClassName: 'c-date-picker__text', styles: textFieldStyles }, minDate: minimumDate, onSelectDate: onDatePickerChange, parseDateFromString: parseDateFromString, placeholder: placeholder || t('dateTime.datePicker.placeholder'), showWeekNumbers: true, styles: datePickerStyles, value: selectedDate })),
        React.createElement(TimePicker, null)));
}
export default DateTime;
