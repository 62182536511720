import React, { useRef, useState } from 'react';
import { IconButton, TextField } from '@fluentui/react';
import CodeScanner from '../CodeScanner/CodeScanner';
import Label from '../Label/Label';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { ModalStyled } from './CodeScannerField.styles';
function CodeScannerField(_a) {
    var defaultValue = _a.defaultValue, description = _a.description, _b = _a.disabled, disabled = _b === void 0 ? false : _b, label = _a.label, labelIconName = _a.labelIconName, placeholder = _a.placeholder, onChange = _a.onChange, _c = _a.required, required = _c === void 0 ? false : _c, textFieldRef = _a.textFieldRef, textFieldId = _a.textFieldId;
    var _d = useState(defaultValue), data = _d[0], setData = _d[1];
    var _e = useState(false), scannerReady = _e[0], setScannerReady = _e[1];
    var _f = useState(null), scannerError = _f[0], setScannerError = _f[1];
    var scannerRef = useRef(null);
    var _g = useState(false), streaming = _g[0], setStreaming = _g[1];
    function renderScannerIconButton() {
        return (React.createElement(IconButton, { iconProps: { iconName: 'GenericScan' }, onClick: function () { return setStreaming(function (prevState) { return !prevState; }); }, disabled: disabled, styles: { root: { height: '30px' } } }));
    }
    function onValueChange(newValue) {
        setData(newValue);
        if (onChange) {
            onChange(newValue);
        }
    }
    function onBarCodeScannerUpdate(result) {
        if (result) {
            resetState();
            setData(result);
            onValueChange(result);
        }
    }
    function onScannerReady() {
        setScannerReady(true);
    }
    function onScannerError(error) {
        setScannerError(error);
    }
    function resetState() {
        setStreaming(false);
        setScannerReady(false);
        setScannerError(null);
    }
    function getCloseModalButton() {
        var ImagePreviewCloseButtonStyles = {
            root: {
                backgroundColor: 'rgb(207, 207, 207, 0.4)',
                color: 'rgb(50, 49, 48);',
                marginRight: 15,
                marginTop: 10,
                position: 'absolute',
                right: 0,
                zIndex: 1000
            },
            rootHovered: { backgroundColor: 'rgb(207, 207, 207, 0.6)', color: 'rgb(32, 31, 30)' },
            rootPressed: { backgroundColor: 'rgb(189, 189, 189, 0.6)', color: 'rgb(32, 31, 30)' }
        };
        return (React.createElement("div", { style: { position: 'relative' } },
            React.createElement(IconButton, { styles: ImagePreviewCloseButtonStyles, iconProps: { iconName: 'Cancel' }, onClick: function () { return resetState(); } })));
    }
    function getModalContent() {
        if (scannerError) {
            return (React.createElement(React.Fragment, null,
                getCloseModalButton(),
                React.createElement("div", { style: { margin: 50 } }, scannerError === null || scannerError === void 0 ? void 0 : scannerError.toString())));
        }
        return (React.createElement(React.Fragment, null,
            getCloseModalButton(),
            React.createElement("div", { style: { display: scannerReady ? 'inline' : 'none' }, ref: scannerRef }),
            !scannerReady && (React.createElement(LoadingSpinner, { styles: {
                    container: {
                        margin: '120px'
                    }
                }, size: 3 })),
            React.createElement("div", null,
                React.createElement(CodeScanner, { onScannerError: onScannerError, scannerRef: scannerRef, onDetected: onBarCodeScannerUpdate, onScannerReady: onScannerReady }))));
    }
    function getTextFieldStyles(_a) {
        var disabled = _a.disabled;
        return {
            fieldGroup: [{ border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }],
            wrapper: disabled ? { border: '1px solid #a19f9d', borderRadius: 3 } : undefined,
            field: { color: '#323130' },
            suffix: { backgroundColor: disabled ? '#f3f2f1' : 'transparent' }
        };
    }
    return (React.createElement("div", null,
        React.createElement(Label, { description: description, iconName: labelIconName, label: label, required: required }),
        streaming && React.createElement(ModalStyled, { isOpen: streaming }, getModalContent()),
        React.createElement(TextField, { autoComplete: "off", componentRef: textFieldRef, disabled: disabled, id: textFieldId, onChange: function (_, newValue) { return onValueChange(newValue); }, onRenderSuffix: renderScannerIconButton, placeholder: placeholder, styles: getTextFieldStyles, value: data })));
}
export default CodeScannerField;
