var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { convertFromHTML } from 'draft-convert';
import { AtomicBlockUtils, CompositeDecorator, EditorState, Modifier, RichUtils, getDefaultKeyBinding } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import { Image } from '@fluentui/react';
import Label from '../Label/Label';
import { EditorWrapper, MentionStyled, RichTextEditorStyled, TagStyled } from './RichTextEditor.styles';
import RichTextEditorCmdBar from './RichTextEditorCmdBar';
import RichTextEditorLinkDialog from './RichTextEditorLinkDialog';
import RichTextEditorSuggestions from './RichTextEditorSuggestions';
function RichTextEditor(_a) {
    var _b = _a.allowMentioning, allowMentioning = _b === void 0 ? false : _b, _c = _a.allowTagging, allowTagging = _c === void 0 ? false : _c, defaultValue = _a.defaultValue, description = _a.description, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.displayCmdBar, displayCmdBar = _e === void 0 ? false : _e, getPersons = _a.getPersons, getTags = _a.getTags, label = _a.label, labelIconName = _a.labelIconName, onBlur = _a.onBlur, onChange = _a.onChange, onFocus = _a.onFocus, onTagClick = _a.onTagClick, placeholder = _a.placeholder, _f = _a.required, required = _f === void 0 ? false : _f, _g = _a.setInitialFocus, setInitialFocus = _g === void 0 ? false : _g, styles = _a.styles;
    var _h = useState(null), autocompleteState = _h[0], setAutocompleteState = _h[1];
    var _j = useState(0), selectedSuggestionIndex = _j[0], setSelectedSuggestionIndex = _j[1];
    var _k = useState(EditorState.createEmpty()), editorState = _k[0], setEditorState = _k[1];
    var _l = useState(false), isEditorInitialized = _l[0], setIsEditorInitialized = _l[1];
    var blockDndPlugin = createBlockDndPlugin();
    var imagePluginDecorator = composeDecorators(blockDndPlugin.decorator);
    var imagePlugin = createImagePlugin({ decorator: imagePluginDecorator });
    var _m = useState(true), isLinkDialogHidden = _m[0], setIsLinkDialogHidden = _m[1];
    var _o = useState(null), defaultDialogLink = _o[0], setDefaultDialogLink = _o[1];
    var _p = useState(null), suggestions = _p[0], setSuggestions = _p[1];
    var _q = useState(null), parentTag = _q[0], setParentTag = _q[1];
    var _r = useState(false), focused = _r[0], setFocused = _r[1];
    var triggers = {
        TAG: 1,
        PERSON: 2,
        TAG_TRIGGER: '#',
        MENTION_TRIGGER: '@'
    };
    var setPersonSuggestions = useCallback(function (searchText) {
        if (getPersons) {
            getPersons(searchText).then(function (peronSuggestions) {
                if (Array.isArray(peronSuggestions)) {
                    var transformedSuggestions = peronSuggestions.map(function (suggestion) { return ({
                        id: suggestion.userId,
                        name: suggestion.name,
                        avatar: suggestion.pictureUrl,
                        userId: suggestion.userId || '',
                        login: suggestion.login || '',
                        pictureUrl: suggestion.pictureUrl,
                        tenantId: suggestion.tenantId
                    }); });
                    setSuggestions(transformedSuggestions);
                }
            });
        }
    }, [getPersons]);
    var setTagSuggestions = useCallback(function (searchText, tagSuggestionParentId) {
        if (getTags) {
            getTags(searchText, tagSuggestionParentId).then(function (fetchedSuggestions) {
                if (Array.isArray(fetchedSuggestions)) {
                    var transformedSuggestions = fetchedSuggestions.map(function (suggestion) { return ({
                        id: suggestion.id,
                        name: suggestion.text,
                        text: suggestion.text,
                        fullText: suggestion.fullText,
                        parentId: suggestion.parentId,
                        children: suggestion.children,
                        disabled: !!suggestion.isDeleted
                    }); });
                    var completeSuggestions = __spreadArray([], transformedSuggestions);
                    completeSuggestions = completeSuggestions.filter(function (suggestion) { var _a; return !((_a = suggestion.children) === null || _a === void 0 ? void 0 : _a.length); });
                    var alreadyExistingTag = completeSuggestions.find(function (suggestion) {
                        return suggestion.text.trim().toLowerCase() === searchText.trim().toLowerCase();
                    });
                    var isTagDisabled = alreadyExistingTag === null || alreadyExistingTag === void 0 ? void 0 : alreadyExistingTag.disabled;
                    if (!isTagDisabled && !alreadyExistingTag && !tagSuggestionParentId) {
                        var createNewTagSuggestion = {
                            id: '',
                            name: searchText.trim(),
                            text: searchText.trim(),
                            disabled: false,
                            fullText: '',
                            parentId: '',
                            children: undefined
                        };
                        completeSuggestions = __spreadArray(__spreadArray([], completeSuggestions), [createNewTagSuggestion]);
                    }
                    setSuggestions(completeSuggestions);
                }
            });
        }
    }, [getTags]);
    useEffect(function () {
        if (autocompleteState) {
            var text = autocompleteState.text;
            text = text.trim().replace(autocompleteState.trigger, '');
            var type = autocompleteState.type;
            if (text) {
                if (type === triggers.PERSON) {
                    setPersonSuggestions(text);
                }
                else if (type === triggers.TAG) {
                    var parentTagId = parentTag ? parentTag.id : null;
                    setTagSuggestions(text, parentTagId);
                }
            }
        }
    }, [
        autocompleteState,
        parentTag,
        setPersonSuggestions,
        setTagSuggestions,
        triggers.PERSON,
        triggers.TAG
    ]);
    useEffect(function () {
        var tagDecorator = function (tagDecoratorProps) {
            var children = tagDecoratorProps.children, entityKey = tagDecoratorProps.entityKey, contentState = tagDecoratorProps.contentState;
            var draftEntityInstance = contentState.getEntity(entityKey);
            var data = draftEntityInstance.getData();
            var onClick = function () {
                if (onTagClick) {
                    onTagClick(data.id);
                }
            };
            return (React.createElement(TagStyled, { readOnly: disabled, onClick: onClick }, children));
        };
        if (!isEditorInitialized) {
            var decorator = new CompositeDecorator([
                {
                    strategy: function (contentBlock, callback, contentState) {
                        return findEntities(contentBlock, callback, contentState, 'LINK');
                    },
                    component: linkDecorator
                },
                {
                    strategy: function (contentBlock, callback, contentState) {
                        return findEntities(contentBlock, callback, contentState, 'MENTION');
                    },
                    component: mentionDecorator
                },
                {
                    strategy: function (contentBlock, callback, contentState) {
                        return findEntities(contentBlock, callback, contentState, 'TAG');
                    },
                    component: tagDecorator
                },
                {
                    strategy: function (contentBlock, callback, contentState) {
                        return findEntities(contentBlock, callback, contentState, 'IMAGE');
                    },
                    component: imageDecorator
                }
            ]);
            if (defaultValue) {
                var hasHtmlTag = function (string) { return string.indexOf('<') === 0; };
                var htmlDefaultValue = hasHtmlTag(defaultValue) ? defaultValue : "<p>" + defaultValue + "</p>";
                var state = convertFromHTML({
                    htmlToEntity: function (nodeName, node, createEntity) {
                        var nodeType = null;
                        if (nodeName === 'span') {
                            nodeType = node.getAttribute('entity-type');
                        }
                        if (nodeType === 'MENTION') {
                            var login = node.getAttribute('login');
                            var userId = node.getAttribute('userId');
                            return createEntity('MENTION', 'IMMUTABLE', { login: login, userId: userId });
                        }
                        if (nodeType === 'TAG') {
                            var text = node.getAttribute('tag-text');
                            var id = node.getAttribute('id');
                            var fullText = node.getAttribute('tag-fulltext');
                            var parentId = node.getAttribute('parent-tag-id');
                            return createEntity('TAG', 'IMMUTABLE', { text: text, id: id, fullText: fullText, parentId: parentId });
                        }
                        if (nodeName === 'a') {
                            var target = '_blank';
                            var href = node.getAttribute('href');
                            return createEntity('LINK', 'MUTABLE', { target: target, url: href });
                        }
                        if (nodeName === 'img') {
                            var src = node.getAttribute('src');
                            return createEntity('IMAGE', 'IMMUTABLE', { src: src });
                        }
                        return undefined;
                    },
                    htmlToBlock: function (nodeName) {
                        if (nodeName === 'img') {
                            return {
                                type: 'atomic',
                                data: {}
                            };
                        }
                        return undefined;
                    }
                })(htmlDefaultValue);
                var editorStateWithContent = EditorState.createWithContent(state, decorator);
                if (setInitialFocus) {
                    editorStateWithContent = EditorState.moveFocusToEnd(editorStateWithContent);
                }
                setEditorState(editorStateWithContent);
            }
            else {
                setEditorState(EditorState.createEmpty(decorator));
            }
            setIsEditorInitialized(true);
        }
    }, [defaultValue, isEditorInitialized, editorState, disabled, setInitialFocus, onTagClick]);
    function findEntities(contentBlock, callback, contentState, type) {
        contentBlock.findEntityRanges(function (character) {
            var entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === type;
        }, callback);
    }
    function linkDecorator(linkProps) {
        var contentState = linkProps.contentState, entityKey = linkProps.entityKey, children = linkProps.children;
        var _a = contentState.getEntity(entityKey).getData(), url = _a.url, target = _a.target;
        return (React.createElement("a", { href: url, rel: "noopener noreferrer", target: target }, children));
    }
    function imageDecorator(imageProps) {
        var contentState = imageProps.contentState, entityKey = imageProps.entityKey;
        var src = contentState.getEntity(entityKey).getData().src;
        return React.createElement(Image, { src: src });
    }
    function mentionDecorator(mentionProps) {
        var children = mentionProps.children;
        return React.createElement(MentionStyled, null, children);
    }
    function keyBindingFn(event) {
        if (autocompleteState) {
            if (event.keyCode === 38) {
                return 'arrowUp';
            }
            if (event.keyCode === 40) {
                return 'arrowDown';
            }
            if (event.keyCode === 9) {
                return 'tab';
            }
            if (event.keyCode === 27) {
                return 'esc';
            }
            if (event.keyCode === 13) {
                return 'enter';
            }
        }
        return getDefaultKeyBinding(event);
    }
    function handleKeyCommand(command, editorState) {
        if (autocompleteState) {
            if (command === 'arrowUp') {
                onArrow(-1);
                return 'handled';
            }
            if (command === 'arrowDown') {
                onArrow(1);
                return 'handled';
            }
            if (command === 'tab') {
                commitSelection();
                return 'handled';
            }
            if (command === 'esc') {
                setAutocompleteState(null);
                setSelectedSuggestionIndex(0);
                return 'handled';
            }
            if (command === 'enter') {
                commitSelection();
                return 'handled';
            }
        }
        var newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }
    function toggleInlineStyle(style) {
        onEditorChange(RichUtils.toggleInlineStyle(editorState, style));
    }
    function toggleBlockType(blockType) {
        onEditorChange(RichUtils.toggleBlockType(editorState, blockType));
    }
    function getTagsAndMentionedPeopleFromHtml(htmlContent) {
        var tagsAndMentionedPeople = {
            tags: [],
            mentions: []
        };
        convertFromHTML({
            htmlToEntity: function (nodeName, node) {
                var nodeType = null;
                if (nodeName === 'span') {
                    nodeType = node.getAttribute('entity-type');
                }
                if (nodeType === 'MENTION') {
                    var login = node.getAttribute('login') || '';
                    var userId = node.getAttribute('userId') || '';
                    tagsAndMentionedPeople.mentions.push({ login: login, userId: userId });
                }
                if (nodeType === 'TAG') {
                    var text = node.getAttribute('tag-text') || '';
                    var id = node.getAttribute('id') || '';
                    var fullText = node.getAttribute('tag-fulltext');
                    var parentId = node.getAttribute('parent-tag-id');
                    tagsAndMentionedPeople.tags.push({
                        children: undefined,
                        disabled: false,
                        fullText: fullText || null,
                        id: id,
                        name: '',
                        parentId: parentId || null,
                        text: text
                    });
                }
                return undefined;
            }
        })(htmlContent);
        return tagsAndMentionedPeople;
    }
    function removeLink() {
        var selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));
        }
    }
    function insertImage(src) {
        var contentState = editorState.getCurrentContent();
        var contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: src });
        var entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        var newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    }
    function handlePastedFiles(files) {
        if (files[0].type.includes('image')) {
            getBase64AndSetNewEditorState(files[0]);
        }
        return 'handled';
    }
    function handleDroppedFiles(_, files) {
        if (files[0].type.includes('image')) {
            getBase64AndSetNewEditorState(files[0]);
        }
        return 'handled';
    }
    function attachImage(imagesArray) {
        getBase64AndSetNewEditorState(imagesArray[0]);
    }
    function getBase64AndSetNewEditorState(image) {
        var reader = new FileReader();
        reader.onload = function () {
            var newEditorState = insertImage(reader.result);
            onEditorChange(newEditorState);
        };
        reader.readAsDataURL(image);
    }
    function getSelectedEntity() {
        var startKey = editorState.getSelection().getStartKey();
        var contentState = editorState.getCurrentContent();
        var startOffset = editorState.getSelection().getStartOffset();
        var blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        var key = blockWithLinkAtBeginning.getEntityAt(startOffset);
        if (key) {
            return contentState.getEntity(key);
        }
        return null;
    }
    function openLinkDialog() {
        var linkEntity = getSelectedEntity();
        if (linkEntity) {
            var defaultUrl = linkEntity.getData().url;
            if (defaultUrl) {
                setDefaultDialogLink(defaultUrl);
            }
        }
        setIsLinkDialogHidden(false);
    }
    function onConfirmLinkCreation(linkText) {
        removeLink();
        if (editorState) {
            var linkTextWithProtocol = linkText;
            var hasProtocol = new RegExp('https?://');
            if (!hasProtocol.test(linkText)) {
                linkTextWithProtocol = "http://" + linkText;
            }
            var contentState = editorState.getCurrentContent();
            var contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
                target: '_blank',
                url: linkTextWithProtocol
            });
            var entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            var newEditorState = EditorState.set(editorState, {
                currentContent: contentStateWithEntity
            });
            var updatedEditorState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
            onEditorChange(updatedEditorState);
            setIsLinkDialogHidden(true);
        }
    }
    var debounceAutocompleteState = useRef(debounce(function () {
        var newAutocompleteState = getAutocompleteState();
        if (newAutocompleteState) {
            var text = newAutocompleteState.text;
            if (text && text.length < 2) {
                setSuggestions(null);
            }
        }
        setAutocompleteState(newAutocompleteState);
    }, 500)).current;
    function onEditorChange(editorState) {
        if (allowTagging || allowMentioning) {
            debounceAutocompleteState();
        }
        var contentState = editorState.getCurrentContent();
        var options = {
            entityStyleFn: function (entity) {
                var entityType = entity.getType();
                if (entityType === 'MENTION') {
                    var data = entity.getData();
                    return {
                        element: 'span',
                        attributes: {
                            'entity-type': "MENTION",
                            login: data.login,
                            userId: data.userId
                        }
                    };
                }
                if (entityType === 'TAG') {
                    var data = entity.getData();
                    if (!data.id) {
                        data.id = uuidv4();
                    }
                    return {
                        element: 'span',
                        attributes: {
                            'entity-type': "TAG",
                            'tag-text': data.text,
                            'tag-fulltext': data.fullText,
                            'parent-tag-id': data.parentId,
                            id: data.id
                        }
                    };
                }
                return undefined;
            }
        };
        var htmlValue = stateToHTML(contentState, options);
        var _a = getTagsAndMentionedPeopleFromHtml(htmlValue), mentions = _a.mentions, tags = _a.tags;
        var onChangeProps = { value: htmlValue, mentions: mentions, tags: tags };
        if (onChange && isEditorInitialized) {
            onChange(onChangeProps);
        }
        setEditorState(editorState);
    }
    function getAutocompleteState() {
        var _a;
        var newAutocompleteState = null;
        var tagRange = null;
        if (allowTagging) {
            tagRange = getAutocompleteRange(triggers.TAG_TRIGGER);
        }
        var personRange = null;
        if (allowMentioning) {
            personRange = getAutocompleteRange(triggers.MENTION_TRIGGER);
        }
        if (!tagRange && !personRange) {
            return null;
        }
        var type = 0;
        var trigger = '';
        var range = null;
        if (!tagRange) {
            range = personRange;
            type = triggers.PERSON;
            trigger = triggers.MENTION_TRIGGER;
        }
        if (!personRange) {
            range = tagRange;
            type = triggers.TAG;
            trigger = triggers.TAG_TRIGGER;
        }
        if (!range && tagRange && personRange) {
            range = tagRange.start > personRange.start ? tagRange : personRange;
            type = tagRange.start > personRange.start ? triggers.TAG : triggers.PERSON;
            trigger =
                tagRange.start > personRange.start ? triggers.TAG_TRIGGER : triggers.MENTION_TRIGGER;
        }
        var tempRange = (_a = window === null || window === void 0 ? void 0 : window.getSelection()) === null || _a === void 0 ? void 0 : _a.getRangeAt(0).cloneRange();
        tempRange === null || tempRange === void 0 ? void 0 : tempRange.setStart(tempRange.startContainer, (range === null || range === void 0 ? void 0 : range.start) || 0);
        var rangeRect = tempRange === null || tempRange === void 0 ? void 0 : tempRange.getBoundingClientRect();
        var _b = [(rangeRect === null || rangeRect === void 0 ? void 0 : rangeRect.left) || 0, (rangeRect === null || rangeRect === void 0 ? void 0 : rangeRect.bottom) || 0], left = _b[0], top = _b[1];
        newAutocompleteState = {
            trigger: trigger,
            type: type,
            left: left,
            top: top,
            text: (range === null || range === void 0 ? void 0 : range.text) || ''
        };
        if (trigger === triggers.TAG_TRIGGER) {
            newAutocompleteState = __assign(__assign({}, newAutocompleteState), { parentTag: parentTag });
        }
        return newAutocompleteState;
    }
    function getAutocompleteRange(trigger) {
        var selection = window.getSelection();
        if (selection && selection.rangeCount === 0) {
            return null;
        }
        if (hasEntityAtSelection()) {
            return null;
        }
        var range = selection === null || selection === void 0 ? void 0 : selection.getRangeAt(0);
        var text = range === null || range === void 0 ? void 0 : range.startContainer.textContent;
        text = text === null || text === void 0 ? void 0 : text.substring(0, range === null || range === void 0 ? void 0 : range.startOffset);
        var index = (text === null || text === void 0 ? void 0 : text.lastIndexOf(trigger)) || -1;
        if (index === -1) {
            return null;
        }
        text = text === null || text === void 0 ? void 0 : text.substring(index);
        var hasTagSeperator = !!(text === null || text === void 0 ? void 0 : text.includes('.'));
        var splittedText = text === null || text === void 0 ? void 0 : text.split('.');
        var parentText = splittedText ? splittedText[0] : null;
        parentText = parentText ? parentText.replace(triggers.TAG_TRIGGER, '') : null;
        var childText = splittedText ? splittedText[1] : null;
        if (parentText) {
            handleParentTag(parentText, childText, hasTagSeperator);
        }
        text = childText || parentText;
        return {
            text: text,
            start: index,
            end: range === null || range === void 0 ? void 0 : range.startOffset
        };
    }
    function handleParentTag(parentText, childText, hasTagSeperator) {
        if (!parentTag && hasTagSeperator && childText) {
            setParentTag(findTagByText(suggestions, parentText));
        }
        if (!hasTagSeperator && !childText) {
            setParentTag(null);
        }
    }
    function findTagByText(tagArray, text) {
        if (tagArray && text) {
            var foundTag = tagArray.find(function (tag) { return tag.text.trim().toLowerCase() === text.trim().toLowerCase(); });
            return foundTag || null;
        }
        return null;
    }
    function hasEntityAtSelection() {
        var selection = editorState.getSelection();
        if (!selection.getHasFocus()) {
            return false;
        }
        var contentState = editorState.getCurrentContent();
        var block = contentState.getBlockForKey(selection.getStartKey());
        return !!block.getEntityAt(selection.getStartOffset() - 1);
    }
    function renderSuggestions() {
        if (!autocompleteState || !suggestions) {
            return null;
        }
        return (React.createElement(RichTextEditorSuggestions, { commitSelection: commitSelection, suggestionsState: __assign(__assign({}, autocompleteState), { suggestions: suggestions }), selectedSuggestionIndex: selectedSuggestionIndex }));
    }
    function onArrow(nudgeAmount) {
        if (!autocompleteState) {
            return;
        }
        setSelectedSuggestionIndex(function (prevState) { return prevState + nudgeAmount; });
    }
    function getInsertState(selectedIndex, trigger) {
        var currentSelectionState = editorState.getSelection();
        var end = currentSelectionState.getAnchorOffset();
        var anchorKey = currentSelectionState.getAnchorKey();
        var currentContent = editorState.getCurrentContent();
        var currentBlock = currentContent.getBlockForKey(anchorKey);
        var blockText = currentBlock.getText();
        var start = blockText.substring(0, end).lastIndexOf(trigger);
        return {
            editorState: editorState,
            start: start,
            end: end,
            trigger: trigger,
            text: '',
            selectedIndex: selectedIndex
        };
    }
    function commitSelection(selectionProps) {
        var _a;
        if (selectionProps === null || selectionProps === void 0 ? void 0 : selectionProps.event) {
            (_a = selectionProps.event) === null || _a === void 0 ? void 0 : _a.preventDefault();
        }
        if (!autocompleteState) {
            return false;
        }
        if (autocompleteState.trigger) {
            onSuggestionSelect(autocompleteState.trigger, selectionProps === null || selectionProps === void 0 ? void 0 : selectionProps.selectedIndex);
        }
        if (autocompleteState) {
            setAutocompleteState(null);
            setSelectedSuggestionIndex(0);
        }
        if (suggestions) {
            setSuggestions(null);
        }
        return true;
    }
    function normalizeIndex(selectedIndex, max) {
        var index = selectedIndex % max;
        if (index < 0) {
            index += max;
        }
        return index;
    }
    var addSuggestion = function (_a) {
        var editorState = _a.editorState, start = _a.start, end = _a.end, trigger = _a.trigger, text = _a.text, data = _a.data;
        var contentState = editorState.getCurrentContent();
        var contentStateWithEntity;
        if (trigger === triggers.MENTION_TRIGGER) {
            contentStateWithEntity = contentState.createEntity('MENTION', 'SEGMENTED', data);
        }
        else {
            contentStateWithEntity = contentState.createEntity('TAG', 'SEGMENTED', data);
        }
        var entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        var newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
        });
        var currentSelectionState = editorState.getSelection();
        var mentionTextSelection = currentSelectionState.merge({
            anchorOffset: start,
            focusOffset: end
        });
        var insertingContent = Modifier.replaceText(newEditorState.getCurrentContent(), mentionTextSelection, text, undefined, entityKey);
        var blockKey = mentionTextSelection.getAnchorKey();
        var blockSize = newEditorState.getCurrentContent().getBlockForKey(blockKey).getLength();
        if (blockSize === end) {
            insertingContent = Modifier.insertText(insertingContent, insertingContent.getSelectionAfter(), ' ');
        }
        newEditorState = EditorState.push(editorState, insertingContent, 'apply-entity');
        return EditorState.forceSelection(newEditorState, insertingContent.getSelectionAfter());
    };
    function isTagSuggestion(suggestion) {
        return suggestion.fullText !== undefined;
    }
    function onInsert(insertState) {
        if (suggestions && (suggestions === null || suggestions === void 0 ? void 0 : suggestions.length)) {
            var insertStateClone = __assign({}, insertState);
            var index = normalizeIndex(insertState.selectedIndex, suggestions.length);
            var text = void 0;
            var suggestionToInsert = suggestions[index];
            if (isTagSuggestion(suggestionToInsert)) {
                text = suggestionToInsert.fullText;
            }
            else {
                text = suggestionToInsert.name;
            }
            insertStateClone.text = insertState.trigger + text;
            insertStateClone.data = suggestions[index];
            return addSuggestion(insertStateClone);
        }
        return null;
    }
    function onSuggestionSelect(trigger, selectedIndex) {
        var insertState = getInsertState(selectedIndex || selectedSuggestionIndex, trigger);
        var newEditorState = onInsert(insertState);
        if (newEditorState) {
            onEditorChange(newEditorState);
        }
    }
    function getRichTextEditorClassNames() {
        var classNames = 'rte-base ';
        if (focused) {
            classNames = 'rte-focused ';
        }
        if (disabled) {
            classNames = 'rte-disabled ';
        }
        return classNames;
    }
    var editorWrapperStyle;
    var editorStyle;
    if (styles) {
        editorWrapperStyle = styles.editorWrapper;
        editorStyle = styles.editor;
    }
    var richTextEditorClassNames = getRichTextEditorClassNames();
    return (React.createElement("div", null,
        React.createElement(Label, { styles: { fluentLabel: styles === null || styles === void 0 ? void 0 : styles.label }, label: label, required: required, description: description, iconName: labelIconName }),
        React.createElement(RichTextEditorStyled, { className: richTextEditorClassNames, focused: focused, style: editorStyle, readOnly: disabled },
            React.createElement(EditorWrapper, { focused: focused, style: editorWrapperStyle, readOnly: disabled },
                React.createElement(Editor, { editorState: editorState, handleDroppedFiles: handleDroppedFiles, handleKeyCommand: handleKeyCommand, handlePastedFiles: handlePastedFiles, keyBindingFn: keyBindingFn, onFocus: function () {
                        if (onFocus) {
                            onFocus();
                        }
                        setFocused(true);
                    }, onBlur: function () {
                        if (onBlur) {
                            onBlur();
                        }
                        setFocused(false);
                    }, onChange: onEditorChange, placeholder: placeholder, plugins: [blockDndPlugin, imagePlugin], readOnly: disabled })),
            React.createElement(RichTextEditorCmdBar, { displayCmdBar: displayCmdBar, attachImage: attachImage, openLinkDialog: openLinkDialog, removeLink: removeLink, toggleBlockType: toggleBlockType, toggleInlineStyle: toggleInlineStyle }),
            renderSuggestions(),
            !isLinkDialogHidden && (React.createElement(RichTextEditorLinkDialog, { defaultLink: defaultDialogLink, onConfirm: onConfirmLinkCreation, onDismiss: function () {
                    setIsLinkDialogHidden(true);
                    setDefaultDialogLink(null);
                } })))));
}
export default RichTextEditor;
