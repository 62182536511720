import React from 'react';
import { DefaultButton, DialogFooter, DialogType, Dialog as FluentDialog, PrimaryButton, getTheme } from '@fluentui/react';
function Dialog(_a) {
    var content = _a.content, defaultButtonFunc = _a.defaultButtonFunc, defaultButtonLabel = _a.defaultButtonLabel, _b = _a.hidden, hidden = _b === void 0 ? true : _b, modalProps = _a.modalProps, onDismiss = _a.onDismiss, primaryButtonFunc = _a.primaryButtonFunc, primaryButtonLabel = _a.primaryButtonLabel, subText = _a.subText, title = _a.title;
    var styles = {
        root: {},
        main: [
            {
                selectors: {
                    '@media (min-width: 650px)': {
                        width: '100%',
                        minWidth: '400px'
                    },
                    '@media (max-width: 480px)': [
                        {
                            position: 'absolute',
                            top: '10px',
                            width: '100%',
                            selectors: {
                                '.ms-Dialog-header': [
                                    {
                                        selectors: {
                                            '.ms-Dialog-title': {
                                                padding: '11px',
                                                fontSize: '16px',
                                                paddingRight: '40px'
                                            },
                                            'div[class^="topButton-"]': {
                                                padding: '5px'
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    };
    return (React.createElement(FluentDialog, { hidden: hidden, onDismiss: onDismiss, dialogContentProps: {
            type: DialogType.normal,
            title: title,
            subText: subText,
            styles: {
                title: {
                    backgroundColor: getTheme().palette.themePrimary,
                    color: '#fff'
                }
            }
        }, modalProps: modalProps, styles: styles },
        content,
        React.createElement(DialogFooter, null,
            primaryButtonFunc ? (React.createElement(PrimaryButton, { onClick: primaryButtonFunc, text: primaryButtonLabel })) : null,
            React.createElement(DefaultButton, { onClick: defaultButtonFunc, text: defaultButtonLabel }))));
}
export default Dialog;
