import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityItem, IconButton } from '@fluentui/react';
export default function Activity(_a) {
    var activity = _a.activity, callInterpolationFunction = _a.callInterpolationFunction, containerContext = _a.containerContext, getActivityIcon = _a.getActivityIcon, handleOnActivityClick = _a.handleOnActivityClick, handleOnReadIconClick = _a.handleOnReadIconClick, timeAgo = _a.timeAgo;
    var t = useTranslation().t;
    function getActivityComments(activity) {
        var commentArray = t("activitiesList.activity." + activity.type + ".comment", {
            activity: activity,
            context: containerContext,
            returnObjects: true,
            interpolation: {
                format: onFormatInterpolation
            }
        });
        if (Array.isArray(commentArray) && commentArray.length > 0) {
            return commentArray.map(function (text) {
                if (text.indexOf('fx.') === 0) {
                    var interpolationFunctionName = text.split('.')[1];
                    return callInterpolationFunction(interpolationFunctionName, activity);
                }
                return (React.createElement("span", { key: activity.id + text, className: "activity-comment" }, text));
            });
        }
        return null;
    }
    function onFormatInterpolation(value, format) {
        if (value instanceof Date) {
            return moment(value).format(format);
        }
        if (format === 'checkListItemStatus') {
            return value ? '2' : '1';
        }
        if (format === 'typeOfTeam') {
            if (value === 1)
                return 'internal';
            if (value === 2)
                return 'external';
            if (value === 3)
                return 'department';
            if (value === 4)
                return 'board';
        }
        return value.toString();
    }
    function getDescriptionSpan(text) {
        return (React.createElement("span", { key: activity.id + text, className: "activity-description" }, text));
    }
    function getActivityDescription(activity) {
        var descriptionArray = t("activitiesList.activity." + activity.type + ".description", {
            activity: activity,
            context: containerContext,
            returnObjects: true,
            interpolation: {
                format: onFormatInterpolation
            }
        });
        if (Array.isArray(descriptionArray) && descriptionArray.length > 1) {
            return descriptionArray.map(function (text) {
                if (text.indexOf('fx.') === 0) {
                    var interpolationFunctionName = text.split('.')[1];
                    return callInterpolationFunction(interpolationFunctionName, activity);
                }
                return getDescriptionSpan(text);
            });
        }
        if (Array.isArray(descriptionArray) && descriptionArray.length === 1) {
            return [getDescriptionSpan(descriptionArray[0])];
        }
        return [];
    }
    function onReadIconClick(activity) {
        if (handleOnReadIconClick) {
            handleOnReadIconClick(activity);
        }
    }
    function getNewActivityIcons(activity) {
        return (React.createElement("div", { className: "new-notification-container" },
            React.createElement(IconButton, { iconProps: { iconName: 'RedEye' }, onClick: function () { return onReadIconClick(activity); }, className: "read-notification-button", title: t('activitiesList.mark-as-read') }),
            React.createElement("div", { className: "new-notification-indicator" })));
    }
    function onActivityClick(activity) {
        if (handleOnActivityClick) {
            handleOnActivityClick(activity);
        }
    }
    return (React.createElement("div", { style: { paddingBottom: '7px' } },
        React.createElement("div", { className: "activity-list-item " + (activity.isNew ? 'unread-notification' : '') },
            React.createElement("div", { "aria-hidden": "true", onClick: function () { return onActivityClick(activity); }, className: "activity-item " + (handleOnActivityClick ? 'active' : '') },
                React.createElement(ActivityItem, { key: activity.id, timeStamp: timeAgo(activity === null || activity === void 0 ? void 0 : activity.creationDate), activityDescription: getActivityDescription(activity), comments: getActivityComments(activity), activityIcon: getActivityIcon(activity.type) })),
            activity.isNew && getNewActivityIcons(activity))));
}
