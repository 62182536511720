export var TaskType;
(function (TaskType) {
    TaskType[TaskType["Task"] = 1] = "Task";
    TaskType[TaskType["Request"] = 2] = "Request";
    TaskType[TaskType["ApprovalParent"] = 3] = "ApprovalParent";
    TaskType[TaskType["Approval"] = 4] = "Approval";
    TaskType[TaskType["SurveyParent"] = 5] = "SurveyParent";
    TaskType[TaskType["Survey"] = 6] = "Survey";
    TaskType[TaskType["RouteTaskParent"] = 7] = "RouteTaskParent";
    TaskType[TaskType["RouteTask"] = 8] = "RouteTask";
    TaskType[TaskType["RouteApprovalParent"] = 9] = "RouteApprovalParent";
    TaskType[TaskType["RouteApproval"] = 10] = "RouteApproval";
    TaskType[TaskType["RouteDataParent"] = 11] = "RouteDataParent";
    TaskType[TaskType["RouteData"] = 12] = "RouteData";
    TaskType[TaskType["RouteSurveyParent"] = 13] = "RouteSurveyParent";
    TaskType[TaskType["RouteSurvey"] = 14] = "RouteSurvey";
    TaskType[TaskType["RouteDataSub"] = 20] = "RouteDataSub";
    TaskType[TaskType["AgileProjectBacklog"] = 50] = "AgileProjectBacklog";
    TaskType[TaskType["AgileProjectTask"] = 51] = "AgileProjectTask";
    TaskType[TaskType["ClassicProjectMilestone"] = 60] = "ClassicProjectMilestone";
    TaskType[TaskType["ClassicProjectTask"] = 61] = "ClassicProjectTask";
    TaskType[TaskType["PlannerTask"] = 100] = "PlannerTask";
})(TaskType || (TaskType = {}));
export var TaskStatus;
(function (TaskStatus) {
    TaskStatus[TaskStatus["NotStarted"] = 1] = "NotStarted";
    TaskStatus[TaskStatus["Deferred"] = 2] = "Deferred";
    TaskStatus[TaskStatus["InProgress"] = 3] = "InProgress";
    TaskStatus[TaskStatus["Waiting"] = 4] = "Waiting";
    TaskStatus[TaskStatus["Completed"] = 5] = "Completed";
})(TaskStatus || (TaskStatus = {}));
export var ApprovalTaskStatus;
(function (ApprovalTaskStatus) {
    ApprovalTaskStatus[ApprovalTaskStatus["Approved"] = 1] = "Approved";
    ApprovalTaskStatus[ApprovalTaskStatus["Rejected"] = 2] = "Rejected";
})(ApprovalTaskStatus || (ApprovalTaskStatus = {}));
export var RequestStatus;
(function (RequestStatus) {
    RequestStatus[RequestStatus["Waiting"] = 1] = "Waiting";
    RequestStatus[RequestStatus["Accepted"] = 2] = "Accepted";
    RequestStatus[RequestStatus["Rejected"] = 3] = "Rejected";
})(RequestStatus || (RequestStatus = {}));
export var ConvertChecklistitemType;
(function (ConvertChecklistitemType) {
    ConvertChecklistitemType[ConvertChecklistitemType["ToTask"] = 1] = "ToTask";
    ConvertChecklistitemType[ConvertChecklistitemType["ToRequest"] = 2] = "ToRequest";
    ConvertChecklistitemType[ConvertChecklistitemType["ToDataSub"] = 3] = "ToDataSub";
})(ConvertChecklistitemType || (ConvertChecklistitemType = {}));
export var TeamType;
(function (TeamType) {
    TeamType[TeamType["Internal"] = 1] = "Internal";
    TeamType[TeamType["External"] = 2] = "External";
    TeamType[TeamType["Department"] = 3] = "Department";
    TeamType[TeamType["Board"] = 4] = "Board";
})(TeamType || (TeamType = {}));
export var MemberRole;
(function (MemberRole) {
    MemberRole[MemberRole["TeamLeader"] = 1] = "TeamLeader";
    MemberRole[MemberRole["TeamMember"] = 2] = "TeamMember";
})(MemberRole || (MemberRole = {}));
export var RouteStepType;
(function (RouteStepType) {
    RouteStepType[RouteStepType["Start"] = 1] = "Start";
    RouteStepType[RouteStepType["End"] = 2] = "End";
    RouteStepType[RouteStepType["Approval"] = 3] = "Approval";
    RouteStepType[RouteStepType["Task"] = 4] = "Task";
    RouteStepType[RouteStepType["DataSwitch"] = 5] = "DataSwitch";
    RouteStepType[RouteStepType["Data"] = 6] = "Data";
    RouteStepType[RouteStepType["Survey"] = 7] = "Survey";
    RouteStepType[RouteStepType["Integration"] = 8] = "Integration";
})(RouteStepType || (RouteStepType = {}));
export var RouteFieldType;
(function (RouteFieldType) {
    RouteFieldType[RouteFieldType["FieldGroup"] = 0] = "FieldGroup";
    RouteFieldType[RouteFieldType["SmallText"] = 1] = "SmallText";
    RouteFieldType[RouteFieldType["LongText"] = 2] = "LongText";
    RouteFieldType[RouteFieldType["Number"] = 3] = "Number";
    RouteFieldType[RouteFieldType["Boolean"] = 4] = "Boolean";
    RouteFieldType[RouteFieldType["DateTime"] = 5] = "DateTime";
    RouteFieldType[RouteFieldType["Choice"] = 6] = "Choice";
    RouteFieldType[RouteFieldType["Person"] = 7] = "Person";
    RouteFieldType[RouteFieldType["Rating"] = 8] = "Rating";
    RouteFieldType[RouteFieldType["Hyperlink"] = 9] = "Hyperlink";
    RouteFieldType[RouteFieldType["Document"] = 10] = "Document";
    RouteFieldType[RouteFieldType["ExternalData"] = 11] = "ExternalData";
    RouteFieldType[RouteFieldType["Lookup"] = 12] = "Lookup";
    RouteFieldType[RouteFieldType["Location"] = 13] = "Location";
    RouteFieldType[RouteFieldType["Signature"] = 14] = "Signature";
    RouteFieldType[RouteFieldType["Multimedia"] = 15] = "Multimedia";
    RouteFieldType[RouteFieldType["Scanner"] = 16] = "Scanner";
})(RouteFieldType || (RouteFieldType = {}));
export var DateTimeFieldFormat;
(function (DateTimeFieldFormat) {
    DateTimeFieldFormat[DateTimeFieldFormat["DateOnly"] = 1] = "DateOnly";
    DateTimeFieldFormat[DateTimeFieldFormat["DateAndTime"] = 2] = "DateAndTime";
})(DateTimeFieldFormat || (DateTimeFieldFormat = {}));
export var ChoiceFieldFormat;
(function (ChoiceFieldFormat) {
    ChoiceFieldFormat[ChoiceFieldFormat["SingleSelect"] = 1] = "SingleSelect";
    ChoiceFieldFormat[ChoiceFieldFormat["MultiSelect"] = 2] = "MultiSelect";
})(ChoiceFieldFormat || (ChoiceFieldFormat = {}));
export var RouteFieldVisibility;
(function (RouteFieldVisibility) {
    RouteFieldVisibility[RouteFieldVisibility["Optional"] = 1] = "Optional";
    RouteFieldVisibility[RouteFieldVisibility["Required"] = 2] = "Required";
    RouteFieldVisibility[RouteFieldVisibility["ReadOnly"] = 3] = "ReadOnly";
})(RouteFieldVisibility || (RouteFieldVisibility = {}));
export var RouteStepAssignment;
(function (RouteStepAssignment) {
    RouteStepAssignment[RouteStepAssignment["Direct"] = 0] = "Direct";
    RouteStepAssignment[RouteStepAssignment["ToCreator"] = 1] = "ToCreator";
})(RouteStepAssignment || (RouteStepAssignment = {}));
export var RouteDefinitionStatus;
(function (RouteDefinitionStatus) {
    RouteDefinitionStatus[RouteDefinitionStatus["Draft"] = 0] = "Draft";
    RouteDefinitionStatus[RouteDefinitionStatus["Published"] = 1] = "Published";
})(RouteDefinitionStatus || (RouteDefinitionStatus = {}));
export var RouteDefinitionUsage;
(function (RouteDefinitionUsage) {
    RouteDefinitionUsage[RouteDefinitionUsage["Deactivated"] = 0] = "Deactivated";
    RouteDefinitionUsage[RouteDefinitionUsage["AsEpProcess"] = 1] = "AsEpProcess";
    RouteDefinitionUsage[RouteDefinitionUsage["InIntegration"] = 2] = "InIntegration";
    RouteDefinitionUsage[RouteDefinitionUsage["InFields"] = 4] = "InFields";
})(RouteDefinitionUsage || (RouteDefinitionUsage = {}));
export var RouteDefinitionAuth;
(function (RouteDefinitionAuth) {
    RouteDefinitionAuth[RouteDefinitionAuth["None"] = 0] = "None";
    RouteDefinitionAuth[RouteDefinitionAuth["AsUser"] = 1] = "AsUser";
    RouteDefinitionAuth[RouteDefinitionAuth["AsApp"] = 2] = "AsApp";
})(RouteDefinitionAuth || (RouteDefinitionAuth = {}));
export var RouteDefinitionContentType;
(function (RouteDefinitionContentType) {
    RouteDefinitionContentType[RouteDefinitionContentType["Json"] = 1] = "Json";
    RouteDefinitionContentType[RouteDefinitionContentType["Xml"] = 2] = "Xml";
})(RouteDefinitionContentType || (RouteDefinitionContentType = {}));
export var QueryFieldUsage;
(function (QueryFieldUsage) {
    QueryFieldUsage[QueryFieldUsage["KeyValue"] = 0] = "KeyValue";
    QueryFieldUsage[QueryFieldUsage["StringFormat"] = 1] = "StringFormat";
})(QueryFieldUsage || (QueryFieldUsage = {}));
export var RouteInstanceStatus;
(function (RouteInstanceStatus) {
    RouteInstanceStatus[RouteInstanceStatus["NotStarted"] = 1] = "NotStarted";
    RouteInstanceStatus[RouteInstanceStatus["Started"] = 2] = "Started";
    RouteInstanceStatus[RouteInstanceStatus["InProgress"] = 3] = "InProgress";
    RouteInstanceStatus[RouteInstanceStatus["Completed"] = 4] = "Completed";
})(RouteInstanceStatus || (RouteInstanceStatus = {}));
export var RouteInstanceStepStatus;
(function (RouteInstanceStepStatus) {
    RouteInstanceStepStatus[RouteInstanceStepStatus["Current"] = 1] = "Current";
    RouteInstanceStepStatus[RouteInstanceStepStatus["Upcoming"] = 2] = "Upcoming";
    RouteInstanceStepStatus[RouteInstanceStepStatus["Done"] = 3] = "Done";
    RouteInstanceStepStatus[RouteInstanceStepStatus["Bypassed"] = 4] = "Bypassed";
    RouteInstanceStepStatus[RouteInstanceStepStatus["Skipped"] = 5] = "Skipped";
})(RouteInstanceStepStatus || (RouteInstanceStepStatus = {}));
export var ProcessOutcome;
(function (ProcessOutcome) {
    ProcessOutcome[ProcessOutcome["Positive"] = 1] = "Positive";
    ProcessOutcome[ProcessOutcome["Negative"] = 2] = "Negative";
    ProcessOutcome[ProcessOutcome["Undecided"] = 3] = "Undecided";
})(ProcessOutcome || (ProcessOutcome = {}));
export var RuleCondition;
(function (RuleCondition) {
    RuleCondition[RuleCondition["Equal"] = 1] = "Equal";
    RuleCondition[RuleCondition["NotEqual"] = 2] = "NotEqual";
    RuleCondition[RuleCondition["GreaterThan"] = 3] = "GreaterThan";
    RuleCondition[RuleCondition["LessThan"] = 4] = "LessThan";
    RuleCondition[RuleCondition["GreaterOrEqual"] = 5] = "GreaterOrEqual";
    RuleCondition[RuleCondition["LessOrEqual"] = 6] = "LessOrEqual";
    RuleCondition[RuleCondition["BeginsWith"] = 7] = "BeginsWith";
    RuleCondition[RuleCondition["Contains"] = 8] = "Contains";
    RuleCondition[RuleCondition["EndsWith"] = 9] = "EndsWith";
    RuleCondition[RuleCondition["NotContains"] = 10] = "NotContains";
    RuleCondition[RuleCondition["IsEmpty"] = 11] = "IsEmpty";
    RuleCondition[RuleCondition["IsNotEmpty"] = 12] = "IsNotEmpty";
})(RuleCondition || (RuleCondition = {}));
export var RuleConditionLink;
(function (RuleConditionLink) {
    RuleConditionLink[RuleConditionLink["And"] = 1] = "And";
    RuleConditionLink[RuleConditionLink["Or"] = 2] = "Or";
})(RuleConditionLink || (RuleConditionLink = {}));
export var OutcomeBehavior;
(function (OutcomeBehavior) {
    OutcomeBehavior[OutcomeBehavior["FirstApplies"] = 1] = "FirstApplies";
    OutcomeBehavior[OutcomeBehavior["MajorityApplies"] = 2] = "MajorityApplies";
    OutcomeBehavior[OutcomeBehavior["UnanimityNecessary"] = 3] = "UnanimityNecessary";
    OutcomeBehavior[OutcomeBehavior["AllDecideWithMajority"] = 4] = "AllDecideWithMajority";
    OutcomeBehavior[OutcomeBehavior["AllDecideUnanimous"] = 5] = "AllDecideUnanimous";
})(OutcomeBehavior || (OutcomeBehavior = {}));
export var FieldAggregationType;
(function (FieldAggregationType) {
    FieldAggregationType[FieldAggregationType["NotSet"] = 0] = "NotSet";
    FieldAggregationType[FieldAggregationType["NoAggregation"] = 1] = "NoAggregation";
    FieldAggregationType[FieldAggregationType["Sum"] = 2] = "Sum";
    FieldAggregationType[FieldAggregationType["Average"] = 4] = "Average";
    FieldAggregationType[FieldAggregationType["CountValues"] = 8] = "CountValues";
})(FieldAggregationType || (FieldAggregationType = {}));
export var FileType;
(function (FileType) {
    FileType[FileType["NotSpecified"] = 0] = "NotSpecified";
    FileType[FileType["MainInstanceImage"] = 1] = "MainInstanceImage";
})(FileType || (FileType = {}));
export var TenantStatus;
(function (TenantStatus) {
    TenantStatus[TenantStatus["Unknown"] = 0] = "Unknown";
    TenantStatus[TenantStatus["FirstTest"] = 1] = "FirstTest";
    TenantStatus[TenantStatus["ExtendedTest"] = 2] = "ExtendedTest";
    TenantStatus[TenantStatus["Customer"] = 3] = "Customer";
    TenantStatus[TenantStatus["FormerCustomer"] = 4] = "FormerCustomer";
    TenantStatus[TenantStatus["Locked"] = 5] = "Locked";
})(TenantStatus || (TenantStatus = {}));
export var NumEmployees;
(function (NumEmployees) {
    NumEmployees[NumEmployees["Unknown"] = 0] = "Unknown";
    NumEmployees[NumEmployees["xSmall"] = 1] = "xSmall";
    NumEmployees[NumEmployees["small"] = 2] = "small";
    NumEmployees[NumEmployees["smallPlus"] = 3] = "smallPlus";
    NumEmployees[NumEmployees["medium"] = 4] = "medium";
    NumEmployees[NumEmployees["mediumPlus"] = 5] = "mediumPlus";
    NumEmployees[NumEmployees["large"] = 6] = "large";
    NumEmployees[NumEmployees["xLarge"] = 7] = "xLarge";
    NumEmployees[NumEmployees["xxLarge"] = 8] = "xxLarge";
})(NumEmployees || (NumEmployees = {}));
export var TextType;
(function (TextType) {
    TextType[TextType["RouteDefinitionName"] = 1] = "RouteDefinitionName";
    TextType[TextType["RouteDefinitionDescription"] = 2] = "RouteDefinitionDescription";
    TextType[TextType["RouteDefinitionStepName"] = 3] = "RouteDefinitionStepName";
    TextType[TextType["RouteDefinitionStepDescription"] = 4] = "RouteDefinitionStepDescription";
    TextType[TextType["RouteDefinitionStepOutcomes"] = 5] = "RouteDefinitionStepOutcomes";
    TextType[TextType["RouteDefinitionStepGroupName"] = 6] = "RouteDefinitionStepGroupName";
    TextType[TextType["RouteDefinitionStepGroupDescription"] = 7] = "RouteDefinitionStepGroupDescription";
    TextType[TextType["RouteFieldName"] = 8] = "RouteFieldName";
    TextType[TextType["RouteFieldDescription"] = 9] = "RouteFieldDescription";
    TextType[TextType["RouteFieldChoiceValue"] = 10] = "RouteFieldChoiceValue";
    TextType[TextType["TeamTitle"] = 11] = "TeamTitle";
    TextType[TextType["RoleName"] = 12] = "RoleName";
    TextType[TextType["CountryName"] = 13] = "CountryName";
    TextType[TextType["LocationName"] = 14] = "LocationName";
    TextType[TextType["ProcessTypeName"] = 15] = "ProcessTypeName";
    TextType[TextType["ProcessTypeDescription"] = 16] = "ProcessTypeDescription";
    TextType[TextType["EndToEndTypeName"] = 17] = "EndToEndTypeName";
    TextType[TextType["EndToEndTypeDescription"] = 18] = "EndToEndTypeDescription";
})(TextType || (TextType = {}));
export var NotificationGroupType;
(function (NotificationGroupType) {
    NotificationGroupType[NotificationGroupType["Task"] = 1] = "Task";
    NotificationGroupType[NotificationGroupType["Team"] = 2] = "Team";
    NotificationGroupType[NotificationGroupType["RouteDefinition"] = 3] = "RouteDefinition";
    NotificationGroupType[NotificationGroupType["RouteInstance"] = 4] = "RouteInstance";
})(NotificationGroupType || (NotificationGroupType = {}));
export var ProcessLookupType;
(function (ProcessLookupType) {
    ProcessLookupType[ProcessLookupType["All"] = 0] = "All";
    ProcessLookupType[ProcessLookupType["OnlyComplted"] = 1] = "OnlyComplted";
    ProcessLookupType[ProcessLookupType["OnlyRunning"] = 2] = "OnlyRunning";
})(ProcessLookupType || (ProcessLookupType = {}));
