/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React, { Suspense, lazy, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import { isInIframe } from '../../utils/helpers';
import NotificationsPanel from '../Activities/NotificationsPanel';
import Header from '../Header/Header';
import MsTeamsHeader from '../Header/MsTeamsHeader';
import NavigationPanel from '../Navigation/NavigationPanel';
import SideBar from '../Navigation/SideBar';
import DownLoadDocumentContainer from './components/Global/DownloadDocumentContainer';
import MsTeamsConfig from './components/Global/MsTeamsConfig';
import ReleaseNotesPanel from './components/Global/ReleaseNotesPanel';
import history from './history';

const Activities = lazy(() => import('../Activities/Activities.container'));
const Administration = lazy(() => import('../Administration/containers/Administration.container'));
const NoAccess = lazy(() => import('../NoAccess/NoAccess.container'));
const NotFound = lazy(() => import('../NotFound/NotFound.container'));
const ProcessDesigner = lazy(() =>
  import('../ProcessDesigner/containers/ProcessDesigner.container')
);
const ProcessInstanceTree = lazy(() =>
  import('../ProcessInstanceTree/containers/ProcessInstanceTree.container')
);
const ProcessInstances = lazy(() =>
  import('../ProcessInstances/containers/ProcessInstances.container')
);
const Projects = lazy(() => import('../Projects/containers/Projects.container'));
const ProcessTemplates = lazy(() =>
  import('../ProcessTemplates/containers/ProcessTemplates.container')
);
const Tags = lazy(() => import('../Tags/Tags.container'));
const TasksContainer = lazy(() => import('../Tasks/Tasks.container'));
const ProjectGantt = lazy(() => import('../ProjectGantt/ProjectGantt.container'));

// styles
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  min-height: 0;
  height: 100%;

  .content-main {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .min-h-full {
    min-height: 100%;
  }

  .navigation-wrapper {
    @media (max-width: ${breakpoints.smallMin}px) {
      display: none;
    }
  }
`;

const SideBarStyledContainer = styled.div`
  @media (max-width: ${breakpoints.mediumMax}px) {
    display: none;
  }
`;

const Routing = ({ logout, contentToShow }) => {
  const [showNavigationPanel, setShowNavigationPanel] = useState(false);
  const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
  const [showReleaseNotesPanel, setShowReleaseNotesPanel] = useState(false);

  const noAccess = window.location.href.indexOf('/access') > -1;

  function toggleNavigationPanel() {
    setShowNavigationPanel(!showNavigationPanel);
  }

  function onToggleNotifications() {
    setShowNotificationsPanel(!showNotificationsPanel);
  }

  function onToggleReleaseNotes() {
    setShowReleaseNotesPanel(!showReleaseNotesPanel);
  }

  if (noAccess) {
    return (
      <Router history={history}>
        <Route path="/access" exact render={() => <NoAccess />} />
      </Router>
    );
  }

  const isMsTeamsAuthPage = window.location.href.includes('teams-config');

  if (isMsTeamsAuthPage) {
    return (
      <Router history={history}>
        <Route path="/teams-config" exact render={() => <MsTeamsConfig />} />
      </Router>
    );
  }

  if (window.location.href.includes('file-download')) {
    return (
      <Router history={history}>
        <Route
          path="/file-download/:fileId/:fileTitle/:tenantId"
          exact
          render={() => <DownLoadDocumentContainer />}
        />
      </Router>
    );
  }

  const header = isInIframe() ? (
    <MsTeamsHeader
      contentToShow={contentToShow}
      logout={logout}
      onToggleNotifications={onToggleNotifications}
      onToggleReleaseNotes={onToggleReleaseNotes}
    />
  ) : (
    <Header
      logout={logout}
      toggleNavigationPanel={toggleNavigationPanel}
      onToggleNotifications={onToggleNotifications}
      onToggleReleaseNotes={onToggleReleaseNotes}
    />
  );

  const sidebar =
    !noAccess || !isInIframe() ? (
      <SideBarStyledContainer>
        <SideBar className="min-h-full" />
      </SideBarStyledContainer>
    ) : null;

  const navigationPanel = (
    <NavigationPanel
      showNavigationPanel={showNavigationPanel}
      toggleSideBar={toggleNavigationPanel}
    />
  );

  const notificationsPanel = (
    <NotificationsPanel
      showNotificationsPanel={showNotificationsPanel}
      onDismiss={onToggleNotifications}
    />
  );

  const releaseNotesPanel = (
    <ReleaseNotesPanel
      showReleaseNotesPanel={showReleaseNotesPanel}
      onDismiss={onToggleReleaseNotes}
    />
  );

  return (
    <Router history={history}>
      <PageWrapper>
        {header}
        <ContentWrapperStyled>
          {sidebar}
          {navigationPanel}
          <Suspense fallback={<div />}>
            <Switch>
              <Route
                path={[
                  '/projects/:filterType/gantt/:projectId',
                  '/projects/:filterType/gantt/:projectId/:tab',
                  '/projects/:filterType/gantt/:projectId/:taskId?/:tab'
                ]}
                exact
                render={(props) => (
                  <ProjectGantt {...props} toggleNavigationPanel={toggleNavigationPanel} />
                )}
              />
              <Route
                path={[
                  '/',
                  '/tasks/:filterType/:taskId?/:tab?',
                  '/teams/:teamId?/:taskId?/:tab?',
                  '/projects/:projectFilterType/task-list/:projectId/',
                  '/projects/:projectFilterType/task-list/:projectId/:sprintId',
                  // Project-Overview
                  '/projects/:projectFilterType/task-list/:projectId/:sprintId/:tab',
                  // Sprint task or Product backlog task
                  '/projects/:projectFilterType/task-list/:projectId/:sprintId/:taskId?/:tab?'
                ]}
                exact
                render={(props) => (
                  <TasksContainer {...props} toggleNavigationPanel={toggleNavigationPanel} />
                )}
              />
              <Route
                path={[
                  '/projects/:filterType',
                  '/projects/:filterType/project-details/:projectId/:tab?'
                ]}
                exact
                render={(props) => (
                  <Projects {...props} toggleNavigationPanel={toggleNavigationPanel} />
                )}
              />
              <Route
                path="/process-instances/:filterType/:templateId?/process-instance-tree/:instanceId/:mode?/:taskId?/:tab?"
                exact
                render={() => <ProcessInstanceTree toggleNavigationPanel={toggleNavigationPanel} />}
              />
              <Route
                path="/process-instances/:filterType/:templateId?/:panelMode?/:instanceId?/:panelTab?"
                exact
                render={() => <ProcessInstances toggleNavigationPanel={toggleNavigationPanel} />}
              />
              <Route
                path="/process-templates/:viewType/:selectedGroupId?/:templateType?/:templateId?"
                exact
                render={(props) => (
                  <ProcessTemplates {...props} toggleNavigationPanel={toggleNavigationPanel} />
                )}
              />
              <Route
                path="/process-designer/:templateId/:version/:stepId?"
                render={() => <ProcessDesigner toggleNavigationPanel={toggleNavigationPanel} />}
              />
              <Route
                path="/activities"
                exact
                render={(props) => (
                  <Activities {...props} toggleNavigationPanel={toggleNavigationPanel} />
                )}
              />
              <Route
                path="/tags/:tagId"
                exact
                render={(props) => (
                  <Tags {...props} toggleNavigationPanel={toggleNavigationPanel} />
                )}
              />
              <Route
                path="/administration/:group?/:type?/:id?/:mode?/:tab?/"
                exact
                render={(props) => (
                  <Administration {...props} toggleNavigationPanel={toggleNavigationPanel} />
                )}
              />
              <Route component={NotFound} />
            </Switch>
            {showNotificationsPanel ? notificationsPanel : null}
            {showReleaseNotesPanel ? releaseNotesPanel : null}
          </Suspense>
        </ContentWrapperStyled>
      </PageWrapper>
    </Router>
  );
};

Routing.propTypes = {
  logout: PropTypes.func.isRequired,
  contentToShow: PropTypes.shape({
    tasks: PropTypes.bool,
    processes: PropTypes.bool
  }).isRequired
};

export default Routing;
