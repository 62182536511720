var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@fluentui/react';
import { Link } from '@fluentui/react/lib/Link';
import { concatStyleSets } from '@fluentui/react/lib/Styling';
import Label from '../Label/Label';
function HyperLinkField(_a) {
    var defaultValue = _a.defaultValue, description = _a.description, disabled = _a.disabled, label = _a.label, labelIconName = _a.labelIconName, onChange = _a.onChange, required = _a.required, styles = _a.styles;
    var t = useTranslation().t;
    var _b = useState(function () { return defaultValue || { text: undefined, url: undefined }; }), link = _b[0], setLink = _b[1];
    var urlTextFieldRef = useRef(null);
    function onTextFieldKeyPress(event) {
        var enterPressed = event && event.key === 'Enter';
        if (enterPressed && (urlTextFieldRef === null || urlTextFieldRef === void 0 ? void 0 : urlTextFieldRef.current)) {
            urlTextFieldRef.current.select();
        }
    }
    function onTextFieldChange(textFieldType, value) {
        setLink(function (prevLink) {
            var _a;
            var newLink = __assign(__assign({}, prevLink), (_a = {}, _a[textFieldType] = value, _a));
            if (onChange) {
                onChange(newLink);
            }
            return newLink;
        });
    }
    function getStyles() {
        var completeStyles = {
            linkStyles: undefined,
            textFieldStyles: {
                fieldGroup: { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
            },
            urlFieldStyles: {
                root: { marginTop: '10px' },
                fieldGroup: { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
            }
        };
        if (styles === null || styles === void 0 ? void 0 : styles.textFieldStyles) {
            completeStyles.textFieldStyles = concatStyleSets(completeStyles.textFieldStyles, styles.textFieldStyles);
        }
        if (styles === null || styles === void 0 ? void 0 : styles.urlFieldStyles) {
            completeStyles.urlFieldStyles = concatStyleSets(completeStyles.urlFieldStyles, styles.urlFieldStyles);
        }
        if (styles === null || styles === void 0 ? void 0 : styles.linkStyles) {
            completeStyles.linkStyles = concatStyleSets(completeStyles.linkStyles, styles.linkStyles);
        }
        return completeStyles;
    }
    var _c = getStyles(), textFieldStyles = _c.textFieldStyles, urlFieldStyles = _c.urlFieldStyles, linkStyles = _c.linkStyles;
    if (disabled) {
        if (!link.url) {
            return (React.createElement("div", null,
                React.createElement(Label, { label: label, required: required, iconName: labelIconName, description: description }),
                React.createElement(TextField, { disabled: true, placeholder: t('createLink.placeholder.emptyValue'), styles: {
                        wrapper: {
                            border: '1px solid #a19f9d',
                            borderRadius: '3px'
                        }
                    } })));
        }
        var linkTextWithProtocol = link.url;
        var hasProtocol = new RegExp('https?://');
        if (!hasProtocol.test(link.url)) {
            linkTextWithProtocol = "http://" + link.url;
        }
        var linkStyle = {
            padding: 7,
            border: '1px solid #a19f9d',
            borderRadius: 3,
            backgroundColor: '#f3f2f1',
            width: '100%',
            color: '#a19f9d'
        };
        if (styles === null || styles === void 0 ? void 0 : styles.linkWrapperStyles) {
            linkStyle = __assign(__assign({}, linkStyle), styles.linkWrapperStyles);
        }
        return (React.createElement("div", null,
            React.createElement(Label, { label: label, required: required, iconName: labelIconName, description: description }),
            React.createElement("div", { style: linkStyle },
                React.createElement(Link, { styles: linkStyles, target: "_blank", href: linkTextWithProtocol }, link.text))));
    }
    return (React.createElement("div", { style: styles === null || styles === void 0 ? void 0 : styles.rootStyles },
        React.createElement(Label, { label: label, required: required, iconName: labelIconName, description: description }),
        React.createElement(TextField, { onChange: function (_, value) { return onTextFieldChange('text', value); }, onKeyPress: function (ev) { return onTextFieldKeyPress(ev); }, placeholder: t('createLink.placeholder.text'), styles: textFieldStyles, value: link.text }),
        React.createElement(TextField, { componentRef: urlTextFieldRef, onChange: function (_, value) { return onTextFieldChange('url', value); }, placeholder: t('createLink.placeholder.url'), styles: urlFieldStyles, value: link.url })));
}
export default HyperLinkField;
