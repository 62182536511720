import i18next from 'i18next';
import { acquireAccessToken } from '../modules/AuthenticationMSAL/msal';
import config from '../utils/config';

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function apiErrorHandler(error) {
  if (!error) {
    return false;
  }

  if (error.message === 'reload') {
    localStorage.clear();
    window.location.reload();
    return false;
  }

  if (error.message === 'reloadOnly') {
    window.location.reload();
    return false;
  }

  if (window.location.pathname.indexOf('access') < 1 && error.message === 'access') {
    window.location = '/access';
    return false;
  }

  return false;
}

function transformTextToObjectForErrorMessage(text) {
  let textMessage = JSON.parse(text);

  // check if the api returned an object instead of a string
  // convert string to object and check if message is defined
  if ({}.hasOwnProperty.call(textMessage, 'Message')) {
    textMessage = textMessage.Message;
  } else {
    textMessage = 'Undefined error';
  }

  return textMessage;
}

function status(fetchRequestParameters, response, ignoreAlert, ignoreConsent) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  }

  // 403 extra consent required
  if (response.status === 403 && !ignoreConsent) {
    const result = response.text().then((text) => {
      // open ms url from response to retrieve extra consent
      if (!isInIframe() && text.includes('login.microsoftonline.com')) {
        window.open(text, '_self');
      }
    });

    return Promise.reject(result);
  }

  if (response.status === 400 || response.status === 401 || response.status === 403) {
    if (fetchRequestParameters.url === 'Init') {
      return Promise.reject(new Error('access'));
    }

    let textMessage = '';
    const result = response.text().then((text) => {
      textMessage = text;

      if (text.indexOf('"Message"') !== -1) {
        textMessage = transformTextToObjectForErrorMessage(text);
      }
      if (!ignoreAlert) {
        alert(textMessage);
      }
      const errMessage = JSON.stringify({
        status: response.status,
        message: textMessage.replace(/^"|"$/g, '')
      });
      return errMessage;
    });

    return Promise.reject(result);
  }

  if (response.status === 425) {
    // retry fetch 2x when status is 425
    const { retries } = fetchRequestParameters;
    const defaultRetries = 3;
    const retriesCount = retries ? retries - 1 : defaultRetries;

    if (retriesCount > 0) {
      setTimeout(() => {
        return fetchRequest({
          ...fetchRequestParameters,
          retries: retriesCount
        });
      }, 5000);
    }

    return Promise.reject(new Error('reloadOnly'));
  }

  if (response.status === 404) {
    alert(i18next.t('api.error.404'));
    return Promise.reject();
  }

  if (response.status >= 500 && response.status <= 600) {
    const result = response.text().then((text) => {
      if (!ignoreAlert) {
        alert(text);
      }
      const errMessage = JSON.stringify({
        status: response.status,
        message: text.replace(/^"|\\r\\n|\\|"$/g, '')
      });
      return errMessage;
    });
    return Promise.reject(result);
  }

  return Promise.reject(new Error('reload'));
}

function dataTransform(response, ignoreContentType) {
  if (response.status === 204 || ignoreContentType) {
    return response;
  }

  return response.json();
}

async function addAuthorizationHeaders(currentHeaders) {
  let token;

  try {
    token = await acquireAccessToken();
  } catch (error) {
    return currentHeaders;
  }

  currentHeaders.append('Authorization', `Bearer ${token.accessToken}`);

  // set tenant id if available
  const tenantId = window.localStorage.getItem('tenantId');
  if (tenantId) {
    currentHeaders.append('x-ep-tenant', tenantId);
  }

  return currentHeaders;
}

async function fetchRequest({
  url,
  method,
  body,
  ignoreContentType,
  signal,
  ignoreAlert,
  ignoreConsent,
  retries,
  anonymousCall
}) {
  // set default headers for every request
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const acceptLanguageHeader = localStorage.getItem('acceptLanguageHeader');

  if (acceptLanguageHeader) {
    // set language header for ms-teams
    headers.set('accept-language', acceptLanguageHeader);
  }

  if (!anonymousCall) {
    // add authorization headers
    headers = await addAuthorizationHeaders(headers);
  }

  // prevent old data from cached calls in IE11
  headers.append('Pragma', 'no-cache');

  // auto set headers if none is given
  if (ignoreContentType) {
    headers.delete('Content-Type');
  }

  const fetchRequestParameters = {
    url,
    method,
    body,
    ignoreContentType,
    signal,
    ignoreAlert,
    ignoreConsent,
    retries
  };

  const completeUrl = `${config.API_URL}/${url}`;

  // execute fetch
  return fetch(completeUrl, {
    method: method || 'GET',
    credentials: 'same-origin',
    signal,
    headers,
    body
  })
    .then((response) => status(fetchRequestParameters, response, ignoreAlert, ignoreConsent))
    .then((response) => dataTransform(response, ignoreContentType));
}

export default fetchRequest;
