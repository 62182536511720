import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

export const HeaderStyled = styled.div`
  background-color: ${(props) => props.theme.themePrimary};
  display: flex;
  align-items: center;
  max-height: 48px;
  height: 48px;
  width: 100%;
  .header-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    font-weight: bold;
    background: white;
    color: ${(props) => props.theme.themePrimary};
    border-radius: 50%;
    padding: 0 5px;
    border: 1px solid ${(props) => props.theme.themePrimary};
    text-align: center;
  }

  .header-badge-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 11px;
    color: white;
    font-weight: bold;
  }

  .c-header__title {
    color: #fff;
    font-size: 26px;
    font-weight: 100;
    padding-left: 0.75rem;
    text-decoration: none;
    padding-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }

  @media (max-width: ${breakpoints.mediumMax}px) {
    .c-header__title {
      font-size: 19px;
      padding-top: 4px;
    }
  }

  @media (max-width: ${breakpoints.mediumMin}px) {
    .c-header__title {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.mediumMin}px) {
    .c-header__searchBox {
      display: none;
    }
  }

  .global-navigation-button {
    padding: 0px 5px 0px 15px;
    color: #fff;
    font-size: 19px;
  }

  .global-navigation-button:hover {
    cursor: pointer;
  }

  .global-navigation-button {
    @media (min-width: ${breakpoints.largeMin}px) {
      display: none;
    }
  }
`;

export const LogoWrapper = styled.div`
  padding: 2px 3px 0 12px;
  cursor: pointer;

  @media (max-width: ${breakpoints.largeMin}px) {
    display: none;
  }
`;
