var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { PersonSuggestion, SuggestionsWrapper, TagSuggestion } from './RichTextEditor.styles';
function RichTextEditorSuggestions(_a) {
    var commitSelection = _a.commitSelection, suggestionsState = _a.suggestionsState, selectedSuggestionIndex = _a.selectedSuggestionIndex;
    var t = useTranslation().t;
    var suggestionsRef = useRef(null);
    var _b = useState({ width: 0, height: 0 }), suggestionsDimensions = _b[0], setSuggestionsDimensions = _b[1];
    var _c = useState(null), disabledSuggestion = _c[0], setDisabledSuggestion = _c[1];
    var text = suggestionsState.text, suggestions = suggestionsState.suggestions;
    var isTagSuggestionArray = useCallback(function (suggestionsArray) {
        return suggestionsArray[0].userId === undefined;
    }, []);
    useEffect(function () {
        var foundDisabledSuggestion;
        if (isTagSuggestionArray(suggestions)) {
            foundDisabledSuggestion = __spreadArray([], suggestions).find(function (suggestion) {
                return suggestion.disabled && suggestion.text.trim().toLowerCase() === text.trim().toLowerCase();
            });
        }
        setDisabledSuggestion(foundDisabledSuggestion || null);
    }, [isTagSuggestionArray, suggestions, text]);
    useLayoutEffect(function () {
        var _a, _b;
        if (suggestionsRef.current) {
            setSuggestionsDimensions({
                width: ((_a = suggestionsRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0,
                height: ((_b = suggestionsRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) || 0
            });
        }
    }, [suggestions.length, disabledSuggestion]);
    function isTagSuggestion(suggestion) {
        return suggestion.userId === undefined;
    }
    function normalizeIndex(selectedIndex, max) {
        var index = selectedIndex % max;
        if (index < 0) {
            index += max;
        }
        return index;
    }
    function getSuggestionTitle() {
        if (suggestions && suggestions.length) {
            if (!isTagSuggestionArray(suggestions)) {
                return null;
            }
            return React.createElement("div", { className: "suggestion-title" }, t('rte.suggestions.selectOrCreate'));
        }
        return React.createElement("div", { className: "suggestion-title" }, t('rte.suggestions.noResult'));
    }
    function isSuggestionDisabled(suggestion) {
        if (isTagSuggestion(suggestion)) {
            return suggestion.disabled;
        }
        return false;
    }
    var normalizedIndex = normalizeIndex(selectedSuggestionIndex, suggestions.length);
    var left = suggestionsState.left, top = suggestionsState.top;
    var innerHeight = window.innerHeight, innerWidth = window.innerWidth;
    var rowHeight = 25;
    if (top + suggestionsDimensions.height > innerHeight) {
        top = top - rowHeight - suggestionsDimensions.height;
    }
    var paddingRight = 5;
    if (left + suggestionsDimensions.width > innerWidth) {
        left = innerWidth - suggestionsDimensions.width - paddingRight;
    }
    var content = (React.createElement(SuggestionsWrapper, { ref: suggestionsRef, left: left, top: top },
        getSuggestionTitle(),
        __spreadArray([], suggestions).filter(function (suggestion) { return !isSuggestionDisabled(suggestion); })
            .map(function (suggestion, index) {
            var isSelected = index === normalizedIndex;
            if (!isTagSuggestion(suggestion)) {
                return (React.createElement(PersonSuggestion, { key: suggestion.userId, onClick: function (event) { return commitSelection({ event: event, selectedIndex: index }); }, isSelected: isSelected },
                    React.createElement(Persona, { text: suggestion.name || undefined, size: PersonaSize.size32, imageUrl: suggestion.pictureUrl || undefined, secondaryText: suggestion.login, showSecondaryText: true })));
            }
            var SuggestionText = function () {
                if (suggestion.id || suggestion.newTag) {
                    return "#" + suggestion.fullText;
                }
                return t('rte.suggestions.createTag') + " #" + suggestion.text;
            };
            return (React.createElement(TagSuggestion, { key: suggestion.text, onClick: function (event) {
                    event.preventDefault();
                    commitSelection({ event: event, selectedIndex: index });
                } }, SuggestionText()));
        }),
        disabledSuggestion && (React.createElement("div", { style: {
                textAlign: 'center',
                color: 'rgb(96, 94, 92)',
                fontSize: '12px',
                lineHeight: '25px',
                fontFamily: 'Segoe UI'
            } },
            text,
            " ist deaktiviert."))));
    var element = document.body;
    if (element) {
        return React.createElement(React.Fragment, null, ReactDOM.createPortal(content, element));
    }
    return null;
}
export default RichTextEditorSuggestions;
