import PropTypes from 'prop-types';
import React from 'react';
import { getTheme } from '@fluentui/react';

export default function ProcessLandscapeGraph({ processes, onGroupClick }) {
  if (!processes || !processes.length) {
    return null;
  }

  const maxWidth = window.innerWidth;
  let fontSize = 15.4;
  if (maxWidth < 1000) {
    fontSize = 14;
  }
  if (maxWidth < 800) {
    fontSize = 11.2;
  }
  if (maxWidth < 450) {
    fontSize = 8.4;
  }
  if (maxWidth < 200) {
    fontSize = 5.6;
  }
  if (maxWidth < 150) {
    fontSize = 4.2;
  }

  let prevColumn = 0;
  let prevSubColumn = 0;
  let prevSubRow = 0;
  let starMainColumnX = 0;
  let startMainColumnY = 0;
  let prevProcessWidth = 0;
  let prevCoreProcesStartY = 0;

  // New
  let iconBackgroundColor = getTheme().palette.themePrimary;
  const alternativeColor = '#A9A9A9';
  let status = 'enabled';

  const categoryTitleHeight = 50;
  let bigHDistancer = maxWidth / (maxWidth / 10);
  const coreBigHDistancer = bigHDistancer;

  let coreSmallHDistancer = -10;
  let smallVDistancer = 2;
  const bigVDistancer = maxWidth / (maxWidth / 10);

  // Management processes
  const managementProcesses = processes.filter((element) => {
    return element.category && element.category.sortOrder === 1;
  });
  const numberOfManProcesses = managementProcesses.length;
  const manCategoryTitle =
    managementProcesses && managementProcesses.length > 0
      ? managementProcesses[0].category.name
      : null;
  let mainManProcessWidth = maxWidth * 0.156;
  let mainManProcessHeight = mainManProcessWidth * 0.33;

  // Core processes
  const coreProcesses = processes.filter((element) => {
    return element.category && element.category.sortOrder === 2;
  });
  coreProcesses.sort((a, b) => {
    const aProcGroupingArray = a.processGrouping.split('.');
    const bProcGroupingArray = b.processGrouping.split('.');
    let sizeRelation = 0;
    for (let i = 0; i < aProcGroupingArray.length; i += 1) {
      if (aProcGroupingArray[i] < bProcGroupingArray[i]) {
        sizeRelation = -1;
        break;
      }
      if (aProcGroupingArray[i] > bProcGroupingArray[i]) {
        sizeRelation = 1;
        break;
      }
    }
    return sizeRelation;
  });

  const coreCategoryTitle =
    coreProcesses && coreProcesses.length > 0 ? coreProcesses[0].category.name : null;

  const numberOfMainCoreProcesses = getNumberOfMainProcesses(coreProcesses);
  let hasOnlyOneMainCoreGroup = true;
  if (numberOfMainCoreProcesses !== undefined && numberOfMainCoreProcesses > 1) {
    hasOnlyOneMainCoreGroup = false;
  }

  let mainCoreProcessWidth = maxWidth * 0.156;
  let mainCoreProcessHeight = mainCoreProcessWidth * 0.4;

  let newCoreProcessStartX = 0;
  let newCoreProcessStartY = 0;

  // Support processes
  const supportProcesses = processes.filter((element) => {
    return element.category && element.category.sortOrder === 3;
  });
  const numberOfSuppProcesses = supportProcesses.length;
  const suppCategoryTitle =
    supportProcesses && supportProcesses.length > 0 ? supportProcesses[0].category.name : null;
  let mainSuppProcessWidth = maxWidth * 0.063;
  const mainSuppProcessHeight = 160;

  let newSuppProcessStartX = 0;
  let newSuppProcessStartY = 0;

  const maxCategoryWidth = getMaxCategoryWidth(
    coreProcesses,
    numberOfMainCoreProcesses,
    numberOfSuppProcesses
  );

  if (
    maxCategoryWidth < 650 ||
    maxCategoryWidth <
      (numberOfManProcesses - 1) * bigHDistancer + numberOfManProcesses * mainManProcessWidth
  ) {
    if (numberOfManProcesses > 1) {
      mainManProcessWidth =
        (maxWidth - (numberOfManProcesses - 1) * bigHDistancer) / numberOfManProcesses;
    } else if (maxWidth < 350) {
      mainManProcessWidth = maxWidth;
    } else {
      mainManProcessWidth = maxWidth / 2;
    }
    if (maxWidth < 650) {
      mainManProcessHeight = 80;
      if (maxWidth < 350) {
        mainManProcessHeight = 50;
      }
    }
  }

  mainSuppProcessWidth =
    (maxCategoryWidth - (numberOfSuppProcesses - 1) * bigHDistancer) / numberOfSuppProcesses;

  function getNumberOfMainProcesses(processes) {
    const mainColumnsNumbers = [];
    processes.map((p) => {
      const mainColumNumber = parseInt(p.processGrouping.split('.')[0], 10);
      if (mainColumnsNumbers) {
        const exists = mainColumnsNumbers.find((element) => {
          return element === mainColumNumber;
        });
        if (exists === undefined) {
          mainColumnsNumbers.push(mainColumNumber);
        }
      }
      return mainColumnsNumbers;
    });
    return mainColumnsNumbers.length;
  }

  function getMaxCategoryWidth(coreProcesses, numberOfMainCoreProcesses, numberOfSuppProcesses) {
    let numberOfSubrows = 0;
    let calcFactor = 18;
    if (maxWidth < 650) {
      calcFactor = 10;
      if (maxWidth < 350) {
        calcFactor = 6;
      }
    }

    if (numberOfMainCoreProcesses === 1) {
      coreSmallHDistancer = -12;
      numberOfSubrows = getNumberOfRowsInColumn(coreProcesses, coreProcesses[0]);
      if (maxWidth < 500) {
        bigHDistancer = smallVDistancer;
      }
      mainCoreProcessWidth = getOnlyProcessGroupWidth(
        coreProcesses,
        numberOfSubrows,
        mainCoreProcessWidth,
        maxWidth,
        bigHDistancer
      );
      if (mainCoreProcessWidth > 1200) {
        coreSmallHDistancer = -17;
      }

      mainCoreProcessHeight =
        (mainCoreProcessWidth / calcFactor) * numberOfSubrows +
        (numberOfSubrows - 1) * bigVDistancer;
      smallVDistancer = bigVDistancer;
    }

    if (mainCoreProcessWidth < 400) {
      coreSmallHDistancer = -7;
    }

    let coreCatWidth =
      numberOfMainCoreProcesses * mainCoreProcessWidth +
      (numberOfMainCoreProcesses - 1) * bigHDistancer;
    let supportCatWidth =
      numberOfSuppProcesses * mainSuppProcessWidth + (numberOfSuppProcesses - 1 * bigHDistancer);

    if (maxWidth >= coreCatWidth && maxWidth >= supportCatWidth) {
      if (coreCatWidth > supportCatWidth) {
        return coreCatWidth;
      }
      mainCoreProcessWidth =
        (supportCatWidth - (numberOfMainCoreProcesses - 1) * bigHDistancer) /
        numberOfMainCoreProcesses;
      if (numberOfSubrows > 0) {
        mainCoreProcessHeight =
          (mainCoreProcessWidth / calcFactor) * numberOfSubrows +
          (numberOfSubrows - 1) * bigVDistancer;
      } else {
        mainCoreProcessHeight *= 1.5;
      }

      return supportCatWidth;
    }
    mainCoreProcessWidth =
      (maxWidth - (numberOfMainCoreProcesses - 1) * bigHDistancer) / numberOfMainCoreProcesses;
    coreCatWidth =
      mainCoreProcessWidth * numberOfMainCoreProcesses +
      (numberOfMainCoreProcesses - 1) * bigHDistancer;
    mainSuppProcessWidth =
      (maxWidth - (numberOfSuppProcesses - 1) * bigHDistancer) / numberOfSuppProcesses;
    supportCatWidth =
      mainSuppProcessWidth * numberOfSuppProcesses + (numberOfSuppProcesses - 1) * bigHDistancer;

    if (coreCatWidth > supportCatWidth) {
      return coreCatWidth;
    }
    return supportCatWidth;
  }

  function getOnlyProcessGroupWidth(
    processes,
    numberOfSubrows,
    processWidth,
    maxWindowWidth,
    bigHorizonalGap
  ) {
    let newProcessWidth = processWidth;
    let largestSubColumnsNumber = 0;
    if (numberOfSubrows > 1) {
      for (let i = 1; i <= numberOfSubrows; i += 1) {
        const process = processes.filter((p) => p.processGrouping.includes(`1.${i}.1`))[0];
        const numberSubColumns = getNumberOfSubColumnsInRow(processes, process);
        if (largestSubColumnsNumber < numberSubColumns) {
          largestSubColumnsNumber = numberSubColumns;
        }
      }
    }
    if (largestSubColumnsNumber > 1) {
      newProcessWidth = maxWindowWidth - bigHorizonalGap * (largestSubColumnsNumber - 1);
    }
    if (maxWindowWidth < 900) {
      return newProcessWidth - largestSubColumnsNumber;
    }
    if (maxWindowWidth < 1500) {
      return newProcessWidth - 100 * largestSubColumnsNumber;
    }
    return newProcessWidth - 200 * largestSubColumnsNumber;
  }

  const coreCategoryWidth = maxCategoryWidth;

  const centerX = maxCategoryWidth / 2;
  const maxLandscapeHeight =
    categoryTitleHeight +
    bigVDistancer +
    mainManProcessHeight +
    4 * bigVDistancer +
    categoryTitleHeight +
    bigVDistancer +
    mainCoreProcessHeight +
    4 * bigVDistancer +
    categoryTitleHeight +
    bigVDistancer +
    mainSuppProcessHeight;

  return (
    <svg
      width={maxCategoryWidth}
      height={maxLandscapeHeight}
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMinYMin meet"
      viewBox={`0 0 ${maxCategoryWidth} ${maxLandscapeHeight}`}
    >
      <g fill="none">
        <text>
          <tspan
            x={centerX}
            y={categoryTitleHeight / 2}
            fill={getTheme().palette.black}
            textAnchor="middle"
            className="categoryTitle"
          >
            {manCategoryTitle}
          </tspan>
        </text>
      </g>
      {managementProcesses.map((manProcess, index) => {
        const manShadowSizeCorrector = 5;
        iconBackgroundColor = getTheme().palette.themePrimary;
        status = 'enabled';
        if (!manProcess.numberOfDefinitions || manProcess.numberOfDefinitions < 1) {
          iconBackgroundColor = alternativeColor;
          status = 'disabled';
        }

        let manProcessWidth = mainManProcessWidth;
        const manProcessHeight = mainManProcessHeight;
        if (
          manProcessWidth * numberOfManProcesses + bigHDistancer * (numberOfManProcesses - 1) >
          maxCategoryWidth
        ) {
          manProcessWidth =
            (maxCategoryWidth - bigHDistancer * (numberOfManProcesses - 1)) / numberOfManProcesses;
        }

        // Calculation for X, Y coordinates for the start of the next process
        const calcFactor = numberOfManProcesses / 2;
        let newManProcessStartX =
          centerX -
          bigHDistancer * Math.floor(calcFactor - index) -
          manProcessWidth * (calcFactor - index);

        if (numberOfManProcesses === 1) {
          newManProcessStartX = centerX - manProcessWidth / 2;
        }
        const newManProcessStartY = 0 + categoryTitleHeight + bigVDistancer;

        const textWidth = manProcessWidth - manProcessWidth / 5;
        const textHeight = manProcessHeight - manProcessHeight / 5;

        const rowDistance = fontSize * 1.3;
        const textX = manProcessWidth / 2;
        const textY = (manProcessHeight - manProcessHeight / 6) / 2;

        let formatedName = manProcess.name;
        const stringWidth = getWidthOfText(manProcess.name, 'Segoe UI', `${fontSize}px`);
        if (stringWidth > textWidth) {
          formatedName = formatStringHorizontal(
            manProcess.name,
            textWidth,
            textHeight,
            textX,
            textY,
            rowDistance,
            fontSize
          );
        }
        return (
          <svg
            key={manProcess.id}
            x={newManProcessStartX}
            y={newManProcessStartY}
            width={manProcessWidth}
            height={manProcessHeight + manShadowSizeCorrector}
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMinYMin meet"
            viewBox={`0 0 ${manProcessWidth + manShadowSizeCorrector} ${
              manProcessHeight + manShadowSizeCorrector
            }`}
            onClick={() => {
              if (manProcess.numberOfDefinitions > 0) {
                onGroupClick(manProcess.id);
              }
            }}
          >
            <defs>
              <filter id="shadow1" width="130%" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
                {/* stdDeviation is how much to blur */}
                <feOffset dx="1.9" dy="1.9" result="offsetblur" /> {/* how much to offset */}
                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.8" /> {/* slope is the opacity of the shadow */}
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode /> {/* this contains the offset blurred image */}
                  <feMergeNode in="SourceGraphic" />
                  {/* this contains the element that the filter is applied to */}
                </feMerge>
              </filter>
            </defs>
            <g className={status} fill="none">
              <path
                d={`M0 0
                  h${manProcessWidth}
                  v${manProcessHeight / 2}
                  l-${manProcessWidth / 2} ${manProcessHeight / 2}
                  l-${manProcessWidth / 2} -${manProcessHeight / 2}
                  v-${manProcessHeight / 2}`}
                fill={iconBackgroundColor}
              />
              <text>
                <tspan
                  fill={getTheme().palette.white}
                  textAnchor="middle"
                  x={textX}
                  y={textY}
                  width={textWidth}
                  height={manProcessHeight}
                  fontSize={fontSize}
                  className="processTypeName"
                >
                  {formatedName}
                </tspan>
                <tspan
                  fill={getTheme().palette.white}
                  textAnchor="middle"
                  x={textX}
                  y={manProcessHeight - manProcessHeight / 6}
                  width={textWidth}
                  height={manProcessHeight}
                  className="processCounter"
                >
                  {manProcess.numberOfDefinitions.toString()}
                </tspan>
              </text>
            </g>
          </svg>
        );
      })}
      <g fill="none">
        <text>
          <tspan
            x={centerX}
            y={
              categoryTitleHeight +
              bigVDistancer +
              mainManProcessHeight +
              4 * bigVDistancer +
              categoryTitleHeight / 2
            }
            fill={getTheme().palette.black}
            textAnchor="middle"
            className="categoryTitle"
          >
            {coreCategoryTitle}
          </tspan>
        </text>
      </g>
      {coreProcesses.map((coreProcess, index) => {
        const coreShadowSizeCorrector = 5;
        const sortIndexes = coreProcess.processGrouping.split('.');
        const currentMainColumn = parseInt(sortIndexes[0], 10);
        const currentSubRow = parseInt(sortIndexes[1], 10);
        const currentSubColumn = sortIndexes.length > 2 ? parseInt(sortIndexes[2], 10) : 0;
        const subColumnsInRow = getNumberOfSubColumnsInRow(coreProcesses, coreProcess);
        const rowsInColumn = getNumberOfRowsInColumn(coreProcesses, coreProcess);
        let coreProcessWidth = mainCoreProcessWidth;
        let coreProcessHeight = mainCoreProcessHeight;

        iconBackgroundColor = getTheme().palette.themePrimary;
        status = 'enabled';
        if (!coreProcess.numberOfDefinitions || coreProcess.numberOfDefinitions < 1) {
          iconBackgroundColor = alternativeColor;
          status = 'disabled';
        }

        if (rowsInColumn > 1) {
          if (subColumnsInRow > 1) {
            coreProcessWidth =
              (mainCoreProcessWidth - coreSmallHDistancer * (subColumnsInRow - 1)) /
              subColumnsInRow;
          }
          coreProcessHeight =
            (mainCoreProcessHeight - smallVDistancer * (rowsInColumn - 1)) / rowsInColumn;
        }
        startMainColumnY =
          categoryTitleHeight +
          bigVDistancer +
          mainManProcessHeight +
          4 * bigVDistancer +
          categoryTitleHeight +
          bigVDistancer;

        if (prevColumn !== currentMainColumn) {
          if (index === 0) {
            // Calculate X position for the first item in first main group
            starMainColumnX = centerX - coreCategoryWidth / 2;
            newCoreProcessStartX = starMainColumnX;
          } else {
            // Calculate X position for the first item in next main group
            starMainColumnX = newCoreProcessStartX;
            newCoreProcessStartX = starMainColumnX + prevProcessWidth + coreBigHDistancer;
            starMainColumnX = newCoreProcessStartX;
          }
          if (currentSubColumn === 0) {
            // Calculate X position for one main item (without sub items)
            starMainColumnX = newCoreProcessStartX;
          }
          newCoreProcessStartY = startMainColumnY;
          prevColumn = currentMainColumn;
          prevSubColumn = 0;
          prevSubRow = 0;
        } else if (currentSubColumn > 1 && currentSubColumn > prevSubColumn) {
          // Calculate X position for new sub item - for new sub column
          newCoreProcessStartX = newCoreProcessStartX + coreProcessWidth + coreSmallHDistancer;
          // Calculate Y position for new sub item - for new sub column
          newCoreProcessStartY = prevCoreProcesStartY;
          prevSubColumn = currentSubColumn;
        } else if (currentSubRow > 1 && currentSubRow !== prevSubRow) {
          // Calculate Y position for new sub item - for new sub row
          newCoreProcessStartY = prevCoreProcesStartY + smallVDistancer + coreProcessHeight;
          // Calculate X position for new sub item - for new sub row
          newCoreProcessStartX = starMainColumnX;
          prevSubRow = currentSubRow;
          prevSubColumn = 0;
        }

        prevProcessWidth = coreProcessWidth;
        prevCoreProcesStartY = newCoreProcessStartY;

        // Calculation for arrow front and back end based on arrow size
        let arrowHeadHorizontal = mainCoreProcessWidth / 10;
        if (rowsInColumn > 1) {
          arrowHeadHorizontal = mainCoreProcessWidth / 30;
          if (rowsInColumn > 2) {
            arrowHeadHorizontal = mainCoreProcessWidth / 60;
          }
        }

        let textX = coreProcessWidth / 1.9;
        let textY = coreProcessHeight / 1.9;
        const textWidth = coreProcessWidth - coreProcessWidth / 5;
        const textHeight = coreProcessHeight - coreProcessHeight / 5;
        let smallTextXDistancer = mainCoreProcessHeight / 15;
        let smallTextYDistancer = mainCoreProcessHeight / 15;
        let rowDistance = fontSize * 1.2;
        if (hasOnlyOneMainCoreGroup) {
          textX = coreProcessWidth / 2;
          textY = coreProcessHeight / 1.8;
          smallTextXDistancer = mainCoreProcessHeight / 14;
          smallTextYDistancer = mainCoreProcessHeight / 15;
          if (coreProcessWidth > 630) {
            smallTextXDistancer = mainCoreProcessHeight / 11;
            smallTextYDistancer = mainCoreProcessHeight / 16;
          }
          if (coreProcessWidth < 400) {
            smallTextXDistancer = mainCoreProcessHeight / 12;
            smallTextYDistancer = mainCoreProcessHeight / 13;
            if (coreProcessWidth < 300) {
              smallTextXDistancer = mainCoreProcessHeight / 13;
              smallTextYDistancer = mainCoreProcessHeight / 13;
              rowDistance = fontSize / 1.4;
              if (coreProcessWidth < 200) {
                if (coreProcessWidth < 150) {
                  smallTextXDistancer = mainCoreProcessHeight / 22;
                  smallTextYDistancer = mainCoreProcessHeight / 18;
                  rowDistance = fontSize * 1.3;
                  if (coreProcessWidth < 100) {
                    rowDistance = fontSize * 1.1;
                  }
                }
              }
            }
          }
        } else {
          textX = coreProcessWidth / 2;
          textY = coreProcessHeight / 1.8;
          smallTextXDistancer = mainCoreProcessHeight / 12;
          smallTextYDistancer = mainCoreProcessHeight / 12;
          if (coreProcessWidth < 400) {
            smallTextXDistancer = mainCoreProcessHeight / 15;
            smallTextYDistancer = mainCoreProcessHeight / 12;
            if (coreProcessWidth < 300) {
              smallTextXDistancer = mainCoreProcessHeight / 15;
              smallTextYDistancer = mainCoreProcessHeight / 11;
              rowDistance = fontSize;
              if (coreProcessWidth < 250) {
                smallTextXDistancer = mainCoreProcessHeight / 25;
                smallTextYDistancer = mainCoreProcessHeight / 14;
                textY = coreProcessHeight / 1.6;
              }
            }
          }
        }

        let formatedName = coreProcess.name;
        const stringWidth = getWidthOfText(coreProcess.name, 'Segoe UI', `${fontSize}px`);
        if (stringWidth > textWidth) {
          formatedName = formatStringHorizontal(
            coreProcess.name,
            textWidth,
            textHeight,
            textX,
            textY,
            rowDistance,
            fontSize
          );
        }

        return (
          <svg
            className="core"
            key={coreProcess.id}
            x={newCoreProcessStartX}
            y={newCoreProcessStartY}
            width={coreProcessWidth}
            height={coreProcessHeight + coreShadowSizeCorrector}
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMinYMin meet"
            viewBox={`0 0 ${coreProcessWidth + coreShadowSizeCorrector} ${
              coreProcessHeight + coreShadowSizeCorrector
            }`}
            onClick={() => {
              if (coreProcess.numberOfDefinitions > 0) {
                onGroupClick(coreProcess.id);
              }
            }}
          >
            <filter id="shadow2" width="130%" height="130%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
              <feOffset dx="1.75" dy="1.75" result="offsetblur" />
              <feComponentTransfer>
                <feFuncA type="linear" slope="0.7" />
              </feComponentTransfer>
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
            <g className={status} fill="none">
              <path
                d={`M0 0
                h${coreProcessWidth - arrowHeadHorizontal}
                l${arrowHeadHorizontal} ${coreProcessHeight / 2}
                l-${arrowHeadHorizontal} ${coreProcessHeight / 2}
                h-${coreProcessWidth - arrowHeadHorizontal}
                l${arrowHeadHorizontal} -${coreProcessHeight / 2}
                l-${arrowHeadHorizontal} -${coreProcessHeight / 2}`}
                fill={iconBackgroundColor}
              />
              <text>
                <tspan
                  fill={getTheme().palette.white}
                  textAnchor="middle"
                  x={textX}
                  y={textY}
                  width={textWidth}
                  height={textHeight}
                  fontSize={fontSize}
                  className="processTypeName"
                >
                  {formatedName}
                </tspan>
                <tspan
                  fill={getTheme().palette.white}
                  textAnchor="start"
                  x={smallTextXDistancer}
                  y={smallTextYDistancer}
                  width={textWidth}
                  height={textHeight}
                  className="processCounter"
                >
                  {coreProcessWidth > 200 ? coreProcess.numberOfDefinitions.toString() : null}
                </tspan>
              </text>
            </g>
          </svg>
        );
      })}
      <g fill="none">
        <text>
          <tspan
            x={centerX}
            y={
              categoryTitleHeight +
              bigVDistancer +
              mainManProcessHeight +
              4 * bigVDistancer +
              categoryTitleHeight +
              bigVDistancer +
              mainCoreProcessHeight +
              4 * bigVDistancer +
              categoryTitleHeight / 2
            }
            fill={getTheme().palette.black}
            textAnchor="middle"
            className="categoryTitle"
          >
            {suppCategoryTitle}
          </tspan>
        </text>
      </g>
      {supportProcesses.map((suppProcess, index) => {
        const suppShadowSizeCorrector = 5;
        iconBackgroundColor = getTheme().palette.themePrimary;
        status = 'enabled';
        if (!suppProcess.numberOfDefinitions || suppProcess.numberOfDefinitions < 0) {
          iconBackgroundColor = alternativeColor;
          status = 'disabled';
        }
        const supportProcessWidth = mainSuppProcessWidth;
        const supportProcessHeight = mainSuppProcessHeight;

        // Calculation for X, Y coordinates for the start of the next process
        const calcFactor = numberOfSuppProcesses / 2;
        if (numberOfSuppProcesses === 1) {
          newSuppProcessStartX = centerX - supportProcessWidth / 2;
        } else {
          newSuppProcessStartX =
            centerX -
            bigHDistancer * Math.floor(calcFactor - index) -
            supportProcessWidth * (calcFactor - index);
        }

        newSuppProcessStartY =
          categoryTitleHeight +
          bigVDistancer +
          mainManProcessHeight +
          4 * bigVDistancer +
          categoryTitleHeight +
          bigVDistancer +
          mainCoreProcessHeight +
          4 * bigVDistancer +
          categoryTitleHeight +
          bigVDistancer;

        // Calculation for arrow top based on arrow process width
        const arrowHeadVertical = supportProcessWidth / 3;

        const textWidth = supportProcessWidth - supportProcessWidth / 5;
        const textHeight =
          supportProcessHeight - arrowHeadVertical - (supportProcessHeight - arrowHeadVertical) / 5;

        const textX = supportProcessWidth / 2;
        const textY = supportProcessHeight / 2 + arrowHeadVertical / 2;
        let textSmallY = arrowHeadVertical / 1.2;
        if (supportProcessWidth < 60) {
          textSmallY = arrowHeadVertical;
        }
        let rowDistance = fontSize * 1.2;
        if (maxWidth < 800) {
          rowDistance = fontSize * 1.15;
        }
        if (maxWidth < 450) {
          rowDistance = fontSize / 1.5;
          textSmallY = arrowHeadVertical * 1.1;
        }
        let formatedName = suppProcess.name;
        const stringWidth = getWidthOfText(suppProcess.name, 'Segoe UI', `${fontSize}px`);
        if (stringWidth > textHeight) {
          formatedName = formatStringVertical(
            suppProcess.name,
            textWidth,
            textHeight,
            textX,
            textY,
            rowDistance,
            fontSize
          );
        }
        return (
          <svg
            key={suppProcess.id}
            x={newSuppProcessStartX}
            y={newSuppProcessStartY}
            width={supportProcessWidth}
            height={supportProcessHeight}
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMinYMin meet"
            viewBox={`0 0 ${supportProcessWidth + suppShadowSizeCorrector} ${
              supportProcessHeight + suppShadowSizeCorrector
            }`}
            onClick={() => {
              if (suppProcess.numberOfDefinitions > 0) {
                onGroupClick(suppProcess.id);
              }
            }}
          >
            <g className={status} key={suppProcess.id} fill="none">
              <path
                d={`M0 ${arrowHeadVertical}
              l${supportProcessWidth / 2} -${arrowHeadVertical}
              l${supportProcessWidth / 2} ${arrowHeadVertical}
              v${supportProcessHeight - arrowHeadVertical}
              h-${supportProcessWidth}
              v-${supportProcessHeight - arrowHeadVertical}`}
                fill={iconBackgroundColor}
              />
              <text
                fill={getTheme().palette.white}
                textAnchor="middle"
                x={textX}
                y={textY}
                width={textWidth}
                height={textHeight}
                className="processTypeName"
                fontSize={fontSize}
                transform={`rotate(-90, ${textX + 2}, ${textY - 2})`}
              >
                {formatedName}
              </text>
              <text
                fill={getTheme().palette.white}
                textAnchor="middle"
                x={supportProcessWidth / 2}
                y={textSmallY}
                width={textWidth}
                height={textHeight}
                className="processCounter"
              >
                {suppProcess.numberOfDefinitions.toString()}
              </text>
            </g>
          </svg>
        );
      })}
    </svg>
  );
}

ProcessLandscapeGraph.propTypes = {
  processes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onGroupClick: PropTypes.func.isRequired
};

export function getNumberOfRowsInColumn(processes, currentProcess) {
  const currentColumn = parseInt(currentProcess.processGrouping.split('.')[0], 10);
  const rowsNumbers = [];
  processes.map((p) => {
    const colNumber = parseInt(p.processGrouping.split('.')[0], 10);
    if (currentColumn === colNumber) {
      const rowNumber = parseInt(p.processGrouping.split('.')[1], 10);
      if (rowsNumbers) {
        const exists = rowsNumbers.find((element) => {
          return element === rowNumber;
        });
        if (exists === undefined) {
          rowsNumbers.push(rowNumber);
        }
      }
    }
    return rowsNumbers;
  });
  const numberOfRowsForCoreProcesses = rowsNumbers.length;
  return numberOfRowsForCoreProcesses;
}

export function getNumberOfSubColumnsInRow(processes, currentProcess) {
  const currProcessGrouping =
    currentProcess && currentProcess.processGrouping
      ? currentProcess.processGrouping.split('.')
      : null;
  const currentCol = currProcessGrouping ? parseInt(currProcessGrouping[0], 10) : null;
  const currentRow = currProcessGrouping ? parseInt(currProcessGrouping[1], 10) : null;
  const colNumbers = [];
  processes.map((p) => {
    const colNumber = parseInt(p.processGrouping.split('.')[0], 10);
    const rowNumber = parseInt(p.processGrouping.split('.')[1], 10);
    if (currentCol === colNumber && currentRow === rowNumber) {
      const subColNumber = parseInt(p.processGrouping.split('.')[2], 10);
      if (colNumbers) {
        const exists = colNumbers.find((element) => {
          return element === subColNumber;
        });
        if (exists === undefined) {
          colNumbers.push(subColNumber);
        }
      }
    }
    return colNumbers;
  });
  const numberOfSubColumnsForCoreProcesses = colNumbers.length;
  return numberOfSubColumnsForCoreProcesses;
}

// all values must pass in px without the 'px'
export const formatStringHorizontal = (
  someString,
  width,
  height,
  startX,
  startY,
  rowDistance,
  fontSize
) => {
  const words = someString.split(' ');
  const newStrings = [];
  const firstWordWidth = getWidthOfText(words[0], 'Segoe UI', `${fontSize}px`);
  if (firstWordWidth > width) {
    newStrings.push(shortenString(width, firstWordWidth, words[0]));
  } else {
    newStrings.push(words[0]);
  }
  for (let i = 1; i < words.length; i += 1) {
    const nextWord = words[i];
    const nextWordWidth = getWidthOfText(nextWord, 'Segoe UI', `${fontSize}px`);
    if (nextWordWidth > width) {
      newStrings.push(shortenString(width, nextWordWidth, nextWord));
    } else {
      const prevString = newStrings[newStrings.length - 1];
      const newString = `${prevString} ${nextWord}`;
      const newStringWidth = getWidthOfText(newString, 'Segoe UI', `${fontSize}px`);
      if (newStringWidth <= width) {
        newStrings[newStrings.length - 1] = newString;
      } else {
        newStrings.push(nextWord);
      }
    }
  }

  const decoratedText = [];
  let numberOfStrings = newStrings.length;

  if (numberOfStrings > 1 && height && height < numberOfStrings * (fontSize / 3)) {
    const extraRows = Math.ceil(numberOfStrings * (fontSize / 3) - height);
    for (let i = 0; i < extraRows; i += 1) {
      newStrings.pop();
    }
    const lastRow = newStrings[newStrings.length - 1];
    if (lastRow) {
      const newLastRowWidth = getWidthOfText(`${lastRow}...`, 'Segoe UI', `${fontSize}px`);
      if (newLastRowWidth > width) {
        const shortedLastRow = `${lastRow.substring(0, lastRow.length - 3)}.`;
        newStrings[newStrings.length - 1] = `${shortedLastRow}...`;
      } else {
        newStrings[newStrings.length - 1] = `${lastRow}...`;
      }
    }
  } else {
    const stringRowWidth = getWidthOfText(newStrings[0], 'Segoe UI', `${fontSize}px`);
    if (stringRowWidth > width) {
      const shortedStringRow = `${newStrings[0].substring(0, newStrings[0].length - 3)}.`;
      newStrings[0] = `${shortedStringRow}...`;
    } else {
      newStrings[0] = `${newStrings[0]}`;
    }
  }

  numberOfStrings = newStrings.length;
  const calcFactor = numberOfStrings / 2;
  let counter = 0;
  newStrings.map((s, index) => {
    counter += 1;
    let calcY = startY - (calcFactor - (index + 0.5)) * rowDistance;
    if (numberOfStrings === 1) {
      calcY = startY;
    }
    decoratedText.push(
      <tspan key={counter} y={calcY} x={startX}>
        {s}
      </tspan>
    );
    return decoratedText;
  });

  if (decoratedText.length > 0) {
    return <>{decoratedText}</>;
  }
  return (
    <>
      <tspan>{someString}</tspan>
    </>
  );
};

export const formatStringVertical = (
  someString,
  width,
  height,
  startX,
  startY,
  rowDistance,
  fontSize
) => {
  const words = someString.split(' ');
  const newStrings = [];
  const firstWordWidth = getWidthOfText(words[0], 'Segoe UI', `${fontSize}px`);
  if (firstWordWidth > height) {
    newStrings.push(shortenString(height, firstWordWidth, words[0]));
  } else {
    newStrings.push(words[0]);
  }
  for (let i = 1; i < words.length; i += 1) {
    const nextWord = words[i];
    const nextWordWidth = getWidthOfText(nextWord, 'Segoe UI', `${fontSize}px`);
    if (nextWordWidth > height) {
      newStrings.push(shortenString(height, nextWordWidth, nextWord));
    } else {
      const prevString = newStrings[newStrings.length - 1];
      const newString = `${prevString} ${nextWord}`;
      const newStringWidth = getWidthOfText(newString, 'Segoe UI', `${fontSize}px`);
      if (newStringWidth <= height) {
        newStrings[newStrings.length - 1] = newString;
      } else {
        newStrings.push(nextWord);
      }
    }
  }
  const decoratedText = [];
  const numberOfStrings = newStrings.length;
  const calcFactor = numberOfStrings / 2;
  let counter = 0;
  newStrings.map((s, index) => {
    counter += 1;
    let calcY = startY - (calcFactor - (index + 0.5)) * rowDistance;
    if (numberOfStrings === 1) {
      calcY = startY;
    }
    decoratedText.push(
      <tspan key={counter} y={calcY} x={startX}>
        {s}
      </tspan>
    );
    return decoratedText;
  });

  if (width && width < numberOfStrings * (fontSize / 3)) {
    const extraRows = Math.ceil(width - numberOfStrings * (fontSize / 3));
    for (let i = 0; i < extraRows; i += 1) {
      newStrings.pop();
    }
    const lastRow = newStrings[newStrings.length - 1];
    const newLastRowWidth = getWidthOfText(`${lastRow}...`, 'Segoe UI', `${fontSize}px`);
    if (newLastRowWidth > height) {
      const shortedLastRow = `${lastRow.substring(0, lastRow.length - 3)}.`;
      newStrings[newStrings.length - 1] = `${shortedLastRow}...`;
    } else {
      newStrings[newStrings.length - 1] = `${lastRow}...`;
    }
  }

  if (decoratedText.length > 0) {
    return <>{decoratedText}</>;
  }
  return (
    <>
      <tspan>{someString}</tspan>
    </>
  );
};

function shortenString(width, wordWidth, word) {
  const characterWidth = wordWidth / word.length;
  const extraCharacters = (wordWidth - width) / characterWidth;
  const lastIndex = word.length - Math.ceil(extraCharacters);
  let shorterWord = word;
  if (lastIndex < 2) {
    shorterWord = word.substring(0, 1);
  } else {
    shorterWord = `${word.substring(0, lastIndex)}.`;
  }
  return shorterWord;
}

export function getWidthOfText(someString, fontName, fontSize) {
  const font = `${fontSize} ${fontName}`;
  if (getWidthOfText.c === undefined) {
    getWidthOfText.c = document.createElement('canvas');
    getWidthOfText.ctx = getWidthOfText.c.getContext('2d');
  }
  getWidthOfText.ctx.font = font;
  const stringWidth = getWidthOfText.ctx.measureText(someString).width;
  return stringWidth;
}
