import { useEffect, useState } from 'react';

export default function useContainerEndNotifer(listRefOrNode, callback) {
  const [listNode, setListNode] = useState(null);

  useEffect(() => {
    if (listRefOrNode) {
      if (listRefOrNode.current) {
        // ref
        setListNode(listRefOrNode.current);
      } else if (listRefOrNode.addEventListener) {
        // node
        setListNode(listRefOrNode);
      }
    }
  }, [listRefOrNode]);

  useEffect(() => {
    const handleScroll = (event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;

      const scrollDiff = Math.round(scrollTop + clientHeight) - Math.round(scrollHeight);

      if (!!callback && scrollDiff < 2 && scrollDiff > -2) {
        callback();
      }
    };

    if (listNode && listNode?.addEventListener) {
      listNode.addEventListener('scroll', handleScroll);
    }

    return () => listNode?.removeEventListener('scroll', handleScroll);
  }, [listNode, callback]);
}
