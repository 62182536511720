var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeFieldFormat, RouteFieldType } from '@evocom/types';
import { convertEpUserOrTeamToPersonaProps } from '@evocom/utils';
import { DetailsList, DetailsRow, Callout as FluentCallout, Icon, IconButton, Link, Persona, PersonaSize, SpinnerSize, TextField } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import Label from '../Label/Label';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import LongTextField from '../LongTextField/LongTextField';
import Rating from '../Rating/Rating';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { LookupWrapper } from './LookUp.styles';
function LookUp(LookUpProps) {
    var defaultValue = LookUpProps.defaultValue, definitionId = LookUpProps.definitionId, description = LookUpProps.description, _a = LookUpProps.disabled, disabled = _a === void 0 ? false : _a, fieldIds = LookUpProps.fieldIds, getFileContents = LookUpProps.getFileContents, getInstance = LookUpProps.getInstance, label = LookUpProps.label, labelIconName = LookUpProps.labelIconName, lookupType = LookUpProps.lookupType, onChange = LookUpProps.onChange, onSearch = LookUpProps.onSearch, outcome = LookUpProps.outcome, _b = LookUpProps.required, required = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var _c = useState(null), searchResult = _c[0], setSearchResult = _c[1];
    var textFieldRef = useRef(null);
    var _d = useState(), selectedInstance = _d[0], setSelectedInstance = _d[1];
    var _e = useState(false), initialized = _e[0], setinitialized = _e[1];
    var _f = useState({
        search: false,
        instance: !!defaultValue
    }), isLoading = _f[0], setIsLoading = _f[1];
    useEffect(function () {
        var instanceId = selectedInstance === null || selectedInstance === void 0 ? void 0 : selectedInstance.id;
        if (instanceId && isLoading.instance) {
            getInstance({ fieldIds: fieldIds, instanceId: instanceId }).then(function (instance) {
                setSelectedInstance(instance);
                setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { instance: false })); });
            });
        }
    }, [defaultValue, fieldIds, getInstance, selectedInstance === null || selectedInstance === void 0 ? void 0 : selectedInstance.id, isLoading.instance]);
    useEffect(function () {
        if (!initialized && defaultValue) {
            setinitialized(true);
            getInstance({ fieldIds: fieldIds, instanceId: defaultValue.id }).then(function (instance) {
                setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { instance: false })); });
                setSelectedInstance(instance);
            });
        }
    }, [defaultValue, fieldIds, getInstance, initialized]);
    var textFieldId = useId('search-textfield');
    var onTextFieldChange = debounce(function (_, filterText) {
        if (filterText && onSearch) {
            setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { search: true })); });
            onSearch({
                searchTerm: filterText,
                definitionId: definitionId,
                lookupType: lookupType,
                outcome: outcome,
                fieldIds: fieldIds
            }).then(function (result) {
                setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { search: false })); });
                setSearchResult(result.items);
            });
        }
        setSearchResult(null);
    }, 800);
    function onRenderDateColumn(item) {
        var date = item.creationDate.toString();
        return React.createElement("div", null, moment(date).format('L'));
    }
    function onRenderCreatorColumn(item) {
        var creatorName = item.creator.name;
        return React.createElement("div", null, creatorName);
    }
    function onRenderRow(props) {
        if (props) {
            return React.createElement(DetailsRow, __assign({}, props, { styles: { root: { cursor: 'pointer' } } }));
        }
        return null;
    }
    function onRowClick(instance) {
        setIsLoading(function (prevState) { return (__assign(__assign({}, prevState), { instance: true })); });
        setSelectedInstance(instance);
        if (onChange)
            onChange({ id: instance.id });
        setSearchResult(null);
    }
    function Callout() {
        var calloutContent = null;
        if (isLoading.search) {
            calloutContent = (React.createElement(LoadingSpinner, { styles: { container: { width: '450px', margin: 10 } }, size: SpinnerSize.large }));
        }
        var columns = [
            {
                fieldName: 'intId',
                isResizable: true,
                key: 'intId',
                minWidth: 20,
                name: 'ID'
            },
            {
                fieldName: 'name',
                isResizable: true,
                key: 'name',
                minWidth: 150,
                name: 'Name'
            },
            {
                fieldName: 'creator',
                isResizable: true,
                key: 'creator',
                minWidth: 120,
                name: 'Creator',
                onRender: onRenderCreatorColumn
            },
            {
                fieldName: 'creationDate',
                isResizable: true,
                key: 'creationDate',
                minWidth: 100,
                name: 'Created',
                onRender: onRenderDateColumn
            }
        ];
        if (searchResult === null || searchResult === void 0 ? void 0 : searchResult.length) {
            calloutContent = (React.createElement(DetailsList, { compact: true, checkboxVisibility: 2, onRenderRow: onRenderRow, onActiveItemChanged: onRowClick, items: searchResult, columns: columns }));
        }
        if (searchResult && searchResult.length === 0) {
            calloutContent = (React.createElement("div", { style: { textAlign: 'center', width: '450px', padding: 20 } },
                React.createElement(Icon, { styles: { root: { fontSize: 28 } }, iconName: "SearchIssue" }),
                React.createElement("div", null, "Kein Ergebnis")));
        }
        return (React.createElement(FluentCallout, { onDismiss: function () {
                setSearchResult(null);
            }, isBeakVisible: false, directionalHint: 4, gapSpace: 2, calloutMaxHeight: 200, target: "#" + textFieldId }, calloutContent));
    }
    function onRemoveSelectedInstance() {
        setSelectedInstance(null);
        setTimeout(function () { var _a; return (_a = textFieldRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 100);
    }
    function getFieldIconName(fieldType) {
        switch (fieldType) {
            case RouteFieldType.SmallText:
                return 'AlignLeft';
            case RouteFieldType.LongText:
                return 'AlignLeft';
            case RouteFieldType.Number:
                return 'NumberSymbol';
            case RouteFieldType.DateTime:
                return 'Calendar';
            case RouteFieldType.Choice:
                return 'CheckboxComposite';
            case RouteFieldType.Person:
                return 'Contact';
            case RouteFieldType.Rating:
                return 'FavoriteStarFill';
            case RouteFieldType.Hyperlink:
                return 'Link';
            case RouteFieldType.Document:
                return 'Attach';
            case RouteFieldType.Location:
                return 'LocationOutline';
            default:
                return null;
        }
    }
    function onRenderResultListIconColum(item) {
        var iconName = getFieldIconName(item.fieldType);
        if (iconName) {
            return React.createElement(Icon, { iconName: iconName, styles: { root: { marginTop: 2 } } });
        }
        return null;
    }
    function onRenderResultListNameColum(item) {
        return React.createElement("div", null, item.name);
    }
    function getLongText(value) {
        if (!value) {
            return null;
        }
        if (typeof value === 'string') {
            return (React.createElement(RichTextEditor, { styles: { editorWrapper: { padding: 0 } }, defaultValue: value, disabled: true }));
        }
        return (React.createElement(LongTextField, { defaultValue: value.currentValue, disabled: true, required: false, versions: value.versions, styles: { version: { padding: 0, paddingBottom: 5 } } }));
    }
    function getDateTime(date, displayTime) {
        if (!date) {
            return null;
        }
        if (displayTime) {
            return moment(date).format('L') + ", " + moment(date).format('LT');
        }
        return moment(date).format('L');
    }
    function renderChoiceListRow(choice) {
        return (React.createElement("div", { style: { display: 'flex', alignItems: 'center' }, key: "option-" + choice.id },
            React.createElement(Icon, { iconName: "CheckboxComposite", style: { fontSize: '12px' } }),
            React.createElement("div", { style: { marginLeft: '5px' } }, choice.value)));
    }
    function getChoiceList(choices) {
        if (!choices) {
            return null;
        }
        return choices.map(renderChoiceListRow);
    }
    function getPersona(user) {
        if (!user) {
            return null;
        }
        var personaProps = convertEpUserOrTeamToPersonaProps(user);
        return (React.createElement(Persona, { imageUrl: personaProps.imageUrl, size: PersonaSize.size24, text: personaProps.text }));
    }
    function getRating(_a) {
        var value = _a.value, maxRating = _a.maxRating;
        if (!value) {
            return null;
        }
        return (React.createElement(Rating, { maxRating: maxRating || 5, defaultValue: value, disabled: true, styles: {
                rootIsSmall: { height: '20px' },
                ratingButton: { padding: 0 },
                root: {
                    height: '20px',
                    border: 'none',
                    backgroundColor: 'transparent',
                    ':hover': { border: 'none' }
                }
            } }));
    }
    function onDocumentLinkClick(id, text) {
        var downloadLink = document.createElement('a');
        getFileContents({ id: id })
            .then(function (response) { return response.blob(); })
            .then(function (blob) { return URL.createObjectURL(blob); })
            .then(function (blobUrl) {
            downloadLink.href = blobUrl;
            downloadLink.download = text;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            URL.revokeObjectURL(blobUrl);
        });
    }
    function getDocumentLink(documentProps) {
        if (documentProps && documentProps.url) {
            var fileIdIndex = documentProps.url.indexOf('id=') + 3;
            var id_1 = documentProps.url.substr(fileIdIndex);
            return (React.createElement(Link, { onClick: function () { return onDocumentLinkClick(id_1, documentProps.text); } }, documentProps.text));
        }
        return null;
    }
    function getHyperLink(linkProps) {
        if (linkProps && linkProps.url) {
            var url = linkProps.url, text = linkProps.text;
            var linkTextWithProtocol = url;
            var hasProtocol = new RegExp('https?://');
            if (!hasProtocol.test(url)) {
                linkTextWithProtocol = "http://" + url;
            }
            return (React.createElement(Link, { target: "_blank", href: linkTextWithProtocol }, text || linkTextWithProtocol));
        }
        return null;
    }
    function isMobileDevice() {
        return typeof window.orientation !== 'undefined';
    }
    function isAppleDevice() {
        var platform = navigator.platform.toLowerCase();
        return (platform === 'macintosh' ||
            platform === 'macintel' ||
            platform === 'iphone' ||
            platform === 'ipad');
    }
    function onOpenMap(latitude, longitude) {
        if (isMobileDevice()) {
            var urlCoords = "?q=" + latitude + "," + longitude;
            var url = isAppleDevice() ? "maps://" + urlCoords : "geo:" + urlCoords;
            window.open(url, '_system');
        }
        else {
            var url = "https://bing.com/maps/default.aspx?cp=" + latitude + "~" + longitude + "&lvl=18&sp=point." + latitude + "_" + longitude;
            window.open(url);
        }
    }
    function getLocationLink(locationProps) {
        if (locationProps && locationProps.latitude && locationProps.longitude) {
            var latitude_1 = locationProps.latitude, longitude_1 = locationProps.longitude;
            return React.createElement(Link, { onClick: function () { return onOpenMap(latitude_1, longitude_1); } }, "Open map");
        }
        return null;
    }
    function onRenderResultListValueColum(item) {
        var fieldType = item.fieldType, value = item.value, field = item.field;
        var defaultValue = React.createElement("div", null, "-");
        switch (fieldType) {
            case RouteFieldType.SmallText:
                return React.createElement("div", null, value) || defaultValue;
            case RouteFieldType.LongText:
                return (getLongText(value) || defaultValue);
            case RouteFieldType.Number:
                return React.createElement("div", null, value) || defaultValue;
            case RouteFieldType.DateTime: {
                var displayTime = field.dateFormat === DateTimeFieldFormat.DateAndTime;
                return getDateTime(value || null, displayTime) || defaultValue;
            }
            case RouteFieldType.Choice: {
                return getChoiceList(value) || defaultValue;
            }
            case RouteFieldType.Person: {
                return getPersona(value || null) || defaultValue;
            }
            case RouteFieldType.Rating: {
                var numStars = field.numStars;
                return (getRating({ value: value, maxRating: numStars }) ||
                    defaultValue);
            }
            case RouteFieldType.Document: {
                return getDocumentLink(value) || defaultValue;
            }
            case RouteFieldType.Hyperlink: {
                return getHyperLink(value) || defaultValue;
            }
            case RouteFieldType.Location: {
                return getLocationLink(value) || defaultValue;
            }
            default:
                return defaultValue;
        }
    }
    if (isLoading.instance) {
        return (React.createElement(LoadingSpinner, { styles: { container: { margin: 10 } }, size: SpinnerSize.large }));
    }
    if (selectedInstance && selectedInstance.columns) {
        var columns = [
            {
                isResizable: false,
                key: 'icon',
                minWidth: 20,
                maxWidth: 20,
                name: 'Icon',
                onRender: onRenderResultListIconColum,
                isIconOnly: true
            },
            {
                fieldName: 'name',
                isResizable: true,
                key: 'name',
                onRender: onRenderResultListNameColum,
                minWidth: 80,
                maxWidth: 140,
                name: 'Name'
            },
            {
                fieldName: 'value',
                isResizable: true,
                key: 'value',
                onRender: onRenderResultListValueColum,
                minWidth: 160,
                name: 'Value'
            }
        ];
        return (React.createElement(LookupWrapper, null,
            React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
            React.createElement("div", { className: "c-selected-instance_wrapper" },
                React.createElement("div", { className: "c-selected-instance_label" },
                    React.createElement("div", { className: "c-selected-instance_name" },
                        "#",
                        selectedInstance.intId,
                        " - ",
                        selectedInstance.name),
                    !disabled && (React.createElement(IconButton, { styles: { root: { marginTop: '3px', height: '25px' } }, iconProps: {
                            styles: { root: { fontSize: '13px' } },
                            iconName: 'CalculatorMultiply'
                        }, onClick: onRemoveSelectedInstance }))),
                React.createElement(DetailsList, { compact: true, checkboxVisibility: 2, items: selectedInstance.columns, columns: columns }))));
    }
    return (React.createElement(LookupWrapper, null,
        React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
        React.createElement(TextField, { autoComplete: "off", componentRef: textFieldRef, disabled: disabled, placeholder: t('lookupField.placeholder', { context: disabled ? 'disabled' : undefined }), id: textFieldId, onChange: onTextFieldChange }),
        React.createElement(Callout, null)));
}
export default LookUp;
