import chroma from 'chroma-js';
import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { getTheme } from '@fluentui/react/lib/Styling';
import DefaultResultContent from './DefaultResultContent';
import SurveyResultOverflowButton from './SurveyResultOverflowButton';
function ChoiceResultContent(_a) {
    var fieldLink = _a.fieldLink;
    var _b = useState(false), showDetails = _b[0], setShowDetails = _b[1];
    var _c = useState(false), showAggregation = _c[0], setShowAggregation = _c[1];
    var _d = useState(false), displayOverflowButton = _d[0], setDisplayOverflowButton = _d[1];
    var chartRef = useRef();
    var values = fieldLink.values;
    useEffect(function () {
        if (values) {
            var shouldShowOverflowButton = !!values.find(function (value) { return value.aggregation === 1; });
            var shouldShowDetails = !values.find(function (value) { return value.aggregation === 8; });
            var shouldShowAggregation = !!values.find(function (value) { return value.aggregation !== 1; });
            var hasAggregatedValues = !!values.find(function (value) { return value.aggregation === 8; });
            setShowDetails(shouldShowDetails);
            setShowAggregation(shouldShowAggregation);
            setDisplayOverflowButton(shouldShowOverflowButton && hasAggregatedValues);
        }
    }, [values]);
    function getDoughnutData() {
        var labels = [];
        var colors = [];
        var data = [];
        var resultValues = values.filter(function (value) { return value.aggregation === 8; });
        resultValues.forEach(function (resultValue) {
            labels.push(resultValue.value.choice);
            data.push(resultValue.value.count);
        });
        colors = chroma
            .scale([getTheme().palette.themePrimary, '#5c2e91', '#0b6a0b', '#69797e'])
            .mode('lch')
            .colors(resultValues.length);
        return { labels: labels, data: data, colors: colors };
    }
    function renderAggregatedField() {
        var _a = getDoughnutData(), labels = _a.labels, data = _a.data, colors = _a.colors;
        return (React.createElement("div", null,
            React.createElement(Doughnut, { ref: chartRef, options: { plugins: { legend: { position: 'left' } }, maintainAspectRatio: false }, height: 200, data: {
                    labels: labels,
                    datasets: [
                        {
                            data: data,
                            backgroundColor: colors,
                            hoverOffset: 4
                        }
                    ]
                } })));
    }
    function renderDetails() {
        return (React.createElement("div", { style: { marginTop: showAggregation ? 10 : 0 } },
            React.createElement(DefaultResultContent, { fieldLink: fieldLink })));
    }
    return (React.createElement("div", null,
        displayOverflowButton && (React.createElement(SurveyResultOverflowButton, { showDetails: showDetails, setShowDetails: setShowDetails })),
        showAggregation && renderAggregatedField(),
        showDetails && renderDetails()));
}
export default ChoiceResultContent;
