import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import { AnimationStyles, Icon, PrimaryButton } from '@fluentui/react/lib';
import { onOpenFile } from '../../../../utils/helpers';

function DownLoadDocumentContainer() {
  const [initialized, setInitialized] = useState(null);
  // const { t } = useTranslation();

  const { fileId, fileTitle, tenantId } = useParams();

  const title = decodeURI(fileTitle);

  useEffect(() => {
    if (!initialized && tenantId) {
      window.localStorage.setItem('tenantId', tenantId);
      setInitialized(true);
    }
  }, [tenantId, initialized]);

  useEffect(() => {
    onOpenFile({ name: title, id: fileId });
  }, [fileId, title]);

  return null;

  // return (
  //   <div
  //     style={{
  //       ...AnimationStyles.fadeIn500,
  //       display: 'flex',
  //       flexDirection: 'column',
  //       marginTop: '30vh',
  //       textAlign: 'center'
  //     }}
  //   >
  //     <Icon
  //       styles={{
  //         root: {
  //           color: '#737373',
  //           fontSize: '84px',
  //           fontWeight: 400,
  //           lineHeight: '100px',
  //           margin: '15px',
  //           marginBottom: '10px'
  //         }
  //       }}
  //       iconName="Attach"
  //     />
  //     <span style={{ color: '#737373' }}>
  //       {t('attachmentsPivotItem.placeholder.noAttachments')}
  //     </span>
  //     <PrimaryButton
  //       styles={{
  //         root: { maxWidth: '200px', marginRight: 'auto', marginLeft: 'auto', marginTop: '15px' }
  //       }}
  //       iconProps={{ iconName: 'Add' }}
  //       text={t('attachmentsPivotItem.placeholder.add')}
  //       onClick={() => {
  //         onOpenFile(file);
  //       }}
  //       allowDisabledFocus
  //     />
  //   </div>
  // );
}

export default DownLoadDocumentContainer;
