var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { ChoiceGroup, getTheme } from '@fluentui/react';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { Checkbox as FabricCheckbox } from '@fluentui/react/lib/Checkbox';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import { concatStyleSets } from '@fluentui/react/lib/Styling';
import { TextField } from '@fluentui/react/lib/TextField';
import Label from '../Label/Label';
import ChoiceGroupWrapper from './ChoiceGroupField.styles';
function ChoiceGroupField(_a) {
    var _b = _a.allowFillIn, allowFillIn = _b === void 0 ? false : _b, _c = _a.allowMultipleSelections, allowMultipleSelections = _c === void 0 ? false : _c, _d = _a.defaultValue, defaultValue = _d === void 0 ? [] : _d, description = _a.description, _e = _a.disabled, disabled = _e === void 0 ? false : _e, label = _a.label, labelIconName = _a.labelIconName, onChange = _a.onChange, options = _a.options, placeholder = _a.placeholder, _f = _a.required, required = _f === void 0 ? false : _f, styles = _a.styles;
    var _g = useState(false), initialized = _g[0], setInitialized = _g[1];
    var _h = useState([]), choiceGroupOptions = _h[0], setChoiceGroupOptions = _h[1];
    var _j = useState(function () { return defaultValue; }), selectedOptions = _j[0], setSelectedOptions = _j[1];
    var _k = useState(null), customOptionKey = _k[0], setCustomOptionKey = _k[1];
    var customOptionTextFieldRef = useRef(null);
    var displayAsComboBox = options && options.length > 7;
    var t = useTranslation().t;
    useEffect(function () {
        var customOpion = choiceGroupOptions.find(function (option) { return option.isCustomOption; });
        setCustomOptionKey((customOpion === null || customOpion === void 0 ? void 0 : customOpion.key) || null);
    }, [choiceGroupOptions]);
    useEffect(function () {
        if (!initialized) {
            var fullOptions = __spreadArray([], options);
            if (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.length) {
                var customOption = defaultValue.find(function (defaultOption) { return !options.some(function (option) { return option.key === defaultOption.key; }); });
                if (customOption) {
                    customOption.isCustomOption = true;
                    if (displayAsComboBox) {
                        fullOptions.unshift(customOption);
                    }
                    else {
                        fullOptions.push(customOption);
                    }
                    setCustomOptionKey(customOption.key);
                }
            }
            if (fullOptions)
                setChoiceGroupOptions(fullOptions);
            setInitialized(true);
        }
    }, [options, initialized, defaultValue, displayAsComboBox]);
    function handleCustomComboBoxOption(newOptionString) {
        var customOptionIndex = choiceGroupOptions.findIndex(function (option) { return !!option.isCustomOption; });
        var customOptionAlreadyExsits = customOptionIndex > -1;
        var customSelectableOption = {
            key: newOptionString,
            text: newOptionString,
            isCustomOption: true
        };
        if (customOptionAlreadyExsits) {
            replaceCustomComboBoxOption(customSelectableOption);
        }
        else {
            setChoiceGroupOptions(function (prevState) { return __spreadArray([customSelectableOption], prevState); });
        }
    }
    function setSelectedComboBoxOption(option, selected) {
        setSelectedOptions(function (prevState) {
            var newSelectedOptionsArray = __spreadArray([], prevState);
            if (allowMultipleSelections) {
                if (selected) {
                    newSelectedOptionsArray = __spreadArray(__spreadArray([], newSelectedOptionsArray), [option]);
                }
                else {
                    var optionIndexToRemove = newSelectedOptionsArray.findIndex(function (selectedOption) { return selectedOption.key === option.key; });
                    newSelectedOptionsArray.splice(optionIndexToRemove, 1);
                }
            }
            else {
                newSelectedOptionsArray = [option];
            }
            if (onChange)
                onChange(newSelectedOptionsArray);
            return newSelectedOptionsArray;
        });
    }
    function onComboBoxChange(_, option, __, value) {
        if (allowFillIn && !option && value) {
            var newOption = { key: value, text: value };
            handleCustomComboBoxOption(value);
            setSelectedComboBoxOption(newOption, true);
        }
        else if (option) {
            var fullOption = options.find(function (fullOption) { return fullOption.key === option.key; });
            var fallbackOption = {
                key: option.key.toString(),
                text: option.text,
                isCustomOption: false
            };
            setSelectedComboBoxOption(fullOption || fallbackOption, !!option.selected);
        }
    }
    function replaceCustomComboBoxOption(customSelectableOption) {
        setChoiceGroupOptions(function (prevSelectableOptions) {
            var prevSelectableOptionsClone = __spreadArray([], prevSelectableOptions);
            prevSelectableOptionsClone[0] = customSelectableOption;
            return prevSelectableOptionsClone;
        });
    }
    function onChoiceGroupChange(_, option) {
        var _a;
        var newSelectedOptionsArray = [];
        var deselection = ((_a = selectedOptions[0]) === null || _a === void 0 ? void 0 : _a.key) === (option === null || option === void 0 ? void 0 : option.key) && !required;
        if (option && !deselection) {
            newSelectedOptionsArray.push(option);
        }
        setSelectedOptions(newSelectedOptionsArray);
        if (onChange) {
            onChange(newSelectedOptionsArray);
        }
    }
    function onCheckboxChange(option, checked) {
        setSelectedOptions(function (prevSelectedOptions) {
            var selectedOptionsClone = __spreadArray([], prevSelectedOptions);
            if (checked) {
                selectedOptionsClone.push(option);
                if (onChange) {
                    onChange(selectedOptionsClone);
                }
                return selectedOptionsClone;
            }
            var selectedOptionIndex = selectedOptionsClone.findIndex(function (selectedOption) { return selectedOption.key === option.key; });
            if (selectedOptionIndex > -1) {
                selectedOptionsClone.splice(selectedOptionIndex, 1);
                if (onChange) {
                    onChange(selectedOptionsClone);
                }
                return selectedOptionsClone;
            }
            return selectedOptionsClone;
        });
    }
    function onRenderCheckboxLabel(checkBoxProps, defaultRender) {
        var completeOption = choiceGroupOptions.find(function (option) { return option.isCustomOption; });
        if ((checkBoxProps === null || checkBoxProps === void 0 ? void 0 : checkBoxProps.id) === 'custom' && checkBoxProps) {
            return (React.createElement("div", { style: { width: '100%', display: 'inline-block', paddingLeft: '4px' } },
                React.createElement("span", null, checkBoxProps.label),
                React.createElement(IconButton, { styles: {
                        root: {
                            display: disabled ? 'none' : 'inline',
                            height: 15,
                            marginLeft: 12,
                            marginTop: 2,
                            position: 'relative',
                            top: 2,
                            width: 20
                        },
                        rootHovered: { backgroundColor: 'transparent' }
                    }, iconProps: { styles: { root: { fontSize: '13px' } }, iconName: 'CalculatorMultiply' }, onClick: function () { return removeCustomOption((completeOption === null || completeOption === void 0 ? void 0 : completeOption.key) || ''); } })));
        }
        if (defaultRender) {
            return defaultRender(checkBoxProps);
        }
        return null;
    }
    function CheckBox(option) {
        var checked = !!selectedOptions.find(function (selectedOption) { return option.key === selectedOption.key; });
        var checkBoxId = option.isCustomOption ? 'custom' : option.key;
        return (React.createElement(FabricCheckbox, { checked: checked, disabled: disabled, id: checkBoxId, key: option.key, label: option.text, onChange: function (_, checked) { return onCheckboxChange(option, checked); }, onRenderLabel: onRenderCheckboxLabel, styles: {
                root: { marginTop: 6 },
                checkbox: {
                    background: checked ? getTheme().palette.themePrimary : 'transparent',
                    borderColor: '#323130'
                },
                text: { color: '#323130' }
            } }));
    }
    function MultipleChoiceGroup() {
        if (!(choiceGroupOptions === null || choiceGroupOptions === void 0 ? void 0 : choiceGroupOptions.length)) {
            return null;
        }
        return (React.createElement(ChoiceGroupWrapper, null,
            React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
            React.createElement("div", { className: "c-choicegroup__wrapper", style: { background: disabled ? '#f3f2f1' : 'white' } },
                choiceGroupOptions.map(CheckBox),
                " ",
                React.createElement(CustomOptionTextField, null))));
    }
    function removeCustomOption(key) {
        setChoiceGroupOptions(function (prevState) {
            var newChoiceGroupOptions = __spreadArray([], prevState);
            var optionIndexToRemove = newChoiceGroupOptions.findIndex(function (option) { return option.key === key; });
            if (optionIndexToRemove > -1) {
                newChoiceGroupOptions.splice(optionIndexToRemove, 1);
            }
            return newChoiceGroupOptions;
        });
        removeSelectedKey(key);
    }
    function removeSelectedKey(key) {
        setSelectedOptions(function (prevState) {
            var newSelectedOptions = __spreadArray([], prevState);
            var optionIndexToRemove = newSelectedOptions.findIndex(function (option) { return option.key === key; });
            if (optionIndexToRemove > -1) {
                newSelectedOptions.splice(optionIndexToRemove, 1);
            }
            if (onChange) {
                onChange(newSelectedOptions);
            }
            return newSelectedOptions;
        });
    }
    function onRenderComboBoxOption(renderComboBoxOptionProps, defaultRender) {
        if (renderComboBoxOptionProps === null || renderComboBoxOptionProps === void 0 ? void 0 : renderComboBoxOptionProps.isCustomOption) {
            var deleteButtonStyles = {
                root: {
                    border: 'none',
                    height: 15,
                    marginLeft: 15,
                    marginTop: 2,
                    minWidth: 10,
                    padding: 0,
                    width: 15
                }
            };
            return (React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement("div", null, renderComboBoxOptionProps.text),
                React.createElement(DefaultButton, { styles: deleteButtonStyles, iconProps: { iconName: 'CalculatorMultiply', styles: { root: { fontSize: 15 } } }, onClick: function (ev) {
                        ev.stopPropagation();
                        removeCustomOption(renderComboBoxOptionProps === null || renderComboBoxOptionProps === void 0 ? void 0 : renderComboBoxOptionProps.key.toString());
                    } })));
        }
        if (defaultRender) {
            return defaultRender(renderComboBoxOptionProps);
        }
        return null;
    }
    function addCustomOption(customOption) {
        setChoiceGroupOptions(function (prevState) { return __spreadArray(__spreadArray([], prevState), [customOption]); });
    }
    function onSaveCustomOption() {
        var _a;
        if ((_a = customOptionTextFieldRef.current) === null || _a === void 0 ? void 0 : _a.value) {
            var value = customOptionTextFieldRef.current.value;
            var newOption = { key: uuidv4(), text: value, isCustomOption: true };
            addCustomOption(newOption);
            if (allowMultipleSelections) {
                onCheckboxChange(newOption, true);
            }
            else {
                onChoiceGroupChange(undefined, newOption);
            }
        }
    }
    function doesCustomOptionExist() {
        if (!allowFillIn) {
            return false;
        }
        if (displayAsComboBox) {
            return !!choiceGroupOptions[0].isCustomOption;
        }
        var lastChoicegroupIndex = choiceGroupOptions.length - 1;
        return !!choiceGroupOptions[lastChoicegroupIndex].isCustomOption;
    }
    function onRenderChoiceGroupLabel(renderChoiceGroupOptionProps, defaultRender) {
        var isCustomOption = (renderChoiceGroupOptionProps === null || renderChoiceGroupOptionProps === void 0 ? void 0 : renderChoiceGroupOptionProps.itemKey) === customOptionKey;
        if (renderChoiceGroupOptionProps && isCustomOption) {
            var completeOption_1 = choiceGroupOptions.find(function (option) { return option.isCustomOption; });
            return (React.createElement("div", { style: { width: '100%', display: 'inline-block', paddingLeft: '26px' } },
                React.createElement("span", null, renderChoiceGroupOptionProps.text),
                React.createElement(IconButton, { styles: {
                        root: {
                            display: disabled ? 'none' : 'inline',
                            height: 15,
                            marginLeft: 12,
                            marginTop: 2,
                            position: 'relative',
                            top: 2,
                            width: 20
                        },
                        rootHovered: { backgroundColor: 'transparent' }
                    }, iconProps: { styles: { root: { fontSize: '13px' } }, iconName: 'CalculatorMultiply' }, onClick: function () { return removeCustomOption((completeOption_1 === null || completeOption_1 === void 0 ? void 0 : completeOption_1.key) || ''); } })));
        }
        if (defaultRender)
            return defaultRender(renderChoiceGroupOptionProps);
        return null;
    }
    function onRenderChoiceGroupField(choiceGroupOptionProps, defaultRender) {
        if (defaultRender && choiceGroupOptionProps && choiceGroupOptionProps.id) {
            var itemKey_1 = choiceGroupOptionProps.itemKey, text_1 = choiceGroupOptionProps.text;
            return (React.createElement("div", { "aria-hidden": "true", onClick: function () {
                    if (!disabled) {
                        onChoiceGroupChange(undefined, { text: text_1, key: itemKey_1 });
                    }
                } }, defaultRender(choiceGroupOptionProps)));
        }
        return null;
    }
    function addChoiceGroupProps(option) {
        var choiceGroupOptionStyles = {
            root: { marginTop: 0 },
            field: {
                selectors: {
                    ':after': {
                        borderColor: getTheme().palette.themePrimary
                    },
                    '&:before': {
                        borderColor: '#323130',
                        background: disabled ? '#f3f2f1' : 'white'
                    },
                    '&.is-checked:before': {
                        borderColor: getTheme().palette.themePrimary
                    },
                    '.ms-ChoiceFieldLabel': {
                        color: '#323130'
                    },
                    ':hover .childElement': {
                        color: 'white'
                    }
                }
            }
        };
        return __assign(__assign({}, option), { id: option.key, onRenderLabel: onRenderChoiceGroupLabel, onRenderField: onRenderChoiceGroupField, styles: choiceGroupOptionStyles });
    }
    function onRenderTextFieldSuffix() {
        return React.createElement(IconButton, { iconProps: { iconName: 'Save' }, onClick: onSaveCustomOption });
    }
    function getChoiceGroupStyles() {
        var choiceGroupStyles = { root: { paddingTop: 3 } };
        if (styles === null || styles === void 0 ? void 0 : styles.choiceGroup) {
            choiceGroupStyles = concatStyleSets(choiceGroupStyles, styles.choiceGroup);
        }
        return choiceGroupStyles;
    }
    function getComboBoxstyles() {
        var comboBoxStyles = {
            optionsContainerWrapper: { maxHeight: 300 },
            inputDisabled: {
                '::placeholder': { color: (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.length) ? '#323130' : '#a19f9d' },
                color: '#323130'
            },
            root: {
                ':after': {
                    border: '1px solid #a19f9d',
                    borderRadius: 3,
                    ':hover': {
                        border: '1px solid black',
                        borderRadius: 3
                    }
                }
            }
        };
        if (styles === null || styles === void 0 ? void 0 : styles.comboBox) {
            comboBoxStyles = concatStyleSets(comboBoxStyles, styles.comboBox);
        }
        return comboBoxStyles;
    }
    function CustomOptionTextField() {
        var hasACustomOption = doesCustomOptionExist();
        if (!allowFillIn || hasACustomOption || disabled) {
            return null;
        }
        return (React.createElement(TextField, { componentRef: customOptionTextFieldRef, onBlur: onSaveCustomOption, onKeyPress: function (event) { return event.key === 'Enter' && onSaveCustomOption(); }, onRenderSuffix: onRenderTextFieldSuffix, placeholder: t('choiceGroupField.input.addNewOption.placeholder'), styles: { suffix: { backgroundColor: 'transparent' } }, suffix: "reset", underlined: true }));
    }
    if (!initialized || !options || !options.length) {
        return null;
    }
    if (displayAsComboBox) {
        var comboBoxstyles = getComboBoxstyles();
        var selectedKeys = selectedOptions.map(function (option) { return option.key; });
        var selectedKeysText = selectedOptions.map(function (option) { return option.text; });
        return (React.createElement(React.Fragment, null,
            React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
            React.createElement(ComboBox, { allowFreeform: true, title: selectedKeysText === null || selectedKeysText === void 0 ? void 0 : selectedKeysText.join(', '), autoComplete: "on", disabled: disabled, multiSelect: allowMultipleSelections, onChange: onComboBoxChange, onRenderOption: onRenderComboBoxOption, options: choiceGroupOptions, placeholder: placeholder || t('comboBoxField.comboBox.placeholder'), selectedKey: selectedKeys, styles: comboBoxstyles })));
    }
    if (allowMultipleSelections) {
        return React.createElement(MultipleChoiceGroup, null);
    }
    var selectedKey = (selectedOptions === null || selectedOptions === void 0 ? void 0 : selectedOptions.length) ? selectedOptions[0].key : '';
    var choiceGroupStyles = getChoiceGroupStyles();
    return (React.createElement(ChoiceGroupWrapper, null,
        React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
        React.createElement("div", { className: "c-choicegroup__wrapper", style: { background: disabled ? '#f3f2f1' : 'white' } },
            React.createElement(ChoiceGroup, { disabled: disabled, options: choiceGroupOptions.map(addChoiceGroupProps), selectedKey: selectedKey, styles: choiceGroupStyles }),
            React.createElement(CustomOptionTextField, null))));
}
export default ChoiceGroupField;
