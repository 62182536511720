import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import { isInIframe } from '../../utils/helpers';
import AppContext from '../App/AppContext';
import AdministrationBar from './AdministrationBar';
import Navigation from './Navigation';

// import SearchBar from './SearchBar';

// styles
const SideBarStyled = styled.div`
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  min-height: 100%;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #eaeaea;

  .ms-SearchBox {
    height: 44px;
    border: #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    min-width: 100%;
    width: 100%;

    .ms-SearchBox {
      padding-right: 9px;
    }
  }
`;

function SideBar() {
  const [showSidebar, setShowSidebar] = useState(false);
  const { pathname } = useLocation();

  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentUser } = globalAppState;

  useEffect(() => {
    if (isInIframe() || pathname.includes('/process-designer')) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [pathname]);

  if (!showSidebar) {
    return null;
  }

  return (
    <SideBarStyled id="sidebar">
      <Navigation />
      {currentUser?.isAdmin ? <AdministrationBar /> : null}
    </SideBarStyled>
  );
}

export default SideBar;
