var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { RouteFieldType } from '@evocom/types';
import { breakpoints } from './breakpoints';
export function checkScreenWidth(ranges) {
    var size = null;
    var largeMax = breakpoints.largeMax, largeMin = breakpoints.largeMin, mediumMax = breakpoints.mediumMax, mediumMin = breakpoints.mediumMin, smallMax = breakpoints.smallMax, smallMin = breakpoints.smallMin, extraSmallMin = breakpoints.extraSmallMin, extraSmallMax = breakpoints.extraSmallMax;
    var mediaQuery = function (minWidth, maxWidth) {
        return window.matchMedia("(min-width: " + minWidth + "px) and (max-width: " + maxWidth + "px)").matches;
    };
    if (mediaQuery(extraSmallMin, extraSmallMax)) {
        size = 'extraSmall';
    }
    else if (mediaQuery(smallMin, smallMax)) {
        size = 'small';
    }
    else if (mediaQuery(mediumMin, mediumMax)) {
        size = 'medium';
    }
    else if (mediaQuery(largeMin, largeMax)) {
        size = 'large';
    }
    else {
        size = 'extraLarge';
    }
    return Array.isArray(ranges) ? ranges.findIndex(function (range) { return range === size; }) > -1 : ranges === size;
}
export function convertEpUserOrTeamToPersonaProps(valueToConvert) {
    var persona;
    if (isEpUserValue(valueToConvert)) {
        persona = __assign(__assign({}, valueToConvert), { text: valueToConvert.name || undefined, secondaryText: valueToConvert.email || undefined, showSecondaryText: !!valueToConvert.email, id: valueToConvert.userId || undefined, imageUrl: valueToConvert.pictureUrl || undefined });
    }
    else {
        persona = __assign(__assign({}, valueToConvert), { text: valueToConvert.title || undefined });
    }
    return persona;
}
export function convertPersonaIntoEpUser(persona) {
    if (!persona) {
        return null;
    }
    var id = persona.id, imageUrl = persona.imageUrl, text = persona.text, secondaryText = persona.secondaryText;
    if (id) {
        return {
            email: secondaryText,
            name: text,
            pictureUrl: imageUrl,
            userId: id
        };
    }
    return undefined;
}
export function isEpUserValue(defaultValueToCheck) {
    return !!defaultValueToCheck.userId;
}
export function findFilesToUploadInTaskGroups(groups) {
    var documentFields = [];
    var getDocumentFields = function (_a) {
        var _b;
        var fieldLink = _a.fieldLink, fieldIndex = _a.fieldIndex, groupIndex = _a.groupIndex, fieldGroupIndex = _a.fieldGroupIndex;
        if (((_b = fieldLink === null || fieldLink === void 0 ? void 0 : fieldLink.field) === null || _b === void 0 ? void 0 : _b.fieldType) === RouteFieldType.Document) {
            var value = fieldLink.value;
            if (value && typeof value === 'object' && 'file' in value && value.file) {
                return {
                    fieldGroupIndex: fieldGroupIndex,
                    fieldId: fieldLink.field.id,
                    fieldIndex: fieldIndex,
                    file: value.file,
                    groupIndex: groupIndex
                };
            }
            return null;
        }
        return null;
    };
    if (groups === null || groups === void 0 ? void 0 : groups.length) {
        groups.map(function (group, groupIndex) {
            var currentGroupDocumentFields = group.fields.map(function (fieldLink, fieldIndex) { return getDocumentFields({ fieldLink: fieldLink, fieldIndex: fieldIndex, groupIndex: groupIndex }); });
            currentGroupDocumentFields = currentGroupDocumentFields.filter(function (field) { return !!field; });
            group.fields.map(function (fieldLink, fieldLinkIndex) {
                if (fieldLink.fieldGroup) {
                    var fieldGroupDocumentFields = fieldLink.fieldGroup.fields.map(function (fieldGroupFieldLink, fieldGroupIndex) {
                        return getDocumentFields({
                            fieldLink: fieldGroupFieldLink,
                            fieldIndex: fieldLinkIndex,
                            groupIndex: groupIndex,
                            fieldGroupIndex: fieldGroupIndex
                        });
                    });
                    fieldGroupDocumentFields = fieldGroupDocumentFields.filter(function (field) { return !!field; });
                    currentGroupDocumentFields = __spreadArray(__spreadArray([], currentGroupDocumentFields), fieldGroupDocumentFields);
                }
            });
            documentFields = __spreadArray(__spreadArray([], documentFields), currentGroupDocumentFields);
        });
    }
    return documentFields;
}
export function getSectionDocuments(fields) {
    return (fields
        .map(function (fieldLink, fieldLinkIndex) { return ({
        fieldType: fieldLink.field.fieldType,
        file: fieldLink.value,
        fieldLinkIndex: fieldLinkIndex
    }); })
        .filter(function (_a) {
        var file = _a.file, fieldType = _a.fieldType;
        return (fieldType === RouteFieldType.Multimedia || fieldType === RouteFieldType.Document) && file;
    }));
}
export function prepareFieldGroupForEvaluation(fieldGroupLink) {
    var fieldGroupLinkClone = __assign({}, fieldGroupLink);
    fieldGroupLinkClone.fieldGroup.fields = fieldGroupLinkClone.fieldGroup.fields.map(function (fieldLink) {
        var fieldLinkClone = __assign({}, fieldLink);
        if (fieldLinkClone.value && fieldLinkClone.field.fieldType === RouteFieldType.Document) {
            var newValue = { text: fieldLinkClone.value.text, url: fieldLinkClone.value.url };
            fieldLinkClone.value = newValue;
        }
        return fieldLinkClone;
    });
    return fieldGroupLinkClone;
}
export function getFluentIconNameByFieldType(fieldType) {
    switch (fieldType) {
        case RouteFieldType.SmallText:
            return 'AlignLeft';
        case RouteFieldType.LongText:
            return 'AlignLeft';
        case RouteFieldType.Number:
            return 'NumberSymbol';
        case RouteFieldType.Boolean:
            return 'DocumentApproval';
        case RouteFieldType.DateTime:
            return 'Calendar';
        case RouteFieldType.Choice:
            return 'CheckboxComposite';
        case RouteFieldType.Person:
            return 'Contact';
        case RouteFieldType.Rating:
            return 'FavoriteStarFill';
        case RouteFieldType.Hyperlink:
            return 'Link';
        case RouteFieldType.Document:
            return 'Attach';
        case RouteFieldType.ExternalData:
            return 'DatabaseSync';
        case RouteFieldType.Lookup:
            return 'Lookup';
        case RouteFieldType.Location:
            return 'LocationOutline';
        case RouteFieldType.Signature:
            return 'InsertSignatureLine';
        case RouteFieldType.Scanner:
            return 'GenericScan';
        case RouteFieldType.Multimedia:
            return 'PhotoVideoMedia';
        default:
            return '';
    }
}
