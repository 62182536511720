import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@fluentui/react';
import { CommandBarButton } from '@fluentui/react/lib/Button';

function HeaderCmdBarButton({ iconName, title, onClickMethod, styles, badgeText, badgeIcon }) {
  return (
    <span style={{ position: badgeText || badgeIcon ? 'relative' : null }}>
      <CommandBarButton
        key={`${iconName}-key`}
        ariaLabel={title}
        styles={{
          root: {
            backgroundColor: 'transparent',
            transition: 'background-color 0.5s ease',
            width: '45px',
            height: '100%',
            ...styles?.commandBarButton?.root
          },
          rootHovered: {
            backgroundColor: 'rgba(255,255,255,0.1)',
            ...styles?.commandBarButton?.rootHovered
          },
          rootPressed: {
            backgroundColor: 'rgba(255,255,255,0.2)',
            ...styles?.commandBarButton?.rootPressed
          },
          iconHovered: { color: 'white', ...styles?.commandBarButton?.iconHovered },
          iconPressed: { color: 'white', ...styles?.commandBarButton?.iconPressed }
        }}
        title={title}
        iconProps={{
          iconName,
          styles: {
            root: { color: 'white', fontSize: '15px', ...styles?.commandBarButton?.iconProps?.root }
          }
        }}
        onClick={onClickMethod}
      />
      {badgeText ? <span className="header-badge">{badgeText}</span> : null}
      {badgeIcon ? <Icon className="header-badge-icon" iconName={badgeIcon} /> : null}
    </span>
  );
}

HeaderCmdBarButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClickMethod: PropTypes.func.isRequired,
  styles: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  badgeText: PropTypes.string,
  badgeIcon: PropTypes.string
};

HeaderCmdBarButton.defaultProps = {
  styles: null,
  title: null,
  badgeText: null,
  badgeIcon: null
};

export default HeaderCmdBarButton;
