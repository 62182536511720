import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@fluentui/react';
function RatingResultContent(_a) {
    var showDetails = _a.showDetails, setShowDetails = _a.setShowDetails;
    var t = useTranslation().t;
    function getOverflowIconButtonMenuItems() {
        var menuItems = [
            {
                key: 'show-details',
                text: showDetails
                    ? t('survey.results.button.details.hide')
                    : t('survey.results.button.details.show'),
                iconProps: { iconName: showDetails ? 'BackToWindow' : 'FullScreen' },
                onClick: function () { return setShowDetails(!showDetails); }
            }
        ];
        return menuItems;
    }
    return (React.createElement(IconButton, { styles: {
            rootHovered: { backgroundColor: '#E1E1E1' },
            root: {
                height: '25px',
                backgroundColor: '#e1e1e1bf'
            },
            menuIcon: {
                fontSize: '15px',
                fontWeight: '600'
            }
        }, className: "c-showdetails-overflow-button", menuIconProps: { iconName: 'More' }, menuProps: { items: getOverflowIconButtonMenuItems() } }));
}
export default RatingResultContent;
