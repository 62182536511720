var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontIcon, Stack, TextField } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import Dialog from '../Dialog/Dialog';
import Files from '../FileManagement/FileManagement';
import Label from '../Label/Label';
import { MultiMediaFieldStyled } from './MultiMediaField.styles';
import MultiMediaImage from './MultiMediaImage';
import MultiMediaModal from './MultiMediaModal';
import MultiMediaVideo from './MultiMediaVideo';
function DocumentField(_a) {
    var defaultValue = _a.defaultValue, description = _a.description, _b = _a.disabled, disabled = _b === void 0 ? false : _b, getFileContents = _a.getFileContents, label = _a.label, labelIconName = _a.labelIconName, onChange = _a.onChange, _c = _a.required, required = _c === void 0 ? false : _c, tenantId = _a.tenantId;
    var t = useTranslation().t;
    var fileManagementRef = useRef(null);
    var textFieldRef = useRef(null);
    var _d = useState(defaultValue || []), currentMediaValues = _d[0], setCurrentMediaValues = _d[1];
    var _e = useState({
        isOpen: false,
        index: 0
    }), modalProps = _e[0], setModalProps = _e[1];
    var _f = useState(true), isLinkDialogHidden = _f[0], setIsLinkDialogHidden = _f[1];
    var isImage = useCallback(function (text) {
        return /.png|.jpg|.jpeg|.gif|.tiff|.tif/i.test(text);
    }, []);
    var isVideo = useCallback(function (text) {
        return /.mp4|.ogg|.ogv|.webm|.MOV|.WMV|.AVI/i.test(text);
    }, []);
    function openFileChooser() {
        var _a;
        if (((_a = fileManagementRef.current) === null || _a === void 0 ? void 0 : _a.openFileChooser) && currentMediaValues.length < 12) {
            fileManagementRef.current.openFileChooser();
        }
    }
    function onFileSelection(files) {
        var mediaValues = __spreadArray(__spreadArray([], currentMediaValues), files.map(convertMediaValue));
        setCurrentMediaValues(mediaValues);
        if (onChange)
            onChange(mediaValues);
    }
    function getDownloadUrl(mediaValue) {
        return window.URL.createObjectURL(mediaValue.file);
    }
    function downloadDocument(itemIndex) {
        var _a;
        var mediaValue = currentMediaValues[itemIndex];
        if (mediaValue.file) {
            var url = getDownloadUrl(mediaValue);
            var element = document.createElement('a');
            element.setAttribute('href', url);
            element.setAttribute('download', mediaValue.file.name);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        else if (getFileContents && mediaValue.url) {
            var downloadLink_1 = document.createElement('a');
            var fileIdIndex = mediaValue.url.indexOf('id=') + 3;
            var id = (_a = mediaValue.url) === null || _a === void 0 ? void 0 : _a.substr(fileIdIndex);
            getFileContents({ id: id })
                .then(function (response) { return response.blob(); })
                .then(function (blob) { return URL.createObjectURL(blob); })
                .then(function (blobUrl) {
                downloadLink_1.href = blobUrl;
                downloadLink_1.download = mediaValue.text || '';
                document.body.appendChild(downloadLink_1);
                downloadLink_1.click();
                URL.revokeObjectURL(blobUrl);
            });
        }
    }
    function renderImagePreview(mediaValue) {
        return (React.createElement(MultiMediaImage, { width: "132px", height: "132px", mediaValue: mediaValue, getFileContents: getFileContents }));
    }
    function getStreamLinkDialogContent() {
        return (React.createElement(TextField, { componentRef: textFieldRef, label: "Video URL", placeholder: "https://web.microsoftstream.com/video/{id}" }));
    }
    function convertMediaValue(mediaValue) {
        return {
            file: mediaValue,
            url: 'https://tempuri.org',
            text: mediaValue === null || mediaValue === void 0 ? void 0 : mediaValue.name
        };
    }
    function onConfirmStreamUrl() {
        var _a;
        setIsLinkDialogHidden(true);
        var url = (_a = textFieldRef.current) === null || _a === void 0 ? void 0 : _a.value;
        if (url && url.includes('https://web.microsoftstream.com/')) {
            if (!url.includes('/embed/')) {
                url = url.slice(0, 32) + "embed/" + url.slice(32);
            }
            var newMediaValues = __spreadArray(__spreadArray([], currentMediaValues), [{ url: url }]);
            setCurrentMediaValues(newMediaValues);
            if (onChange) {
                onChange(newMediaValues);
            }
        }
    }
    function onDeleteMediaValue(index) {
        setCurrentMediaValues(function (prevState) {
            var prevStateClone = __spreadArray([], prevState);
            prevStateClone.splice(index, 1);
            if (onChange) {
                onChange(prevStateClone);
            }
            return prevStateClone;
        });
    }
    function getOverflowIconButtonMenuItems(itemIndex) {
        var _a;
        var menuItems = [];
        var mediaValue = currentMediaValues[itemIndex];
        if (!((_a = mediaValue.url) === null || _a === void 0 ? void 0 : _a.includes('microsoftstream.com'))) {
            menuItems.push({
                key: 'download',
                text: t('documentField.button.menu.download'),
                onClick: function () { return downloadDocument(itemIndex); },
                iconProps: { iconName: 'Download' }
            });
        }
        if (!disabled) {
            menuItems.push({
                key: 'delete',
                text: t('documentField.button.menu.delete'),
                onClick: function () { return onDeleteMediaValue(itemIndex); },
                iconProps: { iconName: 'Delete' }
            });
        }
        return menuItems;
    }
    function getPreviewDivOverlay(children, index) {
        return (React.createElement("div", { "aria-hidden": "true", className: "c-preview-overlay_wrapper", onClick: function () {
                setModalProps({ isOpen: true, index: index });
            }, style: {
                width: 132,
                height: 132,
                position: 'relative',
                border: '1px solid #a19f9d',
                borderRadius: '3px',
                overflow: 'hidden'
            } },
            React.createElement("div", { className: "c-preview-overlay" }),
            isVideo(currentMediaValues[index].text) && (React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                React.createElement(FontIcon, { "aria-label": "Play", iconName: "Play", className: "c-preview-icon" }))),
            React.createElement(IconButton, { styles: {
                    rootHovered: { backgroundColor: '#E1E1E1' },
                    root: {
                        height: '25px',
                        backgroundColor: '#e1e1e1bf'
                    },
                    menuIcon: {
                        fontSize: '15px',
                        fontWeight: '600'
                    }
                }, className: "c-preview-overlay_overflow-button", menuIconProps: { iconName: 'More' }, menuProps: { items: getOverflowIconButtonMenuItems(index) } }),
            children));
    }
    function renderPreview(mediaValue, index) {
        var _a, _b, _c, _d;
        if (mediaValue.url && mediaValue.url.includes('microsoftstream.com')) {
            return getPreviewDivOverlay(React.createElement("iframe", { title: "microsoftstream", width: "132", style: { border: 'none' }, height: "132", src: mediaValue.url + "?autoplay=false&showinfo=false", allowFullScreen: true }), index);
        }
        if (((_b = (_a = mediaValue.file) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.includes('image')) || isImage(mediaValue.text)) {
            return getPreviewDivOverlay(renderImagePreview(mediaValue), index);
        }
        if (((_d = (_c = mediaValue.file) === null || _c === void 0 ? void 0 : _c.type) === null || _d === void 0 ? void 0 : _d.includes('video')) || isVideo(mediaValue.text)) {
            return getPreviewDivOverlay(React.createElement(MultiMediaVideo, { mediaValue: mediaValue, tenantId: tenantId, width: 132, height: 132 }), index);
        }
        return null;
    }
    function onModalDismiss() {
        setModalProps({
            isOpen: false,
            index: 0
        });
    }
    function renderAddMediaIconButton() {
        if (disabled) {
            return null;
        }
        return (React.createElement(IconButton, { menuIconProps: { iconName: 'Add' }, menuProps: {
                items: [
                    {
                        key: 'file',
                        text: t('multiMediaField.uploadFile'),
                        onClick: openFileChooser,
                        iconProps: { iconName: 'MediaAdd' }
                    },
                    {
                        key: 'link',
                        text: 'MS-Stream Link',
                        onClick: function () { return setIsLinkDialogHidden(false); },
                        iconProps: { iconName: 'StreamLogo' }
                    }
                ],
                coverTarget: true
            }, styles: {
                menuIcon: { fontSize: '40px' },
                root: { minWidth: 132, height: 132, border: '1px solid #a19f9d', borderRadius: 3 }
            }, onClick: openFileChooser }));
    }
    function renderEmptyDisabledPlaceholder() {
        if (disabled && !currentMediaValues.length) {
            return (React.createElement(TextField, { disabled: true, placeholder: t('multiMediaField.emptyValue'), styles: {
                    root: {
                        width: '100%',
                        margin: '4px'
                    },
                    wrapper: {
                        border: '1px solid #a19f9d',
                        borderRadius: '3px'
                    }
                } }));
        }
        return null;
    }
    var maxFiles = 12 - currentMediaValues.length;
    return (React.createElement(MultiMediaFieldStyled, null,
        React.createElement(Files, { ref: fileManagementRef, clickable: false, maxFiles: maxFiles, accepts: ['image/*', 'video/WebM', 'video/ogg', 'video/mp4', '.mov'], multiple: true, onChange: onFileSelection },
            React.createElement(Label, { label: label, required: required, iconName: labelIconName, description: description }),
            React.createElement(Stack, { tokens: { childrenGap: 8 }, wrap: true, horizontal: true },
                currentMediaValues.map(renderPreview),
                renderEmptyDisabledPlaceholder(),
                renderAddMediaIconButton())),
        modalProps.isOpen && (React.createElement(MultiMediaModal, { tenantId: tenantId, defaultMediaValueIndex: modalProps.index, mediaValues: currentMediaValues, onDismiss: onModalDismiss, getFileContents: getFileContents })),
        React.createElement(Dialog, { title: t('multiMediaField.dialog.header'), hidden: isLinkDialogHidden, defaultButtonFunc: function () { return setIsLinkDialogHidden(true); }, defaultButtonLabel: t('multiMediaField.dialog.buttonLabel.cancel'), primaryButtonLabel: t('multiMediaField.dialog.buttonLabel.confirm'), primaryButtonFunc: onConfirmStreamUrl, content: getStreamLinkDialogContent() })));
}
export default DocumentField;
