var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { NormalPeoplePicker } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { concatStyleSets } from '@fluentui/react/lib/Styling';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import Label from '../Label/Label';
var CombinedPickerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid #a19f9d;\n  border-radius: 3px;\n  background: #f3f2f1;\n  padding: 5px;\n"], ["\n  border: 1px solid #a19f9d;\n  border-radius: 3px;\n  background: #f3f2f1;\n  padding: 5px;\n"])));
function CombinedPicker(_a) {
    var combinedPickerRef = _a.combinedPickerRef, _b = _a.defaultValue, defaultValue = _b === void 0 ? [] : _b, description = _a.description, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.displayConsentWarning, displayConsentWarning = _d === void 0 ? false : _d, itemLimit = _a.itemLimit, label = _a.label, labelIconName = _a.labelIconName, onBlur = _a.onBlur, onChange = _a.onChange, onEmptyInputFocus = _a.onEmptyInputFocus, onRenderItem = _a.onRenderItem, onSearch = _a.onSearch, placeholder = _a.placeholder, _e = _a.required, required = _e === void 0 ? false : _e, styles = _a.styles;
    var inputIdRef = useState(uuidv4())[0];
    var _f = useState(defaultValue), selectedItems = _f[0], setSelectedItems = _f[1];
    var t = useTranslation().t;
    useLayoutEffect(function () {
        if (displayConsentWarning) {
            var input = document.getElementById(inputIdRef + "-input-id");
            var warningIconPlaceholder = document.createElement('div');
            warningIconPlaceholder.id = inputIdRef + "-warningIconPlaceholder-id";
            warningIconPlaceholder.className = inputIdRef + "-warningIconPlaceholder-id";
            if (input) {
                input.before(warningIconPlaceholder);
            }
        }
    }, [inputIdRef, displayConsentWarning]);
    function onRenderToolTipContent() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, t('combinedPicker.warning.text1')),
            React.createElement("div", null, t('combinedPicker.warning.text2'))));
    }
    function setConsentWarning() {
        var targetContainer = document.getElementById(inputIdRef + "-warningIconPlaceholder-id");
        if (targetContainer) {
            var warningIconStyles = {
                root: {
                    fontSize: '20px',
                    margin: '5px',
                    color: '#a80000',
                    selectors: { ':hover': { cursor: 'help' } }
                }
            };
            var warningIcon = (React.createElement(TooltipHost, { tooltipProps: { onRenderContent: onRenderToolTipContent }, calloutProps: { gapSpace: 15 } },
                React.createElement(Icon, { iconName: "warning", styles: warningIconStyles })));
            ReactDOM.render(warningIcon, targetContainer);
        }
    }
    function onResolveSuggestions(filterText, selectedItems) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (filterText && onSearch) {
                    return [2, onSearch(filterText, selectedItems)];
                }
                return [2, []];
            });
        });
    }
    function handleOnEmptyInputFocus() {
        if (!onEmptyInputFocus && onSearch) {
            return onSearch('***', []);
        }
        if (onEmptyInputFocus) {
            onEmptyInputFocus();
            return [];
        }
        return [];
    }
    function onPeopePickerChange(items) {
        setSelectedItems(items);
        if (onChange) {
            onChange(items);
        }
    }
    function getNormalPeoplePickerStyles() {
        var rootStyles = {
            border: '1px solid #a19f9d',
            borderRadius: 3,
            ':hover': {
                border: '1px solid black',
                borderRadius: 3
            }
        };
        var normalPeoplePickerStyles = {
            root: disabled ? rootStyles : undefined
        };
        if (styles) {
            normalPeoplePickerStyles = concatStyleSets(normalPeoplePickerStyles, styles);
        }
        return normalPeoplePickerStyles;
    }
    if (disabled && selectedItems && selectedItems.length > 0) {
        return (React.createElement("div", null,
            React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
            React.createElement(CombinedPickerWrapper, null,
                React.createElement(Persona, { hidePersonaDetails: false, imageUrl: selectedItems[0].imageUrl, size: PersonaSize.size32, showSecondaryText: true, secondaryText: selectedItems[0].secondaryText, text: selectedItems[0].text }))));
    }
    var pickerSuggestionsProps = {
        loadingText: t('peoplepicker.loading'),
        mostRecentlyUsedHeaderText: t('peoplepicker.recent'),
        noResultsFoundText: t('peoplepicker.noresult'),
        showRemoveButtons: false
    };
    var normalPeoplePickerStyles = getNormalPeoplePickerStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Label, { required: required, iconName: labelIconName, label: label, description: description }),
        React.createElement(NormalPeoplePicker, { defaultSelectedItems: defaultValue, disabled: disabled, componentRef: combinedPickerRef, inputProps: { placeholder: placeholder, autoComplete: 'off', id: inputIdRef + "-input-id" }, itemLimit: itemLimit, onBlur: onBlur, onRenderItem: onRenderItem, onChange: onPeopePickerChange, onEmptyResolveSuggestions: handleOnEmptyInputFocus, onResolveSuggestions: onResolveSuggestions, pickerSuggestionsProps: pickerSuggestionsProps, resolveDelay: 100, styles: normalPeoplePickerStyles })));
}
export default CombinedPicker;
var templateObject_1;
