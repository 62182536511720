import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LoaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Loading({ text }) {
  return (
    <LoaderStyled>
      <Spinner size={SpinnerSize.large} label={text} />
    </LoaderStyled>
  );
}

Loading.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

Loading.defaultProps = {
  text: null
};

export default Loading;
