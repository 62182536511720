import i18next from 'i18next';
import moment from 'moment';
import { getTheme } from '@fluentui/react/lib/Styling';
import fetchRequest from '../services/api';
import { teamsSearch, userSearch } from '../services/fetchRequests';
import breakpoints from './breakpoints';

export function convertUserPropsToPersona({ name, email, pictureUrl, userId }) {
  return {
    text: name,
    secondaryText: email,
    imageUrl: pictureUrl,
    id: userId,
    showSecondaryText: true
  };
}

export function onCombinedPickerSearch({ filterText, searchTeams, searchUser, type }) {
  if (searchTeams && searchUser) {
    return null;
  }

  if (searchUser) {
    return userSearch({ searchTerm: filterText, type }).then((searchResult) => ({
      ...searchResult,
      items: searchResult.items.map(convertUserPropsToPersona)
    }));
  }

  if (searchTeams) {
    return teamsSearch({ searchTerm: filterText }).then((searchResult) => ({
      consentNeeded: false,
      items: searchResult.map(convertTeamPropsToPersona)
    }));
  }

  return null;
}

export function convertTeamPropsToPersona({ title, id }) {
  return { text: title, id };
}

export function getDefaultTheme() {
  const theme = {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff'
  };

  return theme;
}

/**
 * Checks if app is running within an iframe
 *
 * @return {boolean}
 */
export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isInMsTeamsEnvironment() {
  const msTeamsEnvironment = window.sessionStorage.getItem('msTeamsEnvironment');
  return msTeamsEnvironment;
}

export function adjustColor(color, amount) {
  return `#${color
    .replace(/^#/, '')
    .replace(/../g, (color) =>
      `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2)
    )}`;
}

/**
 * Calculate percentage for progress indicator
 *
 * @param {string} startDate
 * @param {string} endDate
 * @returns number
 */

/**
 * Format date
 *
 * @param {*} date
 * @param {string} format
 * @returns string
 */
export function formatDate(date, format) {
  return moment(date).format(format);
}

/**
 * Format date
 * Returns localized date based on browser language
 *
 * @param {*} date
 * @returns string
 */
export function formatToLocalDateString(date) {
  // Local culture setting for converting date in local date format
  const locale = window.navigator.userLanguage || window.navigator.language;

  const localDateString = new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  return localDateString;
}

/**
 * Validate date
 *
 * @param {string} string
 * @param {string} format
 * @param {bool} bool
 * @returns bool
 */
export function validateDate(string, format, bool) {
  return moment(string, format, bool).isValid();
}

/**
 * Convert to date
 *
 * @param {string} string
 * @param {string} format
 * @returns string
 */
export function convertStringToDate(string, format) {
  return moment(string, format).toDate();
}

/**
 * Format given date to german date format
 *
 * @param {date} date
 * @returns string
 */
export function formatGermanDate(date) {
  if (Object.prototype.toString.call(date) !== '[object Date]') {
    throw new Error('Date is not an object!');
  }

  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

/**
 * Format given date to american date format
 *
 * @param {date} date
 * @returns string
 */
export function formatAmericanDate(date) {
  if (Object.prototype.toString.call(date) !== '[object Date]') {
    throw new Error('Date is not an object!');
  }

  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
}

/**
 * Generate time ago string
 *
 * @param {string} value
 * @param {string} format
 * @returns string
 */
export function timeAgo(value, format) {
  let dateFormat = format;
  const date = moment(value);
  const now = moment().startOf('second');

  if (!format) {
    dateFormat = 'DD.MM.YYYY';
  }

  const startOfOneHourAgo = now.clone().add(-1, 'h');
  const startOfToday = now.clone().startOf('day');
  const startOfYesterday = now.clone().add(-1, 'd').startOf('day');

  let formattedTimeAgo = '';

  if (date.isBefore(startOfYesterday)) {
    formattedTimeAgo = moment(value).format(dateFormat);
  }

  if (date.isSameOrAfter(startOfYesterday) && date.isBefore(startOfToday)) {
    formattedTimeAgo = i18next.t('helpers.timeAgo.yesterday');
  }

  if (date.isSameOrAfter(startOfToday) && date.isBefore(startOfOneHourAgo)) {
    const diffHours = now.diff(date, 'hours');
    formattedTimeAgo =
      diffHours === 1
        ? i18next.t('helpers.timeAgo.hour', { hour: diffHours.toString() })
        : i18next.t('helpers.timeAgo.hours', { hours: diffHours.toString() });
  }

  if (date.isSameOrAfter(startOfOneHourAgo) && date.isSameOrBefore(now)) {
    const diffMinutes = now.diff(date, 'minutes');
    formattedTimeAgo =
      diffMinutes === 1
        ? i18next.t('helpers.timeAgo.minute', { minute: diffMinutes.toString() })
        : i18next.t('helpers.timeAgo.minutes', { minutes: diffMinutes.toString() });
  }

  return formattedTimeAgo;
}

/**
 * Get current date
 *
 * @returns object
 */
export function getCurrentDate() {
  return moment();
}

/**
 * Get date of tomorrow
 *
 * @returns object
 */
export function getDateOfTomorrow() {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow;
}

/**
 * Group tasks by date
 *
 * @param {array} tasks
 * @param {string} mode
 * @returns object
 */
export function createColumnByTaskDates(tasks, mode) {
  let group = {};
  // group by given mode
  if (mode === 'dueDate') {
    group = {
      id: 'dueDateGroup',
      title: i18next.t('helpers.status.dueAndOverdue'),
      singleColumn: false,
      tasks: tasks.filter((val) => new Date(val.dueDate) < getDateOfTomorrow())
    };
  } else if (mode === 'upcoming') {
    group = {
      id: 'upcomingGroup',
      title: i18next.t('helpers.status.upcoming'),
      singleColumn: true,
      tasks: tasks.filter((val) => new Date(val.dueDate) >= getDateOfTomorrow())
    };
  } else if (mode === 'early') {
    group = {
      id: 'earlyCompletedGroup',
      title: i18next.t('helpers.status.early-completed'),
      singleColumn: true,
      tasks: tasks.filter(
        (val) =>
          moment.utc(val.editDate).format('YYYY-MM-DD') <
          moment.utc(val.dueDate).format('YYYY-MM-DD')
      )
    };
  } else if (mode === 'punctual') {
    group = {
      id: 'punctualCompletedGroup',
      title: i18next.t('helpers.status.punctual-completed'),
      singleColumn: true,
      tasks: tasks.filter(
        (val) =>
          moment.utc(val.editDate).format('YYYY-MM-DD') ===
          moment.utc(val.dueDate).format('YYYY-MM-DD')
      )
    };
  } else if (mode === 'late') {
    group = {
      id: 'lateCompletedGroup',
      title: i18next.t('helpers.status.late-completed'),
      singleColumn: true,
      tasks: tasks.filter(
        (val) =>
          moment.utc(val.editDate).format('YYYY-MM-DD') >
          moment.utc(val.dueDate).format('YYYY-MM-DD')
      )
    };
  }
  return group;
}

/**
 * Group tasks by status
 *
 * @param {array} tasks
 * @param {array} statuses
 * @returns array
 */
export function createColumnsByTaskStatus(tasks, statuses) {
  const sortedStatus = [statuses[0], statuses[1], statuses[4], statuses[2], statuses[3]];
  const groups = [];

  sortedStatus.map((status) => {
    const list = tasks.filter((task) => task.statusId === status.id);
    groups.push({
      id: `status-${status.id}`,
      title: i18next.t(`taskStatus.${status.id}`),
      tasks: list
    });
    return true;
  });

  return groups;
}

/**
 * Group tasks by assignedTo
 *
 * @param {array} tasks
 * @returns array
 */
export function createColumnsByTaskAssignedTo(tasks) {
  const groups = [];

  tasks.map((task) => {
    const { assignedTo } = task;
    if (assignedTo) {
      let group = groups.find((grp) => grp.id === `assignedTo-${assignedTo.userId}`);
      if (!group) {
        group = {
          id: `assignedTo-${assignedTo.userId}`,
          title: assignedTo.name,
          tasks: []
        };
        groups.push(group);
      }
      group.tasks.push(task);
    }
    return true;
  });

  return groups;
}

/**
 * Set page title for routes containers
 *
 * @param {*} type
 * @returns {string}
 */
export function setPageTitleForRoutesContainer(type) {
  let title = '';
  switch (type) {
    case 'involved':
      title = i18next.t('page.title.involvedRoutes');
      break;
    case 'all':
      title = i18next.t('page.title.allRoutes');
      break;
    case 'completed':
      title = i18next.t('page.title.completedRoutes');
      break;
    default:
      title = i18next.t('page.title.myRoutes');
      break;
  }

  return title;
}

/**
 * Checks if windowsize is in range
 *
 * @param {array} ranges
 * @returns isInRange
 */
export function checkScreenWidth(ranges) {
  let size = null;

  const {
    largeMax,
    largeMin,
    mediumMax,
    mediumMin,
    smallMax,
    smallMin,
    extraSmallMin,
    extraSmallMax
  } = breakpoints;

  const mediaQuery = (minWidth, maxWidth) => {
    return window.matchMedia(`(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`).matches;
  };

  if (mediaQuery(extraSmallMin, extraSmallMax)) {
    // > 480px
    size = 'extraSmall';
  } else if (mediaQuery(smallMin, smallMax)) {
    // 481px - 767px
    size = 'small';
  } else if (mediaQuery(mediumMin, mediumMax)) {
    // 768px - 1024px
    size = 'medium';
  } else if (mediaQuery(largeMin, largeMax)) {
    // 1025px - 1280px
    size = 'large';
  } else {
    // < 1281px
    size = 'extraLarge';
  }

  return Array.isArray(ranges) ? ranges.findIndex((range) => range === size) > -1 : ranges === size;
}

export function getPivotStyles(defaultStyle, ignoreScreenWidth = false) {
  const pivotStyles = {
    root: {
      backgroundColor: getTheme().palette.themePrimary,
      borderBottom: `3px solid ${getTheme().palette.themePrimary}`,
      color: 'white',
      paddingLeft: '10px'
    },
    text: {
      marginLeft: `${checkScreenWidth(['extraSmall']) && !ignoreScreenWidth ? null : '0px'}`,
      color: 'white'
    },
    icon: {
      paddingLeft: `${checkScreenWidth(['extraSmall']) && !ignoreScreenWidth ? '5px' : null}`,
      color: 'white'
    },
    link: {
      width: `${checkScreenWidth(['extraSmall']) && !ignoreScreenWidth ? '50px' : null}`,
      selectors: {
        ':hover': {
          backgroundColor: getTheme().palette.themePrimary
        },
        ':active': {
          backgroundColor: getTheme().palette.themePrimary
        },
        ':hover::before': {
          backgroundColor: 'white'
        }
      }
    },
    linkIsSelected: {
      width: `${checkScreenWidth(['extraSmall']) && !ignoreScreenWidth ? '50px' : null}`,
      selectors: {
        ':hover': {
          backgroundColor: getTheme().palette.themePrimary
        },
        ':active': {
          backgroundColor: getTheme().palette.themePrimary
        },
        ':hover::before': {
          backgroundColor: 'white'
        },
        ':before': {
          backgroundColor: 'white'
        }
      }
    }
  };
  return pivotStyles;
}

export function onOpenFile(file) {
  const downloadLink = document.createElement('a');
  fetchRequest({
    url: `File/Contents?id=${file.id}`,
    ignoreContentType: true
  })
    .then((response) => response.blob())
    .then((blob) => {
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        return navigator.msSaveOrOpenBlob(blob, file.name);
      }
      return URL.createObjectURL(blob);
    })
    .then((blobUrl) => {
      downloadLink.href = blobUrl;
      downloadLink.download = file.name;
      document.body.appendChild(downloadLink);
      if (typeof window.navigator.msSaveBlob === 'undefined') {
        downloadLink.click();
      }
      URL.revokeObjectURL(blobUrl);
    });
}

export function getTaskFormDocumentFields(task) {
  const documentFields = [];

  const addDocumentField = (fieldLink, groupIndex, fieldIndex, groupFieldIndex) => {
    const { field, value } = fieldLink;
    if (field.fieldType === 10 && value?.file) {
      // add documentFields
      documentFields.push({ ...fieldLink, groupIndex, fieldIndex, groupFieldIndex });
    } else if (field.fieldType === 15 && Array.isArray(value)) {
      // check multi media field
      value.forEach((mediaValue, mediaValueIndex) => {
        if (mediaValue.file) {
          documentFields.push({
            ...fieldLink,
            groupIndex,
            fieldIndex,
            groupFieldIndex,
            mediaValueIndex
          });
        }
      });
    }
  };

  if (task && task.groups) {
    task.groups.forEach((group, groupIndex) => {
      group.fields.forEach((fieldLink, fieldIndex) => {
        if (fieldLink.field?.id) {
          // check normal fields
          addDocumentField(fieldLink, groupIndex, fieldIndex);
        } else if (fieldLink.fieldGroup?.id && fieldLink.fieldGroup?.fields?.length) {
          // check fieldgroups
          fieldLink.fieldGroup.fields.forEach((groupFieldLink, groupFieldIndex) => {
            addDocumentField(groupFieldLink, groupIndex, fieldIndex, groupFieldIndex);
          });
        }
      });
    });

    return documentFields;
  }

  return documentFields;
}

export async function mapUploadedDocumentsAndFetchTask(documentFields, task, fetchTask) {
  const taskWithDocumentFields = { ...task };
  const uploadedDocuments = [];

  const uploadFile = (documentField, taskId) => {
    let file = null;

    if (documentField.mediaValueIndex > -1) {
      file = documentField.value[documentField.mediaValueIndex].file;
    } else {
      file = documentField.value.file;
    }

    // build body
    const body = new FormData();
    body.append('file', file, file.name);
    body.append('relatedTask', taskId);
    body.append('relatedField', documentField.field.id);

    // fetch files to upload
    return fetchRequest({
      url: 'File',
      method: 'POST',
      body,
      ignoreContentType: true
    })
      .then((response) => response.json())
      .then((uploadedFile) => {
        const file = {
          fieldIndex: documentField.fieldIndex,
          groupIndex: documentField.groupIndex,
          fieldId: documentField.fieldId,
          mediaValueIndex: documentField.mediaValueIndex,
          groupFieldIndex: documentField.groupFieldIndex,
          text: uploadedFile.name,
          url: uploadedFile.url
        };
        uploadedDocuments.push(file);

        return file;
      });
  };

  await documentFields.reduce((accumulatorPromise, documentField) => {
    return accumulatorPromise.then(() => {
      return uploadFile(documentField, task.id);
    });
  }, Promise.resolve());

  if (documentFields.length) {
    uploadedDocuments.map((document) => {
      let fieldLink = null;

      const {
        url,
        text,
        fieldId,
        groupFieldIndex,
        groupIndex,
        fieldIndex,
        mediaValueIndex
      } = document;

      if (groupFieldIndex) {
        fieldLink = {
          ...taskWithDocumentFields.groups[groupIndex].fields[fieldIndex].fieldGroup.fields[
            groupFieldIndex
          ]
        };
      } else {
        fieldLink = { ...taskWithDocumentFields.groups[groupIndex].fields[fieldIndex] };
      }

      if (fieldLink) {
        if (mediaValueIndex > -1) {
          if (Array.isArray(fieldLink.value)) {
            fieldLink.value[mediaValueIndex] = { url, text, fieldId };
          } else {
            fieldLink.value = [{ url, text, fieldId }];
          }
        } else {
          fieldLink.value = { url, text, fieldId };
        }

        if (groupFieldIndex) {
          taskWithDocumentFields.groups[groupIndex].fields[fieldIndex].fieldGroup.fields[
            groupFieldIndex
          ] = fieldLink;
        } else {
          taskWithDocumentFields.groups[groupIndex].fields[fieldIndex] = fieldLink;
        }
      }

      return null;
    });

    fetchTask(taskWithDocumentFields);
  } else {
    fetchTask(task);
  }
}

export function getTeamImageInitials(title) {
  const splitTitle = title.split(' ');
  let imageInitials = splitTitle[0][0];
  if (splitTitle[1]) {
    imageInitials += splitTitle[1][0];
  }
  return imageInitials;
}

// returns random different colors (for sets of colors)
export function getRandomColorForSet(numOfSteps, step) {
  let r = 0;
  let g = 0;
  let b = 0;
  const h = step / numOfSteps;
  // eslint-disable-next-line no-bitwise
  const i = ~~(h * 6);
  const f = h * 6 - i;
  const q = 1 - f;
  switch (i % 6) {
    case 0:
      r = 1;
      g = f;
      b = 0;
      break;
    case 1:
      r = q;
      g = 1;
      b = 0;
      break;
    case 2:
      r = 0;
      g = 1;
      b = q;
      break;
    case 3:
      r = 0;
      g = q;
      b = 1;
      break;
    case 4:
      r = f;
      g = 0;
      b = 1;
      break;
    case 5:
      r = 1;
      g = 0;
      b = q;
      break;
    default:
      break;
  }
  // eslint-disable-next-line no-bitwise
  const c = `#${`00${(~~(r * 255)).toString(16)}`.slice(-2)}${`00${(~~(g * 255)).toString(
    16
    // eslint-disable-next-line no-bitwise
  )}`.slice(-2)}${`00${(~~(b * 255)).toString(16)}`.slice(-2)}`;
  return c;
}

// usage: rainbowStop(0.5);
export function getRandomColorForSetStop(h) {
  const f = (n, k = (n + h * 12) % 12) => 0.5 - 0.5 * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  const rgb2hex = (r, g, b) =>
    `#${[r, g, b]
      .map((x) =>
        Math.round(x * 255)
          .toString(16)
          .padStart(2, 0)
      )
      .join('')}`;
  return rgb2hex(f(0), f(8), f(4));
}

// returns any random color
export function getRandomColor() {
  const chars = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += chars[Math.floor(Math.random() * 16)];
  }
  return color;
}

// colors for bar diagrams
export function colorsForDashboards() {
  return [
    '#A9A9A9', // grey for deleted
    '#639FB8',
    '#FFB5C1',
    '#9CE1FF',
    '#FFF982',
    '#B3AF64',
    '#9173B8',
    '#FFD69E',
    '#C79CFF',
    '#82FFA3',
    '#64B378',
    '#B88072',
    '#BAEB7A',
    '#FFAF9C',
    '#7D9EFF',
    '#8F99B5',
    '#B8B533',
    '#73BDEB',
    '#FFFD94',
    '#FF474A',
    '#C28889',
    '#A2FAC7',
    '#85478A',
    '#B9FF78',
    '#CCAF64',
    '#4143B8',
    '#FFEC3D',
    '#FF6D7C',
    '#CCAFD2',
    '#0697EA',
    '#8A6287',
    '#A2ED7B',
    '#CBB7E5',
    '#ED867B',
    '#67DFE6',
    '#D6BC7A',
    '#FF1F46',
    '#CFFFB8',
    '#29B5FF',
    '#E6C650',
    '#7978EB',
    '#61AD57',
    '#B6AFFA',
    '#A1FA96',
    '#FAAE7D',
    '#AD7E5F',
    '#4F82A8',
    '#F58E8C',
    '#8CC7F5',
    '#F3F573',
    '#C6C78D',
    '#995D92',
    '#E6D79C',
    '#E69CDD',
    '#85E6DD',
    '#97B8B5',
    '#F7F377',
    '#ABA746',
    '#61A7C9',
    '#F75E6D',
    '#C97D85',
    '#D6B3E8',
    '#8C6D9C',
    '#C6AC8F',
    '#81DB92',
    '#BCE8C4',
    '#31459E',
    '#ACDB81',
    '#B3BCE8',
    '#C79D8F',
    '#D1E8BC',
    '#FF392E',
    '#6DB2F2',
    '#B57774',
    '#C1D11B',
    '#148DFF',
    '#FFB58A',
    '#BAFFFF',
    '#FF554D',
    '#B3FF91',
    '#FF65F4',
    '#FFF763',
    '#9B63FF',
    '#C8FFF1',
    '#AC7DFF',
    '#C2B2AC',
    '#3B9963',
    '#ECFF85',
    '#7CCC6E',
    '#FF7165',
    '#6C9FCC',
    '#AC9EFF',
    '#79CDFF',
    '#8FFFBA',
    '#FF88EF',
    '#FFC276',
    '#70FFF4',
    '#FF9F9A',
    '#0391FF',
    '#FFDCAB',
    '#FFFD42'
  ];
}

export function getIndustryTypes() {
  const industryTypes = [];

  // create option out of i18n references and push option-object into industryTypes
  for (let key = 0; key <= 148; key += 1) {
    const id = `helpers.industry.${key}`;
    industryTypes.push({
      key,
      text: i18next.t(id)
    });
  }

  return industryTypes;
}

export function getEmployeesTypes() {
  const employeesTypes = [
    {
      key: 0,
      text: i18next.t('helpers.employees.unknown')
    },
    {
      key: 1,
      text: '1 - 10'
    },
    {
      key: 2,
      text: '11 - 50'
    },
    {
      key: 3,
      text: '51 - 200'
    },
    {
      key: 4,
      text: '201 - 500'
    },
    {
      key: 5,
      text: '501 - 1000'
    },
    {
      key: 6,
      text: '1001 - 5000'
    },
    {
      key: 7,
      text: '5001 - 10000'
    },
    {
      key: 8,
      text: '10000+'
    }
  ];

  return employeesTypes;
}

export function extractHostAddressFromUrl(url) {
  let hostname;
  let protocol;
  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    [protocol, , hostname] = url.split('/');
  }

  if (protocol && hostname) {
    const hostAddress = `${protocol}//${hostname}`;

    return hostAddress;
  }

  return null;
}

function createNumbersArray(from, length) {
  return Array.from(Array(length), (_, i) => i + from);
}

export function getActivityRedirectUrl(activity) {
  const { type, newTask, newTeam, routeInstance, routeDefinition, newProject } = activity;

  if (newTask) {
    const taskDetailsTypes = [
      ...[16, 30, 43, 51, 52, 55, 57, 59, 61, 63, 65, 67, 69, 91],
      ...createNumbersArray(1, 7), // 1 - 7
      ...createNumbersArray(45, 3), // 45 - 48,
      ...createNumbersArray(70, 3), // 70 - 73,
      ...createNumbersArray(76, 4) // 76 - 79,
    ];

    if (taskDetailsTypes.includes(type)) {
      return `/tasks/all/${newTask.id}`;
    }

    if ([8, 9, 10, 84, 85].includes(type)) {
      return `/tasks/all/${newTask.id}/conversation`;
    }

    if ([...createNumbersArray(11, 7), 82, 83].includes(type)) {
      return `/tasks/all/${newTask.id}/checklist`;
    }

    if ([18, 19, 20].includes(type)) {
      return `/tasks/all/${newTask.id}/attachments`;
    }

    if ([30, 32].includes(type)) {
      return `/tasks/all/${newTask.id}`;
    }

    if ([129, 130, 131, 132, 133, 137, 138, 139, 140].includes(type)) {
      return `/projects/all/task-list/${newTask.projectId}/${
        newTask.sprintId || '00000000-0000-0000-0000-000000000000'
      }/${newTask.id}`;
    }
  }

  if (newTeam && [...createNumbersArray(21, 9)].includes(type) && type !== 26) {
    return `/teams/${newTeam.id}`;
  }

  if (routeDefinition && [35, 36].includes(type)) {
    return `/process-designer/${routeDefinition.id}/${routeDefinition.version}`;
  }

  if (routeInstance && [38, 39, 41, 42].includes(type)) {
    return `/process-instances/all/${routeInstance.routeDefinitionId}/process-instance-tree/${routeInstance.id}`;
  }

  if (newProject) {
    if ([134, 135].includes(type)) {
      return `/projects/all/project-details/${newProject.id}/project`;
    }
  }

  return null;
}

export function getDateStrings() {
  return {
    goToToday: i18next.t(`dateTime.datePicker.goToToday`),
    months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) =>
      i18next.t(`dateTime.datePicker.month`, { context: m.toString() })
    ),
    shortMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) =>
      i18next.t(`dateTime.datePicker.month`, { context: m.toString() }).substring(0, 3)
    ),
    days: [0, 1, 2, 3, 4, 5, 6].map((d) =>
      i18next.t(`dateTime.datePicker.day`, { context: d.toString() })
    ),
    shortDays: [0, 1, 2, 3, 4, 5, 6].map((d) => {
      return i18next.t(`dateTime.datePicker.day`, { context: d.toString() }).substring(0, 2);
    })
  };
}
