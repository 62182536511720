import moment from 'moment';
import React from 'react';
import { Persona, PersonaSize, Text } from '@fluentui/react';
function SurveyResultDefaultValueWrapper(_a) {
    var onRenderContent = _a.onRenderContent, fieldResultProps = _a.fieldResultProps, _b = _a.renderBottomBorder, renderBottomBorder = _b === void 0 ? false : _b;
    var person = fieldResultProps.person;
    function renderPersonaCoin() {
        return (React.createElement("div", { style: { marginRight: 10 } },
            React.createElement(Persona, { size: PersonaSize.size32, hidePersonaDetails: true, imageUrl: person.pictureUrl, text: person.name || '' })));
    }
    function getDateFormat() {
        var dateFormatToDisplay = 'DD.MM.YYYY HH:mm';
        var currentYear = new Date().getFullYear();
        var commentCreationYear = new Date('01/01/2021').getFullYear();
        if (currentYear === commentCreationYear) {
            dateFormatToDisplay = 'DD.MM HH:mm';
        }
        return dateFormatToDisplay;
    }
    function renderHeader() {
        var dateFormat = getDateFormat();
        return (React.createElement("div", null,
            React.createElement(Text, { as: "span", styles: { root: { fontSize: 12, marginRight: 10, fontWeight: 600, color: '#252423' } } }, person.name || ''),
            React.createElement(Text, { as: "span", styles: { root: { fontSize: 12, marginRight: 10, color: '#484644' } } }, moment(fieldResultProps.editDate).format(dateFormat))));
    }
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement("div", { style: {
                width: '100%',
                borderBottom: renderBottomBorder ? '1px solid #d9d9d9' : undefined,
                display: 'flex',
                padding: "7px 12px 7px 12px"
            } },
            renderPersonaCoin(),
            React.createElement("div", { style: { width: '100%', position: 'relative' } },
                renderHeader(),
                onRenderContent(fieldResultProps)))));
}
export default SurveyResultDefaultValueWrapper;
