import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
function AggregationBlock(_a) {
    var _b, _c;
    var fieldLink = _a.fieldLink;
    var t = useTranslation().t;
    var aggregationBlockId = useId('aggregation-block');
    var averageResultValue = (_b = fieldLink.values) === null || _b === void 0 ? void 0 : _b.find(function (resultValue) { return resultValue.aggregation === 4; });
    var sumResultValue = (_c = fieldLink.values) === null || _c === void 0 ? void 0 : _c.find(function (resultValue) { return resultValue.aggregation === 2; });
    function renderHeader(type) {
        if (type === 'countNumberOfTasks') {
            return fieldLink.numberOfTasks;
        }
        if (type === 'sum') {
            var sum = void 0;
            if (sumResultValue) {
                sum = sumResultValue.value;
            }
            return sum;
        }
        var average;
        if (averageResultValue) {
            average = averageResultValue.value;
        }
        return average;
    }
    function renderFooter(type) {
        if (type === 'countNumberOfTasks') {
            return t('survey.results.answers');
        }
        if (type === 'sum') {
            return t('survey.results.number.sum');
        }
        return t('survey.results.number.average');
    }
    function renderBlock(type) {
        return (React.createElement("div", { key: aggregationBlockId },
            React.createElement("div", { className: "c-result-box" },
                React.createElement("div", { className: "c-result-box-value" }, renderHeader(type)),
                React.createElement("div", { className: "c-result-box-text" }, renderFooter(type)))));
    }
    return (React.createElement(Stack, { tokens: { childrenGap: 20 }, wrap: true, horizontal: true },
        renderBlock('countNumberOfTasks'),
        renderBlock(averageResultValue ? 'average' : 'sum')));
}
export default AggregationBlock;
