export var german = {
    languageTag: 'de-DE',
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function () {
        return '.';
    },
    spaceSeparated: true,
    currency: {
        symbol: '€',
        position: 'postfix',
        code: 'EUR'
    },
    currencyFormat: {
        totalLength: 4,
        thousandSeparated: true
    },
    formats: {
        fourDigits: {
            totalLength: 4,
            spaceSeparated: true,
            average: true
        },
        fullWithTwoDecimals: {
            output: 'currency',
            mantissa: 2,
            spaceSeparated: true,
            thousandSeparated: true
        },
        fullWithTwoDecimalsNoCurrency: {
            mantissa: 2,
            thousandSeparated: true
        },
        fullWithNoDecimals: {
            output: 'currency',
            spaceSeparated: true,
            thousandSeparated: true,
            mantissa: 0
        }
    }
};
