import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { loadTheme } from '@fluentui/react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import * as microsoftTeams from '@microsoft/teams-js';
import GlobalStyle from '../../index.styled';
import fetchRequest, { apiErrorHandler } from '../../services/api';
import { getDefaultTheme, isInIframe, isInMsTeamsEnvironment } from '../../utils/helpers';
import AppContext from './AppContext';
import Routing from './Routing';
import ExpiredTestPeriodDialog from './components/Global/ExpiredTestPeriodDialog';
import NewTenantDialog from './components/Global/NewTenantDialog';
import { evocomBeeLogo } from './components/Layout/Icons';

// start interval to keep server instance alive
setInterval(() => {
  try {
    fetch('Init/KeepAlive');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('keep alive', e);
  }
}, 600000);

function reducer(state, action) {
  switch (action.type) {
    case 'init':
      return { ...state, ...action.data };
    case 'searchTerm':
      return { ...state, searchTerm: action.data };
    case 'addTeam':
      return { ...state, teams: [action.data, ...state.teams] };
    case 'updateTeam': {
      const index = state.teams.findIndex((team) => team.id === action.data.id);

      state.teams.splice(index, 1, action.data);

      return { ...state, teams: state.teams };
    }
    case 'deleteTeam':
      return { ...state, teams: state.teams.filter((team) => team.id !== action.data.id) };
    case 'setTheme':
      return { ...state, theme: { ...state.theme, palette: action.data } };
    case 'usePlanner':
      return { ...state, theme: { ...state.theme, usePlanner: action.data } };
    case 'updateNotificationsCount':
      return { ...state, unreadNotifications: action.data };
    default:
      throw new Error();
  }
}

function AppContainer({ logout }) {
  const [loaded, setLoaded] = useState(false);

  const defaultTitle = isInMsTeamsEnvironment() ? 'Evocom' : 'Evocom Productivity';

  const [contentToShow, setContentToShow] = useState({
    tasks: false,
    processes: false,
    projects: false
  });
  const [initialized, setInitialized] = useState(false);
  const [initFetched, setInitFetched] = useState(false);
  const [showLoadingContent, setShowLoadingContent] = useState(false);
  const [showNewTenantDialog, setShowNewTenantDialog] = useState(false);

  const [globalAppState, dispatch] = useReducer(reducer, {
    searchTerm: null,
    theme: { palette: getDefaultTheme() }
  });

  const { t, i18n } = useTranslation(null);

  const noAccess = window.location.pathname.indexOf('access') === 1;
  const isMsTeamsConfigPage = window.location.href.includes('teams-config');

  let onBoardingActive;
  if (window.baseConfig) {
    ({ onBoardingActive } = window.baseConfig);
  }

  useEffect(() => {
    const { theme } = globalAppState;

    // set background image in localStorage for later usage
    if (theme?.background) {
      window.localStorage.setItem('background', theme.background);
    }

    loadTheme({ palette: theme.palette });
  }, [globalAppState]);

  useEffect(() => {
    if (isInIframe()) {
      microsoftTeams.initialize(() => {
        window.sessionStorage.setItem('msTeamsEnvironment', true);
      });
      microsoftTeams.getContext((context) => {
        const { locale } = context;

        if (locale) {
          i18n.changeLanguage(locale);
          window.localStorage.setItem('acceptLanguageHeader', locale);
        }

        const { channelId, entityId, chatId } = context;

        if ((channelId || chatId) && entityId) {
          const hasEntityDevider = entityId.includes('/');

          if (hasEntityDevider) {
            const showTasks = entityId.split('/')[0] === '1';
            const showProcesses = entityId.split('/')[1] === '1';
            const showProjects = entityId.split('/')[2] === '1';

            setContentToShow({
              tasks: showTasks,
              processes: showProcesses,
              projects: showProjects
            });
          }
        } else {
          setContentToShow({ tasks: true, processes: true, projects: true });
        }

        setInitialized(true);

        if (context.theme !== 'default') {
          // eslint-disable-next-line no-alert
          alert('Evocom is not yet configured for dark mode');
        }
      });

      setTimeout(() => {
        setInitialized(true);
      }, 1000);
    } else {
      setInitialized(true);
    }
  }, [i18n]);

  useEffect(() => {
    const acceptLanguageHeader = localStorage.getItem('acceptLanguageHeader');

    if (acceptLanguageHeader && !isInIframe()) {
      localStorage.removeItem('acceptLanguageHeader');
    }
  }, []);

  useEffect(() => {
    if (!noAccess && initialized && !initFetched) {
      const init = async () => {
        setInitFetched(true);

        const initQueryParam = window.localStorage.getItem('initQueryParam');
        const url = initQueryParam ? `Init/${initQueryParam}` : 'Init';

        fetchRequest({ url })
          .then((data) => {
            const localStorageTenantId = window.localStorage.getItem('tenantId');
            const localStorageVersion = JSON.parse(window.localStorage.getItem('version'));

            // load fabric theme
            const palette = { ...data.theme.palette };
            Object.keys(palette).forEach((key) => {
              // remove empty palette keys
              if (palette[key] === null) {
                delete palette[key];
              }
            });

            if (data.currentTenantId !== localStorageTenantId) {
              window.localStorage.setItem('tenantId', data.currentTenantId);
            }

            if (!localStorageVersion || localStorageVersion.number !== data.apiVersion) {
              window.localStorage.setItem(
                'version',
                JSON.stringify({ number: data.apiVersion, new: true })
              );
            }

            const currentTenant = data.tenants.find(
              (tenant) => tenant.tenantId === data.currentTenantId
            );

            const currentUser = {
              ...data.currentUser,
              isInternalUser: currentTenant?.directoryId === data.currentUser?.tenantId,
              isAdmin: data.permissions?.admin
            };

            dispatch({
              type: 'init',
              data: {
                ...data,
                currentUser,
                theme: {
                  ...data.theme,
                  title: data.theme.title || defaultTitle,
                  palette: { ...globalAppState.theme.palette, ...palette }
                }
              }
            });

            if (initQueryParam) {
              window.localStorage.removeItem('initQueryParam');
            }

            setLoaded(true);
            setShowNewTenantDialog(data.isNewTenant);
          })
          .catch(apiErrorHandler);
      };

      init();
    }
  }, [noAccess, initialized, defaultTitle, globalAppState.theme.palette, initFetched]);

  useEffect(() => {
    if (initialized && !loaded) {
      setTimeout(() => {
        // Wait 500 milliseconds before the loading content is displayed
        // otherwise there will be a flickering on every init call
        setShowLoadingContent(true);
      }, 500);
    }
  }, [initialized, loaded]);

  const headerText = defaultTitle;
  const subText = t('app.container.loading.subHeader');

  if (noAccess) {
    return <Routing currentUser={{}} />;
  }

  if (loaded && globalAppState.currentUser) {
    return (
      <AppContext.Provider value={{ globalAppState, dispatch }}>
        <ThemeProvider theme={globalAppState.theme.palette}>
          <GlobalStyle />
          <ExpiredTestPeriodDialog
            currentTenantId={window.localStorage.getItem('tenantId')}
            tenants={globalAppState.tenants}
          />
          <Routing contentToShow={contentToShow} logout={logout} />
          {!isMsTeamsConfigPage && showNewTenantDialog && onBoardingActive && <NewTenantDialog />}
        </ThemeProvider>
      </AppContext.Provider>
    );
  }

  if (showLoadingContent) {
    return (
      <div style={{ marginTop: '15vh', textAlign: 'center' }}>
        <div style={{ maxWidth: 'max-content', marginRight: 'auto', marginLeft: 'auto' }}>
          {evocomBeeLogo(120)}
        </div>
        <div
          style={{
            marginTop: '24px',
            fontFamily:
              'Segoe UI Semibold WestEuropean, Segoe UI Semibold, Segoe UI, Tahoma, Arial, sans-serif',
            fontSize: '28px'
          }}
        >
          {headerText}
        </div>
        <div
          style={{
            marginTop: '24px',
            fontFamily: 'Segoe UI Web Regular, Segoe UI, Segoe WP, Tahoma, Arial, sans-serif',
            fontSize: '17px'
          }}
        >
          {subText}
        </div>
        <div style={{ marginTop: '40px' }}>
          <Spinner
            styles={{
              root: { color: '#004e9e' },
              circle: { color: '#004e9e' },
              label: { color: '#004e9e' }
            }}
            size={SpinnerSize.large}
            label={t('app.container.loading.spinner')}
          />
        </div>
      </div>
    );
  }

  return null;
}

AppContainer.propTypes = {
  logout: PropTypes.func
};

AppContainer.defaultProps = {
  logout: null
};

export default AppContainer;
